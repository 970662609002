export interface DirectusReviewModel {
  id: string;
  user_id: string;
  date_created: string;
  race_date_func: {
    year: number;
    month: number;
  };
  user_display_name: string;
  review: string;
  distance_review: string;
  rating: number;
  user_country: string;
  distance: number;
}

export const REVIEW_MOBILE_SLIDER = 'reviewMobileSlider';
export const REVIEW_TOP_SECTION = 'reviewTopSection';
export const WRITE_REVIEW_COOKIE_KEY = 'writeReview';
