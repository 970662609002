import clsx from 'clsx';
import Link from 'next/link';
import { TFunction } from 'next-i18next';
import React from 'react';
import { Trans } from 'react-i18next';

import { useSnackbarContext } from '~/src/contexts/SnackbarProvider/SnackbarProvider';

export const Snackbar = () => {
  const { snackbarMessage, isError } = useSnackbarContext();
  return (
    <aside
      className={clsx(
        'fixed z-50 max-w-lg flex items-center rounded-md bg-white h-12 top-2 right-2 shadow-30 transition-all',
        snackbarMessage ? 'mr-0' : '-mr-128' // -mr-128 is our custom css class
      )}>
      <i className={clsx('block w-3 h-full flex-grow-0 rounded-l-md', isError ? 'bg-red-100' : 'bg-green-70')} />
      <div className="flex-grow justify-center p-5">{snackbarMessage}</div>
    </aside>
  );
};

interface ToastLink {
  name: string;
  path: string;
}

export function getLinkMessage(t: TFunction, key: string, link: ToastLink): React.ReactElement {
  return (
    <Trans
      className="flex-grow justify-center p-5"
      components={[<CustomLink key="0" link={link} />]}
      t={t}
      i18nKey={key}
    />
  );
}

function CustomLink({ link }: { link: ToastLink }) {
  return (
    <Link href={link.path}>
      <a className="underline">{link.name}</a>
    </Link>
  );
}
