import clsxm from '../../lib/clsxm';
import { EmptyOutlinedRatingStar, EmptyRatingStar, FullRatingStar } from '../icons/ratingStar';

interface RatingStarSelectProps {
  rating?: number;
  starClassName: string;
  isEmpty?: boolean;
  onClick?: (x) => any;
}

const RatingStarSelect = ({ rating, starClassName, onClick }: RatingStarSelectProps) => {
  return rating ? (
    <div className="flex hover:cursor-pointer w-fit">
      {Array(5)
        .fill(1)
        .map((_, idx) =>
          idx < rating ? (
            <FullRatingStar key={'fullStar' + idx} className={starClassName} onClick={() => onClick(idx + 1)} />
          ) : (
            <EmptyRatingStar key={'emptyStar' + idx} className={starClassName} onClick={() => onClick(idx + 1)} />
          )
        )}
    </div>
  ) : (
    <div className="flex flex-row-reverse justify-end hover:cursor-pointer">
      {Array(5)
        .fill(5)
        .map((_, idx) => (
          <EmptyOutlinedRatingStar
            onClick={() => onClick(5 - idx)}
            key={idx}
            className={clsxm(
              'hover:cursor-pointer peer peer-hover:fill-[#FFC700] hover:fill-[#FFC700] stroke-gray-50 peer-hover:stroke-[#FFC700] hover:stroke-[#FFC700]',
              starClassName
            )}
          />
        ))}{' '}
    </div>
  );
};

export default RatingStarSelect;
