import Link from 'next/link';
import { useRouter } from 'next/router';
import { FC, HTMLAttributes, PropsWithChildren } from 'react';

import clsxm from '../../lib/clsxm';
import * as gtag from '../../lib/ga4/gtag';
import { getLocalizedProperty, getLocalizedPropertyWithinKey } from '../../lib/localization';
import LanguageRef from '../../references/language';
import { routes } from '../../utils/routes';
import { Paragraph } from '../atoms/Paragraph';
import { LocationIcon } from '../icons/icons';
import { ISearchResultData, SearchBoxUsage } from './SearchBox';

interface ISearchBoxResult {
  data: {
    index: number;
    result: ISearchResultData;
  };
  showAll?: boolean;
  className?: string;
  dispatch: (x) => void;
  usage: SearchBoxUsage;
}

function getOpenSearchLocalizedFieldValue(data: object, key: string, locale: string): string | null {
  const localeKey = LanguageRef[locale].names.en.toLowerCase();
  const enKey = LanguageRef['en'].names.en.toLowerCase();

  return data[`${key}.${localeKey}`] || data[key] || data[`${key}.${enKey}`] || '';
}

const SearchBoxResult: FC<PropsWithChildren<ISearchBoxResult & HTMLAttributes<HTMLDivElement>>> = ({
  data,
  showAll = false,
  className,
  dispatch,
  usage
}: ISearchBoxResult) => {
  const { locale } = useRouter();
  const hasMatch = (string: string) => {
    if (showAll) return true;
    return string?.match('strong') ? true : false;
  };
  const { result, index } = data;
  const { eventName, raceNames, city, country } = {
    eventName: getOpenSearchLocalizedFieldValue(result, 'eventName', locale),
    raceNames: getOpenSearchLocalizedFieldValue(result, 'raceNames', locale),
    city: getLocalizedPropertyWithinKey(result, 'city', locale),
    country: getLocalizedPropertyWithinKey(result, 'country', locale)
  };

  const onResultOver = () => {
    dispatch({ type: 'select', payload: index });
  };

  const sendGA4Event = () => {
    gtag.sendEvent('click_search_box_result', { event_id: result.id });
  };

  return (
    <div onClick={sendGA4Event}>
      <Link href={getLocalizedProperty(routes.event, locale) + '/' + result?.id} passHref>
        <a>
          <div
            className={clsxm(
              'items-center lg:w-full lg:flex lg:flex-row lg:justify-between px-8 py-4 cursor-pointer ',
              usage === 'homePageHeading' && 'px-20',
              className
            )}
            onMouseOver={onResultOver}>
            <div className="flex flex-col mb-2 lg:justify-center w-full lg:mb-0 lg:w-[360px] lg:h-12">
              <Paragraph
                size="p2"
                dangerouslySetInnerHTML={{ __html: getOpenSearchLocalizedFieldValue(result, 'eventName', locale) }}
                className="mb-0 flex-wrap text-left max-w-full line-clamp-2 lg:line-clamp-1 break-normal"></Paragraph>
              {eventName != getOpenSearchLocalizedFieldValue(result, 'eventName', locale) && (
                <Paragraph
                  dangerouslySetInnerHTML={{ __html: `(${eventName})` }}
                  size="p3"
                  className="mb-0 text-left line-clamp-2 lg:line-clamp-1 break-normal"></Paragraph>
              )}
              {hasMatch(raceNames) && eventName != raceNames && (
                <Paragraph
                  dangerouslySetInnerHTML={{ __html: raceNames }}
                  size="p3"
                  className="mb-0 text-left line-clamp-2 lg:line-clamp-1 break-normal"></Paragraph>
              )}
            </div>
            <div className="flex justify-start items-center lg:w-2/5 text-left lg:max-w-[185px]">
              <div className="h-5 w-5 mr-2">
                <LocationIcon className="h-full w-auto" fill="#202228" />
              </div>
              <Paragraph
                size="p3"
                dangerouslySetInnerHTML={{ __html: `${city}${country ? ` - ${country}` : ''}` }}
                className="mb-0 line-clamp-2 break-normal"></Paragraph>
            </div>
          </div>
        </a>
      </Link>
    </div>
  );
};
export default SearchBoxResult;
