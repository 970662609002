import Reviews from '../types/models/Reviews';

export interface CreateReviewsResponse {
  status: number;
  review: Reviews;
}

//Save the reviews using prisma
export const createReview = async (payload: Reviews): Promise<CreateReviewsResponse> => {
  const response = await fetch('/api/models/reviews', {
    method: 'POST',
    body: JSON.stringify(payload)
  });

  return { status: response.status, review: await response.json() };
};
