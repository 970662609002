const colors = require('tailwindcss/colors');
const { default: AhotuUI } = require('./src/components/AhotuUI');
/** @type {import('tailwindcss').Config} */
module.exports = {
  darkMode: ['class'],
  content: [
    './src/pages/**/*.{js,ts,jsx,tsx}',
    './src/components/**/*.{js,ts,jsx,tsx}',
    './src/templates/**/*.{js,ts,jsx,tsx}',
    'node_modules/preline/dist/*.js',
    './src/components/shadcn/**/*.{ts,tsx}'
  ],
  theme: {
    colors: {
      ...{
        border: 'hsl(var(--border))',
        input: 'hsl(var(--input))',
        ring: 'hsl(var(--ring))',
        background: 'hsl(var(--background))',
        foreground: 'hsl(var(--foreground))',
        primary: {
          DEFAULT: 'hsl(var(--primary))',
          foreground: 'hsl(var(--primary-foreground))'
        },
        secondary: {
          DEFAULT: 'hsl(var(--secondary))',
          foreground: 'hsl(var(--secondary-foreground))'
        },
        destructive: {
          DEFAULT: 'hsl(var(--destructive))',
          foreground: 'hsl(var(--destructive-foreground))'
        },
        muted: {
          DEFAULT: 'hsl(var(--muted))',
          foreground: 'hsl(var(--muted-foreground))'
        },
        accent: {
          DEFAULT: 'hsl(var(--accent))',
          foreground: 'hsl(var(--accent-foreground))'
        },
        popover: {
          DEFAULT: 'hsl(var(--popover))',
          foreground: 'hsl(var(--popover-foreground))'
        },
        card: {
          DEFAULT: 'hsl(var(--card))',
          foreground: 'hsl(var(--card-foreground))'
        }
      },
      ...AhotuUI.palette,
      transparent: 'transparent',
      current: 'currentColor',
      fullblack: '#000000',
      black: '#353E44',
      white: colors.white,
      softblack: '#202228',
      gray: {
        ...colors.gray,
        5: '#F9F9F9',
        10: '#F3F5F6',
        20: '#EBEEEF',
        30: '#D1D7DB',
        40: '#B3B8BC',
        50: '#7F8589',
        70: '#585F63',
        80: '#3F484D',
        90: '#353E44',
        900: '#353E44'
      },
      indigo: colors.indigo,
      yellow: { ...colors.amber, 20: '#FFF9C8' },
      orange: colors.orange,
      blue: {
        ...colors.blue,
        5: '#F1F9FC',
        10: '#F1F9FC',
        70: '#3DADDC',
        80: '#2394C3'
      },
      red: {
        20: '#FCD9E0',
        100: '#E40936'
      },
      purple: {
        10: '#F8EDFF',
        20: '#ECDCF6'
      },
      green: {
        5: '#EAFFF4',
        10: '#D4FDE6',
        20: '#97EBBD',
        50: '#0CD693',
        70: '#00C282',
        80: '#01AD6F'
      }
    },
    underlineOffset: {
      sm: '2px',
      md: '5px',
      xl: '24px'
    },
    screens: {
      sm: '640px',
      md: '768px',
      lg: '1024px',
      xl: '1280px',
      '2xl': '1400px',
      '4xl': '1800px'
    },
    fontFamily: {
      sans: ['Inter', 'Helvetica', 'Arial'],
      body: ['Inter', 'Helvetica', 'Arial'],
      roboto: ['Roboto']
    },
    fontSize: {
      xxs: ['11px', '16px'],
      xs: ['12px', '18px'],
      sm: ['14px', '20px'],
      base: ['16px', '24px'],
      lg: ['18px', '28px'],
      xl: ['20px', '28px'],
      '2xl': ['24px', '28px'],
      '3xl': ['32px', '40px'],
      '4xl': ['40px', '48px'],
      '5xl': ['56px', '60px'],
      '6xl': ['64px', '70px']
    },
    fontWeight: {
      normal: 400,
      medium: 500,
      semibold: 600,
      bold: 700
    },
    extend: {
      keyframes: {
        'accordion-down': {
          from: { height: '0' },
          to: { height: 'var(--radix-accordion-content-height)' }
        },
        'accordion-up': {
          from: { height: 'var(--radix-accordion-content-height)' },
          to: { height: '0' }
        }
      },
      animation: {
        'accordion-down': 'accordion-down 0.2s ease-out',
        'accordion-up': 'accordion-up 0.2s ease-out'
      },
      gridTemplateColumns: {
        accordion: '1fr 48px'
      },
      height: {
        30: '7.5rem', //120px
        42: '10.5rem',
        68: '17rem', // 272px
        26: '6.5rem', //104px
        130: '32rem', // 512px
        107: '27rem', // around 428px
        110: '27.5rem', // 440px
        150: '37rem', // 592px
        160: '40rem'
      },
      width: {
        185: '46.25rem', //740px
        81: '20.25rem' //324px
      },
      boxShadow: {
        x: '0px 4px 4px rgba(0, 0, 0, 0.25)',
        30: '0px 3px 10px rgba(0, 0, 0, 0.09)',
        50: '0px 4px 16px rgba(0, 0, 0, 0.12)',
        toggle: '0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);'
      },
      blur: {
        xxs: '1px',
        xs: '2px'
      },
      minWidth: {
        74: '18.5rem',
        100: '25rem'
      },
      maxWidth: {
        'ahotu-section': '90rem', // 1440px
        100: '25rem' //400px
      },
      padding: {
        22: '5.5rem', //88px
        30: '7.5rem' //120px
      },
      margin: {
        18: '4.5rem', //72px
        22: '5.5rem', //88px
        26: '6.5rem' //104px
      },
      minHeight: {
        42: '10.5rem',
        50: '12.5rem'
      }
    }
  },
  extend: {
    spacing: {
      128: '32rem',
      144: '36rem',
      160: '40rem'
    },

    extend: {
      spacing: {
        128: '32rem',
        144: '36rem',
        160: '40rem'
      },
      borderRadius: {
        default: '0.5rem',
        md: '0.5rem',
        lg: '0.5 rem'
      }
    }
  },
  plugins: [
    require('tailwind-scrollbar-hide'),
    require('daisyui'),
    require('tailwindcss-animate'),
    require('@tailwindcss/line-clamp')
  ],
  daisyui: {
    logs: false,
    themes: [
      {
        'ahotu-light': {
          primary: AhotuUI.palette.ahotuPrimary,
          secondary: AhotuUI.palette.ahotuSecondary,
          neutral: AhotuUI.palette.ahotuNeutral,
          success: AhotuUI.palette.ahotuSuccess,
          info: AhotuUI.palette.ahotuInfo,
          warning: AhotuUI.palette.ahotuWarning,
          error: AhotuUI.palette.ahotuError
        }
      }
    ]
  }
};
