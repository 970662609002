import { createContext, useCallback, useContext, useMemo, useRef } from 'react';

const scrollContext = createContext(null);

export function ScrollRefProvider({ children }) {
  const scrollRef = useRef<number>(0);
  const fromSectionRef = useRef<string>('');

  const updateRefs = useCallback(({ scrollRef: newScrollRef, fromSectionRef: newFromSectionRef }) => {
    scrollRef.current = newScrollRef;
    fromSectionRef.current = newFromSectionRef;
  }, []);

  const resetRefs = useCallback(() => {
    scrollRef.current = 0;
    fromSectionRef.current = '';
  }, []);

  const memoizedValue = useMemo(
    () => ({
      scrollRef: scrollRef?.current,
      fromSectionRef: fromSectionRef?.current,
      updateRefs,
      resetRefs
    }),
    [updateRefs, resetRefs]
  );

  return <scrollContext.Provider value={memoizedValue}>{children}</scrollContext.Provider>;
}

export const useScrollRef = () => useContext(scrollContext);
