import { Listbox } from '@headlessui/react';

import clsxm from '../../../lib/clsxm';
import { ListData } from '../../../types';

interface AhotuFormMultiSelectProps {
  options: Partial<ListData>[];
  selectedOptions: any;
  multiple?: boolean;
  className?: string;
  OptionComponent?: any;
  onChange: (x: ListData[] | ListData) => void;
}
const AhotuFormMultiSelect = ({
  options,
  selectedOptions,
  multiple = false,
  className = '',
  onChange,
  OptionComponent
}: AhotuFormMultiSelectProps) => {
  return (
    <div className="ah-form-group relative">
      <Listbox value={selectedOptions} by="value" onChange={onChange} multiple={multiple}>
        <Listbox.Button className={clsxm('ah-form-control ah-form-select text-left  line-clamp-1', className)}>
          {OptionComponent ? (
            <OptionComponent option={selectedOptions} />
          ) : (
            <div className="line-clamp-1">
              {multiple
                ? (selectedOptions as ListData[]).map(option => option.text).join(', ')
                : (selectedOptions as ListData)?.text}
            </div>
          )}
        </Listbox.Button>
        <Listbox.Options className="absolute bg-white w-full z-10 shadow-50 max-h-[200px] overflow-y-scroll rounded-lg">
          {options?.map(option => (
            <Listbox.Option key={`race-label-${option.key}`} value={option}>
              {({ active, selected }) => (
                <div className={clsxm('block p-4 w-full', selected && 'font-bold', active && 'bg-green-5 rounded-md')}>
                  {OptionComponent ? <OptionComponent option={option} showOverflow={true} /> : option.text}
                </div>
              )}
            </Listbox.Option>
          ))}
        </Listbox.Options>
      </Listbox>
    </div>
  );
};

AhotuFormMultiSelect.displayName = 'AhotuFormMlutiSelect';
export default AhotuFormMultiSelect;
