import { useRouter } from 'next/router';
import { useSession } from 'next-auth/react';
import React, { createContext, useCallback, useEffect, useMemo, useState } from 'react';
import { useContext } from 'react';

import { useSnackbarContext } from '~/src/contexts/SnackbarProvider/SnackbarProvider';
import { getMemberData } from '~/src/lib/organiser/getMemberData';

type User = any;
// Define the context type
interface UserContextType {
  user: User | null;
  setUserData: (data: User) => void;
}

// Create the context
const UserContext = createContext<UserContextType | undefined>(undefined);

// Create a provider component
export const UserProvider = ({ children }) => {
  const [user, setUser] = useState<User | null>(null);
  const { queue } = useSnackbarContext();
  const { data: session } = useSession();
  const router = useRouter();

  const setUserData = useCallback((data: User) => {
    setUser(data);
  }, []);

  const fetchUserData = useCallback(async () => {
    if (session) {
      const userData = await getMemberData(session, router.locale);
      if (userData === 404) {
        queue('User not found', true);
        return;
      }
      setUserData(userData);
    }
  }, [session, router.locale, setUserData, queue]);

  useEffect(() => {
    fetchUserData();
  }, [fetchUserData]);

  const memoizedValue = useMemo(
    () => ({
      user,
      setUserData
    }),
    [user, setUserData]
  );

  return <UserContext.Provider value={memoizedValue}>{children}</UserContext.Provider>;
};

// Custom hook to use the UserContext
export const useUserContext = () => {
  const context = useContext(UserContext);
  if (context === undefined) {
    throw new Error('useUserContext must be used within a UserProvider');
  }
  return context;
};
