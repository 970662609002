import { forwardRef, InputHTMLAttributes, ReactElement } from 'react';

import clsxm from '../../../lib/clsxm';

type ConditionalProps =
  | {
      id?: never;
      label?: never;
    }
  | {
      id: string;
      label: string | ReactElement;
    };

type Props = {
  className?: string;
  type?: 'checkbox' | 'radio';
} & ConditionalProps &
  InputHTMLAttributes<HTMLInputElement>;

const AhotuCheck = forwardRef<HTMLInputElement, Props>(
  ({ className, label, children, type = 'checkbox', ...rest }: Props, ref) => {
    const classNames = clsxm('ah-form-check', className);

    return (
      <div className={classNames}>
        <input type={type} {...rest} ref={ref} />
        {label && <label htmlFor={rest.id}>{label}</label>}
        <div className="text-xs line-clamp-1">{children}</div>
      </div>
    );
  }
);

AhotuCheck.displayName = 'AhotuCheck';

export default AhotuCheck;
