import upperCase from 'lodash/upperCase';
import { useTranslation } from 'next-i18next';
import React from 'react';

import clsxm from '../../lib/clsxm';
import { Paragraph } from '../atoms/Paragraph';
import { ISearchResultData, SearchBoxUsage } from './SearchBox';
import SearchBoxResult from './SearchBoxResult';

interface ITopSearchProps {
  topSearch: ISearchResultData[];
  state: {
    selectedIndex: number;
  };
  dispatch: (x) => void;
  usage: SearchBoxUsage;
}

const TopSearch = ({ topSearch, state, dispatch, usage }: ITopSearchProps) => {
  const { t } = useTranslation();

  return (
    <>
      <Paragraph size="p2" className={clsxm('py-4 px-8 mb-0', usage === 'homePageHeading' && 'px-20')}>
        {upperCase(t('common:top-search'))}
      </Paragraph>
      {topSearch.map((result, index) => {
        return (
          <SearchBoxResult
            key={index}
            data={{ result, index }}
            dispatch={dispatch}
            className={clsxm(index === state.selectedIndex ? 'bg-gray-10' : '')}
            usage={usage}
          />
        );
      })}
    </>
  );
};

export default React.memo(TopSearch);
