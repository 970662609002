import clsx from 'clsx';

type Props = {
  children: JSX.Element | JSX.Element[];
  className?: string;
  horizontal?: boolean;
};

const AhotuFormGroup = ({ className, children, horizontal = false }: Props) => {
  const classNames = clsx(
    'ah-form-group',
    horizontal && 'group-horizontal sm:grid sm:grid-cols-12 sm:gap-4',
    className
  );

  return <div className={classNames}>{children}</div>;
};

export default AhotuFormGroup;
