import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';

import { AhotuButton } from '../atoms/AhotuButton';
import { Heading } from '../atoms/Heading';

interface SignUpBoxProps {
  onClose?: () => void;
}

const SignUpBox = ({ onClose }: SignUpBoxProps) => {
  const router = useRouter();
  const { t } = useTranslation();
  const onCreate = () => {
    onClose && onClose();
    router.push('members/registration');
  };
  return (
    <div className="bg-gray-10 flex flex-col justify-center items-center py-6 px-4 space-y-6 rounded-xl">
      <Heading headingLevel="h6">{t('session:no-account')}?</Heading>
      <AhotuButton onClick={onCreate} type="button" variant="stroke" text={t('session:create-account')} />
    </div>
  );
};

export default SignUpBox;
