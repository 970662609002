import clsx from 'clsx';
import { HTMLAttributes } from 'react';

type ConditionalProps =
  | {
      overlay?: never;
      position?: never;
    }
  | {
      overlay: true;
      position: 'left' | 'right';
    };

type Props = ConditionalProps & HTMLAttributes<HTMLSpanElement>;

const AhotuInputGroupText = ({ children, className, overlay, position, ...rest }: Props) => {
  const classNames = clsx(
    'ah-input-group-text',
    overlay && (position === 'left' ? 'overlay-left' : 'overlay-right'),
    className
  );

  return (
    <span className={classNames} {...rest}>
      {children}
    </span>
  );
};

export default AhotuInputGroupText;
