import { Icon } from '../../types/Icon';

export const FacebookIcon: Icon = ({ className, fill = '#000000' }) => (
  <svg viewBox="0 0 36 36" className={className} fill={fill} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M35.143 18.105C35.143 8.579 27.468.857 18 .857 8.533.857.857 8.579.857 18.105c0 8.608 6.27 15.744 14.465 17.038V23.09h-4.353v-4.985h4.353v-3.8c0-4.323 2.56-6.71 6.475-6.71 1.875 0 3.837.336 3.837.336v4.245h-2.161c-2.13 0-2.794 1.33-2.794 2.693v3.236h4.754l-.76 4.985H20.68v12.053c8.195-1.294 14.464-8.43 14.464-17.038Z"
      fill="#353E44"
    />
  </svg>
);

export const InstagramIcon: Icon = ({ className, fill = '#000000' }) => (
  <svg viewBox="0 0 36 36" className={className} fill={fill} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.9.977c-1.824.086-3.07.377-4.158.805a8.39 8.39 0 0 0-3.033 1.982 8.397 8.397 0 0 0-1.971 3.039C1.316 7.894 1.03 9.14.949 10.966c-.08 1.825-.098 2.411-.09 7.067.01 4.655.03 5.238.118 7.067.088 1.824.378 3.07.805 4.159a8.397 8.397 0 0 0 1.983 3.032 8.388 8.388 0 0 0 3.041 1.972c1.09.42 2.337.708 4.162.788 1.825.08 2.412.099 7.066.09 4.654-.01 5.24-.03 7.069-.117 1.828-.086 3.067-.379 4.156-.804a8.401 8.401 0 0 0 3.033-1.983 8.401 8.401 0 0 0 1.97-3.04c.423-1.091.71-2.338.79-4.162.08-1.83.098-2.413.09-7.068-.01-4.655-.03-5.238-.117-7.066-.087-1.829-.378-3.07-.805-4.16a8.414 8.414 0 0 0-1.983-3.033 8.366 8.366 0 0 0-3.04-1.97c-1.09-.422-2.337-.71-4.162-.789C23.211.87 22.623.85 17.968.86c-4.656.009-5.239.029-7.068.118Zm.2 30.99c-1.67-.073-2.578-.35-3.183-.583a5.337 5.337 0 0 1-1.974-1.278c-.603-.597-.973-1.17-1.286-1.969-.235-.605-.518-1.511-.596-3.183-.085-1.806-.103-2.349-.113-6.925-.01-4.577.008-5.119.087-6.926.071-1.67.35-2.579.583-3.183a5.309 5.309 0 0 1 1.278-1.974 5.292 5.292 0 0 1 1.97-1.286c.604-.236 1.51-.517 3.18-.596 1.809-.086 2.35-.103 6.927-.113 4.576-.01 5.119.007 6.928.087 1.67.073 2.579.35 3.182.583.802.308 1.372.679 1.974 1.278a5.29 5.29 0 0 1 1.287 1.97c.236.602.516 1.509.595 3.18.086 1.808.106 2.35.114 6.926.008 4.576-.008 5.12-.087 6.926-.073 1.671-.35 2.58-.583 3.185-.309.8-.68 1.371-1.28 1.973a5.295 5.295 0 0 1-1.968 1.286c-.604.235-1.511.517-3.18.596-1.808.085-2.35.103-6.928.113-4.578.01-5.118-.009-6.926-.087m13.975-23.13a2.058 2.058 0 1 0 4.115-.006 2.058 2.058 0 0 0-4.115.007Zm-15.878 9.18a8.803 8.803 0 1 0 17.604-.037 8.803 8.803 0 0 0-17.604.037Zm3.088-.006a5.714 5.714 0 1 1 11.428-.023 5.714 5.714 0 0 1-11.428.023Z"
      fill="#353E44"
    />
  </svg>
);

export const TwitterIcon: Icon = ({ className, fill = '#000000' }) => (
  <svg viewBox="0 0 28 28" fill={fill} className={className} xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M26 14C26 20.6274 20.6274 26 14 26C7.37258 26 2 20.6274 2 14C2 7.37258 7.37258 2 14 2C20.6274 2 26 7.37258 26 14ZM20.0361 12.098C20.0361 15.92 17.1261 20.33 11.8041 20.33C10.1721 20.33 8.64808 19.85 7.38208 19.022C7.61008 19.052 7.83808 19.064 8.07208 19.064C9.42808 19.064 10.6761 18.602 11.6661 17.828C10.4001 17.804 9.33208 16.97 8.96608 15.818C9.14608 15.854 9.32608 15.872 9.51208 15.872C9.77608 15.872 10.0341 15.836 10.2741 15.77C8.94808 15.5 7.95208 14.336 7.95208 12.932V12.896C8.34208 13.112 8.78608 13.244 9.26008 13.256C8.48008 12.74 7.97008 11.852 7.97008 10.85C7.97008 10.322 8.11408 9.824 8.36008 9.398C9.78808 11.15 11.9181 12.302 14.3241 12.422C14.2701 12.212 14.2461 11.99 14.2461 11.762C14.2461 10.166 15.5421 8.87 17.1381 8.87C17.9721 8.87 18.7221 9.218 19.2501 9.78199C19.9101 9.64999 20.5281 9.41 21.0861 9.08C20.8701 9.758 20.4141 10.322 19.8141 10.682C20.4021 10.61 20.9601 10.454 21.4761 10.226C21.0861 10.808 20.5941 11.318 20.0301 11.726C20.0361 11.846 20.0361 11.972 20.0361 12.098Z"
      fill="#353E44"
    />
  </svg>
);

export const GoogleIcon: Icon = ({ className }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className={className}>
    <path
      d="M22.56 12.25c0-.78-.07-1.53-.2-2.25H12v4.26h5.92c-.26 1.37-1.04 2.53-2.21 3.31v2.77h3.57c2.08-1.92 3.28-4.74 3.28-8.09z"
      fill="#4285F4"
    />
    <path
      d="M12 23c2.97 0 5.46-.98 7.28-2.66l-3.57-2.77c-.98.66-2.23 1.06-3.71 1.06-2.86 0-5.29-1.93-6.16-4.53H2.18v2.84C3.99 20.53 7.7 23 12 23z"
      fill="#34A853"
    />
    <path
      d="M5.84 14.09c-.22-.66-.35-1.36-.35-2.09s.13-1.43.35-2.09V7.07H2.18C1.43 8.55 1 10.22 1 12s.43 3.45 1.18 4.93l2.85-2.22.81-.62z"
      fill="#FBBC05"
    />
    <path
      d="M12 5.38c1.62 0 3.06.56 4.21 1.64l3.15-3.15C17.45 2.09 14.97 1 12 1 7.7 1 3.99 3.47 2.18 7.07l3.66 2.84c.87-2.6 3.3-4.53 6.16-4.53z"
      fill="#EA4335"
    />
    <path d="M1 1h22v22H1z" fill="none" />
  </svg>
);

export const StravaIcon: Icon = ({ className, fill = '#202228' }) => (
  <svg xmlns="http://www.w3.org/2000/svg" className={className} fill="none" viewBox="0 0 28 29">
    <path
      fill={fill}
      fillRule="evenodd"
      d="M19.296 16.318 11.998 1.799 4.468 16.318H8.94L12 10.556l2.824 5.762h4.472Zm-1.989 10.37-6.484-10.37h3.89l2.594 3.918 2.334-3.918h3.89l-6.224 10.37Z"
      clipRule="evenodd"
    />
  </svg>
);

export const TikTok: Icon = ({ className }) => (
  <svg xmlns="http://www.w3.org/2000/svg" data-name="Lager 1" viewBox="0 0 30 30" className={className}>
    <path d="M15 2.62C8.16 2.62 2.62 8.16 2.62 15S8.16 27.38 15 27.38 27.38 21.84 27.38 15 21.84 2.62 15 2.62Zm2.88 9.03v5.75c0 1.28-.5 2.49-1.41 3.39-.9.9-2.12 1.41-3.39 1.41s-2.49-.5-3.39-1.41c-.9-.9-1.41-2.12-1.41-3.39s.5-2.49 1.41-3.39a4.8 4.8 0 0 1 3.39-1.41h.89v2.52h-.89c-.61 0-1.18.24-1.61.67-.43.43-.67 1-.67 1.61s.24 1.18.67 1.61c.43.43 1 .67 1.61.67s1.18-.24 1.61-.67c.43-.43.67-1 .67-1.6V7.79h2.51l.13.23c.39.72.92 1.41 1.57 1.92.51.39 1.08.67 1.71.78l.45.08-.48 2.43-.43-.08c-.51-.09-1.01-.25-1.48-.47a4.79 4.79 0 0 1-1.45-1.03Z" />
  </svg>
);

export const YoutubeIcon: Icon = ({ className, fill = '#000000' }) => (
  <svg viewBox="0 0 38 26" className={className} fill={fill} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M36.795 4.158A4.627 4.627 0 0 0 33.51.91C30.616.143 19.001.143 19.001.143S7.384.143 4.488.91a4.627 4.627 0 0 0-3.284 3.248C.43 7.024.43 13 .43 13s0 5.977.776 8.843A4.627 4.627 0 0 0 4.49 25.09c2.898.767 14.511.767 14.511.767s11.616 0 14.511-.767a4.627 4.627 0 0 0 3.284-3.247c.777-2.864.777-8.843.777-8.843s0-5.976-.777-8.842ZM15.2 18.428V7.572L24.91 13l-9.708 5.427Z"
      fill="#353E44"
    />
  </svg>
);
