import { v4 as uuidv4 } from 'uuid';

import { AHOTU_UNIQUE_USER_ID } from '../constants/AppConstants';
import { AFTER_SIGN_IN_ACTION_COOKIE_KEY } from '../services/afterSignInAction';
import { WRITE_REVIEW_COOKIE_KEY } from '../types/directus';

export const addRaceLogCookies = (data: { eventPageUrl: string; raceId: number }) => {
  const expires = new Date();
  expires.setSeconds(expires.getSeconds() + 30);
  document.cookie = `addRaceLog=${true};path=/;expires=${expires.toUTCString()}`;
  document.cookie = `eventPageUrl=${data.eventPageUrl};path=/;expires=${expires.toUTCString()}`;
  document.cookie = `raceId=${data.raceId};path=/;expires=${expires.toUTCString()}`;
};

export const getAhotuUniqueUserId = (): string => {
  if (!localStorage.getItem(AHOTU_UNIQUE_USER_ID)) {
    localStorage.setItem(AHOTU_UNIQUE_USER_ID, Date.now() + '-' + uuidv4());
  }
  return localStorage.getItem(AHOTU_UNIQUE_USER_ID);
};

export const addWriteReviewCookies = (data: { eventPageUrl: string }) => {
  const expires = new Date();
  expires.setSeconds(expires.getSeconds() + 30);
  document.cookie = `${WRITE_REVIEW_COOKIE_KEY}=${true};path=/;expires=${expires.toUTCString()}`;
  document.cookie = `eventPageUrl=${data.eventPageUrl};path=/;expires=${expires.toUTCString()}`;
};

export const addAfterSignInCookies = () => {
  const expires = new Date();
  expires.setSeconds(expires.getSeconds() + 300);
  document.cookie = `${AFTER_SIGN_IN_ACTION_COOKIE_KEY}=${true};path=/;expires=${expires.toUTCString()}`;
};

export const removeCookie = (key: string) => {
  document.cookie = `${key}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
};
