import clsx from 'clsx';
import { ButtonHTMLAttributes } from 'react';

type ConditionalProps =
  | {
      overlay?: never;
      position?: never;
    }
  | {
      overlay: true;
      position?: 'left' | 'right';
    };

type Props = ConditionalProps & ButtonHTMLAttributes<HTMLButtonElement>;

const AhotuInputGroupButton = ({ children, className, overlay, position, ...rest }: Props) => {
  const classNames = clsx(
    'ah-input-group-btn',
    overlay && (position === 'left' ? 'overlay-left' : 'overlay-right'),
    className
  );

  return (
    <button type="button" className={classNames} {...rest} aria-label="Show or hide password">
      {children}
    </button>
  );
};

export default AhotuInputGroupButton;
