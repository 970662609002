const eventRoute = {
  de: '/de/veranstaltung',
  en: '/event',
  es: '/es/evento',
  fr: '/fr/evenement',
  it: '/it/evento',
  pl: '/pl/event',
  pt: '/pt/evento',
  'pt-BR': '/pt-BR/evento',
  ru: '/ru/meropriyatie',
  sv: '/sv/event'
};

const newsRoute = {
  de: '/de/nachrichten',
  en: '/news',
  es: '/es/noticias',
  fr: '/fr/news',
  it: '/it/news',
  pl: '/pl/wiadomosci',
  pt: '/pt/noticias',
  'pt-BR': '/pt-BR/noticias',
  ru: '/ru/novosti',
  sv: '/sv/nyheter'
};

export const routes = {
  members: {
    signIn: {
      de: '/de/mitglieder/anmeldung',
      en: '/members/sign-in',
      es: '/es/miembros/acceso',
      fr: '/fr/membres/se-connecter',
      it: '/it/membri/accedi',
      pl: '/pl/czlonkowie/zaloguj',
      pt: '/pt/membros/iniciar-sessao',
      'pt-BR': '/pt-BR/membros/login',
      ru: '/ru/chleny/vojti',
      sv: '/sv/medlemmar/logga-in'
    },
    signUpRole: {
      de: '/de/mitglieder/anmeldung',
      en: '/members/registration',
      es: '/es/miembros/inscripcion',
      fr: '/fr/membres/inscription',
      it: '/it/membri/iscrizione',
      pl: '/pl/czlonkowie/rejestracja',
      pt: '/pt/membros/registo',
      'pt-BR': '/pt-BR/membros/inscricao',
      ru: '/ru/chleny/registraciya',
      sv: '/sv/medlemmar/registrering'
    },

    registration: {
      chooseRole: {
        de: '/de/mitglieder/anmeldung',
        en: '/members/registration',
        es: '/es/miembros/inscripcion',
        fr: '/fr/membres/inscription',
        it: '/it/membri/iscrizione',
        pl: '/pl/czlonkowie/rejestracja',
        pt: '/pt/membros/registo',
        'pt-BR': '/pt-BR/membros/inscricao',
        ru: '/ru/chleny/registraciya',
        sv: '/sv/medlemmar/registrering'
      },
      athlete: {
        de: '/de/mitglieder/anmeldung/athleten',
        en: '/members/registration/athlete',
        es: '/es/miembros/inscripcion/atleta',
        fr: '/fr/membres/inscription/athlete',
        it: '/it/membri/iscrizione/atleta',
        pl: '/pl/czlonkowie/rejestracja/sportowiec',
        pt: '/pt/membros/registo/atleta',
        'pt-BR': '/pt-BR/membros/inscricao/atleta',
        ru: '/ru/chleny/registraciya/sportsmen',
        sv: '/sv/medlemmar/registrering/athlete'
      },
      organiser: {
        de: '/de/mitglieder/anmeldung/veranstalter',
        en: '/members/registration/organiser',
        es: '/es/miembros/inscripcion/organizador',
        fr: '/fr/membres/inscription/organiser',
        it: '/it/membri/iscrizione/organizzatore',
        pl: '/pl/czlonkowie/rejestracja/organizator',
        pt: '/pt/membros/registo/organizador',
        'pt-BR': '/pt-BR/membros/inscricao/organizador',
        ru: '/ru/chleny/registraciya/organizator',
        sv: '/sv/medlemmar/registrering/arrangoer'
      },
      success: {
        de: '/de/mitglieder/anmeldung/erfolg',
        en: '/members/registration/success',
        es: '/es/miembros/inscripcion/exito',
        fr: '/fr/membres/inscription/succes',
        it: '/it/membri/iscrizione/successo',
        pl: '/pl/czlonkowie/rejestracja/sukces',
        pt: '/pt/membros/registo/sucesso',
        'pt-BR': '/pt-BR/membros/inscricao/sucesso',
        ru: '/ru/chleny/registraciya/uspeh',
        sv: '/sv/medlemmar/registrering/framgaang'
      }
    },
    password: {
      resetPassword: {
        de: '/de/mitglieder/passwort/zuruecksetzen',
        en: '/members/password/reset',
        es: '/es/miembros/contrasena/restablecer',
        fr: '/fr/membres/mot-de-passe/reinitialisation',
        it: '/it/membri/password/reset',
        pl: '/pl/czlonkowie/haslo/reset',
        pt: '/pt/membros/palavra-passe/repor',
        'pt-BR': '/pt-BR/membros/senha/redefinir',
        ru: '/ru/chleny/parol/sbros',
        sv: '/sv/medlemmar/loesenord/aaterstaell'
      },
      forgotPassword: {
        de: '/de/mitglieder/passwort/vergessen',
        en: '/members/password/forgot',
        es: '/es/miembros/contrasena/olvido',
        fr: '/fr/membres/mot-de-passe/oublie',
        it: '/it/membri/password/ho-dimenticato',
        pl: '/pl/czlonkowie/haslo/zapomniany',
        pt: '/pt/membros/palavra-passe/esquecido',
        'pt-BR': '/pt-BR/membros/senha/esqueci',
        ru: '/ru/chleny/parol/zabyl',
        sv: '/sv/medlemmar/loesenord/gloemde'
      }
    },
    confirmation: {
      de: '/de/mitglieder/bestaetigung',
      en: '/members/confirmation',
      es: '/es/miembros/confirmacion',
      fr: '/fr/membres/confirmation',
      it: '/it/membri/conferma',
      pl: '/pl/czlonkowie/potwierdzenie',
      pt: '/pt/membros/confirmacao',
      'pt-BR': '/pt-BR/membros/confirmacao',
      ru: '/ru/chleny/podtverzhdenie',
      sv: '/sv/medlemmar/bekraeftelse'
    }
  },
  cookiePolicy: {
    de: '/de/seite/cookie-richtlinie',
    en: '/page/cookie-policy',
    es: '/es/page/politica-de-cookies',
    fr: '/fr/page/politique-des-cookies',
    it: '/it/pagina/politica-dei-cookie',
    pl: '/pl/strona/cookie-policy',
    pt: '/pt/pagina/politica-de-cookies',
    'pt-BR': '/pt-BR/pagina/politica-de-cookies',
    ru: '/ru/stranica/politika-ispolzovaniya-fajlov-cookie',
    sv: '/sv/sida/cookie-policy'
  },
  privacyPolicy: {
    de: '/de/seite/datenschutzbestimmungen',
    en: '/page/privacy-policy',
    es: '/es/page/politica-de-privacidad',
    fr: '/fr/page/politique-de-confidentialite',
    it: '/it/pagina/privacy-policy',
    pl: '/pl/strona/polityka-prywatnosci',
    pt: '/pt/pagina/politica-de-privacidade',
    'pt-BR': '/pt-BR/pagina/politica-de-privacidade',
    ru: '/ru/stranica/politika-konfidencialnosti',
    sv: '/sv/sida/sekretesspolicy'
  },
  news: newsRoute,
  allNews: newsRoute,
  allNewsPerEvent: {
    de: '/de/nachrichten/veranstaltung/',
    en: '/news/event/',
    es: '/es/noticias/evento/',
    fr: '/fr/news/evenement/',
    it: '/it/news/evento/',
    pl: '/pl/wiadomosci/event/',
    pt: '/pt/noticias/evento/',
    'pt-BR': '/pt-BR/noticias/evento/',
    ru: '/ru/novosti/meropriyatie/',
    sv: '/sv/nyheter/event/'
  },
  event: eventRoute,
  eventEdition: eventRoute,
  eventSignup: {
    de: '/de/veranstaltung/anmeldung',
    en: '/event/signup',
    es: '/es/evento/inscripcion',
    fr: '/fr/evenement/inscription',
    it: '/it/evento/iscriviti',
    pl: '/pl/event/rejestracja',
    pt: '/pt/evento/registar-se',
    'pt-BR': '/pt-BR/evento/inscricao',
    ru: '/ru/meropriyatie/registraciya',
    sv: '/sv/event/anmaelan'
  },
  sport: {
    de: '/de/sport',
    en: '/sport',
    es: '/es/deporte',
    fr: '/fr/sport',
    it: '/it/sport',
    pl: '/pl/sport',
    pt: '/pt/desporto',
    'pt-BR': '/pt-BR/esporte',
    ru: '/ru/sport',
    sv: '/sv/sport'
  },
  calendar: {
    de: '/de/kalender',
    en: '/calendar',
    es: '/es/calendario',
    fr: '/fr/calendrier',
    it: '/it/calendario',
    pl: '/pl/kalendarz',
    pt: '/pt/calendario',
    'pt-BR': '/pt-BR/calendario',
    ru: '/ru/kalendar',
    sv: '/sv/kalender'
  },
  ahotuCheckout: {
    de: '/de/kalender/anmeldung',
    en: '/calendar/registration',
    es: '/es/calendario/inscripcion',
    fr: '/fr/calendrier/inscription',
    it: '/it/calendario/iscrizione',
    pl: '/pl/kalendarz/rejestracja',
    pt: '/pt/calendario/registo',
    'pt-BR': '/pt-BR/calendario/inscricao',
    ru: '/ru/kalendar/registraciya',
    sv: '/sv/kalender/registrering'
  },
  aboutUs: {
    de: '/de/ueber-uns',
    en: '/about-us',
    es: '/es/sobre-nosotros',
    fr: '/fr/a-propos',
    it: '/it/chi-siamo',
    pl: '/pl/o-nas',
    pt: '/pt/sobre-nos',
    'pt-BR': '/pt-BR/quem-somos',
    ru: '/ru/o-nas',
    sv: '/sv/om-oss'
  },
  organiser: {
    de: '/de/veranstalter-veranstaltungen',
    en: '/organiser-events',
    es: '/es/eventos-organizador',
    fr: '/fr/organiser-events',
    it: '/it/organizzatore-eventi',
    pl: '/pl/organizator-eventow',
    pt: '/pt/organizador-de-eventos',
    'pt-BR': '/pt-BR/organizador-de-eventos',
    ru: '/ru/organizator-meropriyatij',
    sv: '/sv/arrangoer-event',
    editMedia: {
      de: '/medien',
      en: '/media',
      es: '/media',
      fr: '/media',
      it: '/media',
      pl: '/media',
      pt: '/meios-de-comunicacao',
      'pt-BR': '/midia',
      ru: '/smi',
      sv: '/media'
    },
    newEvent: {
      de: '/neu',
      en: '/new',
      es: '/nuevo',
      fr: '/nouveau',
      it: '/nuovo',
      ja: '/new',
      pl: '/nowy',
      pt: '/novo',
      'pt-BR': '/novo',
      ru: '/novyj',
      sv: '/ny'
    },
    public: {
      de: '/de/veranstalter',
      en: '/organiser',
      es: '/es/organizador',
      fr: '/fr/organiser',
      it: '/it/organizzatore',
      pl: '/pl/organizator',
      pt: '/pt/organizador',
      'pt-BR': '/pt-BR/organizador',
      ru: '/ru/organizator',
      sv: '/sv/arrangoer'
    }
  },
  profile: {
    de: '/de/profil',
    en: '/profile',
    es: '/es/perfil',
    fr: '/fr/profil',
    it: '/it/profilo',
    pl: '/pl/profil',
    pt: '/pt/perfil',
    'pt-BR': '/pt-BR/perfil',
    ru: '/ru/profil',
    sv: '/sv/profil'
  },
  homepage: {
    de: '/de',
    en: '/',
    es: '/es',
    fr: '/fr',
    it: '/it',
    ja: '/ja',
    ko: '/ko',
    pl: '/pl',
    pt: '/pt',
    'pt-BR': '/pt-BR',
    ru: '/ru',
    sv: '/sv',
    th: '/th',
    zh: '/zh'
  }
};
