// Kevin: This is my source of truth for everything relating to typesetting, colors, fonts, etc.
// Anders designs from Figma consistently use a UI library. Given this consistency, we should mirror it in the source.
// the /*tw*/ tags are just hints for vscode tailwind intellisense.

//Most of - but not all - colors here are tailwind colors. They are subject to change though, so are defined here even if it leads to duplication.
const colors = {
  paleGreen: '#EAFFF4',
  paleGrey: '#D1D7DB',
  darkGrey: '#353E44',
  yellow: '#FFC700',
  ahotuGreen: '#00C282',
  ahotuGreenDarker: '#01AD6F',
  softBlack: '#202228',
  ahotuBlue: '#3DADDC',
  sharpRed: '#E40936'
};

const palette = {
  menuHover: colors.paleGreen,
  hr: colors.paleGrey,
  ahotuPrimary: colors.ahotuGreen,
  ahotuSecondary: colors.ahotuGreenDarker,
  ahotuNeutral: colors.paleGreen,
  ahotuSuccess: colors.ahotuGreen,
  ahotuInfo: colors.ahotuBlue,
  ahotuWarning: colors.yellow,
  ahotuError: colors.sharpRed
};

const typography = {
  h1: {
    /* //styleName: Heading/H1 56;
font-family: Inter;
font-size: 56px;
font-weight: 700;
line-height: 60px;
text-align: left;
*/
    'h1-56': /*tw*/ 'text-[56px] leading-[60px] font-bold',
    /* //styleName: Heading/H1 32;
font-family: Inter;
font-size: 32px;
font-weight: 700;
line-height: 40px;
text-align: left;
*/
    'h1-32': /*tw*/ 'text-[32px] leading-[40px] font-bold',
    /* //styleName: Heading/H1 24;
font-family: Inter;
font-size: 24px;
font-weight: 700;
line-height: 28px;
text-align: left;
*/
    'h1-24': /*tw*/ 'text-[24px] leading-[28px] font-bold'
  },
  h2: {
    'h2-20': /*tw*/ 'text-[20px] leading-[28px] font-bold'
  },
  h3: {
    'h3-24': /*tw*/ 'text-[24px] leading-[28px] font-bold'
  },
  body: {
    'b-24': /*tw*/ 'text-[24px] leading-[32px]',
    'b-16': /*tw*/ 'text-[16px] leading-[24px]',
    'b-14': /*tw*/ 'text-[14px] leading-[24px]',
    'b-12': /*tw*/ 'text-[12px] leading-[18px]'
  }
};

export const RichTextRenderer =
  /*tw*/ `flex flex-col gap-8 ` +
  /*tw*/ '[&>h1]:text-[32px] md:[&>h1]:text-[56px] [&>h1]:leading-[40px] md:[&>h1]:leading-[60px] ' + // Heading/H1 56, below tablet Heading/H1 32
  /*tw*/ '[&>[data-style-as=h2]]:text-[24px] md:[&>[data-style-as=h2]]:text-[32px] [&>[data-style-as=h2]]:leading-[28px] md:[&>[data-style-as=h2]]:leading-[40px] [&>[data-style-as=h2]]:font-bold ' + // Heading/H2 32, below tablet Heading/H2 24px
  /*tw*/ '[&>[data-style-as=h3]]:text-[20px] md:[&>[data-style-as=h3]]:text-[24px] [&>[data-style-as=h3]]:leading-[28px] md:[&>[data-style-as=h3]]:leading-[28px] [&>[data-style-as=h3]]:font-bold ' + // Heading/H3 24, below tablet Heading/H3 20px
  /*tw*/ '[&>[data-style-as=h4]]:text-[18px] md:[&>[data-style-as=h4]]:text-[20px] [&>[data-style-as=h4]]:leading-[28px] md:[&>[data-style-as=h4]]:leading-[28px] [&>[data-style-as=h4]]:font-bold ' + // Heading/H4 20, below tablet Heading/H4 18px
  /*tw*/ '[&>[data-style-as=h5]]:text-[16px] md:[&>[data-style-as=h5]]:text-[18px] [&>[data-style-as=h5]]:leading-[24px] md:[&>[data-style-as=h5]]:leading-[28px] [&>[data-style-as=h5]]:font-bold ' + // Heading/H5 18, below tablet Heading/H5 16px
  /*tw*/ '[&>[data-style-as=h6]]:text-[16px] md:[&>[data-style-as=h6]]:text-[16px] [&>[data-style-as=h6]]:leading-[24px] md:[&>[data-style-as=h6]]:leading-[24px] [&>[data-style-as=h6]]:font-bold ' + // Heading/H6 16, below tablet Heading/H6 16px
  /*tw*/ '[&>p:not([data-style-as])]:text-[16px] [&>p:not([data-style-as])]:leading-[24px]'; // Body/B 16 for all screen sizes

const AhotuUI = {
  typography,
  palette
};

export default AhotuUI;
