import { m as motion, useScroll } from 'framer-motion';
import { useEffect, useState } from 'react';

import clsxm from '../../../lib/clsxm';
import { TopNavigationMenu } from '../../../types/homePageTypes';
import { SportType } from '../../../types/referenceTypes';
import AhotuLink from '../../atoms/AhotuLink';
import { AhotuLogo } from '../../atoms/AhotuLogo';
import { Paragraph } from '../../atoms/Paragraph';
import { SearchIcon } from '../../icons/icons';
import OneTapComponent from '../../OneTap';
import { LoginMenu } from '../LoginMenu';
import TopNavigationSearchDesktop from '../TopNavigationSearchDesktop';
import SubMenu from './SubMenu';

export type SubMenuOpen = { [key in SportType]: boolean };
export const ALL_MENU_CLOSED: SubMenuOpen = {
  [SportType.RUN]: false,
  [SportType.CYCLING]: false,
  [SportType.WATER]: false,
  [SportType.WINTER]: false,
  [SportType.OTHER]: false,
  [SportType.MULTI_SPORT]: false
};

interface DesktopNavigationProps {
  isAnimated: boolean;
  menu?: TopNavigationMenu;
}
const DesktopNavigation = ({ isAnimated, menu }: DesktopNavigationProps) => {
  const animateHideNavbar = {
    hidden: {
      opacity: 0,
      y: -120
    },
    visible: {
      opacity: 1
    }
  };

  const { scrollY } = useScroll();
  const transition = 'transition ease-in-out delay-50';
  const [showNavbar, toggleShowNavbar] = useState(true);
  const [subMenuOpen, toggleSubMenuOpen] = useState(ALL_MENU_CLOSED);
  const [searchIsExpanded, setSearchIsExpanded] = useState(false);
  const [isWhiteSearchIcon, setIsWhiteSearchIcon] = useState(true);

  const animated = ['fill-current text-white group-hover:text-softblack', transition];
  const notAnimated = ['fill-current text-softblack'];

  let scrollPosition = 0;

  useEffect(() => {
    return scrollY.onChange(latest => {
      if (latest > scrollPosition && latest > 123) {
        toggleShowNavbar(false);
      } else {
        toggleShowNavbar(true);
      }
      scrollPosition = latest;
    });
  }, []);

  return (
    <>
      <OneTapComponent />
      {searchIsExpanded ? (
        <TopNavigationSearchDesktop
          isAnimated={isAnimated}
          searchIsExpanded={searchIsExpanded}
          setSearchIsExpanded={setSearchIsExpanded}
          animation={{
            animated,
            notAnimated
          }}
        />
      ) : (
        <motion.div
          animate={showNavbar ? 'visible' : 'hidden'}
          variants={animateHideNavbar}
          transition={{ type: 'linear' }}
          style={{ paddingLeft: '2.5%', paddingRight: '2.5%' }}
          className={clsxm(
            'h-30 w-full z-50 hidden lg:flex lg:justify-around',
            isAnimated && [
              'absolute top-0 group bg-none hover:bg-white',
              //scrollPosition < 10 ? "bg-none hover:bg-white" : "bg-white",
              transition
            ],
            subMenuOpen && 'bg-white',
            (!isAnimated || subMenuOpen) && 'bg-white sticky top-0'
          )}
          onMouseEnter={() => setIsWhiteSearchIcon(false)}
          onMouseLeave={() => setIsWhiteSearchIcon(true)}>
          <div className="flex items-center h-full">
            <div className="hover:cursor-pointer">
              <AhotuLogo />
            </div>
          </div>
          <div className="flex items-center h-full space-x-8 xl:space-x-12">
            {menu &&
              Object.keys(menu).map((sportType: SportType, i) => (
                <div key={i} className="relative">
                  <AhotuLink href={menu[sportType].url}>
                    <Paragraph
                      onMouseEnter={() => toggleSubMenuOpen({ ...ALL_MENU_CLOSED, [sportType]: true })}
                      className={clsxm(
                        'mb-0 flex w-fit whitespace-nowrap hover:underline cursor-pointer',
                        isAnimated ? animated : notAnimated,
                        subMenuOpen[sportType] && 'text-softblack',
                        'w-fit'
                      )}>
                      {menu[sportType].name}
                    </Paragraph>
                  </AhotuLink>

                  {subMenuOpen[sportType] && (
                    <SubMenu
                      subMenus={menu[sportType].subMenus}
                      subMenuOpen={subMenuOpen}
                      toggleSubMenuOpen={toggleSubMenuOpen}
                    />
                  )}
                </div>
              ))}
          </div>
          <div className="flex items-center h-full">
            <div
              className="mr-4 xl:mr-8"
              onClick={() => {
                setSearchIsExpanded(true);
                toggleSubMenuOpen(ALL_MENU_CLOSED);
              }}>
              <SearchIcon
                className="h-6 w-6 cursor-pointer"
                fill={isAnimated ? (isWhiteSearchIcon ? '#FFFFFF' : '#00000') : '#000000'}
              />
            </div>
            <LoginMenu />
          </div>
        </motion.div>
      )}
    </>
  );
};

export default DesktopNavigation;
