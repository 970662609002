import { createContext, useCallback, useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ShowSignInModalGroup } from '~/src/components/organisms/reviews/WriteReviewGroup';

interface SignInModalContextData {
  openSignInModal: () => void;
  closeSignInModal: () => void;
}

const SignInModalContext = createContext<SignInModalContextData | null>(null);

const SignInModalProvider = ({ children, passwordSignInCallback }) => {
  const { t } = useTranslation();

  const [showSignInModal, setShowSignInModal] = useState(false);

  const openSignInModal = useCallback(() => {
    setShowSignInModal(true);
  }, []);

  const closeSignInModal = useCallback(() => {
    setShowSignInModal(false);
  }, []);

  function onSignIn() {
    closeSignInModal();
    passwordSignInCallback();
  }

  const memoizedValue = useMemo(
    () => ({
      openSignInModal,
      closeSignInModal
    }),
    [openSignInModal, closeSignInModal]
  );

  return (
    <SignInModalContext.Provider value={memoizedValue}>
      {children}
      {showSignInModal && (
        <ShowSignInModalGroup
          headingText={t('common:log-in-save-event')}
          onCloseSignInModal={closeSignInModal}
          passwordSignInCallback={onSignIn}
        />
      )}
    </SignInModalContext.Provider>
  );
};

export const useSignInModal = () => useContext(SignInModalContext);

export default SignInModalProvider;
