import { addAfterSignInCookies } from '../utils/helper';

export const AFTER_SIGN_IN_ACTION_COOKIE_KEY = 'afterSignInAction';
export const ADD_EVENT_ACTION = 'addEvent';

// Checks if any after sign in action is in localStorage
function hasAfterSignInAction(): boolean {
  let hasAction = false;
  [ADD_EVENT_ACTION].forEach(actionKey => {
    const data = JSON.parse(localStorage.getItem(actionKey));
    if (data) hasAction = true;
  });
  return hasAction;
}

export function addAfterSignInAction() {
  if (hasAfterSignInAction()) {
    addAfterSignInCookies();
  }
}
