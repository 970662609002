import clsx from 'clsx';
import { motion } from 'framer-motion';
import capitalize from 'lodash/capitalize';
import upperCase from 'lodash/upperCase';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { useEffect, useReducer, useState } from 'react';

import clsxm from '../../lib/clsxm';
import * as gtag from '../../lib/ga4/gtag';
import { getLocalizedProperty } from '../../lib/localization';
import { EventSuggestion } from '../../types/SearchTypes';
import { hasOneOrMore } from '../../utils/arrays';
import { handleClientSideError } from '../../utils/handleClientSideError';
import { routes } from '../../utils/routes';
import useKeyPress from '../../utils/useKeyPress';
import { Paragraph } from '../atoms/Paragraph';
import { SearchIcon } from '../icons/icons';
import SearchBoxResult from './SearchBoxResult';
import TopSearch from './SearchBoxTopSearch';

export type SearchBoxUsage = 'homePageHeading' | 'topNavigation' | 'mobileTopNavigation' | 'stacked';

const topNavAnimation = {
  initial: { width: 0, scaleX: 0, x: '350px', originX: 'right' },
  animate: { width: '100%', scaleX: '100%', x: '0px' }
};

const homePageAnimation = {
  initial: { width: 0, scaleX: 0 },
  animate: { width: '100%', scaleX: '100%' }
};

const hasNoMatch = (suggestion): boolean => {
  const keys = ['eventName', 'raceNames', 'city', 'country'];
  const match = Object.entries(suggestion).some(
    ([key, value]: [string, string]) => keys.some(prefix => key.startsWith(prefix)) && value.match('strong')
  );
  return !!match;
};

const getTopSearch = async (): Promise<EventSuggestion[]> => {
  try {
    const req = await fetch('/api/search/getTopSearch');
    const resp = await req.json();
    return resp.list;
  } catch (error) {
    handleClientSideError('Error in SearchBox TopSearch ', error);
  }
};
interface ISearchBox {
  isExpanded: boolean;
  setIsExpanded?: (x) => void;
  showInput: boolean;
  setShowInput?: (x) => void;
  usage: SearchBoxUsage;
  hasTopSearch?: boolean;
}

const SearchBox = ({ isExpanded, showInput, setShowInput, setIsExpanded, usage, hasTopSearch = true }: ISearchBox) => {
  const [suggestions, setSuggestions] = useState([] as EventSuggestion[]);

  const [topSearch, setTopSearch] = useState([]);
  const [query, setQuery] = useState('');
  const [showTopSearch, setShowTopSearch] = useState(false);
  const router = useRouter();
  const { t } = useTranslation();
  let debounce: ReturnType<typeof setTimeout>;

  let hasOtherSuggestions = false;

  const getSuggestions = async () => {
    try {
      if (query.length > 2) {
        const req = await fetch(`/api/search/autocompleteEvents?search=${query}`);
        const eventSuggestions = await req.json();
        gtag.sendEvent('query_search_box', {
          searchbox: { query, searchResults: eventSuggestions.events.length, events: eventSuggestions.events }
        });
        setSuggestions(eventSuggestions.events);
        setShowTopSearch(false);
      } else {
        setSuggestions([]);
      }
    } catch (error) {
      handleClientSideError('Error in SearchBox suggestions ', error);
      setSuggestions([]);
    }
  };

  const arrowUpPressed = useKeyPress('ArrowUp');
  const arrowDownPressed = useKeyPress('ArrowDown');
  const enterPressed = useKeyPress('Enter');
  const escapePressed = useKeyPress('Escape');

  const resultsLength = showTopSearch ? topSearch?.length : suggestions?.length;
  const [state, dispatch] = useReducer(
    (state, action) => {
      switch (action.type) {
        case 'arrowUp':
          return {
            selectedIndex: state.selectedIndex > 0 ? state.selectedIndex - 1 : resultsLength - 1
          };
        case 'arrowDown':
          return {
            selectedIndex: state.selectedIndex !== resultsLength - 1 ? state.selectedIndex + 1 : 0
          };
        case 'select':
          return { selectedIndex: action.payload };
        default:
          throw new Error();
      }
    },
    { selectedIndex: -1 }
  );

  //Handle key selection
  useEffect(() => {
    if (arrowUpPressed) {
      dispatch({ type: 'arrowUp' });
    } else if (arrowDownPressed) {
      dispatch({ type: 'arrowDown' });
    } else if (enterPressed) {
      const index = state.selectedIndex;
      if (index >= 0) {
        const permalink = showTopSearch ? topSearch[index]?.id : suggestions[index]?.id;
        if (permalink) {
          const url = getLocalizedProperty(routes.event, router.locale) + '/' + permalink;
          router.push(url, url);
        }
      }
    } else if (escapePressed) {
      setIsExpanded && setIsExpanded(false);
    }
  }, [arrowUpPressed, arrowDownPressed, enterPressed, escapePressed]);

  //UseEffect when user search
  useEffect(() => {
    getSuggestions();
  }, [query]);

  //UseEffect ran only once at mount
  useEffect(() => {
    const topSearch = async () => {
      const list = await getTopSearch();
      setTopSearch(list);
    };
    hasTopSearch && topSearch();

    if (usage === 'topNavigation') {
      document.body.classList.add('search-open-desktop');
    }
    window.scrollTo(0, 0);

    router.events.on('routeChangeComplete', () => setIsExpanded && setIsExpanded(false));
    return () => {
      document.body.classList.remove('search-open-desktop');

      router.events.off('routeChangeComplete', () => setIsExpanded && setIsExpanded(false));
    };
  }, []);

  const onHomePageHeading = () => {
    usage === 'homePageHeading' && setIsExpanded && setIsExpanded(false);
  };

  const onInputChange = e => {
    if (e.target.value.length > 2) {
      clearTimeout(debounce);
      debounce = setTimeout(() => setQuery(e.target.value), 500);
    } else {
      hasTopSearch && setShowTopSearch(true);
      setQuery('');
    }
  };

  const showSuggestion = (suggestion: EventSuggestion, index: number) => {
    console.log('suggestion', suggestion);
    const noMatch = hasNoMatch(suggestion);
    let showOtherSuggestionsTitle = false;
    if (noMatch && !hasOtherSuggestions && index != 0) showOtherSuggestionsTitle = true;

    const element = (
      <>
        {showOtherSuggestionsTitle && (
          <Paragraph
            size="p2"
            className={clsxm('pt-8 pb-4 px-8 mb-0', index != 0 && 'border-t-2', usage === 'homePageHeading' && 'mx-8')}>
            {upperCase(t('common:other-suggestions'))}
          </Paragraph>
        )}
        <SearchBoxResult
          key={index}
          data={{ result: suggestion, index }}
          dispatch={dispatch}
          className={clsxm(index === state.selectedIndex && 'bg-gray-10')}
          usage={usage}
        />
      </>
    );
    if (noMatch) hasOtherSuggestions = true;
    return element;
  };

  const sendGA4Event = () => {
    gtag.sendEvent('open_search_box', {});
  };

  return (
    <div
      className={clsxm(
        'flex justify-center items-center z-30 w-full lg:w-[638px] px-4',
        usage.match('PageHeading') && 'relative',
        usage === 'stacked' && 'flex-col '
      )}>
      <motion.div
        onAnimationComplete={() => {
          setShowInput && setShowInput(isExpanded);
          hasTopSearch && setShowTopSearch(true);
        }}
        initial={usage.match('Navigation') ? topNavAnimation.initial : homePageAnimation.initial}
        animate={usage.match('Navigation') ? topNavAnimation.animate : homePageAnimation.animate}
        transition={{ duration: 0.2, ease: 'linear' }}
        className="border flex rounded-full border-gray-90 h-14 z-50 bg-white">
        <div className="flex justify-center items-center ml-5 mr-2 ">
          <SearchIcon className="h-6 w-6" fill="#000000" />
        </div>
        {showInput && (
          <input
            autoFocus
            className="w-[75%] hover:outline-none rounded-full bg-whitefocus:outline-none active:outline-none focus:outline-none"
            placeholder={capitalize(t('common:search'))}
            onChange={onInputChange}
            onFocus={sendGA4Event}
          />
        )}
      </motion.div>
      {(suggestions.length >= 0 || showTopSearch) && (
        <>
          <div
            className={clsx(
              ' flex  justify-center radius-sm z-20 w-full bg-white',
              {
                'top-[50px] bg-transparent': usage === 'homePageHeading',
                'top-[89px] left-0': usage === 'topNavigation',
                'top-[89px] mt-8': usage === 'mobileTopNavigation'
              },
              usage === 'stacked' ? '-pt-6' : 'absolute w-full'
            )}
            onClick={onHomePageHeading}>
            {(hasOneOrMore(topSearch) || hasOneOrMore(suggestions)) && (
              <div
                className={clsxm(
                  'py-8 bg-white',
                  usage === 'homePageHeading' && 'w-full lg:w-185',
                  usage === 'topNavigation' && 'w-[638px] ml-20',
                  (usage === 'stacked' || usage === 'mobileTopNavigation') && 'w-full'
                )}>
                {showTopSearch ? (
                  <TopSearch topSearch={topSearch} state={state} dispatch={dispatch} usage={usage} />
                ) : (
                  suggestions.length != 0 &&
                  suggestions.map((result: EventSuggestion, index) => showSuggestion(result, index))
                )}

                {!showTopSearch && suggestions.length === 0 && query != '' && (
                  <div className={clsxm('px-8 mb-0', usage === 'homePageHeading' && 'px-20')}>
                    <Paragraph size="p0" className="mb-0">
                      {t('common:search-no-results')} &quot;<strong>{query}</strong>&quot;
                    </Paragraph>
                    <Paragraph size="p2" className="mb-0">
                      {t('common:search-no-results-text')}
                    </Paragraph>
                  </div>
                )}
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export interface ISearchResultData {
  id: string;
  eventName: string;
  raceNames: string;
  city: string;
  country: string;
}

export default SearchBox;
