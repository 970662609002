import { bucketlist_events } from '@prisma/client';
import { useRouter } from 'next/router';
import { useSession } from 'next-auth/react';
import React, { createContext, useCallback, useEffect, useMemo, useState } from 'react';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { useSnackbarContext } from '~/src/contexts/SnackbarProvider/SnackbarProvider';
import { useUserContext } from '~/src/contexts/UserProvider/UserProvider';

import { getLinkMessage } from '../components/organisms/Snackbar';
import { getLocalizedProperty } from '../lib/localization';
import { toInt } from '../lib/prismaUtils';
import { routes } from '../utils/routes';

// Define the context type
interface BucketListContextType {
  bucketList: bucketlist_events[];
  addToBucketList: (eventID: number) => Promise<void>;
  removeFromBucketList: (eventID: number) => Promise<void>;
  getBucketList: () => Promise<void>;
}

// Create the context
const BucketListContext = createContext<BucketListContextType | undefined>(undefined);

// Create a provider component
export const BucketListProvider = ({ children }) => {
  const [bucketList, setBucketList] = useState<bucketlist_events[]>([]);
  const { queue } = useSnackbarContext();
  const { t } = useTranslation();
  const { user } = useUserContext();
  const router = useRouter();
  const { data: session } = useSession();

  const path = useMemo(() => {
    const profileFragment = t('common:profile-page-fragments-events').toLowerCase();
    return `${getLocalizedProperty(routes.profile, router.locale)}/${session?.permalink}/#${profileFragment}`;
  }, [t, router, session]);

  // Function to add event to bucket list
  const addToBucketList = useCallback(
    async (eventID: number) => {
      return fetch(`/api/events/addToBucketList`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ eventID })
      })
        .then(response => {
          if (response.ok) {
            return response.json() as Promise<bucketlist_events>;
          } else {
            throw response;
          }
        })
        .then(response => setBucketList(prevBucketList => [...prevBucketList, response]))
        .then(() => {
          queue(getLinkMessage(t, 'saved-events-added', { name: t('navmenu-savedevents'), path }), false);
        })
        .catch(error => {
          // Bucket list item already existed
          if (error.status === 409) {
            queue(t('event-bucket-list-added', { bucketListLink: t('event-bucket-list-bucket-list-link') }), false);
            return;
          }
          queue(t('common:generic-error'), true);
          throw error;
        });
    },
    [queue, t, path]
  );

  // Function to remove event from bucket list
  const removeFromBucketList = useCallback(
    async (eventID: number) => {
      return fetch(`/api/events/removeFromBucketList`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ eventID })
      })
        .then(response => {
          if (!response.ok) {
            throw response;
          }
        })
        .then(() =>
          setBucketList(prevBucketList => prevBucketList.filter(event => toInt(event.a_event_id) !== eventID))
        )
        .then(() => {
          queue(getLinkMessage(t, 'saved-events-removed', { name: t('navmenu-savedevents'), path }), false);
        })
        .catch(error => {
          queue(t('common:generic-error'), true);
          throw error;
        });
    },
    [queue, t, path]
  );

  // Function to get the bucket list
  const getBucketList = async () => {
    try {
      const events = await fetch(`/api/events/getBucketList`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      }).then(response => response.json() as Promise<bucketlist_events[]>);

      setBucketList(events);
    } catch (error) {
      //We can reach this due to not being authed, so just swallow the error silently.
    }
  };

  useEffect(() => {
    // Fetch the bucket list on component mount
    if (user) getBucketList();
  }, []);

  //fetch the bucket list if user has become authenticated
  useEffect(() => {
    if (user) {
      getBucketList();
    }
  }, [user]);

  const memoizedValue = useMemo(
    () => ({
      bucketList,
      addToBucketList,
      getBucketList,
      removeFromBucketList
    }),
    [bucketList, addToBucketList, removeFromBucketList]
  );

  return <BucketListContext.Provider value={memoizedValue}>{children}</BucketListContext.Provider>;
};

// Custom hook to use the BucketListContext
export const useBucketList = () => {
  const context = useContext(BucketListContext);
  if (context === undefined) {
    throw new Error('useBucketList must be used within a BucketListProvider');
  }
  return context;
};
