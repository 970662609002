import { create as bool } from 'yup/lib/boolean';
import { create as object } from 'yup/lib/object';
import { create as ref } from 'yup/lib/Reference';
import { create as string } from 'yup/lib/string';

export const registrationRegex = {
  uppercase: /[A-Z]/,
  lowercase: /[a-z]/,
  number: /\d/,
  symbol: /[-!$%^&£*()_+|~=`{}[\]:/;<>?,.@#]/,
  whitespace: /^\S*$/
};

const password = string()
  .required('required')
  .min(8, 'min')
  .matches(registrationRegex.uppercase, 'uppercase')
  .matches(registrationRegex.lowercase, 'lowercase')
  .matches(registrationRegex.number, 'number')
  .matches(registrationRegex.symbol, 'symbol')
  .matches(/^\S*$/, 'pw-whitespace');

const confirmPassword = string()
  .required('required')
  .oneOf([ref('password'), null], 'match');

export const registrationValidationSchema = object().shape({
  username: string().required('required').matches(/^\S*$/, 'no-whitespace'),
  firstname: string().required('required').trim(),
  lastname: string().required('required').trim(),
  email: string().required('required').email('email'),
  toc_accepted: bool().oneOf([true], 'checked'),
  password: password,
  password_confirm: confirmPassword
});

export const emailValidationSchema = object().shape({
  email: string().required('required').email('email')
});

export const passwordValidationSchema = object().shape({
  password: password,
  password_confirm: confirmPassword
});

export const serverSideValidation = (errors, isOrg = false) => {
  const formattedErrors = {};

  Object.keys(errors).forEach(key => {
    if (key === 'email') {
      if (errors.email.match('taken')) {
        formattedErrors['email'] = 'email-taken';
      } else if (errors.email.match('invalid')) {
        formattedErrors['email'] = 'email-invalid';
      }
    } else if (key === 'username') {
      if (errors.username.match('taken')) {
        formattedErrors['username'] = 'username-taken';
      }
    } else if (key === 'permalink') {
      if (errors.permalink.match('taken')) {
        formattedErrors['username'] = isOrg ? 'organisation-name-taken' : 'username-taken';
      }
    } else if (key === 'password') {
      if (errors.password.match('long')) {
        formattedErrors['password'] = 'password-too-long';
      } else if (errors.password.match('short')) {
        formattedErrors['password'] = 'password-too-short';
      }
    } else if (key === 'reset_password_token') {
      formattedErrors['reset_password_token'] = 'invalid';
    }
  });
  return formattedErrors;
};

export const organiserImageValidationSchema = object().shape({
  username: string().required('required').matches(/^\S*$/, 'no-whitespace'),
  firstname: string().required('required').trim(),
  lastname: string().required('required').trim(),
  email: string().required('required').email('email'),
  toc_accepted: bool().oneOf([true], 'checked'),
  password: password,
  password_confirm: confirmPassword
});
