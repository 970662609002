import { useRouter } from 'next/router';
import { useSession } from 'next-auth/react';
import { useEffect } from 'react';

import { ADD_EVENT_ACTION, AFTER_SIGN_IN_ACTION_COOKIE_KEY } from '~/src/services/afterSignInAction';
import { removeCookie } from '~/src/utils/helper';
import { usePrevious } from '~/src/utils/usePrevious';

import { useBucketList } from '../bucketListContext';
import { useSearchContext } from '../SearchProvider/SearchProvider';
import SignInModalProvider from '.';
import { useScrollRef } from './ScrollRefProvider';

export function SignInModalWrapper({ children }) {
  const { updateRefs } = useScrollRef();
  const { data: session } = useSession();
  const previousSession = usePrevious(session);
  const { addToBucketList } = useBucketList();
  const { query } = useRouter();
  const { searchData, setSearchData } = useSearchContext();

  function handleAddEventAction() {
    const eventData = JSON.parse(localStorage.getItem(ADD_EVENT_ACTION));

    if (eventData) {
      localStorage.removeItem(ADD_EVENT_ACTION);
      removeCookie(AFTER_SIGN_IN_ACTION_COOKIE_KEY);

      addToBucketList(eventData.payload?.eventId);
      updateRefs({
        scrollRef: eventData.payload?.eventId,
        fromSectionRef: eventData.payload?.fromSection ?? ''
      });

      if (eventData.payload?.useMap) {
        setSearchData({
          ...searchData,
          showMap: true
        });
      }
    }
  }

  useEffect(() => {
    if (session && !previousSession) {
      handleAddEventAction();
    }
  }, [query, session, previousSession]);

  return <SignInModalProvider passwordSignInCallback={handleAddEventAction}>{children}</SignInModalProvider>;
}
