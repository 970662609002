import { useState } from 'react';

import PanelSliderMobile from '../atoms/PanelSliderMobile';
import { XIcon } from '../icons/icons';
import SearchBox from './SearchBox';

interface ITopNavigationSearchMobile {
  searchIsExpanded: boolean;
  setSearchIsExpanded: (x) => void;
}

const TopNavigationSearchMobile = ({ searchIsExpanded, setSearchIsExpanded }: ITopNavigationSearchMobile) => {
  const [showInput, setShowInput] = useState(false);

  return (
    <PanelSliderMobile side="right">
      <div className="absolute w-screen h-full top-0 left-0 z-50 min-h-screen">
        <div
          onClick={() => {
            setSearchIsExpanded(false);
            setShowInput(false);
          }}
          className="flex justify-end items-center mr-6 pb-4 pt-6">
          <XIcon className="h-4 w-auto" />
        </div>
        <SearchBox
          isExpanded={searchIsExpanded}
          setIsExpanded={setSearchIsExpanded}
          showInput={showInput}
          setShowInput={setShowInput}
          usage="mobileTopNavigation"
        />
      </div>
    </PanelSliderMobile>
  );
};

export default TopNavigationSearchMobile;
