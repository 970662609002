'use strict';

import { ValuesOf } from '../pages/landing/[landingPageSlug]';

export function translateWithFallback<ARRAY extends readonly any[]>(
  languageCode: string,
  translate:
    | (ARRAY & Array<{ languages_code?: { code?: string | null } | null } | null | undefined>)
    | null
    | undefined,
  fallbackLanguageCode: string = 'en'
): ValuesOf<ARRAY> {
  if (!translate) {
    return {};
  }
  const fallbacks = translate.find(translation => translation.languages_code.code === fallbackLanguageCode) ?? {}; //Do not remove null values so that it becomes obvious if translation is missing from fallback language. Maybe there's a better way to handle this case.
  const intendedTranslations = removeNullValues(
    translate.find(translation => translation?.languages_code?.code === languageCode) ?? {}
  );
  return {
    ...fallbacks,
    ...intendedTranslations
  };
}
export function removeNullValues(obj) {
  // If the provided value is not an object, return it as it is
  if (typeof obj !== 'object' || obj === null) {
    return obj;
  }

  // If the provided value is an array, iterate through its elements
  if (Array.isArray(obj)) {
    return obj.map(removeNullValues);
  }

  // If the provided value is an object, recursively remove null values
  const cleanedObj = {};
  for (const key in obj) {
    const value = obj[key];
    // Recursively remove null values from nested objects
    const cleanedValue = removeNullValues(value);
    // Only add key-value pairs where the value is not null
    if (cleanedValue !== null) {
      cleanedObj[key] = cleanedValue;
    }
  }
  return cleanedObj;
}
