import clsx from 'clsx';
import React, { HTMLAttributes } from 'react';

import AhotuInputGroupButton from './AhotuInputGroupButton';
import AhotuInputGroupText from './AhotuInputGroupText';

type Props = { children: JSX.Element[] } & HTMLAttributes<HTMLElement>;

const AhotuFormInputGroup = ({ children, className }: Props) => {
  const filterChildren = children.filter(child => child !== undefined);
  const overlayLeft = filterChildren.find(({ props }) => props.overlay && props.position === 'left');
  const overlayRight = filterChildren.find(({ props }) => props.overlay && props.position === 'right');
  const prependControls = [];
  const control = filterChildren.find(child => child.type !== overlayLeft?.type && child.type !== overlayRight?.type);

  filterChildren.every(child => {
    if (overlayLeft && !overlayRight && child !== overlayLeft) {
      prependControls.push(child);
      return true;
    }

    return false;
  });

  const appendIndex = filterChildren.indexOf(overlayRight);
  const controlIndex = filterChildren.indexOf(control);
  const appendControls =
    controlIndex > -1 ? [...filterChildren.slice(appendIndex === -1 ? controlIndex + 1 : appendIndex + 1)] : [];
  const hasOverlay = overlayLeft || overlayRight;

  const classNames = clsx(
    'ah-form-input-group',
    hasOverlay && !!prependControls.length && 'prepend-controls',
    hasOverlay && !!appendControls.length && 'append-controls',
    className
  );
  const innerClassNames = clsx('ah-form-input-group-inner', overlayLeft && 'prepend', overlayRight && 'append');

  return (
    <div className={classNames}>
      {hasOverlay ? (
        <>
          {!!prependControls.length && prependControls}
          <div className={innerClassNames}>
            {!!overlayLeft && overlayLeft}
            {!!control && control}
            {!!overlayRight && overlayRight}
          </div>
          {!!appendControls.length && appendControls}
        </>
      ) : (
        filterChildren
      )}
    </div>
  );
};

AhotuFormInputGroup.Button = AhotuInputGroupButton;
AhotuFormInputGroup.Text = AhotuInputGroupText;

export default AhotuFormInputGroup;
