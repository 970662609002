import React, { forwardRef, TextareaHTMLAttributes } from 'react';

import clsxm from '../../../lib/clsxm';

type Props = {
  isInvalid?: boolean;
  className?: string;
} & TextareaHTMLAttributes<HTMLTextAreaElement>;

const AhotuFormTextArea = forwardRef<HTMLTextAreaElement, Props>(({ isInvalid, className, ...props }: Props, ref) => {
  const classNames = clsxm('ah-form-control ah-form-textarea', isInvalid && 'is-invalid', className);

  return <textarea className={classNames} ref={ref} {...props} />;
});

AhotuFormTextArea.displayName = 'AhotuFormTextArea';

export default AhotuFormTextArea;
