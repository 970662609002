import React, { createContext, useCallback, useContext, useMemo, useState } from 'react';

// Define the context type
interface SearchData {
  showMap: boolean;
  count: number;
  events: any[];
  limit: number;
}

// Create the context
const SearchContext = createContext<any | undefined>(undefined);

// Create a provider component
export const SearchProvider = ({ children }) => {
  const [searchData, setSearchData] = useState({
    gtagSearchResults: null,
    showMap: false,
    count: 0,
    events: [],
    limit: 200
  });
  const keepPreviousState = useCallback(
    (newData: Partial<SearchData>) =>
      setSearchData(prevState => ({
        ...prevState,
        ...newData // Directly merge newData into the previous state
      })),
    [setSearchData]
  );

  const memoizedValue = useMemo(() => {
    return {
      searchData,
      setSearchData: keepPreviousState
    };
  }, [searchData, keepPreviousState]);

  return <SearchContext.Provider value={memoizedValue}>{children}</SearchContext.Provider>;
};

// Custom hook to use the SearchContext
export const useSearchContext = () => {
  const context = useContext(SearchContext);
  if (context === undefined) {
    throw new Error('useSearchContext must be used within a SearchProvider');
  }
  return context;
};
