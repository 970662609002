import clsx from 'clsx';
import React, { forwardRef, SelectHTMLAttributes } from 'react';

type Props = {
  isInvalid?: boolean;
} & SelectHTMLAttributes<HTMLSelectElement>;

const AhotuFormSelect = forwardRef<HTMLSelectElement, Props>(({ className, isInvalid, ...props }: Props, ref) => {
  const classNames = clsx('ah-form-control ah-form-select', isInvalid && 'is-invalid', className);
  const { placeholder = 'Please select', children, ...rest } = props;

  return (
    <select className={classNames} ref={ref} {...rest}>
      <option value="" disabled hidden>
        {placeholder}
      </option>
      {children}
    </select>
  );
});

AhotuFormSelect.displayName = 'AhotuFormSelect';

export default AhotuFormSelect;
