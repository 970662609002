import { useTranslation } from 'next-i18next';
import { create as array } from 'yup/lib/array';
import { create as bool } from 'yup/lib/boolean';
import { create as number } from 'yup/lib/number';
import { create as object } from 'yup/lib/object';
import { create as string } from 'yup/lib/string';

import { DEFAULT_EDITION_ID_OPTIONS, DEFAULT_RACE_ID_OPTIONS } from '../lib/reviews/constants';

const nullableNumber = number()
  .nullable()
  .transform((value, originalValue) => {
    if (originalValue === '') return null;
    return value;
  });

const useValidationSchema = () => {
  const { t } = useTranslation();
  const requiredValidationMsg = t(`validation:required`);

  const validUrl = (value: string) => {
    if (!value) return true;

    let givenURL;

    try {
      givenURL = new URL(value);
    } catch (error) {
      return false;
    }

    return givenURL.protocol === 'http:' || givenURL.protocol === 'https:';
  };

  const validUrlTest = {
    name: 'validUrl',
    message: t(`validation:validUrl`),
    test: validUrl
  };

  const locationSchema = object().shape({
    city: string().nullable(),
    country: string().required(requiredValidationMsg).nullable(),
    has_state: bool().optional(),
    lat: number().nullable(),
    lon: number().nullable(),
    region_1: number().nullable(),
    region_2: number().nullable()
  });

  const activitySchema = object().shape(
    {
      distance_unit_id: nullableNumber
        .when('distance', {
          is: val => val,
          then: schema => schema.required(t('validation:distanceUnit'))
        })
        .when('unknown_distance', {
          is: val => val,
          then: schema => schema.notRequired()
        }),
      distance: string()
        .nullable()
        .required(t('validation:distance'))
        .when('distance_unit_id', {
          is: val => val,
          then: schema => schema.required(requiredValidationMsg)
        })
        .when('unknown_distance', {
          is: val => val,
          then: schema => schema.notRequired()
        }),
      elevation_drop: string()
        .nullable()
        .when('elevation_drop_unit_id', {
          is: val => val,
          then: schema => schema.required(requiredValidationMsg)
        }),
      elevation_drop_unit_id: nullableNumber.when('elevation_drop', {
        is: val => val,
        then: schema => schema.required(requiredValidationMsg),
        otherwise: schema => schema.transform(() => null)
      }),
      elevation_gain_unit_id: nullableNumber.when('elevation_gain', {
        is: val => val,
        then: schema => schema.required(requiredValidationMsg),
        otherwise: schema => schema.transform(() => null)
      }),
      elevation_gain: string()
        .nullable()
        .when('elevation_gain_unit_id', {
          is: val => val,
          then: schema => schema.required(requiredValidationMsg)
        }),
      max_elevation_unit_id: nullableNumber.when('max_elevation', {
        is: val => val,
        then: schema => schema.required(requiredValidationMsg),
        otherwise: schema => schema.transform(() => null)
      }),
      max_elevation: string()
        .nullable()
        .when('max_elevation_unit_id', {
          is: val => val,
          then: schema => schema.required(requiredValidationMsg)
        }),
      min_elevation_unit_id: nullableNumber.when('min_elevation', {
        is: val => val,
        then: schema => schema.required(requiredValidationMsg),
        otherwise: schema => schema.transform(() => null)
      }),
      min_elevation: string()
        .nullable()
        .when('min_elevation_unit_id', {
          is: val => val,
          then: schema => schema.required(requiredValidationMsg)
        }),
      sport: object().shape({
        id: number(),
        code: string()
      })
    },
    [
      ['distance', 'distance_unit_id'],
      ['elevation_drop', 'elevation_drop_unit_id'],
      ['elevation_gain', 'elevation_gain_unit_id'],
      ['max_elevation', 'max_elevation_unit_id'],
      ['min_elevation', 'min_elevation_unit_id']
    ]
  );

  const raceSchema = object().shape({
    activities: array().of(activitySchema),
    sport: object().shape({
      id: number(),
      code: string()
    }),
    date: string().nullable(),
    name: string(),
    venue: string(),
    minimum_age: number()
      .nullable()
      .transform((value, originalValue) => {
        if (originalValue === 0) return null;
        else if (Number.isNaN(originalValue)) return null;
        return value;
      })
  });

  const editionSchema = object().shape({
    a_location: locationSchema.nullable(),
    a_races: array().of(raceSchema),
    date: string().required(requiredValidationMsg).nullable(),
    max_participants: number().nullable(),
    registration_begins_on: string().nullable(),
    registration_ends_on: string().nullable(),
    registration_url: string().nullable().test(validUrlTest),
    status: string()
  });

  const eventSchema = object().shape({
    contact_email: string().nullable().email(t('validation:validEmail')),
    facebook: string().nullable().test(validUrlTest),
    instagram: string().nullable().test(validUrlTest),
    name: string().nullable().required(requiredValidationMsg),
    twitter: string().nullable().test(validUrlTest),
    url: string().nullable().test(validUrlTest),
    youtube: string().nullable().test(validUrlTest)
  });

  return {
    editionSchema,
    eventSchema
  };
};

export default useValidationSchema;

export const useWriteReviewValidationSchema = () => {
  const { t } = useTranslation();
  return object().shape({
    rating: number()
      .typeError('')
      .nullable()
      .required(t(`validation:at-least-one-star`))
      .transform((value, originalValue) => {
        //after 2 save with error => input value === "" but field needs to be null=> hence transform
        if (originalValue === '') return null;
        return value;
      }),
    edition: object()
      .shape({
        value: string().nullable()
      })
      .transform((value, originalValue) => {
        //after 2 save with error => input value === "" but field needs to be null=> hence transform
        if (originalValue === '') return DEFAULT_EDITION_ID_OPTIONS;
        return value;
      }),
    race: object()
      .shape({
        value: string().nullable()
      })
      .nullable()
      .transform((value, originalValue) => {
        //after 2 save with error => input value === "" but field needs to be null=> hence transform
        if (originalValue === '') return DEFAULT_RACE_ID_OPTIONS;
        return value;
      }),
    text: string()
      .typeError('')
      .transform((value, originalValue) => {
        //after 2 save with error => input value === "" but field needs to be null=> hence transform
        if (originalValue === '') return '';
        return value;
      }),
    isAuthentic: bool().oneOf([true], t('validation:only-word-required'))
  });
};
