import clsx from 'clsx';
import { HTMLAttributes } from 'react';

type Props = { type?: 'valid' | 'invalid' } & HTMLAttributes<HTMLElement>;

const AhotuFormFeedback = ({ children, className, type = 'valid' }: Props) => {
  const classNames = clsx('ah-form-feedback', type === 'invalid' && 'is-invalid', className);

  return <div className={classNames}>{children}</div>;
};

export default AhotuFormFeedback;
