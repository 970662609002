import Image from 'next/image';
import { useRouter } from 'next/router';
import { signOut, useSession } from 'next-auth/react';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { useUserContext } from '~/src/contexts/UserProvider/UserProvider';
import { getLocalizedProperty } from '~/src/lib/localization';
import { routes } from '~/src/utils/routes';

import tailwindConfig from '../../../tailwind.config';
import AhotuLink from '../atoms/AhotuLink';
import { AhotuPerson, Bookmark, GearsIcon, LogoutIcon, MedalIcon } from '../icons/icons';

interface AccountNavigationProps {
  disablePx?: boolean;
}

const navStyles = /*tw*/ `flex p-4 flex-col items-start self-stretch hover:cursor-pointer hover:bg-menuHover`;
const navInnerStyles = /*tw*/ 'flex h-8 items-center justify-start content-s self-stretch gap-2';

const AccountNavigation: React.FC<AccountNavigationProps> = ({ disablePx = false }) => {
  const { user } = useUserContext();
  const isOrganizer = user?.role?.toLowerCase() === 'organiser';

  const router = useRouter();
  const fragmentSplit = router.asPath.split('#');
  const navigation = decodeURIComponent(fragmentSplit.length === 2 ? fragmentSplit[1] : '').toLowerCase();

  const { t } = useTranslation();
  const profileFragment = t('common:profile-page-fragments-profile').toLowerCase();
  const organizerFragment = t('common:profile-page-fragments-organizer').toLowerCase();
  const achievementsFragment = t('common:profile-page-fragments-achievements').toLowerCase();
  const eventsFragment = t('common:profile-page-fragments-events').toLowerCase();

  return (
    <>
      <Navigation
        disablePx={disablePx}
        fragment={profileFragment}
        isSelected={
          navigation === profileFragment ||
          (router.route === '/profile/[[...userPermalink]]' &&
            navigation !== profileFragment &&
            navigation !== eventsFragment &&
            navigation !== organizerFragment &&
            navigation !== achievementsFragment)
        }
        id="upc_nav_profile"
        label={t('common:navmenu-profile')}>
        <div className="h-8 w-8 ah-profile-avatar m-0">
          {user?.avatar ? (
            <Image
              src={user.avatar}
              alt={`Avatar of ${user.username}`}
              layout="fill"
              sizes="128px"
              objectFit="cover"
              placeholder="empty"
            />
          ) : (
            <AhotuPerson className="w-16 h-16" fill={tailwindConfig.theme.colors['green']['70']} />
          )}
        </div>
      </Navigation>

      {isOrganizer && (
        <>
          <Navigation
            disablePx={disablePx}
            fragment={organizerFragment}
            isSelected={navigation === organizerFragment}
            id="upc_nav_organizer"
            label={t('common:navmenu-organizerevents')}>
            <div className="flex items-center justify-center h-8 w-8">
              <GearsIcon />
            </div>
          </Navigation>
          <hr className="text-hr hidden lg:block" />
        </>
      )}

      <Navigation
        disablePx={disablePx}
        fragment={eventsFragment}
        isSelected={navigation === eventsFragment}
        id="upc_nav_events"
        label={t('common:navmenu-savedevents')}>
        <div>
          <div className="flex items-center justify-center h-8 w-8">
            <Bookmark />
          </div>
        </div>
      </Navigation>

      <Navigation
        disablePx={disablePx}
        fragment={achievementsFragment}
        isSelected={navigation === achievementsFragment}
        id="upc_nav_achievements"
        label={t('common:navmenu-achievements')}>
        <MedalIcon showNumber={false} className="h-6 w-6 m-1" />
      </Navigation>

      <hr className="text-hr hidden lg:block" />

      <Navigation
        disablePx={disablePx}
        onClick={() => signOut({ callbackUrl: router.asPath })}
        isSelected={false}
        id="upc_nav_logout"
        fragment={'foo'} //Doesn't do anything since onClick is provided - leave it as is or fix ts def, not worth
        label={t('common:navmenu-logout')}>
        <div>
          <LogoutIcon />
        </div>
      </Navigation>
    </>
  );
};

const Navigation = ({
  id,
  label,
  children,
  isSelected,
  fragment,
  onClick,
  disablePx
}: {
  id: string;
  label: string;
  children: ReactElement | ReactElement[];
  isSelected: boolean;
  fragment: string;
  onClick?: () => void;
  disablePx?: boolean;
}) => {
  const router = useRouter();
  const { data: session } = useSession();

  return onClick ? (
    <div className={navStyles + (disablePx ? ' px-0' : '')} id={id} onClick={onClick}>
      <div className={navInnerStyles}>
        {children}
        <span className={isSelected ? 'font-bold' : ''}>{label}</span>
      </div>
    </div>
  ) : (
    <AhotuLink
      className={navStyles + (disablePx ? ' px-0' : '')}
      id={id}
      href={`${getLocalizedProperty(routes.profile, router.locale)}/${session.permalink}/#${fragment}`}>
      <div className={navInnerStyles}>
        {children}
        <span className={isSelected ? 'font-bold' : ''}>{label}</span>
      </div>
    </AhotuLink>
  );
};

export default AccountNavigation;
