import clsx from 'clsx';
import React, { forwardRef, InputHTMLAttributes } from 'react';

type Props = {
  isInvalid?: boolean;
} & InputHTMLAttributes<HTMLInputElement>;

const AhotuFormInput = forwardRef<HTMLInputElement, Props>(
  ({ className, isInvalid, type = 'text', ...rest }: Props, ref) => {
    const classNames = clsx('ah-form-control ah-form-input', isInvalid && 'is-invalid', className);

    return <input className={classNames} type={type} {...rest} ref={ref} />;
  }
);

AhotuFormInput.displayName = 'AhotuFormInput';

export default AhotuFormInput;
