import React, { createContext, useCallback, useContext, useMemo, useState } from 'react';

import { getLinkMessage } from '~/src/components/organisms/Snackbar';

// Define the context type
interface SnackbarContextType {
  queue: (message: string | ReturnType<typeof getLinkMessage> | undefined, isError?: boolean) => void;
  snackbarMessage: string | ReturnType<typeof getLinkMessage> | undefined;
  isError: boolean;
}

// Ensure SnackbarContext is properly initialized
const SnackbarContext = createContext<SnackbarContextType | undefined>(undefined);

// Create a provider component
export const SnackbarProvider = ({ children }) => {
  const [snackbarState, setSnackbarState] = useState<{
    message: string | ReturnType<typeof getLinkMessage> | undefined;
    isError: boolean;
  }>({ message: undefined, isError: false });

  const queue = useCallback(
    (message: string | ReturnType<typeof getLinkMessage> | undefined, isError: boolean = false) => {
      setSnackbarState({ message, isError });
      setTimeout(() => {
        setSnackbarState({ message: undefined, isError: false });
      }, 3500); // Automatically clear the message after 3.5 seconds
    },
    []
  );

  const memoizedValue = useMemo(
    () => ({
      queue,
      snackbarMessage: snackbarState.message, // Exposing the message
      isError: snackbarState.isError // Exposing the error state
    }),
    [snackbarState, queue]
  );

  return <SnackbarContext.Provider value={memoizedValue}>{children}</SnackbarContext.Provider>;
};

// Custom hook to use the SnackbarContext
export const useSnackbarContext = () => {
  const context = useContext(SnackbarContext);
  if (context === undefined) {
    throw new Error('useSnackbarContext must be used within a SnackbarProvider');
  }
  return context;
};
