import { StandardReference } from '../types/referenceTypes';
const dict: StandardReference = {
  ar: {
    names: {
      en: 'Arabic',
      fr: "L'arabe",
      de: 'Arabisch',
      es: 'Árabe',
      pt: 'Árabe',
      pl: 'Arabski',
      'pt-BR': 'Árabe',
      sv: 'Arabiska',
      zh: '阿拉伯语',
      ko: '아랍어',
      ja: 'アラビア語',
      ru: 'Арабский ',
      th: 'อาหรับ',
      it: 'Arabo'
    },
    permalinks: {
      en: 'arabic',
      fr: 'l-arabe',
      de: 'arabisch',
      es: 'arabe',
      pt: 'arabe',
      pl: 'arabski',
      'pt-BR': 'arabe',
      sv: 'arabiska',
      ru: '',
      it: 'arabo'
    }
  },
  bg: {
    names: {
      en: 'Bulgarian',
      fr: 'Bulgare',
      de: 'Bulgarisch',
      es: 'Búlgaro',
      pt: 'Búlgaro',
      pl: 'Bułgarski',
      'pt-BR': 'Búlgaro',
      sv: 'Bulgariska',
      zh: '保加利亚语',
      ko: '불가리아어',
      ja: 'ブルガリア語',
      ru: 'Болгарский',
      th: 'บัลแกเรีย',
      it: 'Bulgaro'
    },
    permalinks: {
      en: 'bulgarian',
      fr: 'bulgare',
      de: 'bulgarisch',
      es: 'bulgaro',
      pt: 'bulgaro',
      pl: 'bulgarski',
      'pt-BR': 'bulgaro',
      sv: 'bulgariska',
      ru: '',
      it: 'bulgaro'
    }
  },
  bs: {
    names: {
      en: 'Bosnian',
      fr: 'Bosniaque',
      de: 'Bosnisch',
      es: 'Bosnio',
      pt: 'Bósnio',
      pl: 'Bośniacki',
      'pt-BR': 'Bósnio',
      sv: 'Bosniska',
      zh: '波斯尼亚语',
      ko: '보스니아어',
      ja: 'ボスニア語',
      ru: 'Боснийский',
      th: 'บอสเนีย',
      it: 'Bosniaco'
    },
    permalinks: {
      en: 'bosnian',
      fr: 'bosniaque',
      de: 'bosnisch',
      es: 'bosnio',
      pt: 'bosnio',
      pl: 'bosniacki',
      'pt-BR': 'bosnio',
      sv: 'bosniska',
      ru: '',
      it: 'bosniaco'
    }
  },
  ca: {
    names: {
      en: 'Catalan',
      fr: 'Catalan',
      de: 'Katalanisch',
      es: 'Catalán',
      pt: 'Catalão',
      pl: 'Kataloński',
      'pt-BR': 'Catalão',
      sv: 'Katalanska',
      zh: '加泰罗尼亚语',
      ko: '카탈루냐어',
      ja: 'カタロニア語',
      ru: 'Каталонский',
      th: 'คาตาลัน',
      it: 'Catalano'
    },
    permalinks: {
      en: 'catalan',
      fr: 'catalan',
      de: 'katalanisch',
      es: 'catalan',
      pt: 'catalao',
      pl: 'katalonski',
      'pt-BR': 'catalao',
      sv: 'katalanska',
      ru: '',
      it: 'catalano'
    }
  },
  cs: {
    names: {
      en: 'Czech',
      fr: 'Tchèque',
      de: 'Tschechisch',
      es: 'Checo',
      pt: 'Checo',
      pl: 'Czeski',
      'pt-BR': 'Tcheco',
      sv: 'Tjeckiska',
      zh: '捷克语',
      ko: '체코어',
      ja: 'チェコ語',
      ru: 'Чешский',
      th: 'เช็ก',
      it: 'Ceco'
    },
    permalinks: {
      en: 'czech',
      fr: 'tcheque',
      de: 'tschechisch',
      es: 'checo',
      pt: 'checo',
      pl: 'czeski',
      'pt-BR': 'tcheco',
      sv: 'tjeckiska',
      ru: '',
      it: 'ceco'
    }
  },
  da: {
    names: {
      en: 'Danish',
      fr: 'Danois',
      de: 'Dänisch',
      es: 'Danés',
      pt: 'Dinamarquês',
      pl: 'Duński',
      'pt-BR': 'Dinamarquês',
      sv: 'Danska',
      zh: '丹麦语',
      ko: '덴마크어',
      ja: 'デンマーク語',
      ru: 'Датский',
      th: 'เดนมาร์ก',
      it: 'Danese'
    },
    permalinks: {
      en: 'danish',
      fr: 'danois',
      de: 'danisch',
      es: 'danes',
      pt: 'dinamarques',
      pl: 'dunski',
      'pt-BR': 'dinamarques',
      sv: 'danska',
      ru: '',
      it: 'danese'
    }
  },
  de: {
    names: {
      en: 'German',
      fr: 'Allemand',
      de: 'Deutsch',
      es: 'Alemán',
      pt: 'Alemão',
      pl: 'Niemiecki',
      'pt-BR': 'Alemão',
      sv: 'Tyska',
      zh: '德语',
      ko: '독일어',
      ja: 'ドイツ語',
      ru: 'Немецкий ',
      th: 'เยอรมัน',
      it: 'Tedesco'
    },
    permalinks: {
      en: 'german',
      fr: 'allemand',
      de: 'deutsch',
      es: 'aleman',
      pt: 'alemao',
      pl: 'niemiecki',
      'pt-BR': 'alemao',
      sv: 'tyska',
      ru: '',
      it: 'tedesco'
    }
  },
  el: {
    names: {
      en: 'Greek',
      fr: 'Grecque',
      de: 'Griechisch',
      es: 'Griego',
      pt: 'Grego',
      pl: 'Grecki',
      'pt-BR': 'Grego',
      sv: 'Grekiska',
      zh: '希腊语',
      ko: '그리스어',
      ja: 'ギリシャ語',
      ru: 'Греческий',
      th: 'กรีก',
      it: 'Greco'
    },
    permalinks: {
      en: 'greek',
      fr: 'grecque',
      de: 'griechisch',
      es: 'griego',
      pt: 'grego',
      pl: 'grecki',
      'pt-BR': 'grego',
      sv: 'grekiska',
      ru: '',
      it: 'greco'
    }
  },
  en: {
    names: {
      en: 'English',
      fr: 'Anglais',
      de: 'Englisch',
      es: 'Inglés',
      pt: 'Inglês',
      pl: 'Angielski',
      'pt-BR': 'Inglês',
      sv: 'Engelska',
      zh: '英语',
      ko: '영어',
      ja: '英語',
      ru: 'Английский ',
      th: 'อังกฤษ',
      it: 'Inglese'
    },
    permalinks: {
      en: 'english',
      fr: 'anglais',
      de: 'englisch',
      es: 'ingles',
      pt: 'ingles',
      pl: 'angielski',
      'pt-BR': 'ingles',
      sv: 'engelska',
      ru: '',
      it: 'inglese'
    }
  },
  es: {
    names: {
      en: 'Spanish',
      fr: 'Espagnol',
      de: 'Spanisch',
      es: 'Español',
      pt: 'Espanhol',
      pl: 'Hiszpański',
      'pt-BR': 'Espanhol',
      sv: 'Spanska',
      zh: '西班牙语',
      ko: '스페인어',
      ja: 'スペイン語',
      ru: 'Испанский',
      th: 'สเปน',
      it: 'Spagnolo'
    },
    permalinks: {
      en: 'spanish',
      fr: 'espagnol',
      de: 'spanisch',
      es: 'espanol',
      pt: 'espanhol',
      pl: 'hiszpanski',
      'pt-BR': 'espanhol',
      sv: 'spanska',
      ru: '',
      it: 'spagnolo'
    }
  },
  et: {
    names: {
      en: 'Estonian',
      fr: 'Estonien',
      de: 'Estnisch',
      es: 'Estonio',
      pt: 'Estónio',
      pl: 'Estoński',
      'pt-BR': 'Estoniano',
      sv: 'Estniska',
      zh: '爱沙尼亚语',
      ko: '에스토니아어',
      ja: 'エストニア語',
      ru: 'Эстонский',
      th: 'เอสโตเนีย',
      it: 'Estone'
    },
    permalinks: {
      en: 'estonian',
      fr: 'estonien',
      de: 'estnisch',
      es: 'estonio',
      pt: 'estonio',
      pl: 'estonski',
      'pt-BR': 'estoniano',
      sv: 'estniska',
      ru: '',
      it: 'estone'
    }
  },
  eu: {
    names: {
      en: 'Basque',
      fr: 'Basque',
      de: 'Baskisch',
      es: 'Vasco',
      pt: 'Basco',
      pl: 'Baskijski',
      'pt-BR': 'Basco',
      sv: 'Baskiska',
      zh: '巴斯克语',
      ko: '바스크어',
      ja: 'バスク語',
      ru: 'Баскский',
      th: 'บาสก์',
      it: 'Basco'
    },
    permalinks: {
      en: 'basque',
      fr: 'basque',
      de: 'baskisch',
      es: 'vasco',
      pt: 'basco',
      pl: 'baskijski',
      'pt-BR': 'basco',
      sv: 'baskiska',
      ru: '',
      it: 'basco'
    }
  },
  fi: {
    names: {
      en: 'Finnish',
      fr: 'Finlandais',
      de: 'Finnisch',
      es: 'Finés',
      pt: 'Finlandês',
      pl: 'Fiński',
      'pt-BR': 'Finlandês',
      sv: 'Finska',
      zh: '芬兰语',
      ko: '핀란드어',
      ja: 'フィンランド語',
      ru: 'Финский',
      th: 'ฟินแลนด์',
      it: 'Finlandese'
    },
    permalinks: {
      en: 'finnish',
      fr: 'finlandais',
      de: 'finnisch',
      es: 'fines',
      pt: 'finlandes',
      pl: 'finski',
      'pt-BR': 'finlandes',
      sv: 'finska',
      ru: '',
      it: 'finlandese'
    }
  },
  fr: {
    names: {
      en: 'French',
      fr: 'Français',
      de: 'Französisch',
      es: 'Francés',
      pt: 'Francês',
      pl: 'Francuski',
      'pt-BR': 'Francês',
      sv: 'Franska',
      zh: '法语',
      ko: '프랑스어',
      ja: 'フランス語',
      ru: 'Французский',
      th: 'ฝรั่งเศส',
      it: 'Francese'
    },
    permalinks: {
      en: 'french',
      fr: 'francais',
      de: 'franzosisch',
      es: 'frances',
      pt: 'frances',
      pl: 'francuski',
      'pt-BR': 'frances',
      sv: 'franska',
      ru: '',
      it: 'francese'
    }
  },
  he: {
    names: {
      en: 'Hebrew',
      fr: 'Hébreu',
      de: 'Hebräisch',
      es: 'Hebreo',
      pt: 'Hebraico',
      pl: 'Hebrajski',
      'pt-BR': 'Hebraico',
      sv: 'Hebreiska',
      zh: '希伯来文',
      ko: '히브리어',
      ja: 'ヘブライ語',
      ru: 'Иврит',
      th: 'ฮีบรู',
      it: 'Ebraico'
    },
    permalinks: {
      en: 'hebrew',
      fr: 'hebreu',
      de: 'hebraisch',
      es: 'hebreo',
      pt: 'hebraico',
      pl: 'hebrajski',
      'pt-BR': 'hebraico',
      sv: 'hebreiska',
      ru: '',
      it: 'ebraico'
    }
  },
  hr: {
    names: {
      en: 'Croatian',
      fr: 'Croate',
      de: 'Kroatisch',
      es: 'Croata',
      pt: 'Croata',
      pl: 'Chorwacki',
      'pt-BR': 'Croata',
      sv: 'Kroatiska',
      zh: '克罗地亚语',
      ko: '크로아티아어',
      ja: 'クロアチア語',
      ru: 'Хорватский',
      th: 'โครเอเชียน',
      it: 'Croato'
    },
    permalinks: {
      en: 'croatian',
      fr: 'croate',
      de: 'kroatisch',
      es: 'croata',
      pt: 'croata',
      pl: 'chorwacki',
      'pt-BR': 'croata',
      sv: 'kroatiska',
      ru: '',
      it: 'croato'
    }
  },
  hu: {
    names: {
      en: 'Hungarian',
      fr: 'Hongrois',
      de: 'Ungarisch',
      es: 'Húngaro',
      pt: 'Húngaro',
      pl: 'Węgierski',
      'pt-BR': 'Húngaro',
      sv: 'Ungerska',
      zh: '匈牙利语',
      ko: '헝가리어',
      ja: 'ハンガリー語',
      ru: 'Венгерский',
      th: 'ฮังการี',
      it: 'Ungherese'
    },
    permalinks: {
      en: 'hungarian',
      fr: 'hongrois',
      de: 'ungarisch',
      es: 'hungaro',
      pt: 'hungaro',
      pl: 'wegierski',
      'pt-BR': 'hungaro',
      sv: 'ungerska',
      ru: '',
      it: 'ungherese'
    }
  },
  hy: {
    names: {
      en: 'Armenian',
      fr: 'Arménien',
      de: 'Armenisch',
      es: 'Armenio',
      pt: 'Arménio',
      pl: 'Ormiański',
      'pt-BR': 'Armênio',
      sv: 'Armeniska',
      zh: '亚美尼亚语',
      ko: '아르메니아어',
      ja: 'アルメニア語',
      ru: 'Армянский',
      th: 'อาร์เมเนีย',
      it: 'Armeno'
    },
    permalinks: {
      en: 'armenian',
      fr: 'armenien',
      de: 'armenisch',
      es: 'armenio',
      pt: 'armenio',
      pl: 'ormianski',
      'pt-BR': 'armenio',
      sv: 'armeniska',
      ru: '',
      it: 'armeno'
    }
  },
  id: {
    names: {
      en: 'Indonesian',
      fr: 'Indonésien',
      de: 'Indonesisch',
      es: 'Indonesio',
      pt: 'Indonésio',
      pl: 'Indonezyjski',
      'pt-BR': 'Indonésio',
      sv: 'Indonesiska',
      zh: '印尼语',
      ko: '인도네시아어',
      ja: 'インドネシア語',
      ru: 'Индонезийский',
      th: 'อินโดนีเซีย',
      it: 'Indonesiano'
    },
    permalinks: {
      en: 'indonesian',
      fr: 'indonesien',
      de: 'indonesisch',
      es: 'indonesio',
      pt: 'indonesio',
      pl: 'indonezyjski',
      'pt-BR': 'indonesio',
      sv: 'indonesiska',
      ru: '',
      it: 'indonesiano'
    }
  },
  is: {
    names: {
      en: 'Icelandic',
      fr: 'Islandais',
      de: 'Isländisch',
      es: 'Islandés',
      pt: 'Islandês',
      pl: 'Islandzki',
      'pt-BR': 'Islandês',
      sv: 'Isländska',
      zh: '冰岛',
      ko: '아이슬란드어',
      ja: 'アイスランド語',
      ru: 'Исландский',
      th: 'ไอซ์แลนด์',
      it: 'Islandese'
    },
    permalinks: {
      en: 'icelandic',
      fr: 'islandais',
      de: 'islandisch',
      es: 'islandes',
      pt: 'islandes',
      pl: 'islandzki',
      'pt-BR': 'islandes',
      sv: 'islandska',
      ru: '',
      it: 'islandese'
    }
  },
  it: {
    names: {
      en: 'Italian',
      fr: 'Italien',
      de: 'Italienisch',
      es: 'Italiano',
      pt: 'Italiano',
      pl: 'Włoski',
      'pt-BR': 'Italiano',
      sv: 'Italienska',
      zh: '意大利语',
      ko: '이탈리아어',
      ja: 'イタリア語',
      ru: 'Итальянский',
      th: 'อิตาลี',
      it: 'Italiano'
    },
    permalinks: {
      en: 'italian',
      fr: 'italien',
      de: 'italienisch',
      es: 'italiano',
      pt: 'italiano',
      pl: 'wloski',
      'pt-BR': 'italiano',
      sv: 'italienska',
      ru: '',
      it: 'italiano'
    }
  },
  ja: {
    names: {
      en: 'Japanese',
      fr: 'Japonais',
      de: 'Japanisch',
      es: 'Japonés',
      pt: 'Japonês',
      pl: 'Japoński',
      'pt-BR': 'Japonês',
      sv: 'Japanska',
      zh: '日语',
      ko: '일본어',
      ja: '日本語',
      ru: 'Японский',
      th: 'ญี่ปุ่น',
      it: 'Giapponese'
    },
    permalinks: {
      en: 'japanese',
      fr: 'japonais',
      de: 'japanisch',
      es: 'japones',
      pt: 'japones',
      pl: 'japonski',
      'pt-BR': 'japones',
      sv: 'japanska',
      ru: '',
      it: 'giapponese'
    }
  },
  kl: {
    names: {
      en: 'Greenlandic',
      fr: 'Groenlandais',
      de: 'Grönländisch',
      es: 'Groenlandés',
      pt: 'Gronelandês',
      pl: 'Grenlandzki',
      'pt-BR': 'Groenlandês',
      sv: 'Grönländska',
      zh: '格林兰语',
      ko: '그린란드어',
      ja: 'グリーンランド語',
      ru: 'Гренландский',
      th: 'กรีนแลนด์',
      it: 'Groenlandese'
    },
    permalinks: {
      en: 'greenlandic',
      fr: 'groenlandais',
      de: 'gronlandisch',
      es: 'groenlandes',
      pt: 'gronelandes',
      pl: 'grenlandzki',
      'pt-BR': 'groenlandes',
      sv: 'gronlandska',
      ru: '',
      it: 'groenlandese'
    }
  },
  ko: {
    names: {
      en: 'Korean',
      fr: 'Coréen',
      de: 'Koreanisch',
      es: 'Coreano',
      pt: 'Coreano',
      pl: 'Koreański',
      'pt-BR': 'Coreano',
      sv: 'Koreanska',
      zh: '韩语',
      ko: '한국어',
      ja: '韓国語',
      ru: 'Корейский',
      th: 'เกาหลี',
      it: 'Coreano'
    },
    permalinks: {
      en: 'korean',
      fr: 'coreen',
      de: 'koreanisch',
      es: 'coreano',
      pt: 'coreano',
      pl: 'koreanski',
      'pt-BR': 'coreano',
      sv: 'koreanska',
      ru: '',
      it: 'coreano'
    }
  },
  lb: {
    names: {
      en: 'Luxembourgish',
      fr: 'Luxembourgeois',
      de: 'Luxemburgisch',
      es: 'Luxemburgués',
      pt: 'Luxemburguês',
      pl: 'Luksemburski',
      'pt-BR': 'Luxemburguês',
      sv: 'Luxemburgska',
      zh: '卢森堡语',
      ko: '룩셈부르크어',
      ja: 'ルクセンブルク語',
      ru: 'Люксембургский',
      th: 'ลักเซมเบิร์ก',
      it: 'Lussemburghese'
    },
    permalinks: {
      en: 'luxembourgish',
      fr: 'luxembourgeois',
      de: 'luxemburgisch',
      es: 'luxemburgues',
      pt: 'luxemburgues',
      pl: 'luksemburski',
      'pt-BR': 'luxemburgues',
      sv: 'luxemburgska',
      ru: '',
      it: 'lussemburghese'
    }
  },
  lt: {
    names: {
      en: 'Lithuanian',
      fr: 'Lituanien',
      de: 'Litauisch',
      es: 'Lituano',
      pt: 'Lituano',
      pl: 'Litewski',
      'pt-BR': 'Lituano',
      sv: 'Litauiska',
      zh: '立陶宛语',
      ko: '리투아니아어',
      ja: 'リトアニア語',
      ru: 'Литовский',
      th: 'ลิทัวเนีย',
      it: 'Lituano'
    },
    permalinks: {
      en: 'lithuanian',
      fr: 'lituanien',
      de: 'litauisch',
      es: 'lituano',
      pt: 'lituano',
      pl: 'litewski',
      'pt-BR': 'lituano',
      sv: 'litauiska',
      ru: '',
      it: 'lituano'
    }
  },
  lv: {
    names: {
      en: 'Latvian',
      fr: 'Letton',
      de: 'Lettisch',
      es: 'Letón',
      pt: 'Letão',
      pl: 'Łotewski',
      'pt-BR': 'Letão',
      sv: 'Lettiska',
      zh: '拉脱维亚语',
      ko: '라트비아어',
      ja: 'ラトビア語',
      ru: 'Латвийский',
      th: 'ลัตเวีย',
      it: 'Lettone'
    },
    permalinks: {
      en: 'latvian',
      fr: 'letton',
      de: 'lettisch',
      es: 'leton',
      pt: 'letao',
      pl: 'lotewski',
      'pt-BR': 'letao',
      sv: 'lettiska',
      ru: '',
      it: 'lettone'
    }
  },
  me: {
    names: {
      en: 'Montenegrin',
      fr: 'Monténégrin',
      de: 'Montenegrinisch',
      es: 'Montenegrino',
      pt: 'Montenegrino',
      pl: 'Czarnogórski',
      'pt-BR': 'Montenegrino',
      sv: 'Montenegrinska',
      zh: '黑山共和国语',
      ko: '몬테네그로어',
      ja: 'モンテネグロ語',
      ru: 'Черногорский',
      th: 'มอนเตเนโกร',
      it: 'Montenegrino'
    },
    permalinks: {
      en: 'montenegrin',
      fr: 'montenegrin',
      de: 'montenegrinisch',
      es: 'montenegrino',
      pt: 'montenegrino',
      pl: 'czarnogorski',
      'pt-BR': 'montenegrino',
      sv: 'montenegrinska',
      ru: '',
      it: 'montenegrino'
    }
  },
  mk: {
    names: {
      en: 'Macedonian',
      fr: 'Macédonien',
      de: 'Mazedonisch',
      es: 'Macedonio',
      pt: 'Macedónio',
      pl: 'Macedoński',
      'pt-BR': 'Macedônio',
      sv: 'Makedonska',
      zh: '马其顿',
      ko: '마케도니아어',
      ja: 'マケドニア語',
      ru: 'Македонский',
      th: 'มาซิโดเนีย',
      it: 'Macedone'
    },
    permalinks: {
      en: 'macedonian',
      fr: 'macedonien',
      de: 'mazedonisch',
      es: 'macedonio',
      pt: 'macedonio',
      pl: 'macedonski',
      'pt-BR': 'macedonio',
      sv: 'makedonska',
      ru: '',
      it: 'macedone'
    }
  },
  mn: {
    names: {
      en: 'Mongolian',
      fr: 'Mongol',
      de: 'Mongolei',
      es: 'Mongol',
      pt: 'Mongol',
      pl: 'Mongolski',
      'pt-BR': 'Mongol',
      sv: 'Mongoliska',
      zh: '蒙古语',
      ko: '몽골어',
      ja: 'モンゴル語',
      ru: 'Монгольский',
      th: 'มองโกล',
      it: 'Mongolo'
    },
    permalinks: {
      en: 'mongolian',
      fr: 'mongol',
      de: 'mongolei',
      es: 'mongol',
      pt: 'mongol',
      pl: 'mongolski',
      'pt-BR': 'mongol',
      sv: 'mongoliska',
      ru: '',
      it: 'mongolo'
    }
  },
  nl: {
    names: {
      en: 'Dutch',
      fr: 'Néerlandais',
      de: 'Niederländisch',
      es: 'Holandés',
      pt: 'Holandês',
      pl: 'Holenderski',
      'pt-BR': 'Holandês',
      sv: 'Holländska',
      zh: '荷兰语',
      ko: '네덜란드어',
      ja: 'オランダ語',
      ru: 'Голландский',
      th: 'ดัตช์',
      it: 'Olandese'
    },
    permalinks: {
      en: 'dutch',
      fr: 'neerlandais',
      de: 'niederlandisch',
      es: 'holandes',
      pt: 'holandes',
      pl: 'holenderski',
      'pt-BR': 'holandes',
      sv: 'hollandska',
      ru: '',
      it: 'olandese'
    }
  },
  no: {
    names: {
      en: 'Norwegian',
      fr: 'Norvégien',
      de: 'Norwegisch',
      es: 'Noruego',
      pt: 'Norueguês',
      pl: 'Norweski',
      'pt-BR': 'Norueguês',
      sv: 'Norska',
      zh: '挪威语',
      ko: '노르웨이어',
      ja: 'ノルウェー語',
      ru: 'Норвежский',
      th: 'นอร์เวย์',
      it: 'Norvegese'
    },
    permalinks: {
      en: 'norwegian',
      fr: 'norvegien',
      de: 'norwegisch',
      es: 'noruego',
      pt: 'noruegues',
      pl: 'norweski',
      'pt-BR': 'noruegues',
      sv: 'norska',
      ru: '',
      it: 'norvegese'
    }
  },
  pl: {
    names: {
      en: 'Polish',
      fr: 'Polonais',
      de: 'Polnisch',
      es: 'Polaco',
      pt: 'Polaco',
      pl: 'Polski',
      'pt-BR': 'Polonês',
      sv: 'Polska',
      zh: '波兰语',
      ko: '폴란드어',
      ja: 'ポーランド語',
      ru: 'Польский',
      th: 'โปแลนด์',
      it: 'Polacco'
    },
    permalinks: {
      en: 'polish',
      fr: 'polonais',
      de: 'polnisch',
      es: 'polaco',
      pt: 'polaco',
      pl: 'polski',
      'pt-BR': 'polones',
      sv: 'polska',
      ru: '',
      it: 'polacco'
    }
  },
  pt: {
    names: {
      en: 'Portuguese',
      fr: 'Portugais',
      de: 'Portugiesisch',
      es: 'Portugués',
      pt: 'Português',
      pl: 'Portugalski',
      'pt-BR': 'Português',
      sv: 'Portugisiska',
      zh: '葡萄牙语',
      ko: '포르투갈어',
      ja: 'ポルトガル語',
      ru: 'Португальский',
      th: 'โปรตุเกส',
      it: 'Portoghese'
    },
    permalinks: {
      en: 'portuguese',
      fr: 'portugais',
      de: 'portugiesisch',
      es: 'portugues',
      pt: 'portugues',
      pl: 'portugalski',
      'pt-BR': 'portugues',
      sv: 'portugisiska',
      ru: '',
      it: 'portoghese'
    }
  },
  'pt-BR': {
    names: {
      en: 'Portuguese (brazil)',
      fr: 'Portugais (brésil)',
      de: 'Portugiesisch (brasilien)',
      es: 'Portugués (brasil)',
      pt: 'Português (brasil)',
      pl: 'Portugalski (brazylia)',
      'pt-BR': 'Português (brasil)',
      sv: 'Portugisiska (brasilien)',
      zh: '葡萄牙语（巴西）',
      ko: '포르투갈어(브라질)',
      ja: 'ポルトガル語（ブラジル）',
      ru: 'Португальский (бразилия)',
      th: 'โปรตุเกส (บราซิล)',
      it: 'Portoghese (brasile)'
    },
    permalinks: {
      en: 'portuguese-brazil',
      fr: 'portugais-bresil',
      de: 'portugiesisch-brasilien',
      es: 'portugues-brasil',
      pt: 'portugues-brasil',
      pl: 'portugalski-brazylia',
      'pt-BR': 'portugues-brasil',
      sv: 'portugisiska-brasilien',
      ru: '',
      it: 'portoghese-brasile'
    }
  },
  ro: {
    names: {
      en: 'Romanian',
      fr: 'Roumain',
      de: 'Rumänisch',
      es: 'Rumano',
      pt: 'Romeno',
      pl: 'Rumuński',
      'pt-BR': 'Romeno',
      sv: 'Rumänska',
      zh: '罗马尼亚语',
      ko: '루마니아어',
      ja: 'ルーマニア語',
      ru: 'Румынский',
      th: 'โรมาเนีย',
      it: 'Rumeno'
    },
    permalinks: {
      en: 'romanian',
      fr: 'roumain',
      de: 'rumanisch',
      es: 'rumano',
      pt: 'romeno',
      pl: 'rumunski',
      'pt-BR': 'romeno',
      sv: 'rumanska',
      ru: '',
      it: 'rumeno'
    }
  },
  ru: {
    names: {
      en: 'Russian',
      fr: 'Russie',
      de: 'Russisch',
      es: 'Ruso',
      pt: 'Russo',
      pl: 'Rosyjski',
      'pt-BR': 'Russo',
      sv: 'Ryska',
      zh: '俄语',
      ko: '러시아어',
      ja: 'ロシア語',
      ru: 'Русский',
      th: 'รัสเซีย',
      it: 'Russo'
    },
    permalinks: {
      en: 'russian',
      fr: 'russie',
      de: 'russisch',
      es: 'ruso',
      pt: 'russo',
      pl: 'rosyjski',
      'pt-BR': 'russo',
      sv: 'ryska',
      ru: '',
      it: 'russo'
    }
  },
  sk: {
    names: {
      en: 'Slovak',
      fr: 'Slovaque',
      de: 'Slowakisch',
      es: 'Eslovaco',
      pt: 'Eslovaco',
      pl: 'Słowacki',
      'pt-BR': 'Eslovaco',
      sv: 'Slovakiska',
      zh: '斯洛伐克语',
      ko: '슬로바키아어',
      ja: 'スロバキア語',
      ru: 'Словакский',
      th: 'สโลวัก',
      it: 'Slovacco'
    },
    permalinks: {
      en: 'slovak',
      fr: 'slovaque',
      de: 'slowakisch',
      es: 'eslovaco',
      pt: 'eslovaco',
      pl: 'slowacki',
      'pt-BR': 'eslovaco',
      sv: 'slovakiska',
      ru: '',
      it: 'slovacco'
    }
  },
  sl: {
    names: {
      en: 'Slovene',
      fr: 'Slovène',
      de: 'Slowenisch',
      es: 'Esloveno',
      pt: 'Esloveno',
      pl: 'Słoweński',
      'pt-BR': 'Esloveno',
      sv: 'Slovenska',
      zh: '斯洛文尼亚语',
      ko: '슬로베니아',
      ja: 'スロベニア語',
      ru: 'Словенский',
      th: 'สโลวีเนีย',
      it: 'Sloveno'
    },
    permalinks: {
      en: 'slovene',
      fr: 'slovene',
      de: 'slowenisch',
      es: 'esloveno',
      pt: 'esloveno',
      pl: 'slowenski',
      'pt-BR': 'esloveno',
      sv: 'slovenska',
      ru: '',
      it: 'sloveno'
    }
  },
  sr: {
    names: {
      en: 'Serbian',
      fr: 'Serbe',
      de: 'Serbisch',
      es: 'Serbio',
      pt: 'Sérvio',
      pl: 'Serbski',
      'pt-BR': 'Sérvio',
      sv: 'Serbiska',
      zh: '塞尔维亚语',
      ko: '세르비아어',
      ja: 'セルビア語',
      ru: 'Сербский',
      th: 'เซอร์เบีย',
      it: 'Serbo'
    },
    permalinks: {
      en: 'serbian',
      fr: 'serbe',
      de: 'serbisch',
      es: 'serbio',
      pt: 'servio',
      pl: 'serbski',
      'pt-BR': 'servio',
      sv: 'serbiska',
      ru: '',
      it: 'serbo'
    }
  },
  sv: {
    names: {
      en: 'Swedish',
      fr: 'Suédois',
      de: 'Schwedisch',
      es: 'Sueco',
      pt: 'Sueco',
      pl: 'Szwedzki',
      'pt-BR': 'Sueco',
      sv: 'Svenska',
      zh: '瑞典语',
      ko: '스웨덴어',
      ja: 'スウェーデン語',
      ru: 'Шведский',
      th: 'สวีเดน',
      it: 'Svedese'
    },
    permalinks: {
      en: 'swedish',
      fr: 'suedois',
      de: 'schwedisch',
      es: 'sueco',
      pt: 'sueco',
      pl: 'szwedzki',
      'pt-BR': 'sueco',
      sv: 'svenska',
      ru: '',
      it: 'svedese'
    }
  },
  th: {
    names: {
      en: 'Thai',
      fr: 'Thaïlande',
      de: 'Thailändisch',
      es: 'Tailandés',
      pt: 'Tailandês',
      pl: 'Tajski',
      'pt-BR': 'Tailandês',
      sv: 'Thailändska',
      zh: '泰语',
      ko: '태국어',
      ja: 'タイ語',
      ru: 'Тайский',
      th: 'ไทย',
      it: 'Thailandese'
    },
    permalinks: {
      en: 'thai',
      fr: 'thailande',
      de: 'thailandisch',
      es: 'tailandes',
      pt: 'tailandes',
      pl: 'tajski',
      'pt-BR': 'tailandes',
      sv: 'thailandska',
      ru: '',
      it: 'thailandese'
    }
  },
  tr: {
    names: {
      en: 'Turkish',
      fr: 'Turc',
      de: 'Türkisch',
      es: 'Turco',
      pt: 'Turco',
      pl: 'Turecki',
      'pt-BR': 'Turco',
      sv: 'Turkiska',
      zh: '土耳其语',
      ko: '터키어',
      ja: 'トルコ語',
      ru: 'Турецкий',
      th: 'ตุรกี',
      it: 'Turco'
    },
    permalinks: {
      en: 'turkish',
      fr: 'turc',
      de: 'turkisch',
      es: 'turco',
      pt: 'turco',
      pl: 'turecki',
      'pt-BR': 'turco',
      sv: 'turkiska',
      ru: '',
      it: 'turco'
    }
  },
  uk: {
    names: {
      en: 'Ukrainian',
      fr: 'Ukrainien',
      de: 'Ukrainisch',
      es: 'Ucraniano',
      pt: 'Ucraniano',
      pl: 'Ukraiński',
      'pt-BR': 'Ucraniano',
      sv: 'Ukrainska',
      zh: '乌克兰语',
      ko: '우크라이나어',
      ja: 'ウクライナ語',
      ru: 'Украинский',
      th: 'ยูเครน',
      it: 'Ucraino'
    },
    permalinks: {
      en: 'ukrainian',
      fr: 'ukrainien',
      de: 'ukrainisch',
      es: 'ucraniano',
      pt: 'ucraniano',
      pl: 'ukrainski',
      'pt-BR': 'ucraniano',
      sv: 'ukrainska',
      ru: '',
      it: 'ucraino'
    }
  },
  zh: {
    names: {
      en: 'Chinese',
      fr: 'Chinois',
      de: 'Chinesisch',
      es: 'Chino',
      pt: 'Chinês',
      pl: 'Chiński',
      'pt-BR': 'Chinês',
      sv: 'Kinesiska',
      zh: '中文',
      ko: '중국어',
      ja: '中国語',
      ru: 'Китайский',
      th: 'จีน',
      it: 'Cinese'
    },
    permalinks: {
      en: 'chinese',
      fr: 'chinois',
      de: 'chinesisch',
      es: 'chino',
      pt: 'chines',
      pl: 'chinski',
      'pt-BR': 'chines',
      sv: 'kinesiska',
      ru: '',
      it: 'cinese'
    }
  }
};
export default dict;
