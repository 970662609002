import { Popover } from '@headlessui/react';
import { useRouter } from 'next/router';
import { useSession } from 'next-auth/react';
import { useTranslation } from 'next-i18next';
import { useRef } from 'react';

import { getLocalizedProperty } from '../../lib/localization';
import { routes } from '../../utils/routes';
import AhotuLink from '../atoms/AhotuLink';
import { LinkButton } from '../atoms/LinkButton';
import { AhotuPerson } from '../icons/icons';
import AccountNavigation from './AccountNavigation';

export const LoginMenu = () => {
  const { data: session } = useSession();
  const router = useRouter();
  const { t } = useTranslation();

  const buttonRef = useRef(null);

  const closePopover = () => {
    return buttonRef.current?.dispatchEvent(
      new KeyboardEvent('keydown', {
        key: 'Escape',
        bubbles: true,
        cancelable: true
      })
    );
  };

  const onMouseEnter = open => {
    if (open) return;
    return buttonRef.current?.click();
  };

  const onMouseLeave = open => {
    if (!open) return;
    closePopover();
  };

  return (
    <div className="z-10">
      <Popover>
        {({ open }) => (
          <div>
            <Popover.Button ref={buttonRef} onMouseEnter={onMouseEnter.bind(null, open)} aria-label="Login menu">
              <AhotuPerson className="h-6 w-6" />
            </Popover.Button>
            <Popover.Panel
              className={`absolute right-10 top-20 bg-white ${
                !session && 'p-8'
              } w-[300px] shadow-30 rounded-b-lg overflow-hidden`}
              onMouseLeave={onMouseLeave.bind(null, open)}>
              <div className="flex flex-col justify-center items-stretch space-y-8">
                {!session && (
                  <>
                    <LinkButton
                      href={getLocalizedProperty(routes.members.signIn, router.locale)}
                      type="link"
                      text={t('session:log-in')}></LinkButton>
                    <AhotuLink
                      className="mx-auto"
                      href={getLocalizedProperty(routes.members.registration.chooseRole, router.locale)}>
                      {t('session:create-account')}
                    </AhotuLink>
                  </>
                )}
                {session && (
                  <div>
                    <AccountNavigation />
                  </div>
                )}
              </div>
            </Popover.Panel>
          </div>
        )}
      </Popover>
    </div>
  );
};
