import clsx from 'clsx';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { useEffect, useState } from 'react';

import clsxm from '../../../lib/clsxm';
import { getLocalizedProperty } from '../../../lib/localization';
import { registrationRegex } from '../../../lib/schemas/validationSchemas';
import { XIcon } from '../../icons/icons';
import { CheckIcon } from '../../icons/icons';
import { Paragraph } from '../Paragraph';
import AhotuForm from './AhotuForm';

const passwordRequirements = {
  lowercase: {
    messages: {
      en: 'lowercase letter (a-z)',
      fr: 'lettre minuscule (a-z)'
    },
    noError: false
  },
  uppercase: {
    messages: {
      en: 'uppercase letter (A-Z)',
      fr: 'lettre majuscule (A-Z)'
    },
    noError: false
  },
  number: {
    messages: {
      en: 'number (0-9)',
      fr: 'nombre (0-9)'
    },
    noError: false
  },
  symbol: {
    messages: {
      en: "symbol (!”#$%&'()*+,-./)",
      fr: "symbole (!”#$%&'()*+,-./)"
    },
    noError: false
  },
  length: {
    messages: {
      en: 'at least 8 characters',
      fr: 'au moins 8 caractères'
    },
    noError: false
  },
  whitespace: {
    messages: {
      en: 'no whitespaces',
      fr: 'pas de blancs'
    },
    noError: false
  }
};

const AhotuFormRequirements = ({ password, error, focus, isDirty }) => {
  const { locale } = useRouter();
  const { t } = useTranslation();
  useEffect(() => {
    passwordRequirements.uppercase.noError = registrationRegex.uppercase.test(password);
    passwordRequirements.lowercase.noError = registrationRegex.lowercase.test(password);
    passwordRequirements.number.noError = registrationRegex.number.test(password);
    passwordRequirements.symbol.noError = registrationRegex.symbol.test(password);
    passwordRequirements.whitespace.noError = registrationRegex.whitespace.test(password);
    passwordRequirements.length.noError = password.length >= 8;
    setRequirements(prevState => {
      return { ...prevState, ...requirements };
    });
  }, [password]);
  const [requirements, setRequirements] = useState(passwordRequirements);
  return (
    <>
      <AhotuForm.Help className={clsxm('mt-2', error && 'text-red-100')}>
        {t(focus || isDirty ? 'session:password-contain' : 'session:password-rules')}
      </AhotuForm.Help>
      {(focus || isDirty) && (
        <div className="ml-2 mt-2">
          {Object.values(passwordRequirements).map(requirement => {
            return (
              <div
                key={getLocalizedProperty(requirement.messages, locale)}
                className={clsxm('flex row space-x-2 items-center')}>
                <div className="w-3 flex justify-center">
                  {requirement.noError ? (
                    <CheckIcon
                      className={clsx('h-3 w-3 fill-green-70', isDirty ? 'fill-green-70' : 'fill-softblack')}
                    />
                  ) : (
                    <XIcon className={clsx('h-2 w-2 fill-red-100', isDirty ? 'fill-red-100' : 'fill-softblack')} />
                  )}
                </div>
                <Paragraph
                  size="p3"
                  className={clsxm(
                    'mb-0',
                    isDirty ? (requirement.noError ? 'text-green-70' : 'text-red-100') : 'text-black'
                  )}>
                  {getLocalizedProperty(requirement.messages, locale)}
                </Paragraph>
              </div>
            );
          })}
        </div>
      )}
    </>
  );
};

export default AhotuFormRequirements;
