import { forwardRef, InputHTMLAttributes, useState } from 'react';

import { AhotuFormInput } from '../atoms/form';
import { AhotuFormInputGroup } from '../atoms/input-group';
import { EyeCrossedIcon, EyeIcon } from '../icons/icons';

type Props = Omit<InputHTMLAttributes<HTMLInputElement>, 'type'> & { isInvalid?: boolean }; //TODO: double check this isInvalidProp

const AhotuPassword = forwardRef<HTMLInputElement, Props>(({ ...props }: Props, ref) => {
  const [passwordVisible, setPasswordVisibility] = useState(false);

  const onClick = () => {
    setPasswordVisibility(!passwordVisible);
  };

  return (
    <AhotuFormInputGroup>
      <AhotuFormInput type={passwordVisible ? 'text' : 'password'} {...props} ref={ref} />
      <AhotuFormInputGroup.Button onClick={onClick} overlay position="right">
        {passwordVisible ? <EyeIcon className="h-6 w-6" /> : <EyeCrossedIcon className="h-6 w-6" />}
      </AhotuFormInputGroup.Button>
    </AhotuFormInputGroup>
  );
});

AhotuPassword.displayName = 'AhotuPassword';

export default AhotuPassword;
