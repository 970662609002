// /event/$permalink
// /calendar/$criteria
// /

// /fr/evenement/$permalink
// /fr/calendrier/$criteria
// /fr

// /fr/event/$permalink

import get from 'lodash/get';

import { getLocalizedProperty } from '../lib/localization';
import SportRef from '../references/sport';
import { routes } from './routes';

const getTranslatedRoute = (page: string, locale: string) =>
  get(
    routes,
    [page, locale].join('.'),
    get(routes, [page, 'en'].join('.'), `${locale === 'en' ? '' : '/' + locale}/${page}`)
  );

export const translatedUrl = (page: string, locale: string, params?: any) => {
  switch (page) {
    case 'event':
      return `${getTranslatedRoute(page, locale)}/${params}`;

    case 'eventEdition':
      return `${getTranslatedRoute(page, locale)}/${params.permalink}/${params.date}`;

    case 'eventSignup':
      return `${getTranslatedRoute(page, locale)}`;

    case 'sport': {
      const permalink = getLocalizedProperty(SportRef[params].permalinks, locale);
      return `${getTranslatedRoute(page, locale)}/${permalink}`;
    }

    case 'calendar': {
      return `${getTranslatedRoute(page, locale)}${params ? params : ''}`;
    }

    case 'member':
      return `/fr/membres/${params}`;

    case 'news':
      return `${getTranslatedRoute(page, locale)}/${params}`;

    case 'allNews':
      return `${getTranslatedRoute(page, locale)}`;

    case 'homepage':
      return `${getTranslatedRoute(page, locale)}`;

    case 'cookiePolicy':
      return `${getTranslatedRoute(page, locale)}`;

    case 'privacyPolicy':
      return `${getTranslatedRoute(page, locale)}`;

    case 'signIn':
      return `${getTranslatedRoute(`members.${page}`, locale)}`;

    case 'forgotPasssword':
      return `${getTranslatedRoute(`members.password.forgotPassword`, locale)}`;

    case 'signUpRole':
      return `${getTranslatedRoute(`members.${page}`, locale)}`;

    case 'chooseRole':
      return `${getTranslatedRoute(`members.registration.chooseRole`, locale)}`;

    case 'signUpAthlete':
      return `${getTranslatedRoute(`members.registration.athlete`, locale)}`;

    case 'signUpOrganiser':
      return `${getTranslatedRoute(`members.registration.organiser`, locale)}`;

    case 'signUpSuccess':
      return `${getTranslatedRoute(`members.registration.sucess`, locale)}`;

    case 'signUpConfirmation':
      return `${getTranslatedRoute(`members.registration.sucess`, locale)}?confirmation_token=${params}`;

    case 'forgotPassword':
      return `${getTranslatedRoute(`members.password.${page}`, locale)}?confirmation_token=${params}`;

    case 'resetPassword':
      return `${getTranslatedRoute(`members.password.${page}`, locale)}?confirmation_token=${params}`;

    case 'allNewsPerEvent':
      return `${getTranslatedRoute(page, locale)}/${params}`;

    case '404':
      return getTranslatedRoute(page, locale);

    case 'challenge':
      return `${getTranslatedRoute(page, locale)}/${params}`;

    case 'organiser.public':
    case 'organiser':
      return `${getTranslatedRoute(page, locale)}/${params}`;

    case 'profile':
      return `${getTranslatedRoute(page, locale)}/${params}`;

    case 'aboutUs':
      return getTranslatedRoute(page, locale);

    default:
      return getTranslatedRoute(page, locale);
  }
};

export default translatedUrl;
