import { useRouter } from 'next/router';
import { useState } from 'react';

import { getLocalizedProperty } from '../../lib/localization';
import { routes } from '../../utils/routes';
import AhotuLink from '../atoms/AhotuLink';
import { AhotuLogo } from '../atoms/AhotuLogo';
import { XIcon } from '../icons/icons';
import SearchBox from './SearchBox';

interface ITopNavigationSearchDesktop {
  isAnimated: boolean;
  searchIsExpanded: boolean;
  setSearchIsExpanded: (x) => void;
  animation: {
    animated: string[];
    notAnimated: string[];
  };
}
const TopNavigationSearchDesktop = ({ searchIsExpanded, setSearchIsExpanded }: ITopNavigationSearchDesktop) => {
  const [showInput, setShowInput] = useState(false);
  const { locale } = useRouter();
  return (
    <>
      <div
        className="backdrop-blur-xxs backdrop-brightness-50 w-[100%] h-screen absolute z-20 top-24 left-0"
        onClick={() => setSearchIsExpanded(false)}
      />
      <div className=" flex absolute w-screen items-center justify-between h-30 bg-white z-50 px-8">
        <div className="flex items-center w-fit h-full">
          <AhotuLink href={getLocalizedProperty(routes.homepage, locale)}>
            <div className="ml-[20%] hover:cursor-pointer">
              <AhotuLogo />
            </div>
          </AhotuLink>
        </div>
        <SearchBox
          isExpanded={searchIsExpanded}
          showInput={showInput}
          setShowInput={setShowInput}
          setIsExpanded={setSearchIsExpanded}
          usage="topNavigation"
        />
        <div
          onClick={() => {
            setSearchIsExpanded(false);
            setShowInput(false);
          }}
          className="flex justify-end items-center mr-4 hover:cursor-pointer">
          <XIcon className="h-5 w-5" />
        </div>
      </div>
    </>
  );
};

export default TopNavigationSearchDesktop;
