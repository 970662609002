import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';

import AhotwoProperty from '../atoms/AhotwoProperty';
import { Heading } from '../atoms/Heading';
import { AhotuThumbUp } from '../icons/icons';
import SignInOptions from '../molecules/SignInOptions';
import SignUpBox from '../molecules/SignUpBox';
interface SignInGroup {
  headingText: string;
  headingLevel: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'; //Needs to be refactored to use Heading.h1 etc
  headingSize: '' | 'xs' | 'sm' | 'base' | 'lg' | 'xl' | '2xl' | '3xl' | '4xl' | '5xl'; //Needs to refactored to use Size
  headingClassName?: string;
  className?: string;
  passwordSignInCallback: () => void;
  onClose: () => void;
}
const SignInGroup = ({
  headingLevel,
  headingSize,
  headingText,
  headingClassName = '',
  className = '',
  passwordSignInCallback,
  onClose
}: SignInGroup) => {
  const router = useRouter();
  const { t } = useTranslation();

  return (
    <div className={className}>
      {router.query?.confirmation === 'success' && (
        <div className="w-full flex justify-center items-center mb-6">
          <AhotwoProperty icon={AhotuThumbUp}>{t('session:account-confirmed')}</AhotwoProperty>
        </div>
      )}
      <div className="flex flex-row justify-center">
        <Heading headingLevel={headingLevel} size={headingSize} className={headingClassName}>
          {headingText}
        </Heading>
      </div>

      <SignInOptions onClose={onClose} passwordSignInCallback={passwordSignInCallback} />
      {router.query?.confirmation != 'success' && <SignUpBox onClose={onClose} />}
    </div>
  );
};

export default SignInGroup;
