import clsx from 'clsx';
import { FormHTMLAttributes } from 'react';

import AhotuCheck from './AhotuCheck';
import AhotuFormFeedback from './AhotuFormFeedback';
import AhotuFormGroup from './AhotuFormGroup';
import AhotuFormHelp from './AhotuFormHelp';
import AhotuFormInput from './AhotuFormInput';
import AhotuFormLabel from './AhotuFormLabel';
import AhotuFormMultiSelect from './AhotuFormMultiSelect';
import AhotuFormRequirements from './AhotuFormRequirements';
import AhotuFormSelect from './AhotuFormSelect';
import AhotuFormTextArea from './AhotuFormTextArea';

type Props = {
  displayMode?: 'default' | 'compact';
} & FormHTMLAttributes<HTMLFormElement>;

const AhotuForm = ({ children, className, displayMode = 'default', ...rest }: Props) => {
  const classes = clsx('ah-form', displayMode === 'compact' && 'ah-form-compact', className);

  return (
    <form className={classes} {...rest} noValidate autoComplete="false">
      {children}
    </form>
  );
};

AhotuForm.Check = AhotuCheck;
AhotuForm.Feedback = AhotuFormFeedback;
AhotuForm.Group = AhotuFormGroup;
AhotuForm.Help = AhotuFormHelp;
AhotuForm.Input = AhotuFormInput;
AhotuForm.Label = AhotuFormLabel;
AhotuForm.Select = AhotuFormSelect;
AhotuForm.TextArea = AhotuFormTextArea;
AhotuForm.Requirements = AhotuFormRequirements;
AhotuForm.MultiSelect = AhotuFormMultiSelect;

export default AhotuForm;
