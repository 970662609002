import capitalize from 'lodash/capitalize';
import { useRouter } from 'next/router';
import { useSession } from 'next-auth/react';
import { useTranslation } from 'next-i18next';

import { getLocalizedProperty } from '../../../lib/localization';
import { REVIEW_MOBILE_BREAKPOINT } from '../../../lib/reviews/constants';
import { routes } from '../../../utils/routes';
import useMediaQuery from '../../../utils/useMediaQuery';
import AhotuLink from '../../atoms/AhotuLink';
import { Heading } from '../../atoms/Heading';
import { CheckIcon, SearchIcon } from '../../icons/icons';
import SearchBox from '../../molecules/SearchBox';
import UserDisplay from '../../molecules/UserDisplay';

const ReviewSubmitted = ({ setShowSearchEvent }) => {
  const { data: session } = useSession();
  const { locale } = useRouter();
  const { t } = useTranslation();
  const isMobile = useMediaQuery(REVIEW_MOBILE_BREAKPOINT);

  const onFocusClick = () => {
    setShowSearchEvent(true);
  };
  return (
    <div className={isMobile ? '' : 'mx-[88px]'}>
      <AhotuLink href={getLocalizedProperty(routes.profile, locale) + '/' + session?.permalink} className="underline">
        <UserDisplay name={session?.firstName} countryCode={session?.country?.iso2} avatarUrl={session?.avatar} />
      </AhotuLink>
      <div className="mt-6 w-full flex flex-col items-center space-y-4">
        <CheckIcon className="h-16 w-16" />
        <Heading headingLevel="h2" size="2xl" className="block">
          {session?.firstName
            ? t('common:thanks-known-user', { name: session?.firstName })
            : t('common:thanks-unknown-user')}
        </Heading>
        <span
          className="block text-center"
          dangerouslySetInnerHTML={{ __html: t('event-page:review-need-to-be-approved') }}></span>
      </div>
      <div className="mt-8 py-10 h-full bg-gray-5 flex flex-col items-center space-y-4 px-4">
        <Heading headingLevel="h3" size="xl" className="text-center">
          {t('event-page:review-more')}
        </Heading>
        {isMobile ? (
          <div className="border flex rounded-full border-gray-50 h-14 z-50 bg-white w-full overflow-hidden pr-4">
            <div className="flex justify-center items-center  ml-5 mr-2 ">
              <SearchIcon className="h-6 w-6" fill="#000000" />
            </div>
            <input
              className="w-[75%] placeholder:text-sm "
              placeholder={capitalize(t('event-page:review-submitted-search-events-placeholder'))}
              onFocus={onFocusClick}
            />
          </div>
        ) : (
          <SearchBox isExpanded={false} showInput={true} usage={'stacked'} hasTopSearch={false} />
        )}
      </div>
    </div>
  );
};
export default ReviewSubmitted;
