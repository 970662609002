import { useRouter } from 'next/router';

import tailwindConfig from '../../../tailwind.config';
import { getLocalizedProperty } from '../../lib/localization';
import { routes } from '../../utils/routes';
import AhotuLink from './AhotuLink';

export const AhotuLogo = ({
  className,
  disableWidthHeight = false,
  disableLink = false
}: {
  className?: string;
  disableWidthHeight?: boolean;
  disableLink?: boolean;
}) => {
  const { locale } = useRouter();
  const Logo = () => (
    //FIXME: this ought to resize to its container but does not. it needs to retain its original size when the optional props are not included.
    <svg
      xmlns="http://www.w3.org/2000/svg"
      {...(disableWidthHeight ? {} : { width: '128', height: '24' })}
      fill="none"
      className={className}>
      <g fill={tailwindConfig.theme.colors['green']['70']} clipPath="url(#Logo-ahotu)">
        <path d="M45.2 6.6c0 1.2-1 2.1-2.2 2.1h-6v-8h-6.7v22H37v-6.4c0-1.2 1-2.1 2.2-2.1h6v8.5h6.7V.7h-6.7v5.9ZM68.1.1C62 .1 56.2 3 56.2 11.7c0 8.8 5.8 11.7 11.9 11.7 6.1 0 11.9-2.9 11.9-11.7C80 3 74.2.1 68.1.1Zm0 17.8c-3.3 0-5.1-2.4-5.1-6.2 0-3.7 1.8-6.1 5.1-6.1s5.1 2.4 5.1 6.1c0 3.8-1.8 6.2-5.1 6.2ZM83 6.3h4.6c1.2 0 2.2 1 2.2 2.1v14.4h6.7V6.3h6.7V.7H83v5.6ZM121.1 13.8c0 2.7-1 4.2-3.5 4.2s-3.5-1.5-3.5-4.2V.7h-6.7v13c0 7.4 4.1 9.7 10.2 9.7s10.2-2.4 10.2-9.7V.7h-6.7v13.1ZM18.4 2.7 17.7.8h-6.6c-1.3 0-2.5.8-3 2.1l-7.7 20h7.2l.8-2c.3-.8 1.1-1.4 2-1.4h7.1s.3.7.4 1l.9 2.4H26l-7.7-20c.1-.1.1-.2.1-.2Zm-7.7 11.7 2.6-6.7 2.6 6.7h-5.2Z" />
      </g>
      <defs>
        <clipPath id="logo-ahotu">
          <path fill="#fff" d="M0 0h128v24H0z" />
        </clipPath>
      </defs>
    </svg>
  );
  return disableLink ? (
    <Logo />
  ) : (
    <AhotuLink href={getLocalizedProperty(routes.homepage, locale)}>
      <Logo />
    </AhotuLink>
  );
};
