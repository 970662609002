import { HTMLAttributes } from 'react';

import clsxm from '../../../lib/clsxm';

type Props = {
  htmlFor?: string;
  isInvalid?: boolean;
  visuallyHidden?: boolean;
} & HTMLAttributes<HTMLElement>;

const AhotuFormLabel = ({ htmlFor, children, isInvalid, className }: Props) => {
  const classNames = clsxm('ah-form-label', isInvalid && 'is-invalid', className);

  return (
    <label htmlFor={htmlFor} className={classNames}>
      {children}
    </label>
  );
};

export default AhotuFormLabel;
