import clsx from 'clsx';
import { HTMLAttributes } from 'react';

type Props = { isolated?: boolean } & HTMLAttributes<HTMLElement>;

const AhotuFormHelp = ({ children, className, isolated }: Props) => {
  const classNames = clsx('ah-form-help', isolated && 'p-0 mb-6', className);

  return <div className={classNames}>{children}</div>;
};

export default AhotuFormHelp;
