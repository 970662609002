import clsxm from '../../../lib/clsxm';
import { TopNavigationSubMenu } from '../../../types/homePageTypes';
import { activityIcon } from '../../../utils/icons';
import AhotuLink from '../../atoms/AhotuLink';
import AhotwoProperty from '../../atoms/AhotwoProperty';
import { ALL_MENU_CLOSED, SubMenuOpen } from './DesktopNavigation';
interface SubMenuProps {
  subMenus: TopNavigationSubMenu[];
  subMenuOpen: SubMenuOpen;
  toggleSubMenuOpen: (x) => void;
}
const SubMenu = ({ subMenus, subMenuOpen, toggleSubMenuOpen }: SubMenuProps) => {
  return (
    <div
      onMouseLeave={() => toggleSubMenuOpen(ALL_MENU_CLOSED)}
      className={clsxm(
        'absolute rounded-lg top-[34px] -left-[42px]  space-y-6 py-8 px-10 min-w-[270px] w-fit bg-white shadow-50'
      )}>
      {subMenus.map((sport, i) => (
        <div key={i} onClick={() => toggleSubMenuOpen(ALL_MENU_CLOSED)}>
          <AhotuLink key={sport.name} href={sport.url} passHref prefetch={false}>
            <AhotwoProperty
              icon={sport.id && activityIcon(sport.id)}
              className={clsxm('mb-0 cursor-pointer hover:underline whitespace-nowrap', subMenuOpen ? '' : 'hidden')}>
              {sport.name}
            </AhotwoProperty>
          </AhotuLink>
        </div>
      ))}
    </div>
  );
};

export default SubMenu;
