import { CountryReference } from '../types/referenceTypes';
const dict: CountryReference = {
  ABW: {
    iso2: 'aw',
    old_id: 160,
    subContinent: 29,
    continent: 2,
    names: {
      en: 'Aruba',
      fr: 'Aruba',
      de: 'Aruba',
      es: 'Aruba',
      pt: 'Aruba',
      pl: 'Aruba',
      'pt-BR': 'Aruba',
      sv: 'Aruba',
      zh: '阿鲁巴',
      ko: '아루바',
      ja: 'Aruba',
      ru: 'Аруба',
      th: 'Aruba',
      it: 'Aruba'
    },
    permalinks: {
      en: 'aruba',
      fr: 'aruba',
      de: 'aruba',
      es: 'aruba',
      pt: 'aruba',
      pl: 'aruba',
      'pt-BR': 'aruba',
      sv: 'aruba',
      ru: '',
      it: 'aruba'
    },
    hasRegion: false,
    ins: {}
  },
  AGO: {
    iso2: 'ao',
    old_id: 141,
    subContinent: 17,
    continent: 7,
    names: {
      en: 'Angola',
      fr: 'Angola',
      de: 'Angola',
      es: 'Angola',
      pt: 'Angola',
      pl: 'Angola',
      'pt-BR': 'Angola',
      sv: 'Angola',
      zh: '安哥拉',
      ko: '앙골라',
      ja: 'アンゴラ',
      ru: 'Ангола',
      th: 'ประเทศแองโกลา',
      it: 'Angola'
    },
    permalinks: {
      en: 'angola',
      fr: 'angola',
      de: 'angola',
      es: 'angola',
      pt: 'angola',
      pl: 'angola',
      'pt-BR': 'angola',
      sv: 'angola',
      ru: '',
      it: 'angola'
    },
    hasRegion: false,
    ins: {}
  },
  AIA: {
    iso2: 'ai',
    old_id: 142,
    subContinent: 29,
    continent: 3,
    names: {
      en: 'Anguilla',
      fr: 'Anguilla',
      de: 'Anguilla',
      es: 'Anguila',
      pt: 'Anguilla',
      pl: 'Anguilla',
      'pt-BR': 'Anguilla',
      sv: 'Anguilla',
      zh: '安圭拉',
      ko: '앵귈라',
      ja: 'アンギラ',
      ru: 'Ангвилла',
      th: 'แองกวิลลา',
      it: 'Anguilla'
    },
    permalinks: {
      en: 'anguilla',
      fr: 'anguilla',
      de: 'anguilla',
      es: 'anguila',
      pt: 'anguilla',
      pl: 'anguilla',
      'pt-BR': 'anguilla',
      sv: 'anguilla',
      ru: '',
      it: 'anguilla'
    },
    hasRegion: false,
    ins: { fr: 'à' }
  },
  ALA: {
    iso2: 'ax',
    old_id: 448,
    subContinent: 154,
    continent: 1,
    names: {
      en: 'Åland Islands',
      fr: 'Åland, Îles',
      de: 'Åland-Inseln',
      es: 'Islas Äland',
      pt: 'Ilhas Åland',
      pl: 'Wyspy Alandzkie',
      'pt-BR': 'Ilhas Åland',
      sv: 'Åland',
      zh: '奥兰群岛',
      ko: '올란드 제도',
      ja: 'オーランド諸島',
      ru: 'Аландские острова',
      th: 'หมู่เกาะโอลันด์',
      it: 'Isole Åland'
    },
    permalinks: {
      en: 'aland-islands',
      fr: 'aland-iles',
      de: 'aland-inseln',
      es: 'islas-aland',
      pt: 'ilhas-aland',
      pl: 'wyspy-alandzkie',
      'pt-BR': 'ilhas-aland',
      sv: 'aland',
      ru: '',
      it: 'isole-aland'
    },
    hasRegion: false,
    ins: { en: 'in the', fr: 'dans les îles Åland' }
  },
  ALB: {
    iso2: 'al',
    old_id: 140,
    subContinent: 39,
    continent: 1,
    names: {
      en: 'Albania',
      fr: 'Albanie',
      de: 'Albanien',
      es: 'Albania',
      pt: 'Albânia',
      pl: 'Albania',
      'pt-BR': 'Albânia',
      sv: 'Albanien',
      zh: '阿尔巴尼亚',
      ko: '알바니아',
      ja: 'アルバニア',
      ru: 'Албания',
      th: 'ประเทศแอลเบเนีย',
      it: 'Albania'
    },
    permalinks: {
      en: 'albania',
      fr: 'albanie',
      de: 'albanien',
      es: 'albania',
      pt: 'albania',
      pl: 'albania',
      'pt-BR': 'albania',
      sv: 'albanien',
      ru: '',
      it: 'albania'
    },
    hasRegion: false,
    ins: {}
  },
  ALG: {
    iso2: 'dz',
    old_id: 1,
    subContinent: 15,
    continent: 7,
    names: {
      en: 'Algeria',
      fr: 'Algérie',
      de: 'Algerien',
      es: 'Algeria',
      pt: 'Argélia',
      pl: 'Algieria',
      'pt-BR': 'Argélia',
      sv: 'Algeriet',
      zh: '阿尔及利亚',
      ko: '알제리',
      ja: 'アルジェリア',
      ru: 'Алжир',
      th: 'ประเทศแอลจีเรีย',
      it: 'Algeria'
    },
    permalinks: {
      en: 'algeria',
      fr: 'algerie',
      de: 'algerien',
      es: 'algeria',
      pt: 'argelia',
      pl: 'algieria',
      'pt-BR': 'argelia',
      sv: 'algeriet',
      ru: '',
      it: 'algeria'
    },
    hasRegion: false,
    ins: {}
  },
  AND: {
    iso2: 'ad',
    old_id: 131,
    subContinent: 39,
    continent: 1,
    names: {
      en: 'Andorra',
      fr: 'Andorre',
      de: 'Andorra',
      es: 'Andorra',
      pt: 'Andorra',
      pl: 'Andora',
      'pt-BR': 'Andorra',
      sv: 'Andorra',
      zh: '安道尔',
      ko: '안도라',
      ja: 'アンドラ',
      ru: 'Андорра',
      th: 'อันดอร์รา',
      it: 'Andorra'
    },
    permalinks: {
      en: 'andorra',
      fr: 'andorre',
      de: 'andorra',
      es: 'andorra',
      pt: 'andorra',
      pl: 'andora',
      'pt-BR': 'andorra',
      sv: 'andorra',
      ru: '',
      it: 'andorra'
    },
    hasRegion: false,
    ins: {}
  },
  ANT: {
    iso2: 'an',
    old_id: 118,
    subContinent: 29,
    continent: 2,
    names: {
      en: 'Netherlands Antilles',
      fr: 'Antilles néerlandaises',
      de: 'Netherlands Antilles',
      es: 'Antillas Neerlandesas',
      pt: 'Netherlands Antilles',
      pl: 'Netherlands Antilles',
      'pt-BR': 'Netherlands Antilles',
      sv: 'Netherlands Antilles',
      zh: 'Netherlands Antilles',
      ko: 'Netherlands Antilles',
      ja: 'Netherlands Antilles',
      ru: 'Netherlands Antilles',
      th: 'Netherlands Antilles',
      it: 'Antille olandesi'
    },
    permalinks: {
      en: 'netherlands-antilles',
      fr: 'antilles-neerlandaises',
      de: 'netherlands-antilles',
      es: 'antillas-neerlandesas',
      pt: 'netherlands-antilles',
      pl: 'netherlands-antilles',
      'pt-BR': 'netherlands-antilles',
      sv: 'netherlands-antilles',
      ru: 'netherlands-antilles',
      it: 'antille-olandesi'
    },
    hasRegion: false,
    ins: { en: 'in the', fr: 'aux' }
  },
  ARE: {
    iso2: 'ae',
    old_id: 93,
    subContinent: 145,
    continent: 4,
    names: {
      en: 'United Arab Emirates',
      fr: 'Emirats Arabes Unis',
      de: 'Vereinigte Arabische Emirate',
      es: 'Emiratos Árabes Unidos',
      pt: 'Emirados Árabes Unidos',
      pl: 'Emiraty Arabskie',
      'pt-BR': 'Emirados Árabes Unidos',
      sv: 'Förenade Arabemiraten',
      zh: '阿联酋',
      ko: '아랍에미리트',
      ja: 'アラブ首長国連邦',
      ru: 'Объединённые Арабские Эмираты',
      th: 'สหรัฐอาหรับเอมิเรตส์',
      it: 'Emirati arabi uniti'
    },
    permalinks: {
      en: 'united-arab-emirates',
      fr: 'emirats-arabes-unis',
      de: 'vereinigte-arabische-emirate',
      es: 'emiratos-arabes-unidos',
      pt: 'emirados-arabes-unidos',
      pl: 'emiraty-arabskie',
      'pt-BR': 'emirados-arabes-unidos',
      sv: 'forenade-arabemiraten',
      ru: '',
      it: 'emirati-arabi-uniti'
    },
    hasRegion: false,
    ins: { en: 'in the ', fr: 'aux' }
  },
  ARG: {
    iso2: 'ar',
    old_id: 3,
    subContinent: null,
    continent: 2,
    names: {
      en: 'Argentina',
      fr: 'Argentine',
      de: 'Argentinien',
      es: 'Argentina',
      pt: 'Argentina',
      pl: 'Argentyna',
      'pt-BR': 'Argentina',
      sv: 'Argentina',
      zh: '阿根廷',
      ko: '아르헨티나',
      ja: 'アルゼンチン',
      ru: 'Аргентина',
      th: 'อาร์เจนตินา',
      it: 'Argentina'
    },
    permalinks: {
      en: 'argentina',
      fr: 'argentine',
      de: 'argentinien',
      es: 'argentina',
      pt: 'argentina',
      pl: 'argentyna',
      'pt-BR': 'argentina',
      sv: 'argentina',
      ru: '',
      it: 'argentina'
    },
    hasRegion: false,
    ins: {}
  },
  ARM: {
    iso2: 'am',
    old_id: 145,
    subContinent: 145,
    continent: 4,
    names: {
      en: 'Armenia',
      fr: 'Arménie',
      de: 'Armenien',
      es: 'Armenia',
      pt: 'Arménia',
      pl: 'Armenia',
      'pt-BR': 'Arménia',
      sv: 'Armenien',
      zh: '亚美尼亚',
      ko: '아르메니아',
      ja: 'アルメニア',
      ru: 'Армения',
      th: 'อาร์มีเนีย',
      it: 'Armenia'
    },
    permalinks: {
      en: 'armenia',
      fr: 'armenie',
      de: 'armenien',
      es: 'armenia',
      pt: 'armenia',
      pl: 'armenia',
      'pt-BR': 'armenia',
      sv: 'armenien',
      ru: '',
      it: 'armenia'
    },
    hasRegion: false,
    ins: {}
  },
  ASM: {
    iso2: 'as',
    old_id: 447,
    subContinent: 61,
    continent: 6,
    names: {
      en: 'American Samoa',
      fr: 'Samoa américaines',
      de: 'Amerikanisch-Samoa',
      es: 'Samoa Americana',
      pt: 'Samoa Americana',
      pl: 'Samoa Amerykańskie',
      'pt-BR': 'Samoa Americana',
      sv: 'Amerikanska Samoa',
      zh: '美属萨摩亚',
      ko: '아메리칸사모아',
      ja: 'アメリカ領サモア',
      ru: 'Американские Самоа',
      th: 'อเมริกันซามัว',
      it: 'Samoa americane'
    },
    permalinks: {
      en: 'american-samoa',
      fr: 'samoa-americaines',
      de: 'amerikanisch-samoa',
      es: 'samoa-americana',
      pt: 'samoa-americana',
      pl: 'samoa-amerykanskie',
      'pt-BR': 'samoa-americana',
      sv: 'amerikanska-samoa',
      ru: '',
      it: 'samoa-americane'
    },
    hasRegion: false,
    ins: { fr: 'aux' }
  },
  ATA: {
    iso2: 'aq',
    old_id: 2,
    subContinent: null,
    continent: 5,
    names: {
      en: 'Antartica',
      fr: 'Antartique',
      de: 'Antarktis',
      es: 'Antártida',
      pt: 'Antárctida',
      pl: 'Antarktyka',
      'pt-BR': 'Antártida',
      sv: 'Antarktis',
      zh: '南极洲',
      ko: '남극',
      ja: '南極大陸',
      ru: 'Антарктика',
      th: 'ทวีปแอนตาร์กติกา',
      it: 'Antartide'
    },
    permalinks: {
      en: 'antartica',
      fr: 'antartique',
      de: 'antarktis',
      es: 'antartida',
      pt: 'antarctida',
      pl: 'antarktyka',
      'pt-BR': 'antartida',
      sv: 'antarktis',
      ru: '',
      it: 'antartide'
    },
    hasRegion: false,
    ins: {}
  },
  ATF: {
    iso2: 'tf',
    old_id: 460,
    subContinent: 14,
    continent: 7,
    names: {
      en: 'French Southern Territories',
      fr: 'Terres australes françaises',
      de: 'Französische Süd- und Antarktisgebiete',
      es: 'Territorios Franceses del Sur',
      pt: 'Territórios Franceses do Sul',
      pl: 'Francuskie Terytoria Południowe i Antarktyczne',
      'pt-BR': 'Terras Austrais e Antárticas Francesas',
      sv: 'Franska sydterritorierna',
      zh: '法属南半球领地',
      ko: '프랑스령 남부와 남극 지역',
      ja: 'フランス領南方・南極地域',
      ru: 'Французские южные территории',
      th: 'เฟรนช์เซาเทิร์นเทร์ริทอรีส์',
      it: 'Territori meridionali francesi'
    },
    permalinks: {
      en: 'french-southern-territories',
      fr: 'terres-australes-francaises',
      de: 'franzosische-sud-und-antarktisgebiete',
      es: 'territorios-franceses-del-sur',
      pt: 'territorios-franceses-do-sul',
      pl: 'francuskie-terytoria-poludniowe-i-antarktyczne',
      'pt-BR': 'terras-austrais-e-antarticas-francesas',
      sv: 'franska-sydterritorierna',
      ru: '',
      it: 'territori-meridionali-francesi'
    },
    hasRegion: false,
    ins: { en: 'in the ', fr: 'aux' }
  },
  ATG: {
    iso2: 'ag',
    old_id: 143,
    subContinent: 29,
    continent: 3,
    names: {
      en: 'Antigua and Barbuda',
      fr: 'Antigua et Barbuda',
      de: 'Antigua und Barbuda',
      es: 'Antigua y Barbuda',
      pt: 'Antígua e Barbuda',
      pl: 'Antigua i Barbuda',
      'pt-BR': 'Antígua e Barbuda',
      sv: 'Antigua och Barbuda',
      zh: '安提瓜和巴布达',
      ko: '앤티가 바부다',
      ja: 'アンティグア・バーブーダ',
      ru: 'Антигуа и Барбуда',
      th: 'แอนทีกาและบาร์บิวดา',
      it: 'Antigua e Barbuda'
    },
    permalinks: {
      en: 'antigua-and-barbuda',
      fr: 'antigua-et-barbuda',
      de: 'antigua-und-barbuda',
      es: 'antigua-y-barbuda',
      pt: 'antigua-e-barbuda',
      pl: 'antigua-i-barbuda',
      'pt-BR': 'antigua-e-barbuda',
      sv: 'antigua-och-barbuda',
      ru: '',
      it: 'antigua-e-barbuda'
    },
    hasRegion: false,
    ins: { fr: 'à ' }
  },
  AUS: {
    iso2: 'au',
    old_id: 4,
    subContinent: 53,
    continent: 6,
    names: {
      en: 'Australia',
      fr: 'Australie',
      de: 'Australien',
      es: 'Australia',
      pt: 'Austrália',
      pl: 'Australia',
      'pt-BR': 'Austrália',
      sv: 'Australien',
      zh: '澳大利亚',
      ko: '오스트레일리아',
      ja: 'オーストラリア',
      ru: 'Австралия',
      th: 'ออสเตรเลีย',
      it: 'Australia'
    },
    permalinks: {
      en: 'australia',
      fr: 'australie',
      de: 'australien',
      es: 'australia',
      pt: 'australia',
      pl: 'australia',
      'pt-BR': 'australia',
      sv: 'australien',
      ru: '',
      it: 'australia'
    },
    hasRegion: true,
    ins: {}
  },
  AUT: {
    iso2: 'at',
    old_id: 5,
    subContinent: 155,
    continent: 1,
    names: {
      en: 'Austria',
      fr: 'Autriche',
      de: 'Österreich',
      es: 'Austria',
      pt: 'Áustria',
      pl: 'Austria',
      'pt-BR': 'Áustria',
      sv: 'Österrike',
      zh: '奥地利',
      ko: '오스트리아',
      ja: 'オーストリア',
      ru: 'Австрия',
      th: 'ประเทศออสเตรีย',
      it: 'Austria'
    },
    permalinks: {
      en: 'austria',
      fr: 'autriche',
      de: 'osterreich',
      es: 'austria',
      pt: 'austria',
      pl: 'austria',
      'pt-BR': 'austria',
      sv: 'osterrike',
      ru: '',
      it: 'austria'
    },
    hasRegion: false,
    ins: {}
  },
  AZE: {
    iso2: 'az',
    old_id: 161,
    subContinent: 145,
    continent: 4,
    names: {
      en: 'Azerbaijan',
      fr: 'Azerbaïdjan',
      de: 'Aserbaidschan',
      es: 'Azerbayán',
      pt: 'Azerbaijão',
      pl: 'Azerbejdżan',
      'pt-BR': 'Azerbaijão',
      sv: 'Azerbajdzjan',
      zh: '阿塞拜疆',
      ko: '아제르바이잔',
      ja: 'アゼルバイジャン',
      ru: 'Азербайджан',
      th: 'อาเซอร์ไบจาน',
      it: 'Azerbaigian'
    },
    permalinks: {
      en: 'azerbaijan',
      fr: 'azerbaidjan',
      de: 'aserbaidschan',
      es: 'azerbayan',
      pt: 'azerbaijao',
      pl: 'azerbejdzan',
      'pt-BR': 'azerbaijao',
      sv: 'azerbajdzjan',
      ru: '',
      it: 'azerbaigian'
    },
    hasRegion: false,
    ins: {}
  },
  BDI: {
    iso2: 'bi',
    old_id: 182,
    subContinent: 14,
    continent: 7,
    names: {
      en: 'Burundi',
      fr: 'Burundi',
      de: 'Burundi',
      es: 'Burundi',
      pt: 'Burundi',
      pl: 'Burundi',
      'pt-BR': 'Burundi',
      sv: 'Burundi',
      zh: '布隆迪',
      ko: '부룬디',
      ja: 'ブルンジ',
      ru: 'Бурунди',
      th: 'บุรุนดี',
      it: 'Burundi'
    },
    permalinks: {
      en: 'burundi',
      fr: 'burundi',
      de: 'burundi',
      es: 'burundi',
      pt: 'burundi',
      pl: 'burundi',
      'pt-BR': 'burundi',
      sv: 'burundi',
      ru: '',
      it: 'burundi'
    },
    hasRegion: false,
    ins: { fr: 'au' }
  },
  BEL: {
    iso2: 'be',
    old_id: 7,
    subContinent: 155,
    continent: 1,
    names: {
      en: 'Belgium',
      fr: 'Belgique',
      de: 'Belgien',
      es: 'Bélgica',
      pt: 'Bélgica',
      pl: 'Belgia',
      'pt-BR': 'Bélgica',
      sv: 'Belgien',
      zh: '比利时',
      ko: '벨기에',
      ja: 'ベルギー',
      ru: 'Бельгия',
      th: 'เบลเยียม',
      it: 'Belgio'
    },
    permalinks: {
      en: 'belgium',
      fr: 'belgique',
      de: 'belgien',
      es: 'belgica',
      pt: 'belgica',
      pl: 'belgia',
      'pt-BR': 'belgica',
      sv: 'belgien',
      ru: '',
      it: 'belgio'
    },
    hasRegion: true,
    ins: {}
  },
  BEN: {
    iso2: 'bj',
    old_id: 164,
    subContinent: 11,
    continent: 7,
    names: {
      en: 'Benin',
      fr: 'Bénin',
      de: 'Benin',
      es: 'Benín',
      pt: 'Benim',
      pl: 'Benin',
      'pt-BR': 'Benim',
      sv: 'Benin',
      zh: '贝宁',
      ko: '베냉',
      ja: 'ベナン',
      ru: 'Бенин',
      th: 'เบนิน',
      it: 'Benin'
    },
    permalinks: {
      en: 'benin',
      fr: 'benin',
      de: 'benin',
      es: 'benin',
      pt: 'benim',
      pl: 'benin',
      'pt-BR': 'benim',
      sv: 'benin',
      ru: '',
      it: 'benin'
    },
    hasRegion: false,
    ins: { fr: 'au' }
  },
  BES: {
    iso2: 'bq',
    old_id: 449,
    subContinent: 29,
    continent: 3,
    names: {
      en: 'Bonaire, Sint Eustatius and Saba',
      fr: 'Bonaire, Saint-Eustache et Saba',
      de: 'Bonaire, Sint Eustatius und Saba',
      es: 'Islas BES (Caribe Neerlandés)',
      pt: 'Bonaire, Santo Eustáquio e Saba',
      pl: 'Bonaire, Sint Eustatius and Saba',
      'pt-BR': 'Países Baixos Caribenhos',
      sv: 'Bonaire, Sint Eustatius och Saba',
      zh: '博奈尔、圣尤斯特歇斯岛和萨巴',
      ko: '카리브 네덜란드',
      ja: 'Bonaire, Sint Eustatius and Saba',
      ru: 'Бонайре, Синт-Эстатиус и Саба',
      th: 'Bonaire, Sint Eustatius and Saba',
      it: 'Bonaire, Saint Eustatius e Saba'
    },
    permalinks: {
      en: 'bonaire-sint-eustatius-and-saba',
      fr: 'bonaire-saint-eustache-et-saba',
      de: 'bonaire-sint-eustatius-und-saba',
      es: 'islas-bes-caribe-neerlandes',
      pt: 'bonaire-santo-eustaquio-e-saba',
      pl: 'bonaire-sint-eustatius-and-saba',
      'pt-BR': 'paises-baixos-caribenhos',
      sv: 'bonaire-sint-eustatius-och-saba',
      ru: '',
      it: 'bonaire-saint-eustatius-e-saba'
    },
    hasRegion: false,
    ins: { fr: 'à' }
  },
  BFA: {
    iso2: 'bf',
    old_id: 183,
    subContinent: 11,
    continent: 7,
    names: {
      en: 'Burkina Faso',
      fr: 'Burkina Faso',
      de: 'Burkina Faso',
      es: 'Burquina Faso',
      pt: 'Burkina Faso',
      pl: 'Burkina Faso',
      'pt-BR': 'Burquina Faso',
      sv: 'Burkina Faso',
      zh: '布基纳法索',
      ko: '부르키나파소',
      ja: 'ブルキナファソ',
      ru: 'Буркина-Фасо',
      th: 'บูร์กินาฟาโซ',
      it: 'Burkina-Faso'
    },
    permalinks: {
      en: 'burkina-faso',
      fr: 'burkina-faso',
      de: 'burkina-faso',
      es: 'burquina-faso',
      pt: 'burkina-faso',
      pl: 'burkina-faso',
      'pt-BR': 'burquina-faso',
      sv: 'burkina-faso',
      ru: '',
      it: 'burkina-faso'
    },
    hasRegion: false,
    ins: {}
  },
  BGD: {
    iso2: 'bd',
    old_id: 108,
    subContinent: 34,
    continent: 4,
    names: {
      en: 'Bangladesh',
      fr: 'Bangladesh',
      de: 'Bangladesch',
      es: 'Bangladesh',
      pt: 'Bangladesh',
      pl: 'Bangladesz',
      'pt-BR': 'Bangladeche',
      sv: 'Bangladesh',
      zh: '孟加拉',
      ko: '방글라데시',
      ja: 'バングラデシュ',
      ru: 'Бангладеш',
      th: 'บังกลาเทศ',
      it: 'Bangladesh'
    },
    permalinks: {
      en: 'bangladesh',
      fr: 'bangladesh',
      de: 'bangladesch',
      es: 'bangladesh',
      pt: 'bangladesh',
      pl: 'bangladesz',
      'pt-BR': 'bangladeche',
      sv: 'bangladesh',
      ru: '',
      it: 'bangladesh'
    },
    hasRegion: false,
    ins: { fr: 'au' }
  },
  BGR: {
    iso2: 'bg',
    old_id: 181,
    subContinent: 151,
    continent: 1,
    names: {
      en: 'Bulgaria',
      fr: 'Bulgarie',
      de: 'Bulgarien',
      es: 'Bulgaria',
      pt: 'Bulgária',
      pl: 'Bułgaria',
      'pt-BR': 'Bulgária',
      sv: 'Bulgarien',
      zh: '保加利亚',
      ko: '불가리아',
      ja: 'ブルガリア',
      ru: 'Болгария',
      th: 'ประเทศบัลแกเรีย',
      it: 'Bulgaria'
    },
    permalinks: {
      en: 'bulgaria',
      fr: 'bulgarie',
      de: 'bulgarien',
      es: 'bulgaria',
      pt: 'bulgaria',
      pl: 'bulgaria',
      'pt-BR': 'bulgaria',
      sv: 'bulgarien',
      ru: '',
      it: 'bulgaria'
    },
    hasRegion: false,
    ins: {}
  },
  BHR: {
    iso2: 'bh',
    old_id: 162,
    subContinent: 145,
    continent: 4,
    names: {
      en: 'Bahrain',
      fr: 'Bahreïn',
      de: 'Bahrain',
      es: 'Bahrein',
      pt: 'Barein',
      pl: 'Bahrajn',
      'pt-BR': 'Bahrein',
      sv: 'Bahrain',
      zh: '巴林',
      ko: '바레인',
      ja: 'バーレーン',
      ru: 'Бахрейн',
      th: 'บาห์เรน',
      it: 'Bahrein'
    },
    permalinks: {
      en: 'bahrain',
      fr: 'bahrein',
      de: 'bahrain',
      es: 'bahrein',
      pt: 'barein',
      pl: 'bahrajn',
      'pt-BR': 'bahrein',
      sv: 'bahrain',
      ru: '',
      it: 'bahrein'
    },
    hasRegion: false,
    ins: { fr: 'à' }
  },
  BHS: {
    iso2: 'bs',
    old_id: 123,
    subContinent: 29,
    continent: 3,
    names: {
      en: 'Bahamas',
      fr: 'Bahamas',
      de: 'Bahamas',
      es: 'Bahamas',
      pt: 'Bahamas',
      pl: 'Bahamy',
      'pt-BR': 'Bahamas',
      sv: 'Bahamas',
      zh: '巴哈马',
      ko: '바하마',
      ja: 'バハマ',
      ru: 'Багамы',
      th: 'ประเทศบาฮามาส',
      it: 'Bahamas'
    },
    permalinks: {
      en: 'bahamas',
      fr: 'bahamas',
      de: 'bahamas',
      es: 'bahamas',
      pt: 'bahamas',
      pl: 'bahamy',
      'pt-BR': 'bahamas',
      sv: 'bahamas',
      ru: '',
      it: 'bahamas'
    },
    hasRegion: false,
    ins: { en: 'in the', fr: 'aux' }
  },
  BIH: {
    iso2: 'ba',
    old_id: 119,
    subContinent: 39,
    continent: 1,
    names: {
      en: 'Bosnia and Herzegovina',
      fr: 'Bosnie-Herzégovine',
      de: 'Bosnien und Herzegowina',
      es: 'Bosnia y Herzegovina',
      pt: 'Bósnia-Herzegóvina',
      pl: 'Bośnia i Hercegowina',
      'pt-BR': 'Bósnia e Herzegovina',
      sv: 'Bosnien-Hercegovina',
      zh: '波斯尼亚和黑塞哥维那',
      ko: '보스니아 헤르체고비나',
      ja: 'ボスニア・ヘルツェゴビナ',
      ru: 'Босния и Герцеговина',
      th: 'บอสเนียและเฮอร์เซโกวีนา',
      it: 'Bosnia-Erzegovina'
    },
    permalinks: {
      en: 'bosnia-and-herzegovina',
      fr: 'bosnie-herzegovine',
      de: 'bosnien-und-herzegowina',
      es: 'bosnia-y-herzegovina',
      pt: 'bosnia-herzegovina',
      pl: 'bosnia-i-hercegowina',
      'pt-BR': 'bosnia-e-herzegovina',
      sv: 'bosnien-hercegovina',
      ru: '',
      it: 'bosnia-erzegovina'
    },
    hasRegion: false,
    ins: {}
  },
  BLM: {
    iso2: 'bl',
    old_id: 257,
    subContinent: 29,
    continent: 3,
    names: {
      en: 'Saint Barthélemy',
      fr: 'Saint Barthélemy',
      de: 'Saint-Barthélemy',
      es: 'San Bartolomé',
      pt: 'Saint Barthélemy',
      pl: 'Saint-Barthélemy',
      'pt-BR': 'São Bartolomeu',
      sv: 'Saint-Barthélemy',
      zh: '圣巴泰勒米岛',
      ko: '생바르텔레미',
      ja: 'サン・バルテルミー',
      ru: 'Сен-Бартельми',
      th: 'แซ็ง-บาร์เตเลมี',
      it: 'Saint-Barths'
    },
    permalinks: {
      en: 'saint-barthelemy',
      fr: 'saint-barthelemy',
      de: 'saint-barthelemy',
      es: 'san-bartolome',
      pt: 'saint-barthelemy',
      pl: 'saint-barthelemy',
      'pt-BR': 'sao-bartolomeu',
      sv: 'saint-barthelemy',
      ru: '',
      it: 'saint-barths'
    },
    hasRegion: false,
    ins: { fr: 'à' }
  },
  BLR: {
    iso2: 'by',
    old_id: 136,
    subContinent: 151,
    continent: 1,
    names: {
      en: 'Belarus',
      fr: 'Biélorussie',
      de: 'Weißrussland',
      es: 'Bielorrusia',
      pt: 'Bielorússia',
      pl: 'Białoruś',
      'pt-BR': 'Bielorrússia',
      sv: 'Vitryssland',
      zh: '白俄罗斯',
      ko: '벨라루스',
      ja: 'ベラルーシ',
      ru: 'Беларусь',
      th: 'ประเทศเบลารุส',
      it: 'Bielorussia'
    },
    permalinks: {
      en: 'belarus',
      fr: 'bielorussie',
      de: 'weissrussland',
      es: 'bielorrusia',
      pt: 'bielorussia',
      pl: 'bialorus',
      'pt-BR': 'bielorrussia',
      sv: 'vitryssland',
      ru: '',
      it: 'bielorussia'
    },
    hasRegion: false,
    ins: {}
  },
  BLZ: {
    iso2: 'bz',
    old_id: 134,
    subContinent: 13,
    continent: 3,
    names: {
      en: 'Belize',
      fr: 'Belize',
      de: 'Belize',
      es: 'Belice',
      pt: 'Belize',
      pl: 'Belize',
      'pt-BR': 'Belize',
      sv: 'Belize',
      zh: '伯利兹',
      ko: '벨리즈',
      ja: 'ベリーズ',
      ru: 'Белиз',
      th: 'เบลีซ',
      it: 'Belize'
    },
    permalinks: {
      en: 'belize',
      fr: 'belize',
      de: 'belize',
      es: 'belice',
      pt: 'belize',
      pl: 'belize',
      'pt-BR': 'belize',
      sv: 'belize',
      ru: '',
      it: 'belize'
    },
    hasRegion: false,
    ins: { fr: 'à ' }
  },
  BMU: {
    iso2: 'bm',
    old_id: 8,
    subContinent: null,
    continent: 3,
    names: {
      en: 'Bermuda',
      fr: 'Bermudes',
      de: 'Bermuda',
      es: 'Islas Bermudas',
      pt: 'Bermuda',
      pl: 'Bermudy',
      'pt-BR': 'Bermudas',
      sv: 'Bermuda',
      zh: '百慕大',
      ko: '버뮤다',
      ja: 'バミューダ諸島',
      ru: 'Бермуды',
      th: 'เบอร์มิวดา',
      it: 'Bermuda'
    },
    permalinks: {
      en: 'bermuda',
      fr: 'bermudes',
      de: 'bermuda',
      es: 'islas-bermudas',
      pt: 'bermuda',
      pl: 'bermudy',
      'pt-BR': 'bermudas',
      sv: 'bermuda',
      ru: '',
      it: 'bermuda'
    },
    hasRegion: false,
    ins: { fr: 'aux' }
  },
  BOL: {
    iso2: 'bo',
    old_id: 179,
    subContinent: null,
    continent: 2,
    names: {
      en: 'Bolivia',
      fr: 'Bolivie',
      de: 'Bolivien, Plurinationaler Staat',
      es: 'Bolivia, Estado plurinacional de',
      pt: 'Bolívia, Estado Plurinacional da',
      pl: 'Boliwia',
      'pt-BR': 'Bolívia',
      sv: 'Bolivia, Mångnationella staten',
      zh: '玻利维亚共和国',
      ko: '볼리비아',
      ja: 'ボリビア',
      ru: 'Боливия',
      th: 'โบลิเวีย',
      it: 'Bolivia, Stato Plurinazionale'
    },
    permalinks: {
      en: 'bolivia',
      fr: 'bolivie',
      de: 'bolivien-plurinationaler-staat',
      es: 'bolivia-estado-plurinacional-de',
      pt: 'bolivia-estado-plurinacional-da',
      pl: 'boliwia',
      'pt-BR': 'bolivia',
      sv: 'bolivia-mangnationella-staten',
      ru: '',
      it: 'bolivia-stato-plurinazionale'
    },
    hasRegion: false,
    ins: {}
  },
  BRB: {
    iso2: 'bb',
    old_id: 6,
    subContinent: 29,
    continent: 3,
    names: {
      en: 'Barbados',
      fr: 'Barbade',
      de: 'Barbados',
      es: 'Barbados',
      pt: 'Barbados',
      pl: 'Barbados',
      'pt-BR': 'Barbados',
      sv: 'Barbados',
      zh: '巴巴多斯',
      ko: '바베이도스',
      ja: 'バルバドス',
      ru: 'Барбадос',
      th: 'ประเทศบาร์เบโดส',
      it: 'Barbados'
    },
    permalinks: {
      en: 'barbados',
      fr: 'barbade',
      de: 'barbados',
      es: 'barbados',
      pt: 'barbados',
      pl: 'barbados',
      'pt-BR': 'barbados',
      sv: 'barbados',
      ru: '',
      it: 'barbados'
    },
    hasRegion: false,
    ins: { fr: 'à la ' }
  },
  BRE: {
    iso2: 'br',
    old_id: 10,
    subContinent: null,
    continent: 2,
    names: {
      en: 'Brazil',
      fr: 'Brésil',
      de: 'Brasilien',
      es: 'Brasil',
      pt: 'Brasil',
      pl: 'Brazylia',
      'pt-BR': 'Brasil',
      sv: 'Brasilien',
      zh: '巴西',
      ko: '브라질',
      ja: 'ブラジル',
      ru: 'Бразилия',
      th: 'บราซิล',
      it: 'Brasile'
    },
    permalinks: {
      en: 'brazil',
      fr: 'bresil',
      de: 'brasilien',
      es: 'brasil',
      pt: 'brasil',
      pl: 'brazylia',
      'pt-BR': 'brasil',
      sv: 'brasilien',
      ru: '',
      it: 'brasile'
    },
    hasRegion: false,
    ins: {}
  },
  BRN: {
    iso2: 'bn',
    old_id: 180,
    subContinent: 35,
    continent: 4,
    names: {
      en: 'Brunei',
      fr: 'Brunei',
      de: 'Brunei Darussalam',
      es: 'Brunei Darussalam',
      pt: 'Brunei',
      pl: 'Brunei',
      'pt-BR': 'Brunei',
      sv: 'Brunei',
      zh: '文莱',
      ko: '브루나이',
      ja: 'ブルネイ',
      ru: 'Бруней Даруссалам',
      th: 'บรูไน',
      it: 'Brunei'
    },
    permalinks: {
      en: 'brunei',
      fr: 'brunei',
      de: 'brunei-darussalam',
      es: 'brunei-darussalam',
      pt: 'brunei',
      pl: 'brunei',
      'pt-BR': 'brunei',
      sv: 'brunei',
      ru: '',
      it: 'brunei'
    },
    hasRegion: false,
    ins: { fr: 'à' }
  },
  BTN: {
    iso2: 'bt',
    old_id: 9,
    subContinent: 34,
    continent: 4,
    names: {
      en: 'Bhutan',
      fr: 'Bhoutan',
      de: 'Bhutan',
      es: 'Bhután',
      pt: 'Butão',
      pl: 'Bhutan',
      'pt-BR': 'Butão',
      sv: 'Bhutan',
      zh: '不丹',
      ko: '부탄',
      ja: 'ブータン',
      ru: 'Бутан',
      th: 'ประเทศภูฏาน',
      it: 'Bhutan'
    },
    permalinks: {
      en: 'bhutan',
      fr: 'bhoutan',
      de: 'bhutan',
      es: 'bhutan',
      pt: 'butao',
      pl: 'bhutan',
      'pt-BR': 'butao',
      sv: 'bhutan',
      ru: '',
      it: 'bhutan'
    },
    hasRegion: false,
    ins: { fr: 'au' }
  },
  BVT: {
    iso2: 'bv',
    old_id: 450,
    subContinent: null,
    continent: 5,
    names: {
      en: 'Bouvet Island',
      fr: 'Bouvet, Île',
      de: 'Bouvet-Insel',
      es: 'Isla Bouvet',
      pt: 'Ilha Bouvet',
      pl: 'Wyspa Bouveta',
      'pt-BR': 'Ilha Bouvet',
      sv: 'Bouvetön',
      zh: '布维群岛',
      ko: 'Bouvet Island',
      ja: 'Bouvet Island',
      ru: 'Остров Буве',
      th: 'Bouvet Island',
      it: 'Isola di Bouvet'
    },
    permalinks: {
      en: 'bouvet-island',
      fr: 'bouvet-ile',
      de: 'bouvet-insel',
      es: 'isla-bouvet',
      pt: 'ilha-bouvet',
      pl: 'wyspa-bouveta',
      'pt-BR': 'ilha-bouvet',
      sv: 'bouveton',
      ru: '',
      it: 'isola-di-bouvet'
    },
    hasRegion: false,
    ins: { en: 'in the', fr: "dans l'île Bouvet" }
  },
  BWA: {
    iso2: 'bw',
    old_id: 171,
    subContinent: 18,
    continent: 7,
    names: {
      en: 'Botswana',
      fr: 'Botswana',
      de: 'Botsuana',
      es: 'Botsuana',
      pt: 'Botsuana',
      pl: 'Botswana',
      'pt-BR': 'Botsuana',
      sv: 'Botswana',
      zh: '博兹瓦那',
      ko: '보츠와나',
      ja: 'ボツワナ',
      ru: 'Ботсвана',
      th: 'บอตสวานา',
      it: 'Botswana'
    },
    permalinks: {
      en: 'botswana',
      fr: 'botswana',
      de: 'botsuana',
      es: 'botsuana',
      pt: 'botsuana',
      pl: 'botswana',
      'pt-BR': 'botsuana',
      sv: 'botswana',
      ru: '',
      it: 'botswana'
    },
    hasRegion: false,
    ins: { fr: 'au' }
  },
  CAF: {
    iso2: 'cf',
    old_id: 184,
    subContinent: 17,
    continent: 7,
    names: {
      en: 'Central African Republic',
      fr: 'République centrafricaine',
      de: 'Zentralafrikanische Republik',
      es: 'República Centro-africana',
      pt: 'República Centro-Africana',
      pl: 'Republika Środkowoafrykańska',
      'pt-BR': 'República Centro-Africana',
      sv: 'Centralafrikanska republiken',
      zh: '中非',
      ko: '중앙아프리카 공화국',
      ja: '中央アフリカ共和国',
      ru: 'Центрально-африканская республика',
      th: 'สาธารณรัฐแอฟริกากลาง',
      it: 'Repubblica Centrafricana'
    },
    permalinks: {
      en: 'central-african-republic',
      fr: 'republique-centrafricaine',
      de: 'zentralafrikanische-republik',
      es: 'republica-centro-africana',
      pt: 'republica-centro-africana',
      pl: 'republika-srodkowoafrykanska',
      'pt-BR': 'republica-centro-africana',
      sv: 'centralafrikanska-republiken',
      ru: '',
      it: 'repubblica-centrafricana'
    },
    hasRegion: false,
    ins: { en: 'in the', fr: '' }
  },
  CAN: {
    iso2: 'ca',
    old_id: 13,
    subContinent: null,
    continent: 3,
    names: {
      en: 'Canada',
      fr: 'Canada',
      de: 'Kanada',
      es: 'Canadá',
      pt: 'Canadá',
      pl: 'Kanada',
      'pt-BR': 'Canadá',
      sv: 'Kanada',
      zh: '加拿大',
      ko: '캐나다',
      ja: 'カナダ',
      ru: 'Канада',
      th: 'ประเทศแคนาดา',
      it: 'Canada'
    },
    permalinks: {
      en: 'canada',
      fr: 'canada',
      de: 'kanada',
      es: 'canada',
      pt: 'canada',
      pl: 'kanada',
      'pt-BR': 'canada',
      sv: 'kanada',
      ru: '',
      it: 'canada'
    },
    hasRegion: true,
    ins: { fr: 'au' }
  },
  CCK: {
    iso2: 'cc',
    old_id: 451,
    subContinent: 53,
    continent: 6,
    names: {
      en: 'Cocos (Keeling) Islands',
      fr: 'Cocos (Keeling), Îles',
      de: 'Kokos-(Keeling-)Inseln',
      es: 'Islas Cocos (Keeling)',
      pt: 'Ilhas Cocos',
      pl: 'Wyspy Kokosowe',
      'pt-BR': 'Ilhas Coco',
      sv: 'Kokosöarna',
      zh: '科科斯群岛',
      ko: '코코스제도',
      ja: 'ココス[キーリング]諸島',
      ru: 'Кокосовые острова',
      th: 'หมู่เกาะโคโคส',
      it: 'Isole Cocos (Keeling)'
    },
    permalinks: {
      en: 'cocos-keeling-islands',
      fr: 'cocos-keeling-iles',
      de: 'kokos-keeling-inseln',
      es: 'islas-cocos-keeling',
      pt: 'ilhas-cocos',
      pl: 'wyspy-kokosowe',
      'pt-BR': 'ilhas-coco',
      sv: 'kokosoarna',
      ru: '',
      it: 'isole-cocos-keeling'
    },
    hasRegion: false,
    ins: { en: 'in the ', fr: 'dans les ïles Cocos' }
  },
  CHE: {
    iso2: 'ch',
    old_id: 85,
    subContinent: 155,
    continent: 1,
    names: {
      en: 'Switzerland',
      fr: 'Suisse',
      de: 'Schweiz',
      es: 'Suiza',
      pt: 'Suíça',
      pl: 'Szwajcaria',
      'pt-BR': 'Suíça',
      sv: 'Schweiz',
      zh: '瑞士',
      ko: '스위스',
      ja: 'スイス',
      ru: 'Швейцария',
      th: 'ประเทศสวิตเซอร์แลนด์',
      it: 'Svizzera'
    },
    permalinks: {
      en: 'switzerland',
      fr: 'suisse',
      de: 'schweiz',
      es: 'suiza',
      pt: 'suica',
      pl: 'szwajcaria',
      'pt-BR': 'suica',
      sv: 'schweiz',
      ru: '',
      it: 'svizzera'
    },
    hasRegion: true,
    ins: {}
  },
  CHL: {
    iso2: 'cl',
    old_id: 15,
    subContinent: null,
    continent: 2,
    names: {
      en: 'Chile',
      fr: 'Chili',
      de: 'Chile',
      es: 'Chile',
      pt: 'Chile',
      pl: 'Chile',
      'pt-BR': 'Chile',
      sv: 'Chile',
      zh: '智利',
      ko: '칠레',
      ja: 'チリ',
      ru: 'Чили',
      th: 'ชิลี',
      it: 'Cile'
    },
    permalinks: {
      en: 'chile',
      fr: 'chili',
      de: 'chile',
      es: 'chile',
      pt: 'chile',
      pl: 'chile',
      'pt-BR': 'chile',
      sv: 'chile',
      ru: '',
      it: 'cile'
    },
    hasRegion: false,
    ins: { fr: 'au' }
  },
  CHN: {
    iso2: 'cn',
    old_id: 16,
    subContinent: 30,
    continent: 4,
    names: {
      en: 'China',
      fr: 'Chine',
      de: 'China',
      es: 'China',
      pt: 'China',
      pl: 'Chiny',
      'pt-BR': 'China',
      sv: 'Kina',
      zh: '中国',
      ko: '중화인민공화국',
      ja: '中国',
      ru: 'Китай',
      th: 'ประเทศจีน',
      it: 'Cina'
    },
    permalinks: {
      en: 'china',
      fr: 'chine',
      de: 'china',
      es: 'china',
      pt: 'china',
      pl: 'chiny',
      'pt-BR': 'china',
      sv: 'kina',
      ru: '',
      it: 'cina'
    },
    hasRegion: false,
    ins: {}
  },
  CIV: {
    iso2: 'ci',
    old_id: 452,
    subContinent: 11,
    continent: 7,
    names: {
      en: 'Ivory Coast',
      fr: "Côte d'Ivoire",
      de: "Côte d'Ivoire",
      es: 'Costa de Marfíl',
      pt: 'Costa do Marfim',
      pl: 'Wybrzeże Kości Słoniowej',
      'pt-BR': 'Costa do Marfim',
      sv: 'Elfenbenskusten',
      zh: '科特迪瓦',
      ko: '코트디부아르',
      ja: 'コートジボワール',
      ru: "Кот-д'Ивуар",
      th: 'ประเทศโกตดิวัวร์',
      it: "Costa d'Avorio"
    },
    permalinks: {
      en: 'ivory-coast',
      fr: 'cote-d-ivoire',
      de: 'cote-d-ivoire',
      es: 'costa-de-marfil',
      pt: 'costa-do-marfim',
      pl: 'wybrzeze-kosci-sloniowej',
      'pt-BR': 'costa-do-marfim',
      sv: 'elfenbenskusten',
      ru: '',
      it: 'costa-d-avorio'
    },
    hasRegion: false,
    ins: { en: 'in the' }
  },
  CMR: {
    iso2: 'cm',
    old_id: 174,
    subContinent: 17,
    continent: 7,
    names: {
      en: 'Cameroon',
      fr: 'Cameroun',
      de: 'Kamerun',
      es: 'Camerún',
      pt: 'Camarões',
      pl: 'Kamerun',
      'pt-BR': 'Camarões',
      sv: 'Kamerun',
      zh: '喀麦隆',
      ko: '카메룬',
      ja: 'カメルーン',
      ru: 'Камерун',
      th: 'ประเทศแคเมอรูน',
      it: 'Camerun'
    },
    permalinks: {
      en: 'cameroon',
      fr: 'cameroun',
      de: 'kamerun',
      es: 'camerun',
      pt: 'camaroes',
      pl: 'kamerun',
      'pt-BR': 'camaroes',
      sv: 'kamerun',
      ru: '',
      it: 'camerun'
    },
    hasRegion: false,
    ins: { fr: 'au' }
  },
  COD: {
    iso2: 'cd',
    old_id: 188,
    subContinent: 17,
    continent: 7,
    names: {
      en: 'Democratic Republic of the Congo',
      fr: 'République démocratique du Congo',
      de: 'Demokratische Republik Kongo',
      es: 'Congo, República Democrática del',
      pt: 'Congo, República Democrática do',
      pl: 'Demokratyczna Republika Konga',
      'pt-BR': 'República Democrática do Congo',
      sv: 'Kongo, demokratiska republiken',
      zh: '刚果民主共和国',
      ko: '콩고 민주 공화국',
      ja: 'コンゴ民主共和国',
      ru: 'Демократическая Республика Конго',
      th: 'สาธารณรัฐประชาธิปไตยคองโก',
      it: 'Repubblica democratica del Congo'
    },
    permalinks: {
      en: 'democratic-republic-of-the-congo',
      fr: 'republique-democratique-du-congo',
      de: 'demokratische-republik-kongo',
      es: 'congo-republica-democratica-del',
      pt: 'congo-republica-democratica-do',
      pl: 'demokratyczna-republika-konga',
      'pt-BR': 'republica-democratica-do-congo',
      sv: 'kongo-demokratiska-republiken',
      ru: '',
      it: 'repubblica-democratica-del-congo'
    },
    hasRegion: false,
    ins: { en: 'in the' }
  },
  COG: {
    iso2: 'cg',
    old_id: 187,
    subContinent: 17,
    continent: 7,
    names: {
      en: 'Congo',
      fr: 'Congo',
      de: 'Kongo',
      es: 'Congo',
      pt: 'Congo',
      pl: 'Kongo',
      'pt-BR': 'República do Congo',
      sv: 'Kongo',
      zh: '刚果',
      ko: '콩고 공화국',
      ja: 'コンゴ共和国',
      ru: 'Конго',
      th: 'สาธารณรัฐคองโก',
      it: 'Congo'
    },
    permalinks: {
      en: 'congo',
      fr: 'congo',
      de: 'kongo',
      es: 'congo',
      pt: 'congo',
      pl: 'kongo',
      'pt-BR': 'republica-do-congo',
      sv: 'kongo',
      ru: '',
      it: 'congo'
    },
    hasRegion: false,
    ins: { fr: 'au' }
  },
  COK: {
    iso2: 'ck',
    old_id: 189,
    subContinent: 61,
    continent: 6,
    names: {
      en: 'Cook Islands',
      fr: 'Îles Cook',
      de: 'Cookinseln',
      es: 'Islas Cook',
      pt: 'Ilhas Cook',
      pl: 'Wyspy Cooka',
      'pt-BR': 'Ilhas Cook',
      sv: 'Cooköarna',
      zh: '库克群岛',
      ko: '쿡 제도',
      ja: 'クック諸島',
      ru: 'Острова Кука',
      th: 'หมู่เกาะคุก',
      it: 'Isole Cook'
    },
    permalinks: {
      en: 'cook-islands',
      fr: 'iles-cook',
      de: 'cookinseln',
      es: 'islas-cook',
      pt: 'ilhas-cook',
      pl: 'wyspy-cooka',
      'pt-BR': 'ilhas-cook',
      sv: 'cookoarna',
      ru: '',
      it: 'isole-cook'
    },
    hasRegion: false,
    ins: { en: 'in the', fr: 'aux ' }
  },
  COL: {
    iso2: 'co',
    old_id: 111,
    subContinent: null,
    continent: 2,
    names: {
      en: 'Colombia',
      fr: 'Colombie',
      de: 'Kolumbien',
      es: 'Colombia',
      pt: 'Colômbia',
      pl: 'Kolumbia',
      'pt-BR': 'Colômbia',
      sv: 'Colombia',
      zh: '哥伦比亚',
      ko: '콜롬비아',
      ja: 'コロンビア',
      ru: 'Колумбия',
      th: 'ประเทศโคลอมเบีย',
      it: 'Colombia'
    },
    permalinks: {
      en: 'colombia',
      fr: 'colombie',
      de: 'kolumbien',
      es: 'colombia',
      pt: 'colombia',
      pl: 'kolumbia',
      'pt-BR': 'colombia',
      sv: 'colombia',
      ru: '',
      it: 'colombia'
    },
    hasRegion: false,
    ins: {}
  },
  COM: {
    iso2: 'km',
    old_id: 186,
    subContinent: 14,
    continent: 7,
    names: {
      en: 'Comoros',
      fr: 'Comores',
      de: 'Komoren',
      es: 'Comores, Islas',
      pt: 'Comoros',
      pl: 'Komory',
      'pt-BR': 'Comores',
      sv: 'Comorerna',
      zh: '科摩罗',
      ko: '코모로',
      ja: 'コモロ',
      ru: 'Коморские острова',
      th: 'ประเทศคอโมโรส',
      it: 'Comore'
    },
    permalinks: {
      en: 'comoros',
      fr: 'comores',
      de: 'komoren',
      es: 'comores-islas',
      pt: 'comoros',
      pl: 'komory',
      'pt-BR': 'comores',
      sv: 'comorerna',
      ru: '',
      it: 'comore'
    },
    hasRegion: false,
    ins: { fr: 'aux' }
  },
  CPV: {
    iso2: 'cv',
    old_id: 109,
    subContinent: 11,
    continent: 7,
    names: {
      en: 'Cape Verde',
      fr: 'Cap Vert',
      de: 'Cabo Verde',
      es: 'Cabo Verde',
      pt: 'Cabo Verde',
      pl: 'Republika Zielonego Przylądka',
      'pt-BR': 'Cabo Verde',
      sv: 'Kap Verde',
      zh: '佛得角',
      ko: '카보베르데',
      ja: 'カーボベルデ',
      ru: 'Кабо-Верде',
      th: 'Cape Verde',
      it: 'Capo Verde'
    },
    permalinks: {
      en: 'cape-verde',
      fr: 'cap-vert',
      de: 'cabo-verde',
      es: 'cabo-verde',
      pt: 'cabo-verde',
      pl: 'republika-zielonego-przyladka',
      'pt-BR': 'cabo-verde',
      sv: 'kap-verde',
      ru: '',
      it: 'capo-verde'
    },
    hasRegion: false,
    ins: { fr: 'au' }
  },
  CRI: {
    iso2: 'cr',
    old_id: 17,
    subContinent: 13,
    continent: 3,
    names: {
      en: 'Costa Rica',
      fr: 'Costa Rica',
      de: 'Costa Rica',
      es: 'Costa Rica',
      pt: 'Costa Rica',
      pl: 'Kostaryka',
      'pt-BR': 'Costa Rica',
      sv: 'Costa Rica',
      zh: '哥斯达黎加',
      ko: '코스타리카',
      ja: 'コスタリカ',
      ru: 'Коста-Рика',
      th: 'ประเทศคอสตาริกา',
      it: 'Costa Rica'
    },
    permalinks: {
      en: 'costa-rica',
      fr: 'costa-rica',
      de: 'costa-rica',
      es: 'costa-rica',
      pt: 'costa-rica',
      pl: 'kostaryka',
      'pt-BR': 'costa-rica',
      sv: 'costa-rica',
      ru: '',
      it: 'costa-rica'
    },
    hasRegion: false,
    ins: { fr: 'au' }
  },
  CUB: {
    iso2: 'cu',
    old_id: 19,
    subContinent: 29,
    continent: 3,
    names: {
      en: 'Cuba',
      fr: 'Cuba',
      de: 'Kuba',
      es: 'Cuba',
      pt: 'Cuba',
      pl: 'Kuba',
      'pt-BR': 'Cuba',
      sv: 'Kuba',
      zh: '古巴',
      ko: '쿠바',
      ja: 'キューバ',
      ru: 'Куба',
      th: 'ประเทศคิวบา',
      it: 'Cuba'
    },
    permalinks: {
      en: 'cuba',
      fr: 'cuba',
      de: 'kuba',
      es: 'cuba',
      pt: 'cuba',
      pl: 'kuba',
      'pt-BR': 'cuba',
      sv: 'kuba',
      ru: '',
      it: 'cuba'
    },
    hasRegion: false,
    ins: { fr: 'à' }
  },
  CUW: {
    iso2: 'cw',
    old_id: 190,
    subContinent: 29,
    continent: 2,
    names: {
      en: 'Curaçao',
      fr: 'Curaçao',
      de: 'Curaçao',
      es: 'Curasao',
      pt: 'Curação',
      pl: 'Curaçao',
      'pt-BR': 'Curaçao',
      sv: 'Curaçao',
      zh: '库拉索',
      ko: '퀴라소',
      ja: 'キュラソー',
      ru: 'Кюрасао',
      th: 'คูราเซา',
      it: 'Curaçao'
    },
    permalinks: {
      en: 'curacao',
      fr: 'curacao',
      de: 'curacao',
      es: 'curasao',
      pt: 'curacao',
      pl: 'curacao',
      'pt-BR': 'curacao',
      sv: 'curacao',
      ru: '',
      it: 'curacao'
    },
    hasRegion: false,
    ins: { fr: 'à' }
  },
  CXR: {
    iso2: 'cx',
    old_id: 453,
    subContinent: 53,
    continent: 6,
    names: {
      en: 'Christmas Island',
      fr: 'Christmas, Île',
      de: 'Weihnachtsinseln',
      es: 'Isla de Navidad',
      pt: 'Ilha Natal',
      pl: 'Wyspa Bożego Narodzenia',
      'pt-BR': 'Ilha Christmas',
      sv: 'Julön',
      zh: '圣诞岛',
      ko: '크리스마스 섬',
      ja: 'クリスマス島',
      ru: 'Остров Рождества',
      th: 'เกาะคริสต์มาส',
      it: 'Isola Christmas'
    },
    permalinks: {
      en: 'christmas-island',
      fr: 'christmas-ile',
      de: 'weihnachtsinseln',
      es: 'isla-de-navidad',
      pt: 'ilha-natal',
      pl: 'wyspa-bozego-narodzenia',
      'pt-BR': 'ilha-christmas',
      sv: 'julon',
      ru: '',
      it: 'isola-christmas'
    },
    hasRegion: false,
    ins: { en: 'in the', fr: "dans l'Île Christmas" }
  },
  CYM: {
    iso2: 'ky',
    old_id: 14,
    subContinent: 29,
    continent: 3,
    names: {
      en: 'Cayman Islands',
      fr: 'Iles Cayman',
      de: 'Cayman-Inseln',
      es: 'Islas Caimán',
      pt: 'Ilhas Caimão',
      pl: 'Kajmany',
      'pt-BR': 'Ilhas Cayman',
      sv: 'Caymanöarna',
      zh: '开曼群岛',
      ko: '케이맨제도',
      ja: 'ケイマン諸島',
      ru: 'Каймановы острова',
      th: 'หมู่เกาะเคย์แมน',
      it: 'Isole Cayman'
    },
    permalinks: {
      en: 'cayman-islands',
      fr: 'iles-cayman',
      de: 'cayman-inseln',
      es: 'islas-caiman',
      pt: 'ilhas-caimao',
      pl: 'kajmany',
      'pt-BR': 'ilhas-cayman',
      sv: 'caymanoarna',
      ru: '',
      it: 'isole-cayman'
    },
    hasRegion: false,
    ins: { en: 'in the', fr: 'aux' }
  },
  CYP: {
    iso2: 'cy',
    old_id: 20,
    subContinent: 151,
    continent: 1,
    names: {
      en: 'Cyprus',
      fr: 'Chypre',
      de: 'Zypern',
      es: 'Chipre',
      pt: 'Chipre',
      pl: 'Cypr',
      'pt-BR': 'Chipre',
      sv: 'Cypern',
      zh: '塞浦路斯',
      ko: '키프로스',
      ja: 'キプロス',
      ru: 'Кипр',
      th: 'ประเทศไซปรัส',
      it: 'Cipro'
    },
    permalinks: {
      en: 'cyprus',
      fr: 'chypre',
      de: 'zypern',
      es: 'chipre',
      pt: 'chipre',
      pl: 'cypr',
      'pt-BR': 'chipre',
      sv: 'cypern',
      ru: '',
      it: 'cipro'
    },
    hasRegion: false,
    ins: { fr: 'à' }
  },
  CZE: {
    iso2: 'cz',
    old_id: 21,
    subContinent: 151,
    continent: 1,
    names: {
      en: 'Czech Republic',
      fr: 'République Tchèque',
      de: 'Tschechische Republik',
      es: 'República Checa',
      pt: 'República Checa',
      pl: 'Czechy',
      'pt-BR': 'Tcheca',
      sv: 'Tjeckien',
      zh: '捷克',
      ko: '체코',
      ja: 'チェコ',
      ru: 'Чешская Республика',
      th: 'ประเทศเช็กเกีย',
      it: 'Repubblica Ceca'
    },
    permalinks: {
      en: 'czech-republic',
      fr: 'republique-tcheque',
      de: 'tschechische-republik',
      es: 'republica-checa',
      pt: 'republica-checa',
      pl: 'czechy',
      'pt-BR': 'tcheca',
      sv: 'tjeckien',
      ru: '',
      it: 'repubblica-ceca'
    },
    hasRegion: false,
    ins: {}
  },
  DEU: {
    iso2: 'de',
    old_id: 31,
    subContinent: 155,
    continent: 1,
    names: {
      en: 'Germany',
      fr: 'Allemagne',
      de: 'Deutschland',
      es: 'Alemania',
      pt: 'Alemanha',
      pl: 'Niemcy',
      'pt-BR': 'Alemanha',
      sv: 'Tyskland',
      zh: '德国',
      ko: '독일',
      ja: 'ドイツ',
      ru: 'Германия',
      th: 'ประเทศเยอรมนี',
      it: 'Germania'
    },
    permalinks: {
      en: 'germany',
      fr: 'allemagne',
      de: 'deutschland',
      es: 'alemania',
      pt: 'alemanha',
      pl: 'niemcy',
      'pt-BR': 'alemanha',
      sv: 'tyskland',
      ru: '',
      it: 'germania'
    },
    hasRegion: true,
    ins: {}
  },
  DJI: {
    iso2: 'dj',
    old_id: 191,
    subContinent: 14,
    continent: 7,
    names: {
      en: 'Djibouti',
      fr: 'Djibouti',
      de: 'Dschibuti',
      es: 'Yibuti',
      pt: 'Djibouti',
      pl: 'Dżibuti',
      'pt-BR': 'Djibouti',
      sv: 'Djibouti',
      zh: '吉布提',
      ko: '지부티',
      ja: 'ジブチ',
      ru: 'Джибути',
      th: 'ประเทศจิบูตี',
      it: 'Gibuti'
    },
    permalinks: {
      en: 'djibouti',
      fr: 'djibouti',
      de: 'dschibuti',
      es: 'yibuti',
      pt: 'djibouti',
      pl: 'dzibuti',
      'pt-BR': 'djibouti',
      sv: 'djibouti',
      ru: '',
      it: 'gibuti'
    },
    hasRegion: false,
    ins: { fr: 'à' }
  },
  DMA: {
    iso2: 'dm',
    old_id: 192,
    subContinent: 29,
    continent: 3,
    names: {
      en: 'Dominica',
      fr: 'Dominique',
      de: 'Dominica',
      es: 'Dominica',
      pt: 'Dominica',
      pl: 'Dominika',
      'pt-BR': 'Dominica',
      sv: 'Dominica',
      zh: '多米尼克',
      ko: '도미니카',
      ja: 'ドミニカ国',
      ru: 'Доминика',
      th: 'ประเทศโดมินิกา',
      it: 'Dominica'
    },
    permalinks: {
      en: 'dominica',
      fr: 'dominique',
      de: 'dominica',
      es: 'dominica',
      pt: 'dominica',
      pl: 'dominika',
      'pt-BR': 'dominica',
      sv: 'dominica',
      ru: '',
      it: 'dominica'
    },
    hasRegion: false,
    ins: {}
  },
  DNK: {
    iso2: 'dk',
    old_id: 22,
    subContinent: 154,
    continent: 1,
    names: {
      en: 'Denmark',
      fr: 'Danemark',
      de: 'Dänemark',
      es: 'Dinamarca',
      pt: 'Dinamarca',
      pl: 'Dania',
      'pt-BR': 'Dinamarca',
      sv: 'Danmark',
      zh: '丹麦',
      ko: '덴마크',
      ja: 'デンマーク',
      ru: 'Дания',
      th: 'ประเทศเดนมาร์ก',
      it: 'Danimarca'
    },
    permalinks: {
      en: 'denmark',
      fr: 'danemark',
      de: 'danemark',
      es: 'dinamarca',
      pt: 'dinamarca',
      pl: 'dania',
      'pt-BR': 'dinamarca',
      sv: 'danmark',
      ru: '',
      it: 'danimarca'
    },
    hasRegion: true,
    ins: { fr: 'au' }
  },
  DOM: {
    iso2: 'do',
    old_id: 193,
    subContinent: 29,
    continent: 3,
    names: {
      en: 'Dominican Republic',
      fr: 'République Dominicaine',
      de: 'Dominikanische Republik',
      es: 'República Dominicana',
      pt: 'República Dominicana',
      pl: 'Dominikana',
      'pt-BR': 'República Dominicana',
      sv: 'Dominikanska republiken',
      zh: '多米尼加共和国',
      ko: '도미니카 공화국',
      ja: 'ドミニカ共和国',
      ru: 'Доминиканская республика',
      th: 'สาธารณรัฐโดมินิกัน',
      it: 'Repubblica Dominicana'
    },
    permalinks: {
      en: 'dominican-republic',
      fr: 'republique-dominicaine',
      de: 'dominikanische-republik',
      es: 'republica-dominicana',
      pt: 'republica-dominicana',
      pl: 'dominikana',
      'pt-BR': 'republica-dominicana',
      sv: 'dominikanska-republiken',
      ru: '',
      it: 'repubblica-dominicana'
    },
    hasRegion: false,
    ins: { en: 'in the' }
  },
  ECU: {
    iso2: 'ec',
    old_id: 23,
    subContinent: null,
    continent: 2,
    names: {
      en: 'Ecuador',
      fr: 'Equateur',
      de: 'Ecuador',
      es: 'Ecuador',
      pt: 'Equador',
      pl: 'Ekwador',
      'pt-BR': 'Equador',
      sv: 'Ecuador',
      zh: '厄瓜多尔',
      ko: '에콰도르',
      ja: 'エクアドル',
      ru: 'Эквадор',
      th: 'ประเทศเอกวาดอร์',
      it: 'Ecuador'
    },
    permalinks: {
      en: 'ecuador',
      fr: 'equateur',
      de: 'ecuador',
      es: 'ecuador',
      pt: 'equador',
      pl: 'ekwador',
      'pt-BR': 'equador',
      sv: 'ecuador',
      ru: '',
      it: 'ecuador'
    },
    hasRegion: false,
    ins: {}
  },
  EGY: {
    iso2: 'eg',
    old_id: 24,
    subContinent: 15,
    continent: 7,
    names: {
      en: 'Egypt',
      fr: 'Egypte',
      de: 'Ägypten',
      es: 'Egipto',
      pt: 'Egipto',
      pl: 'Egipt',
      'pt-BR': 'Egito',
      sv: 'Egypten',
      zh: '埃及',
      ko: '이집트',
      ja: 'エジプト',
      ru: 'Египт',
      th: 'ประเทศอียิปต์',
      it: 'Egitto'
    },
    permalinks: {
      en: 'egypt',
      fr: 'egypte',
      de: 'agypten',
      es: 'egipto',
      pt: 'egipto',
      pl: 'egipt',
      'pt-BR': 'egito',
      sv: 'egypten',
      ru: '',
      it: 'egitto'
    },
    hasRegion: false,
    ins: {}
  },
  ERI: {
    iso2: 'er',
    old_id: 194,
    subContinent: 14,
    continent: 7,
    names: {
      en: 'Eritrea',
      fr: 'Érythrée',
      de: 'Eritrea',
      es: 'Eritrea',
      pt: 'Eritreia',
      pl: 'Erytrea',
      'pt-BR': 'Eritreia',
      sv: 'Eritrea',
      zh: '厄立特里亚',
      ko: '에리트레아',
      ja: 'エリトリア',
      ru: 'Эритрея',
      th: 'ประเทศเอริเทรีย',
      it: 'Eritrea'
    },
    permalinks: {
      en: 'eritrea',
      fr: 'erythree',
      de: 'eritrea',
      es: 'eritrea',
      pt: 'eritreia',
      pl: 'erytrea',
      'pt-BR': 'eritreia',
      sv: 'eritrea',
      ru: '',
      it: 'eritrea'
    },
    hasRegion: false,
    ins: {}
  },
  ESH: {
    iso2: 'eh',
    old_id: 454,
    subContinent: 15,
    continent: 7,
    names: {
      en: 'Western Sahara',
      fr: 'Sahara Occidental',
      de: 'Westsahara',
      es: 'Sahara Occidental',
      pt: 'Sahara Ocidental',
      pl: 'Sahara Zachodnia',
      'pt-BR': 'Saara Ocidental',
      sv: 'Västsahara',
      zh: '西撒哈拉',
      ko: '서사하라',
      ja: '西サハラ',
      ru: 'Западная Сахара',
      th: 'เวสเทิร์นสะฮารา',
      it: 'Sahara occidentale'
    },
    permalinks: {
      en: 'western-sahara',
      fr: 'sahara-occidental',
      de: 'westsahara',
      es: 'sahara-occidental',
      pt: 'sahara-ocidental',
      pl: 'sahara-zachodnia',
      'pt-BR': 'saara-ocidental',
      sv: 'vastsahara',
      ru: '',
      it: 'sahara-occidentale'
    },
    hasRegion: false,
    ins: { en: 'in the', fr: 'au' }
  },
  ESP: {
    iso2: 'es',
    old_id: 83,
    subContinent: 39,
    continent: 1,
    names: {
      en: 'Spain',
      fr: 'Espagne',
      de: 'Spanien',
      es: 'España',
      pt: 'Espanha',
      pl: 'Hiszpania',
      'pt-BR': 'Espanha',
      sv: 'Spanien',
      zh: '西班牙',
      ko: '스페인',
      ja: 'スペイン',
      ru: 'Испания',
      th: 'ประเทศสเปน',
      it: 'Spagna'
    },
    permalinks: {
      en: 'spain',
      fr: 'espagne',
      de: 'spanien',
      es: 'espana',
      pt: 'espanha',
      pl: 'hiszpania',
      'pt-BR': 'espanha',
      sv: 'spanien',
      ru: '',
      it: 'spagna'
    },
    hasRegion: true,
    ins: {}
  },
  EST: {
    iso2: 'ee',
    old_id: 25,
    subContinent: 154,
    continent: 1,
    names: {
      en: 'Estonia',
      fr: 'Estonie',
      de: 'Estland',
      es: 'Estonia',
      pt: 'Estónia',
      pl: 'Estonia',
      'pt-BR': 'Estónia',
      sv: 'Estland',
      zh: '爱沙尼亚',
      ko: '에스토니아',
      ja: 'エストニア',
      ru: 'Эстония',
      th: 'ประเทศเอสโตเนีย',
      it: 'Estonia'
    },
    permalinks: {
      en: 'estonia',
      fr: 'estonie',
      de: 'estland',
      es: 'estonia',
      pt: 'estonia',
      pl: 'estonia',
      'pt-BR': 'estonia',
      sv: 'estland',
      ru: '',
      it: 'estonia'
    },
    hasRegion: false,
    ins: {}
  },
  ETH: {
    iso2: 'et',
    old_id: 195,
    subContinent: 14,
    continent: 7,
    names: {
      en: 'Ethiopia',
      fr: 'Ethiopie',
      de: 'Äthiopien',
      es: 'Etiopía',
      pt: 'Etiópia',
      pl: 'Etiopia',
      'pt-BR': 'Etiópia',
      sv: 'Etiopien',
      zh: '埃塞俄比亚',
      ko: '에티오피아',
      ja: 'エチオピア',
      ru: 'Эфиопия',
      th: 'เอธิโอเปีย',
      it: 'Etiopia'
    },
    permalinks: {
      en: 'ethiopia',
      fr: 'ethiopie',
      de: 'athiopien',
      es: 'etiopia',
      pt: 'etiopia',
      pl: 'etiopia',
      'pt-BR': 'etiopia',
      sv: 'etiopien',
      ru: '',
      it: 'etiopia'
    },
    hasRegion: false,
    ins: {}
  },
  FIN: {
    iso2: 'fi',
    old_id: 28,
    subContinent: 154,
    continent: 1,
    names: {
      en: 'Finland',
      fr: 'Finlande',
      de: 'Finnland',
      es: 'Finlandia',
      pt: 'Finlândia',
      pl: 'Finlandia',
      'pt-BR': 'Finlândia',
      sv: 'Finland',
      zh: '芬兰',
      ko: '핀란드',
      ja: 'フィンランド',
      ru: 'Финляндия',
      th: 'ประเทศฟินแลนด์',
      it: 'Finlandia'
    },
    permalinks: {
      en: 'finland',
      fr: 'finlande',
      de: 'finnland',
      es: 'finlandia',
      pt: 'finlandia',
      pl: 'finlandia',
      'pt-BR': 'finlandia',
      sv: 'finland',
      ru: '',
      it: 'finlandia'
    },
    hasRegion: true,
    ins: {}
  },
  FJI: {
    iso2: 'fj',
    old_id: 27,
    subContinent: 54,
    continent: 6,
    names: {
      en: 'Fiji',
      fr: 'Fidji',
      de: 'Fidschi',
      es: 'Fiyi',
      pt: 'Fiji',
      pl: 'Fidżi',
      'pt-BR': 'Fiji',
      sv: 'Fiji',
      zh: '斐济',
      ko: '피지',
      ja: 'フィジー',
      ru: 'Фиджи',
      th: 'ประเทศฟิจิ',
      it: 'Fiji'
    },
    permalinks: {
      en: 'fiji',
      fr: 'fidji',
      de: 'fidschi',
      es: 'fiyi',
      pt: 'fiji',
      pl: 'fidzi',
      'pt-BR': 'fiji',
      sv: 'fiji',
      ru: '',
      it: 'fiji'
    },
    hasRegion: false,
    ins: { fr: 'aux' }
  },
  FLK: {
    iso2: 'fk',
    old_id: 26,
    subContinent: null,
    continent: 2,
    names: {
      en: 'Falklands Islands',
      fr: 'Malouines',
      de: 'Falklandinseln (Malwinen)',
      es: 'Islas Falkland (Malvinas)',
      pt: 'Ilhas Falkland (Malvinas)',
      pl: 'Falklandy',
      'pt-BR': 'Ilhas Malvinas',
      sv: 'Falklandsöarna (Malvinas)',
      zh: '福克兰群岛(马尔维纳斯)',
      ko: '포클랜드 제도 (말비나스 군도)',
      ja: 'フォークランド諸島 (マルビナス諸島)',
      ru: 'Фолклендские (Мальвинские) острова',
      th: 'หมู่เกาะฟอล์กแลนด์',
      it: 'Isole Falkland (Malvine)'
    },
    permalinks: {
      en: 'falklands-islands',
      fr: 'malouines',
      de: 'falklandinseln-malwinen',
      es: 'islas-falkland-malvinas',
      pt: 'ilhas-falkland-malvinas',
      pl: 'falklandy',
      'pt-BR': 'ilhas-malvinas',
      sv: 'falklandsoarna-malvinas',
      ru: '',
      it: 'isole-falkland-malvine'
    },
    hasRegion: false,
    ins: { en: 'in the ', fr: 'aux' }
  },
  FRA: {
    iso2: 'fr',
    old_id: 29,
    subContinent: 155,
    continent: 1,
    names: {
      en: 'France',
      fr: 'France',
      de: 'Frankreich',
      es: 'Francia',
      pt: 'França',
      pl: 'Francja',
      'pt-BR': 'França',
      sv: 'Frankrike',
      zh: '法国',
      ko: '프랑스',
      ja: 'フランス',
      ru: 'Франция',
      th: 'ประเทศฝรั่งเศส',
      it: 'Francia'
    },
    permalinks: {
      en: 'france',
      fr: 'france',
      de: 'frankreich',
      es: 'francia',
      pt: 'franca',
      pl: 'francja',
      'pt-BR': 'franca',
      sv: 'frankrike',
      ru: '',
      it: 'francia'
    },
    hasRegion: true,
    ins: {}
  },
  FRO: {
    iso2: 'fo',
    old_id: 455,
    subContinent: 154,
    continent: 1,
    names: {
      en: 'Faroe Islands',
      fr: 'Féroé, Îles',
      de: 'Färöer-Inseln',
      es: 'Islas Feroe',
      pt: 'Ilhas Feroé',
      pl: 'Wyspy Owcze',
      'pt-BR': 'Ilhas Feroe',
      sv: 'Färöarna',
      zh: '法罗群岛',
      ko: '페로 제도',
      ja: 'フェロー諸島',
      ru: 'Фарерские острова',
      th: 'หมู่เกาะแฟโร',
      it: 'Isole Fær Øer'
    },
    permalinks: {
      en: 'faroe-islands',
      fr: 'feroe-iles',
      de: 'faroer-inseln',
      es: 'islas-feroe',
      pt: 'ilhas-feroe',
      pl: 'wyspy-owcze',
      'pt-BR': 'ilhas-feroe',
      sv: 'faroarna',
      ru: '',
      it: 'isole-faer-oer'
    },
    hasRegion: false,
    ins: { en: 'in the ', fr: 'dans les Îles Féroé' }
  },
  FSM: {
    iso2: 'fm',
    old_id: 217,
    subContinent: 57,
    continent: 6,
    names: {
      en: 'Micronesia (FSM)',
      fr: 'Micronesie (FSM)',
      de: 'Mikronesien, Föderierte Staaten von',
      es: 'Micronesia, Estados Federados de',
      pt: 'Micronesia, Estados Federados da',
      pl: 'Mikronezja',
      'pt-BR': 'Estados Federados da Micronésia',
      sv: 'Mikronesien, federala staterna',
      zh: '密克罗尼西亚',
      ko: '미크로네시아 연방',
      ja: 'ミクロネシア連邦',
      ru: 'Федеративные Штаты Микронезии',
      th: 'ประเทศไมโครนีเชีย',
      it: 'Micronesia (FSM)'
    },
    permalinks: {
      en: 'micronesia-fsm',
      fr: 'micronesie-fsm',
      de: 'mikronesien-foderierte-staaten-von',
      es: 'micronesia-estados-federados-de',
      pt: 'micronesia-estados-federados-da',
      pl: 'mikronezja',
      'pt-BR': 'estados-federados-da-micronesia',
      sv: 'mikronesien-federala-staterna',
      ru: '',
      it: 'micronesia-fsm'
    },
    hasRegion: false,
    ins: {}
  },
  GAB: {
    iso2: 'ga',
    old_id: 196,
    subContinent: 17,
    continent: 7,
    names: {
      en: 'Gabon',
      fr: 'Gabon',
      de: 'Gabun',
      es: 'Gabón',
      pt: 'Gabão',
      pl: 'Gabon',
      'pt-BR': 'Gabão',
      sv: 'Gabon',
      zh: '加蓬',
      ko: '가봉',
      ja: 'ガボン',
      ru: 'Габон',
      th: 'ประเทศกาบอง',
      it: 'Gabon'
    },
    permalinks: {
      en: 'gabon',
      fr: 'gabon',
      de: 'gabun',
      es: 'gabon',
      pt: 'gabao',
      pl: 'gabon',
      'pt-BR': 'gabao',
      sv: 'gabon',
      ru: '',
      it: 'gabon'
    },
    hasRegion: false,
    ins: { fr: 'au' }
  },
  GBR: {
    iso2: 'gb',
    old_id: 94,
    subContinent: 154,
    continent: 1,
    names: {
      en: 'United Kingdom',
      fr: 'Royaume-Uni',
      de: 'Vereinigtes Königreich',
      es: 'Reino Unido',
      pt: 'Reino Unido',
      pl: 'Wielka Brytania',
      'pt-BR': 'Reino Unido',
      sv: 'Storbritannien',
      zh: '英国',
      ko: '영국',
      ja: 'イギリス',
      ru: 'Соединённое Королевство',
      th: 'สหราชอาณาจักร',
      it: 'Regno Unito'
    },
    permalinks: {
      en: 'united-kingdom',
      fr: 'royaume-uni',
      de: 'vereinigtes-konigreich',
      es: 'reino-unido',
      pt: 'reino-unido',
      pl: 'wielka-brytania',
      'pt-BR': 'reino-unido',
      sv: 'storbritannien',
      ru: '',
      it: 'regno-unito'
    },
    hasRegion: true,
    ins: { en: 'in the', fr: 'au' }
  },
  GEO: {
    iso2: 'ge',
    old_id: 137,
    subContinent: 145,
    continent: 4,
    names: {
      en: 'Georgia (country)',
      fr: 'Géorgie (pays)',
      de: 'Georgien (land)',
      es: 'Georgia (país)',
      pt: 'Geórgia (país)',
      pl: 'Gruzja',
      'pt-BR': 'Geórgia',
      sv: 'Georgien (land)',
      zh: '格鲁吉亚',
      ko: '조지아',
      ja: 'ジョージア',
      ru: 'Грузия',
      th: 'ประเทศจอร์เจีย',
      it: 'Georgia (natione)'
    },
    permalinks: {
      en: 'georgia-country',
      fr: 'georgie-pays',
      de: 'georgien-land',
      es: 'georgia-pais',
      pt: 'georgia-pais',
      pl: 'gruzja',
      'pt-BR': 'georgia',
      sv: 'georgien-land',
      ru: '',
      it: 'georgia-natione'
    },
    hasRegion: false,
    ins: { en: 'in Georgia', fr: 'en' }
  },
  GGY: {
    iso2: 'gg',
    old_id: 173,
    subContinent: 830,
    continent: 1,
    names: {
      en: 'Guernsey',
      fr: 'Guernesey',
      de: 'Guernsey',
      es: 'Guernsey',
      pt: 'Guernsey',
      pl: 'Guernsey',
      'pt-BR': 'Guernsey',
      sv: 'Guernsey',
      zh: '根西岛',
      ko: '건지 섬',
      ja: 'ガーンジー',
      ru: 'Гернси',
      th: 'เกิร์นซีย์',
      it: 'Guernsey'
    },
    permalinks: {
      en: 'guernsey',
      fr: 'guernesey',
      de: 'guernsey',
      es: 'guernsey',
      pt: 'guernsey',
      pl: 'guernsey',
      'pt-BR': 'guernsey',
      sv: 'guernsey',
      ru: '',
      it: 'guernsey'
    },
    hasRegion: false,
    ins: { fr: 'à' }
  },
  GHA: {
    iso2: 'gh',
    old_id: 100,
    subContinent: 11,
    continent: 7,
    names: {
      en: 'Ghana',
      fr: 'Ghana',
      de: 'Ghana',
      es: 'Ghana',
      pt: 'Gana',
      pl: 'Ghana',
      'pt-BR': 'Gana',
      sv: 'Ghana',
      zh: '加纳',
      ko: '가나',
      ja: 'ガーナ',
      ru: 'Гана',
      th: 'ประเทศกานา',
      it: 'Ghana'
    },
    permalinks: {
      en: 'ghana',
      fr: 'ghana',
      de: 'ghana',
      es: 'ghana',
      pt: 'gana',
      pl: 'ghana',
      'pt-BR': 'gana',
      sv: 'ghana',
      ru: '',
      it: 'ghana'
    },
    hasRegion: false,
    ins: { fr: 'au' }
  },
  GIB: {
    iso2: 'gi',
    old_id: 198,
    subContinent: 39,
    continent: 1,
    names: {
      en: 'Gibraltar',
      fr: 'Gibraltar',
      de: 'Gibraltar',
      es: 'Gibraltar',
      pt: 'Gibraltar',
      pl: 'Gibraltar',
      'pt-BR': 'Gibraltar',
      sv: 'Gibraltar',
      zh: '直布罗陀',
      ko: '지브롤터',
      ja: 'ジブラルタル',
      ru: 'Гибралтар',
      th: 'ยิบรอลตาร์',
      it: 'Gibilterra'
    },
    permalinks: {
      en: 'gibraltar',
      fr: 'gibraltar',
      de: 'gibraltar',
      es: 'gibraltar',
      pt: 'gibraltar',
      pl: 'gibraltar',
      'pt-BR': 'gibraltar',
      sv: 'gibraltar',
      ru: '',
      it: 'gibilterra'
    },
    hasRegion: false,
    ins: { fr: 'à' }
  },
  GIN: {
    iso2: 'gn',
    old_id: 157,
    subContinent: 11,
    continent: 7,
    names: {
      en: 'Guinea',
      fr: 'Guinée',
      de: 'Guinea',
      es: 'Guinea',
      pt: 'Guiné',
      pl: 'Gwinea',
      'pt-BR': 'Guiné',
      sv: 'Guinea',
      zh: '几内亚',
      ko: '기니',
      ja: 'ギニア',
      ru: 'Гвинея',
      th: 'ประเทศกินี',
      it: 'Guinea'
    },
    permalinks: {
      en: 'guinea',
      fr: 'guinee',
      de: 'guinea',
      es: 'guinea',
      pt: 'guine',
      pl: 'gwinea',
      'pt-BR': 'guine',
      sv: 'guinea',
      ru: '',
      it: 'guinea'
    },
    hasRegion: false,
    ins: {}
  },
  GLP: {
    iso2: 'gp',
    old_id: 122,
    subContinent: 29,
    continent: 3,
    names: {
      en: 'Guadeloupe',
      fr: 'Guadeloupe',
      de: 'Guadeloupe',
      es: 'Guadalupe',
      pt: 'Guadalupe',
      pl: 'Gwadelupa',
      'pt-BR': 'Guadalupe',
      sv: 'Guadeloupe',
      zh: '瓜德罗普',
      ko: '과들루프',
      ja: 'グアドループ',
      ru: 'Гваделупа',
      th: 'กวาเดอลูป',
      it: 'Guadalupa'
    },
    permalinks: {
      en: 'guadeloupe',
      fr: 'guadeloupe',
      de: 'guadeloupe',
      es: 'guadalupe',
      pt: 'guadalupe',
      pl: 'gwadelupa',
      'pt-BR': 'guadalupe',
      sv: 'guadeloupe',
      ru: '',
      it: 'guadalupa'
    },
    hasRegion: false,
    ins: {}
  },
  GMB: {
    iso2: 'gm',
    old_id: 197,
    subContinent: 11,
    continent: 7,
    names: {
      en: 'Gambia',
      fr: 'Gambie',
      de: 'Gambia',
      es: 'Gambia',
      pt: 'Gâmbia',
      pl: 'Gambia',
      'pt-BR': 'Gâmbia',
      sv: 'Gambia',
      zh: '冈比亚',
      ko: '감비아',
      ja: 'ガンビア',
      ru: 'Гамбия',
      th: 'ประเทศแกมเบีย',
      it: 'Gambia'
    },
    permalinks: {
      en: 'gambia',
      fr: 'gambie',
      de: 'gambia',
      es: 'gambia',
      pt: 'gambia',
      pl: 'gambia',
      'pt-BR': 'gambia',
      sv: 'gambia',
      ru: '',
      it: 'gambia'
    },
    hasRegion: false,
    ins: {}
  },
  GNB: {
    iso2: 'gw',
    old_id: 158,
    subContinent: 11,
    continent: 7,
    names: {
      en: 'Guinea-Bissau',
      fr: 'Guinée-Bissau',
      de: 'Guinea-Bissau',
      es: 'Guinea-Bissau',
      pt: 'Guiné-Bissáu',
      pl: 'Gwinea Bissau',
      'pt-BR': 'Guiné-Bissau',
      sv: 'Guinea-Bissau',
      zh: '几内亚比绍',
      ko: '기니비사우',
      ja: 'ギニアビサウ',
      ru: 'Гвинея-Бисау',
      th: 'ประเทศกินี-บิสเซา',
      it: 'Guinea-Bissau'
    },
    permalinks: {
      en: 'guinea-bissau',
      fr: 'guinee-bissau',
      de: 'guinea-bissau',
      es: 'guinea-bissau',
      pt: 'guine-bissau',
      pl: 'gwinea-bissau',
      'pt-BR': 'guine-bissau',
      sv: 'guinea-bissau',
      ru: '',
      it: 'guinea-bissau'
    },
    hasRegion: false,
    ins: {}
  },
  GNQ: {
    iso2: 'gq',
    old_id: 159,
    subContinent: 17,
    continent: 7,
    names: {
      en: 'Equatorial Guinea',
      fr: 'Guinée équatoriale',
      de: 'Äquatorialguinea',
      es: 'Guinea Ecuatorial',
      pt: 'Guiné Equatorial',
      pl: 'Gwinea Równikowa',
      'pt-BR': 'Guiné Equatorial',
      sv: 'Ekvatorialguinea',
      zh: '赤道几内亚',
      ko: '적도 기니',
      ja: '赤道ギニア',
      ru: 'Экваториальная Гвинея',
      th: 'อิเควทอเรียลกินี',
      it: 'Guinea equatoriale'
    },
    permalinks: {
      en: 'equatorial-guinea',
      fr: 'guinee-equatoriale',
      de: 'aquatorialguinea',
      es: 'guinea-ecuatorial',
      pt: 'guine-equatorial',
      pl: 'gwinea-rownikowa',
      'pt-BR': 'guine-equatorial',
      sv: 'ekvatorialguinea',
      ru: '',
      it: 'guinea-equatoriale'
    },
    hasRegion: false,
    ins: {}
  },
  GRC: {
    iso2: 'gr',
    old_id: 32,
    subContinent: 39,
    continent: 1,
    names: {
      en: 'Greece',
      fr: 'Grèce',
      de: 'Griechenland',
      es: 'Grecia',
      pt: 'Grécia',
      pl: 'Grecja',
      'pt-BR': 'Grécia',
      sv: 'Grekland',
      zh: '希腊',
      ko: '그리스',
      ja: 'ギリシャ',
      ru: 'Греция',
      th: 'ประเทศกรีซ',
      it: 'Grecia'
    },
    permalinks: {
      en: 'greece',
      fr: 'grece',
      de: 'griechenland',
      es: 'grecia',
      pt: 'grecia',
      pl: 'grecja',
      'pt-BR': 'grecia',
      sv: 'grekland',
      ru: '',
      it: 'grecia'
    },
    hasRegion: false,
    ins: {}
  },
  GRD: {
    iso2: 'gd',
    old_id: 199,
    subContinent: 29,
    continent: 2,
    names: {
      en: 'Grenada',
      fr: 'Grenade',
      de: 'Grenada',
      es: 'Granada',
      pt: 'Granada',
      pl: 'Grenada',
      'pt-BR': 'Granada',
      sv: 'Grenada',
      zh: '格林纳达',
      ko: '그레나다',
      ja: 'グレナダ',
      ru: 'Гренада',
      th: 'Grenada',
      it: 'Grenada'
    },
    permalinks: {
      en: 'grenada',
      fr: 'grenade',
      de: 'grenada',
      es: 'granada',
      pt: 'granada',
      pl: 'grenada',
      'pt-BR': 'granada',
      sv: 'grenada',
      ru: '',
      it: 'grenada'
    },
    hasRegion: false,
    ins: { fr: 'à la' }
  },
  GRL: {
    iso2: 'gl',
    old_id: 33,
    subContinent: null,
    continent: 3,
    names: {
      en: 'Greenland',
      fr: 'Groenland',
      de: 'Grönland',
      es: 'Groenlandia',
      pt: 'Gronelândia',
      pl: 'Grenlandia',
      'pt-BR': 'Gronelândia',
      sv: 'Grönland',
      zh: '格陵兰',
      ko: '그린란드',
      ja: 'グリーンランド',
      ru: 'Гренландия',
      th: 'กรีนแลนด์',
      it: 'Groenlandia'
    },
    permalinks: {
      en: 'greenland',
      fr: 'groenland',
      de: 'gronland',
      es: 'groenlandia',
      pt: 'gronelandia',
      pl: 'grenlandia',
      'pt-BR': 'gronelandia',
      sv: 'gronland',
      ru: '',
      it: 'groenlandia'
    },
    hasRegion: false,
    ins: { fr: 'au' }
  },
  GTM: {
    iso2: 'gt',
    old_id: 99,
    subContinent: 13,
    continent: 3,
    names: {
      en: 'Guatemala',
      fr: 'Guatemala',
      de: 'Guatemala',
      es: 'Guatemala',
      pt: 'Guatemala',
      pl: 'Gwatemala',
      'pt-BR': 'Guatemala',
      sv: 'Guatemala',
      zh: '瓜地马拉',
      ko: '과테말라',
      ja: 'グアテマラ',
      ru: 'Гватемала',
      th: 'ประเทศกัวเตมาลา',
      it: 'Guatemala'
    },
    permalinks: {
      en: 'guatemala',
      fr: 'guatemala',
      de: 'guatemala',
      es: 'guatemala',
      pt: 'guatemala',
      pl: 'gwatemala',
      'pt-BR': 'guatemala',
      sv: 'guatemala',
      ru: '',
      it: 'guatemala'
    },
    hasRegion: false,
    ins: { fr: 'au' }
  },
  GUF: {
    iso2: 'gf',
    old_id: 107,
    subContinent: null,
    continent: 2,
    names: {
      en: 'French Guiana',
      fr: 'Guyane',
      de: 'Französisch-Guyana',
      es: 'Guayana Francesa',
      pt: 'Guiana Francesa',
      pl: 'Gujana Francuska',
      'pt-BR': 'Guiana Francesa',
      sv: 'Franska Guiana',
      zh: '法属圭亚那',
      ko: '프랑스령 기아나',
      ja: '仏領ギアナ',
      ru: 'Французская Гвиана',
      th: 'เฟรนช์เกียนา',
      it: 'Guyana francese'
    },
    permalinks: {
      en: 'french-guiana',
      fr: 'guyane',
      de: 'franzosisch-guyana',
      es: 'guayana-francesa',
      pt: 'guiana-francesa',
      pl: 'gujana-francuska',
      'pt-BR': 'guiana-francesa',
      sv: 'franska-guiana',
      ru: '',
      it: 'guyana-francese'
    },
    hasRegion: false,
    ins: {}
  },
  GUM: {
    iso2: 'gu',
    old_id: 34,
    subContinent: 57,
    continent: 6,
    names: {
      en: 'Guam',
      fr: 'Guam',
      de: 'Guam',
      es: 'Guam',
      pt: 'Guam',
      pl: 'Guam',
      'pt-BR': 'Guam',
      sv: 'Guam',
      zh: '关岛',
      ko: 'Guam',
      ja: 'グアム',
      ru: 'Гуам',
      th: 'Guam',
      it: 'Guam'
    },
    permalinks: {
      en: 'guam',
      fr: 'guam',
      de: 'guam',
      es: 'guam',
      pt: 'guam',
      pl: 'guam',
      'pt-BR': 'guam',
      sv: 'guam',
      ru: '',
      it: 'guam'
    },
    hasRegion: false,
    ins: { fr: 'à' }
  },
  GUY: {
    iso2: 'gy',
    old_id: 200,
    subContinent: null,
    continent: 2,
    names: {
      en: 'Guyana',
      fr: 'Guyana',
      de: 'Guyana',
      es: 'Guyana',
      pt: 'Guiana',
      pl: 'Gujana',
      'pt-BR': 'Guiana',
      sv: 'Guyana',
      zh: '圭亚那',
      ko: '가이아나',
      ja: 'ガイアナ',
      ru: 'Гайана',
      th: 'ประเทศกายอานา',
      it: 'Guyana'
    },
    permalinks: {
      en: 'guyana',
      fr: 'guyana',
      de: 'guyana',
      es: 'guyana',
      pt: 'guiana',
      pl: 'gujana',
      'pt-BR': 'guiana',
      sv: 'guyana',
      ru: '',
      it: 'guyana'
    },
    hasRegion: false,
    ins: { fr: 'au' }
  },
  HKG: {
    iso2: 'hk',
    old_id: 35,
    subContinent: 30,
    continent: 4,
    names: {
      en: 'Hong-Kong',
      fr: 'Honk-Kong',
      de: 'Hongkong',
      es: 'Hong Kong',
      pt: 'Hong Kong',
      pl: 'Hongkong',
      'pt-BR': 'Hong Kong',
      sv: 'Hong Kong',
      zh: '香港',
      ko: '홍콩',
      ja: '香港',
      ru: 'Гонконг',
      th: 'ฮ่องกง',
      it: 'Hong Kong'
    },
    permalinks: {
      en: 'hong-kong',
      fr: 'honk-kong',
      de: 'hongkong',
      es: 'hong-kong',
      pt: 'hong-kong',
      pl: 'hongkong',
      'pt-BR': 'hong-kong',
      sv: 'hong-kong',
      ru: '',
      it: 'hong-kong'
    },
    hasRegion: false,
    ins: { fr: 'à' }
  },
  HMD: {
    iso2: 'hm',
    old_id: 457,
    subContinent: 53,
    continent: 5,
    names: {
      en: 'Heard Island and McDonald Islands',
      fr: 'Heard, Île et McDonald, Îles',
      de: 'Heard und McDonaldinseln',
      es: 'Islas Heard e Islas McDonald',
      pt: 'Ilha Heard e Ilhas McDonald',
      pl: 'Wyspy Heard i McDonalda',
      'pt-BR': 'Ilha Heard e Ilhas McDonald',
      sv: 'Heardön och McDonaldöarna',
      zh: '赫德岛与麦克唐纳群岛',
      ko: '허드 맥도널드 제도',
      ja: 'ハード島とマクドナルド諸島',
      ru: 'Остров Херд и острова МакДональд',
      th: 'เกาะเฮิร์ดและหมู่เกาะแมกดอนัลด์',
      it: 'Isole Heard e McDonald'
    },
    permalinks: {
      en: 'heard-island-and-mcdonald-islands',
      fr: 'heard-ile-et-mcdonald-iles',
      de: 'heard-und-mcdonaldinseln',
      es: 'islas-heard-e-islas-mcdonald',
      pt: 'ilha-heard-e-ilhas-mcdonald',
      pl: 'wyspy-heard-i-mcdonalda',
      'pt-BR': 'ilha-heard-e-ilhas-mcdonald',
      sv: 'heardon-och-mcdonaldoarna',
      ru: '',
      it: 'isole-heard-e-mcdonald'
    },
    hasRegion: false,
    ins: { fr: 'dans les Îles Heard et McDonald' }
  },
  HND: {
    iso2: 'hn',
    old_id: 132,
    subContinent: 13,
    continent: 3,
    names: {
      en: 'Honduras',
      fr: 'Honduras',
      de: 'Honduras',
      es: 'Honduras',
      pt: 'Honduras',
      pl: 'Honduras',
      'pt-BR': 'Honduras',
      sv: 'Honduras',
      zh: '洪都拉斯',
      ko: '온두라스',
      ja: 'ホンジュラス',
      ru: 'Гондурас',
      th: 'ประเทศฮอนดูรัส',
      it: 'Honduras'
    },
    permalinks: {
      en: 'honduras',
      fr: 'honduras',
      de: 'honduras',
      es: 'honduras',
      pt: 'honduras',
      pl: 'honduras',
      'pt-BR': 'honduras',
      sv: 'honduras',
      ru: '',
      it: 'honduras'
    },
    hasRegion: false,
    ins: { fr: 'au ' }
  },
  HRV: {
    iso2: 'hr',
    old_id: 18,
    subContinent: 39,
    continent: 1,
    names: {
      en: 'Croatia',
      fr: 'Croatie',
      de: 'Kroatien',
      es: 'Croacia',
      pt: 'Croácia',
      pl: 'Chorwacja',
      'pt-BR': 'Croácia',
      sv: 'Kroatien',
      zh: '克罗地亚',
      ko: '크로아티아',
      ja: 'クロアチア',
      ru: 'Хорватия',
      th: 'ประเทศโครเอเชีย',
      it: 'Croazia'
    },
    permalinks: {
      en: 'croatia',
      fr: 'croatie',
      de: 'kroatien',
      es: 'croacia',
      pt: 'croacia',
      pl: 'chorwacja',
      'pt-BR': 'croacia',
      sv: 'kroatien',
      ru: '',
      it: 'croazia'
    },
    hasRegion: false,
    ins: {}
  },
  HTI: {
    iso2: 'ht',
    old_id: 201,
    subContinent: 29,
    continent: 3,
    names: {
      en: 'Haiti',
      fr: 'Haïti',
      de: 'Haiti',
      es: 'Haití',
      pt: 'Haiti',
      pl: 'Haiti',
      'pt-BR': 'Haiti',
      sv: 'Haiti',
      zh: '海地',
      ko: '아이티',
      ja: 'ハイチ',
      ru: 'Гаити',
      th: 'ประเทศเฮติ',
      it: 'Haiti'
    },
    permalinks: {
      en: 'haiti',
      fr: 'haiti',
      de: 'haiti',
      es: 'haiti',
      pt: 'haiti',
      pl: 'haiti',
      'pt-BR': 'haiti',
      sv: 'haiti',
      ru: '',
      it: 'haiti'
    },
    hasRegion: false,
    ins: {}
  },
  HUN: {
    iso2: 'hu',
    old_id: 36,
    subContinent: 151,
    continent: 1,
    names: {
      en: 'Hungary',
      fr: 'Hongrie',
      de: 'Ungarn',
      es: 'Hungría',
      pt: 'Hungria',
      pl: 'Węgry',
      'pt-BR': 'Hungria',
      sv: 'Ungern',
      zh: '匈牙利',
      ko: '헝가리',
      ja: 'ハンガリー',
      ru: 'Венгрия',
      th: 'ประเทศฮังการี',
      it: 'Ungheria'
    },
    permalinks: {
      en: 'hungary',
      fr: 'hongrie',
      de: 'ungarn',
      es: 'hungria',
      pt: 'hungria',
      pl: 'wegry',
      'pt-BR': 'hungria',
      sv: 'ungern',
      ru: '',
      it: 'ungheria'
    },
    hasRegion: false,
    ins: {}
  },
  IDN: {
    iso2: 'id',
    old_id: 39,
    subContinent: 35,
    continent: 4,
    names: {
      en: 'Indonesia',
      fr: 'Indonésie',
      de: 'Indonesien',
      es: 'Indonesia',
      pt: 'Indonésia',
      pl: 'Indonezja',
      'pt-BR': 'Indonésia',
      sv: 'Indonesien',
      zh: '印度尼西亚',
      ko: '인도네시아',
      ja: 'インドネシア',
      ru: 'Индонезия',
      th: 'ประเทศอินโดนีเซีย',
      it: 'Indonesia'
    },
    permalinks: {
      en: 'indonesia',
      fr: 'indonesie',
      de: 'indonesien',
      es: 'indonesia',
      pt: 'indonesia',
      pl: 'indonezja',
      'pt-BR': 'indonesia',
      sv: 'indonesien',
      ru: '',
      it: 'indonesia'
    },
    hasRegion: false,
    ins: {}
  },
  IMN: {
    iso2: 'im',
    old_id: 116,
    subContinent: 154,
    continent: 1,
    names: {
      en: 'Isle of Man',
      fr: 'Île de Man',
      de: 'Insel Man',
      es: 'Isla de Man',
      pt: 'Ilha de Man',
      pl: 'Wyspa Man',
      'pt-BR': 'Ilha de Man',
      sv: 'Isle of Man',
      zh: '曼岛',
      ko: '맨섬',
      ja: 'マン島',
      ru: 'Остров Мэн',
      th: 'เกาะแมน',
      it: 'Isola di Man'
    },
    permalinks: {
      en: 'isle-of-man',
      fr: 'ile-de-man',
      de: 'insel-man',
      es: 'isla-de-man',
      pt: 'ilha-de-man',
      pl: 'wyspa-man',
      'pt-BR': 'ilha-de-man',
      sv: 'isle-of-man',
      ru: '',
      it: 'isola-di-man'
    },
    hasRegion: false,
    ins: { en: 'in the', fr: "dans l'île de Man" }
  },
  IND: {
    iso2: 'in',
    old_id: 38,
    subContinent: 34,
    continent: 4,
    names: {
      en: 'India',
      fr: 'Inde',
      de: 'Indien',
      es: 'India',
      pt: 'Índia',
      pl: 'Indie',
      'pt-BR': 'Índia',
      sv: 'Indien',
      zh: '印度',
      ko: '인도',
      ja: 'インド',
      ru: 'Индия',
      th: 'ประเทศอินเดีย',
      it: 'India'
    },
    permalinks: {
      en: 'india',
      fr: 'inde',
      de: 'indien',
      es: 'india',
      pt: 'india',
      pl: 'indie',
      'pt-BR': 'india',
      sv: 'indien',
      ru: '',
      it: 'india'
    },
    hasRegion: true,
    ins: {}
  },
  IOT: {
    iso2: 'io',
    old_id: 11,
    subContinent: 14,
    continent: 6,
    names: {
      en: 'British Indian Ocean Territory',
      fr: "Territoire britanique de l'océan indien",
      de: 'Britisches Territorium im Indischen Ozean',
      es: 'Británico del Océano Índico, Territorio',
      pt: 'Território Britânico do Oceano Índico',
      pl: 'Brytyjskie Terytorium Oceanu Indyjskiego',
      'pt-BR': 'Território Britânico do Oceano Índico',
      sv: 'Brittiskt territorium i Indiska Oceanen',
      zh: '英属印度洋领地',
      ko: '영국령 인도양 지역',
      ja: 'イギリス領インド洋地域',
      ru: 'Британская территория Индийского океана',
      th: 'บริติชอินเดียนโอเชียนเทร์ริทอรี',
      it: "Territorio britannico dell'Oceano Indiano"
    },
    permalinks: {
      en: 'british-indian-ocean-territory',
      fr: 'territoire-britanique-de-l-ocean-indien',
      de: 'britisches-territorium-im-indischen-ozean',
      es: 'britanico-del-oceano-indico-territorio',
      pt: 'territorio-britanico-do-oceano-indico',
      pl: 'brytyjskie-terytorium-oceanu-indyjskiego',
      'pt-BR': 'territorio-britanico-do-oceano-indico',
      sv: 'brittiskt-territorium-i-indiska-oceanen',
      ru: '',
      it: 'territorio-britannico-dell-oceano-indiano'
    },
    hasRegion: false,
    ins: { en: 'in the', fr: 'dans le' }
  },
  IRL: {
    iso2: 'ie',
    old_id: 40,
    subContinent: 154,
    continent: 1,
    names: {
      en: 'Ireland',
      fr: 'Irlande',
      de: 'Irland',
      es: 'Irlanda',
      pt: 'Irlanda',
      pl: 'Irlandia',
      'pt-BR': 'Irlanda',
      sv: 'Irland',
      zh: '爱尔兰',
      ko: '아일랜드',
      ja: 'アイルランド',
      ru: 'Ирландия',
      th: 'สาธารณรัฐไอร์แลนด์',
      it: 'Irlanda'
    },
    permalinks: {
      en: 'ireland',
      fr: 'irlande',
      de: 'irland',
      es: 'irlanda',
      pt: 'irlanda',
      pl: 'irlandia',
      'pt-BR': 'irlanda',
      sv: 'irland',
      ru: '',
      it: 'irlanda'
    },
    hasRegion: true,
    ins: {}
  },
  IRN: {
    iso2: 'ir',
    old_id: 203,
    subContinent: 34,
    continent: 4,
    names: {
      en: 'Iran',
      fr: 'Iran',
      de: 'Iran, Islamische Republik',
      es: 'Irán, República islámica de',
      pt: 'Irão, República Islâmica do',
      pl: 'Iran',
      'pt-BR': 'Irão',
      sv: 'Iran, islamiska republiken',
      zh: '伊朗',
      ko: '이란',
      ja: 'イラン',
      ru: 'Иран',
      th: 'ประเทศอิหร่าน',
      it: 'Iran'
    },
    permalinks: {
      en: 'iran',
      fr: 'iran',
      de: 'iran-islamische-republik',
      es: 'iran-republica-islamica-de',
      pt: 'irao-republica-islamica-do',
      pl: 'iran',
      'pt-BR': 'irao',
      sv: 'iran-islamiska-republiken',
      ru: '',
      it: 'iran'
    },
    hasRegion: false,
    ins: {}
  },
  IRQ: {
    iso2: 'iq',
    old_id: 165,
    subContinent: 145,
    continent: 4,
    names: {
      en: 'Iraq',
      fr: 'Irak',
      de: 'Irak',
      es: 'Irak',
      pt: 'Iraque',
      pl: 'Irak',
      'pt-BR': 'Iraque',
      sv: 'Irak',
      zh: '伊拉克',
      ko: '이라크',
      ja: 'イラク',
      ru: 'Ирак',
      th: 'ประเทศอิรัก',
      it: 'Iraq'
    },
    permalinks: {
      en: 'iraq',
      fr: 'irak',
      de: 'irak',
      es: 'irak',
      pt: 'iraque',
      pl: 'irak',
      'pt-BR': 'iraque',
      sv: 'irak',
      ru: '',
      it: 'iraq'
    },
    hasRegion: false,
    ins: {}
  },
  ISL: {
    iso2: 'is',
    old_id: 37,
    subContinent: 154,
    continent: 1,
    names: {
      en: 'Iceland',
      fr: 'Islande',
      de: 'Island',
      es: 'Islandia',
      pt: 'Islândia',
      pl: 'Islandia',
      'pt-BR': 'Islândia',
      sv: 'Island',
      zh: '冰岛',
      ko: '아이슬란드',
      ja: 'アイスランド',
      ru: 'Исландия',
      th: 'ประเทศไอซ์แลนด์',
      it: 'Islanda'
    },
    permalinks: {
      en: 'iceland',
      fr: 'islande',
      de: 'island',
      es: 'islandia',
      pt: 'islandia',
      pl: 'islandia',
      'pt-BR': 'islandia',
      sv: 'island',
      ru: '',
      it: 'islanda'
    },
    hasRegion: false,
    ins: {}
  },
  ISR: {
    iso2: 'il',
    old_id: 41,
    subContinent: 145,
    continent: 4,
    names: {
      en: 'Israel',
      fr: 'Israël',
      de: 'Israel',
      es: 'Israel',
      pt: 'Israel',
      pl: 'Izrael',
      'pt-BR': 'Israel',
      sv: 'Israel',
      zh: '以色列',
      ko: '이스라엘',
      ja: 'イスラエル',
      ru: 'Израиль',
      th: 'ประเทศอิสราเอล',
      it: 'Israele'
    },
    permalinks: {
      en: 'israel',
      fr: 'israel',
      de: 'israel',
      es: 'israel',
      pt: 'israel',
      pl: 'izrael',
      'pt-BR': 'israel',
      sv: 'israel',
      ru: '',
      it: 'israele'
    },
    hasRegion: false,
    ins: {}
  },
  ITA: {
    iso2: 'it',
    old_id: 42,
    subContinent: 39,
    continent: 1,
    names: {
      en: 'Italy',
      fr: 'Italie',
      de: 'Italien',
      es: 'Italia',
      pt: 'Itália',
      pl: 'Włochy',
      'pt-BR': 'Itália',
      sv: 'Italien',
      zh: '意大利',
      ko: '이탈리아',
      ja: 'イタリア',
      ru: 'Италия',
      th: 'ประเทศอิตาลี',
      it: 'Italia'
    },
    permalinks: {
      en: 'italy',
      fr: 'italie',
      de: 'italien',
      es: 'italia',
      pt: 'italia',
      pl: 'wlochy',
      'pt-BR': 'italia',
      sv: 'italien',
      ru: '',
      it: 'italia'
    },
    hasRegion: true,
    ins: {}
  },
  JAM: {
    iso2: 'jm',
    old_id: 43,
    subContinent: 29,
    continent: 3,
    names: {
      en: 'Jamaica',
      fr: 'Jamaique',
      de: 'Jamaika',
      es: 'Jamaica',
      pt: 'Jamaica',
      pl: 'Jamajka',
      'pt-BR': 'Jamaica',
      sv: 'Jamaica',
      zh: '牙买加',
      ko: '자메이카',
      ja: 'ジャマイカ',
      ru: 'Ямайка',
      th: 'Jamaica',
      it: 'Giamaica'
    },
    permalinks: {
      en: 'jamaica',
      fr: 'jamaique',
      de: 'jamaika',
      es: 'jamaica',
      pt: 'jamaica',
      pl: 'jamajka',
      'pt-BR': 'jamaica',
      sv: 'jamaica',
      ru: '',
      it: 'giamaica'
    },
    hasRegion: false,
    ins: {}
  },
  JEY: {
    iso2: 'je',
    old_id: 258,
    subContinent: 830,
    continent: 1,
    names: {
      en: 'Jersey',
      fr: 'Jersey',
      de: 'Jersey',
      es: 'Jersey',
      pt: 'Jersey',
      pl: 'Jersey',
      'pt-BR': 'Jersey',
      sv: 'Jersey',
      zh: '泽西岛',
      ko: '저지 섬',
      ja: 'ジャージー',
      ru: 'Джерси',
      th: 'เจอร์ซีย์',
      it: 'Jersey'
    },
    permalinks: {
      en: 'jersey',
      fr: 'jersey',
      de: 'jersey',
      es: 'jersey',
      pt: 'jersey',
      pl: 'jersey',
      'pt-BR': 'jersey',
      sv: 'jersey',
      ru: '',
      it: 'jersey'
    },
    hasRegion: false,
    ins: { fr: 'à' }
  },
  JOR: {
    iso2: 'jo',
    old_id: 45,
    subContinent: 145,
    continent: 4,
    names: {
      en: 'Jordan',
      fr: 'Jordanie',
      de: 'Jordanien',
      es: 'Jordania',
      pt: 'Jordânia',
      pl: 'Jordania',
      'pt-BR': 'Jordânia',
      sv: 'Jordanien',
      zh: '约旦',
      ko: '요르단',
      ja: 'ヨルダン',
      ru: 'Иордания',
      th: 'ประเทศจอร์แดน',
      it: 'Giordania'
    },
    permalinks: {
      en: 'jordan',
      fr: 'jordanie',
      de: 'jordanien',
      es: 'jordania',
      pt: 'jordania',
      pl: 'jordania',
      'pt-BR': 'jordania',
      sv: 'jordanien',
      ru: '',
      it: 'giordania'
    },
    hasRegion: false,
    ins: {}
  },
  JPN: {
    iso2: 'jp',
    old_id: 44,
    subContinent: 30,
    continent: 4,
    names: {
      en: 'Japan',
      fr: 'Japon',
      de: 'Japan',
      es: 'Japón',
      pt: 'Japão',
      pl: 'Japonia',
      'pt-BR': 'Japão',
      sv: 'Japan',
      zh: '日本',
      ko: '일본',
      ja: '日本',
      ru: 'Япония',
      th: 'ประเทศญี่ปุ่น',
      it: 'Giappone'
    },
    permalinks: {
      en: 'japan',
      fr: 'japon',
      de: 'japan',
      es: 'japon',
      pt: 'japao',
      pl: 'japonia',
      'pt-BR': 'japao',
      sv: 'japan',
      ru: '',
      it: 'giappone'
    },
    hasRegion: false,
    ins: { fr: 'au' }
  },
  KAZ: {
    iso2: 'kz',
    old_id: 172,
    subContinent: 143,
    continent: 4,
    names: {
      en: 'Kazakhstan',
      fr: 'Kazakhstan',
      de: 'Kasachstan',
      es: 'Kazajistán',
      pt: 'Cazaquistão',
      pl: 'Kazachstan',
      'pt-BR': 'Cazaquistão',
      sv: 'Kazakstan',
      zh: '哈萨克斯坦',
      ko: '카자흐스탄',
      ja: 'カザフスタン',
      ru: 'Казахстан',
      th: 'ประเทศคาซัคสถาน',
      it: 'Kazakistan'
    },
    permalinks: {
      en: 'kazakhstan',
      fr: 'kazakhstan',
      de: 'kasachstan',
      es: 'kazajistan',
      pt: 'cazaquistao',
      pl: 'kazachstan',
      'pt-BR': 'cazaquistao',
      sv: 'kazakstan',
      ru: '',
      it: 'kazakistan'
    },
    hasRegion: false,
    ins: { fr: 'au' }
  },
  KEN: {
    iso2: 'ke',
    old_id: 46,
    subContinent: 14,
    continent: 7,
    names: {
      en: 'Kenya',
      fr: 'Kenya',
      de: 'Kenia',
      es: 'Kenia',
      pt: 'Quénia',
      pl: 'Kenia',
      'pt-BR': 'Quénia',
      sv: 'Kenya',
      zh: '肯尼亚',
      ko: '케냐',
      ja: 'ケニア',
      ru: 'Кения',
      th: 'ประเทศเคนยา',
      it: 'Kenya'
    },
    permalinks: {
      en: 'kenya',
      fr: 'kenya',
      de: 'kenia',
      es: 'kenia',
      pt: 'quenia',
      pl: 'kenia',
      'pt-BR': 'quenia',
      sv: 'kenya',
      ru: '',
      it: 'kenya'
    },
    hasRegion: false,
    ins: { fr: 'au' }
  },
  KGZ: {
    iso2: 'kg',
    old_id: 207,
    subContinent: 143,
    continent: 4,
    names: {
      en: 'Kyrgyzstan',
      fr: 'Kyrgyzstan',
      de: 'Kirgisistan',
      es: 'Kirgizstán',
      pt: 'Quirgistão',
      pl: 'Kirgistan',
      'pt-BR': 'Quirguistão',
      sv: 'Kirgisistan',
      zh: '吉尔吉斯坦',
      ko: '키르기스스탄',
      ja: 'キルギス',
      ru: 'Кыргызстан',
      th: 'ประเทศคีร์กีซสถาน',
      it: 'Kirghizistan'
    },
    permalinks: {
      en: 'kyrgyzstan',
      fr: 'kyrgyzstan',
      de: 'kirgisistan',
      es: 'kirgizstan',
      pt: 'quirgistao',
      pl: 'kirgistan',
      'pt-BR': 'quirguistao',
      sv: 'kirgisistan',
      ru: '',
      it: 'kirghizistan'
    },
    hasRegion: false,
    ins: { fr: 'au' }
  },
  KHM: {
    iso2: 'kh',
    old_id: 12,
    subContinent: 35,
    continent: 4,
    names: {
      en: 'Cambodia',
      fr: 'Cambodge',
      de: 'Kambodscha',
      es: 'Camboya',
      pt: 'Cambodja',
      pl: 'Burundi',
      'pt-BR': 'Camboja',
      sv: 'Kambodja',
      zh: '柬埔塞',
      ko: '캄보디아',
      ja: 'カンボジア',
      ru: 'Камбоджа',
      th: 'ประเทศกัมพูชา',
      it: 'Cambogia'
    },
    permalinks: {
      en: 'cambodia',
      fr: 'cambodge',
      de: 'kambodscha',
      es: 'camboya',
      pt: 'cambodja',
      pl: 'burundi',
      'pt-BR': 'camboja',
      sv: 'kambodja',
      ru: '',
      it: 'cambogia'
    },
    hasRegion: false,
    ins: { fr: 'au ' }
  },
  KIR: {
    iso2: 'ki',
    old_id: 204,
    subContinent: 57,
    continent: 6,
    names: {
      en: 'Kiribati',
      fr: 'Kiribati',
      de: 'Kiribati',
      es: 'Kiribati',
      pt: 'Kiribati',
      pl: 'Kiribati',
      'pt-BR': 'Kiribati',
      sv: 'Kiribati',
      zh: '基里巴斯',
      ko: '키리바시',
      ja: 'キリバス',
      ru: 'Кирибати',
      th: 'Kiribati',
      it: 'Kiribati'
    },
    permalinks: {
      en: 'kiribati',
      fr: 'kiribati',
      de: 'kiribati',
      es: 'kiribati',
      pt: 'kiribati',
      pl: 'kiribati',
      'pt-BR': 'kiribati',
      sv: 'kiribati',
      ru: '',
      it: 'kiribati'
    },
    hasRegion: false,
    ins: { fr: 'aux' }
  },
  KNA: {
    iso2: 'kn',
    old_id: 256,
    subContinent: 29,
    continent: 3,
    names: {
      en: 'Saint Kitts and Nevis',
      fr: 'Saint Kitts et Nevis',
      de: 'St. Kitts und Nevis',
      es: 'San Cristobo y Nevis',
      pt: 'Saint Kitts e Nevis',
      pl: 'Saint Kitts i Nevis',
      'pt-BR': 'São Cristóvão e Neves',
      sv: 'Sankt Kitts och Nevis',
      zh: '圣基茨和尼维斯',
      ko: '세인트키츠 네비스',
      ja: 'セントクリストファー・ネイビス',
      ru: 'Сент-Китс и Невис',
      th: 'Saint Kitts and Nevis',
      it: 'Saint Kitts e Nevis'
    },
    permalinks: {
      en: 'saint-kitts-and-nevis',
      fr: 'saint-kitts-et-nevis',
      de: 'st-kitts-und-nevis',
      es: 'san-cristobo-y-nevis',
      pt: 'saint-kitts-e-nevis',
      pl: 'saint-kitts-i-nevis',
      'pt-BR': 'sao-cristovao-e-neves',
      sv: 'sankt-kitts-och-nevis',
      ru: '',
      it: 'saint-kitts-e-nevis'
    },
    hasRegion: false,
    ins: { fr: 'à' }
  },
  KOR: {
    iso2: 'kr',
    old_id: 47,
    subContinent: 30,
    continent: 4,
    names: {
      en: 'South Korea',
      fr: 'Corée du Sud',
      de: 'Korea, Republik',
      es: 'Corea, República de',
      pt: 'Coreia, República da',
      pl: 'Korea Południowa',
      'pt-BR': 'Coreia do Sul',
      sv: 'Sydkorea',
      zh: '韩国',
      ko: '대한민국',
      ja: '大韓民国',
      ru: 'Республика Корея',
      th: 'ประเทศเกาหลีใต้',
      it: 'Corea del Sud'
    },
    permalinks: {
      en: 'south-korea',
      fr: 'coree-du-sud',
      de: 'korea-republik',
      es: 'corea-republica-de',
      pt: 'coreia-republica-da',
      pl: 'korea-poludniowa',
      'pt-BR': 'coreia-do-sul',
      sv: 'sydkorea',
      ru: '',
      it: 'corea-del-sud'
    },
    hasRegion: false,
    ins: {}
  },
  KWT: {
    iso2: 'kw',
    old_id: 206,
    subContinent: 145,
    continent: 4,
    names: {
      en: 'Kuwait',
      fr: 'Koweit',
      de: 'Kuwait',
      es: 'Kuwait',
      pt: 'Kuwait',
      pl: 'Kuwejt',
      'pt-BR': 'Kuwait',
      sv: 'Kuwait',
      zh: '科威特',
      ko: '쿠웨이트',
      ja: 'クウェート',
      ru: 'Кувейт',
      th: 'ประเทศคูเวต',
      it: 'Kuwait'
    },
    permalinks: {
      en: 'kuwait',
      fr: 'koweit',
      de: 'kuwait',
      es: 'kuwait',
      pt: 'kuwait',
      pl: 'kuwejt',
      'pt-BR': 'kuwait',
      sv: 'kuwait',
      ru: '',
      it: 'kuwait'
    },
    hasRegion: false,
    ins: { fr: 'au' }
  },
  LAO: {
    iso2: 'la',
    old_id: 208,
    subContinent: 35,
    continent: 4,
    names: {
      en: 'Laos',
      fr: 'Laos',
      de: 'Laos, Demokratische Volksrepublik',
      es: 'República Democrática Popular de Lao',
      pt: 'República Democrática Popular do Laos',
      pl: 'Laos',
      'pt-BR': 'Laos',
      sv: 'Demokratiska folkrepubliken Lao',
      zh: '老挝人民民主共和国',
      ko: '라오스',
      ja: 'ラオス',
      ru: 'Лаосская Народно-Демократическая Республика',
      th: 'ประเทศลาว',
      it: 'Laos'
    },
    permalinks: {
      en: 'laos',
      fr: 'laos',
      de: 'laos-demokratische-volksrepublik',
      es: 'republica-democratica-popular-de-lao',
      pt: 'republica-democratica-popular-do-laos',
      pl: 'laos',
      'pt-BR': 'laos',
      sv: 'demokratiska-folkrepubliken-lao',
      ru: '',
      it: 'laos'
    },
    hasRegion: false,
    ins: { fr: 'au' }
  },
  LBN: {
    iso2: 'lb',
    old_id: 49,
    subContinent: 145,
    continent: 4,
    names: {
      en: 'Lebanon',
      fr: 'Liban',
      de: 'Libanon',
      es: 'Líbano',
      pt: 'Líbano',
      pl: 'Liban',
      'pt-BR': 'Líbano',
      sv: 'Libanon',
      zh: '黎巴嫩',
      ko: '레바논',
      ja: 'レバノン',
      ru: 'Ливан',
      th: 'ประเทศเลบานอน',
      it: 'Libano'
    },
    permalinks: {
      en: 'lebanon',
      fr: 'liban',
      de: 'libanon',
      es: 'libano',
      pt: 'libano',
      pl: 'liban',
      'pt-BR': 'libano',
      sv: 'libanon',
      ru: '',
      it: 'libano'
    },
    hasRegion: false,
    ins: { fr: 'au' }
  },
  LBR: {
    iso2: 'lr',
    old_id: 210,
    subContinent: 11,
    continent: 7,
    names: {
      en: 'Liberia',
      fr: 'Liberia',
      de: 'Liberia',
      es: 'Liberia',
      pt: 'Libéria',
      pl: 'Liberia',
      'pt-BR': 'Libéria',
      sv: 'Liberia',
      zh: '利比里亚',
      ko: '라이베리아',
      ja: 'リベリア',
      ru: 'Либерия',
      th: 'ประเทศไลบีเรีย',
      it: 'Liberia'
    },
    permalinks: {
      en: 'liberia',
      fr: 'liberia',
      de: 'liberia',
      es: 'liberia',
      pt: 'liberia',
      pl: 'liberia',
      'pt-BR': 'liberia',
      sv: 'liberia',
      ru: '',
      it: 'liberia'
    },
    hasRegion: false,
    ins: { fr: 'au' }
  },
  LBY: {
    iso2: 'ly',
    old_id: 211,
    subContinent: 15,
    continent: 7,
    names: {
      en: 'Lybia',
      fr: 'Lybie',
      de: 'Libyen',
      es: 'Libia',
      pt: 'Líbia',
      pl: 'Libia',
      'pt-BR': 'Líbia',
      sv: 'Libyen',
      zh: '利比亚',
      ko: '리비아',
      ja: 'リビア',
      ru: 'Ливия',
      th: 'ประเทศลิเบีย',
      it: 'Lybia'
    },
    permalinks: {
      en: 'lybia',
      fr: 'lybie',
      de: 'libyen',
      es: 'libia',
      pt: 'libia',
      pl: 'libia',
      'pt-BR': 'libia',
      sv: 'libyen',
      ru: '',
      it: 'lybia'
    },
    hasRegion: false,
    ins: {}
  },
  LCA: {
    iso2: 'lc',
    old_id: 255,
    subContinent: 29,
    continent: 3,
    names: {
      en: 'Saint Lucia',
      fr: 'Saint Lucie',
      de: 'St. Lucia',
      es: 'Santa Lucía',
      pt: 'Santa Lúcia',
      pl: 'Saint Lucia',
      'pt-BR': 'Santa Lúcia',
      sv: 'Sankt Lucia',
      zh: '圣路西亚',
      ko: '세인트루시아',
      ja: 'セントルシア',
      ru: 'Сент-Люсия',
      th: 'ประเทศเซนต์ลูเซีย',
      it: 'Saint Lucia'
    },
    permalinks: {
      en: 'saint-lucia',
      fr: 'saint-lucie',
      de: 'st-lucia',
      es: 'santa-lucia',
      pt: 'santa-lucia',
      pl: 'saint-lucia',
      'pt-BR': 'santa-lucia',
      sv: 'sankt-lucia',
      ru: '',
      it: 'saint-lucia'
    },
    hasRegion: false,
    ins: { fr: 'à' }
  },
  LIE: {
    iso2: 'li',
    old_id: 50,
    subContinent: 155,
    continent: 1,
    names: {
      en: 'Liechtenstein',
      fr: 'Liechtenstein',
      de: 'Liechtenstein',
      es: 'Liechtenstein',
      pt: 'Liechtenstein',
      pl: 'Liechtenstein',
      'pt-BR': 'Listenstaine',
      sv: 'Liechtenstein',
      zh: '列支敦士登',
      ko: 'Liechtenstein',
      ja: 'リヒテンシュタイン',
      ru: 'Лихтенштейн',
      th: 'Liechtenstein',
      it: 'Liechtenstein'
    },
    permalinks: {
      en: 'liechtenstein',
      fr: 'liechtenstein',
      de: 'liechtenstein',
      es: 'liechtenstein',
      pt: 'liechtenstein',
      pl: 'liechtenstein',
      'pt-BR': 'listenstaine',
      sv: 'liechtenstein',
      ru: '',
      it: 'liechtenstein'
    },
    hasRegion: false,
    ins: { fr: 'au' }
  },
  LKA: {
    iso2: 'lk',
    old_id: 124,
    subContinent: 34,
    continent: 4,
    names: {
      en: 'Sri Lanka',
      fr: 'Sri Lanka',
      de: 'Sri Lanka',
      es: 'Sri Lanka',
      pt: 'Sri Lanka',
      pl: 'Sri Lanka',
      'pt-BR': 'Seri-Lanca',
      sv: 'Sri Lanka',
      zh: '斯里兰卡',
      ko: '스리랑카',
      ja: 'スリランカ',
      ru: 'Шри-Ланка',
      th: 'ประเทศศรีลังกา',
      it: 'Sri Lanka'
    },
    permalinks: {
      en: 'sri-lanka',
      fr: 'sri-lanka',
      de: 'sri-lanka',
      es: 'sri-lanka',
      pt: 'sri-lanka',
      pl: 'sri-lanka',
      'pt-BR': 'seri-lanca',
      sv: 'sri-lanka',
      ru: '',
      it: 'sri-lanka'
    },
    hasRegion: false,
    ins: { fr: 'au' }
  },
  LSO: {
    iso2: 'ls',
    old_id: 209,
    subContinent: 18,
    continent: 7,
    names: {
      en: 'Lesotho',
      fr: 'Lesotho',
      de: 'Lesotho',
      es: 'Lesoto',
      pt: 'Lesoto',
      pl: 'Lesotho',
      'pt-BR': 'Lesoto',
      sv: 'Lesotho',
      zh: '莱索托',
      ko: '레소토',
      ja: 'レソト',
      ru: 'Лесото',
      th: 'ประเทศเลโซโท',
      it: 'Lesotho'
    },
    permalinks: {
      en: 'lesotho',
      fr: 'lesotho',
      de: 'lesotho',
      es: 'lesoto',
      pt: 'lesoto',
      pl: 'lesotho',
      'pt-BR': 'lesoto',
      sv: 'lesotho',
      ru: '',
      it: 'lesotho'
    },
    hasRegion: false,
    ins: { fr: 'au' }
  },
  LTU: {
    iso2: 'lt',
    old_id: 51,
    subContinent: 154,
    continent: 1,
    names: {
      en: 'Lithuania',
      fr: 'Lituanie',
      de: 'Litauen',
      es: 'Lituania',
      pt: 'Lituânia',
      pl: 'Litwa',
      'pt-BR': 'Lituânia',
      sv: 'Litauen',
      zh: '立陶宛',
      ko: '리투아니아',
      ja: 'リトアニア',
      ru: 'Литва',
      th: 'ประเทศลิทัวเนีย',
      it: 'Lituania'
    },
    permalinks: {
      en: 'lithuania',
      fr: 'lituanie',
      de: 'litauen',
      es: 'lituania',
      pt: 'lituania',
      pl: 'litwa',
      'pt-BR': 'lituania',
      sv: 'litauen',
      ru: '',
      it: 'lituania'
    },
    hasRegion: false,
    ins: {}
  },
  LUX: {
    iso2: 'lu',
    old_id: 52,
    subContinent: 155,
    continent: 1,
    names: {
      en: 'Luxembourg',
      fr: 'Luxembourg',
      de: 'Luxemburg',
      es: 'Luxemburgo',
      pt: 'Luxemburgo',
      pl: 'Luksemburg',
      'pt-BR': 'Luxemburgo',
      sv: 'Luxemburg',
      zh: '卢森堡',
      ko: '룩셈부르크',
      ja: 'ルクセンブルク',
      ru: 'Люксембург',
      th: 'ประเทศลักเซมเบิร์ก',
      it: 'Lussemburgo'
    },
    permalinks: {
      en: 'luxembourg',
      fr: 'luxembourg',
      de: 'luxemburg',
      es: 'luxemburgo',
      pt: 'luxemburgo',
      pl: 'luksemburg',
      'pt-BR': 'luxemburgo',
      sv: 'luxemburg',
      ru: '',
      it: 'lussemburgo'
    },
    hasRegion: false,
    ins: { fr: 'au' }
  },
  LVA: {
    iso2: 'lv',
    old_id: 48,
    subContinent: 154,
    continent: 1,
    names: {
      en: 'Latvia',
      fr: 'Lettonie',
      de: 'Lettland',
      es: 'Letonia',
      pt: 'Letónia',
      pl: 'Łotwa',
      'pt-BR': 'Letónia',
      sv: 'Lettland',
      zh: '拉脱维亚',
      ko: '라트비아',
      ja: 'ラトビア',
      ru: 'Латвия',
      th: 'ประเทศลัตเวีย',
      it: 'Lettonia'
    },
    permalinks: {
      en: 'latvia',
      fr: 'lettonie',
      de: 'lettland',
      es: 'letonia',
      pt: 'letonia',
      pl: 'lotwa',
      'pt-BR': 'letonia',
      sv: 'lettland',
      ru: '',
      it: 'lettonia'
    },
    hasRegion: false,
    ins: {}
  },
  MAC: {
    iso2: 'mo',
    old_id: 53,
    subContinent: 30,
    continent: 4,
    names: {
      en: 'Macau',
      fr: 'Macao',
      de: 'Macao',
      es: 'Macao',
      pt: 'Macau',
      pl: 'Makau',
      'pt-BR': 'Macau',
      sv: 'Macao',
      zh: '澳门',
      ko: '마카오',
      ja: 'マカオ',
      ru: 'Аомынь',
      th: 'มาเก๊า',
      it: 'Macao'
    },
    permalinks: {
      en: 'macau',
      fr: 'macao',
      de: 'macao',
      es: 'macao',
      pt: 'macau',
      pl: 'makau',
      'pt-BR': 'macau',
      sv: 'macao',
      ru: '',
      it: 'macao'
    },
    hasRegion: false,
    ins: { fr: 'à' }
  },
  MAF: {
    iso2: 'mf',
    old_id: 254,
    subContinent: 29,
    continent: 3,
    names: {
      en: 'Saint Martin',
      fr: 'Saint Martin',
      de: 'Saint Martin (Französischer Teil)',
      es: 'San Martín (zona francesa)',
      pt: 'São Martin (Território Francês)',
      pl: 'Saint-Martin (Francja)',
      'pt-BR': 'São Martinho (França)',
      sv: 'Saint Martin (franska delen)',
      zh: '法属圣马丁',
      ko: '세인트 마틴 (프랑스)',
      ja: 'サン・マルタン (フランス)',
      ru: 'Сен-Мартен (Франция)',
      th: 'Saint Martin',
      it: 'Saint-Martin (Francia)'
    },
    permalinks: {
      en: 'saint-martin',
      fr: 'saint-martin',
      de: 'saint-martin-franzosischer-teil',
      es: 'san-martin-zona-francesa',
      pt: 'sao-martin-territorio-frances',
      pl: 'saint-martin-francja',
      'pt-BR': 'sao-martinho-franca',
      sv: 'saint-martin-franska-delen',
      ru: '',
      it: 'saint-martin-francia'
    },
    hasRegion: false,
    ins: { fr: 'à' }
  },
  MAR: {
    iso2: 'ma',
    old_id: 60,
    subContinent: 15,
    continent: 7,
    names: {
      en: 'Morocco',
      fr: 'Maroc',
      de: 'Marokko',
      es: 'Marruecos',
      pt: 'Marrocos',
      pl: 'Maroko',
      'pt-BR': 'Marrocos',
      sv: 'Marocko',
      zh: '摩洛哥',
      ko: '모로코',
      ja: 'モロッコ',
      ru: 'Марокко',
      th: 'ประเทศโมร็อกโก',
      it: 'Marocco'
    },
    permalinks: {
      en: 'morocco',
      fr: 'maroc',
      de: 'marokko',
      es: 'marruecos',
      pt: 'marrocos',
      pl: 'maroko',
      'pt-BR': 'marrocos',
      sv: 'marocko',
      ru: '',
      it: 'marocco'
    },
    hasRegion: false,
    ins: { fr: 'au' }
  },
  MCO: {
    iso2: 'mc',
    old_id: 58,
    subContinent: 155,
    continent: 1,
    names: {
      en: 'Monaco',
      fr: 'Monaco',
      de: 'Monaco',
      es: 'Mónaco',
      pt: 'Mónaco',
      pl: 'Monako',
      'pt-BR': 'Mónaco',
      sv: 'Monaco',
      zh: '摩纳哥',
      ko: '모나코',
      ja: 'モナコ',
      ru: 'Монако',
      th: 'ประเทศโมนาโก',
      it: 'Monaco'
    },
    permalinks: {
      en: 'monaco',
      fr: 'monaco',
      de: 'monaco',
      es: 'monaco',
      pt: 'monaco',
      pl: 'monako',
      'pt-BR': 'monaco',
      sv: 'monaco',
      ru: '',
      it: 'monaco'
    },
    hasRegion: false,
    ins: { fr: 'à' }
  },
  MDA: {
    iso2: 'md',
    old_id: 148,
    subContinent: 151,
    continent: 1,
    names: {
      en: 'Moldova',
      fr: 'Moldavie',
      de: 'Moldau, Republik',
      es: 'Moldavia, República de',
      pt: 'Moldávia, República da',
      pl: 'Mołdawia',
      'pt-BR': 'Moldávia',
      sv: 'Moldavien, republiken',
      zh: '摩尔多瓦共和国',
      ko: '몰도바',
      ja: 'モルドバ',
      ru: 'Республика Молдова',
      th: 'ประเทศมอลโดวา',
      it: 'Moldavia'
    },
    permalinks: {
      en: 'moldova',
      fr: 'moldavie',
      de: 'moldau-republik',
      es: 'moldavia-republica-de',
      pt: 'moldavia-republica-da',
      pl: 'moldawia',
      'pt-BR': 'moldavia',
      sv: 'moldavien-republiken',
      ru: '',
      it: 'moldavia'
    },
    hasRegion: false,
    ins: {}
  },
  MDG: {
    iso2: 'mg',
    old_id: 125,
    subContinent: 14,
    continent: 7,
    names: {
      en: 'Madagascar',
      fr: 'Madagascar',
      de: 'Madagaskar',
      es: 'Madagascar',
      pt: 'Madagáscar',
      pl: 'Madagaskar',
      'pt-BR': 'Madagáscar',
      sv: 'Madagaskar',
      zh: '马达加斯加',
      ko: '마다가스카르',
      ja: 'マダガスカル',
      ru: 'Мадагаскар',
      th: 'ประเทศมาดากัสการ์',
      it: 'Madagascar'
    },
    permalinks: {
      en: 'madagascar',
      fr: 'madagascar',
      de: 'madagaskar',
      es: 'madagascar',
      pt: 'madagascar',
      pl: 'madagaskar',
      'pt-BR': 'madagascar',
      sv: 'madagaskar',
      ru: '',
      it: 'madagascar'
    },
    hasRegion: false,
    ins: { fr: 'à' }
  },
  MDV: {
    iso2: 'mv',
    old_id: 213,
    subContinent: 34,
    continent: 4,
    names: {
      en: 'Maldives',
      fr: 'Maldives',
      de: 'Malediven',
      es: 'Islas Maldivas',
      pt: 'Maldivas',
      pl: 'Malediwy',
      'pt-BR': 'Maldivas',
      sv: 'Maldiverna',
      zh: '马尔代夫',
      ko: 'Maldives',
      ja: 'モルディブ',
      ru: 'Мальдивы',
      th: 'Maldives',
      it: 'Maldive'
    },
    permalinks: {
      en: 'maldives',
      fr: 'maldives',
      de: 'malediven',
      es: 'islas-maldivas',
      pt: 'maldivas',
      pl: 'malediwy',
      'pt-BR': 'maldivas',
      sv: 'maldiverna',
      ru: '',
      it: 'maldive'
    },
    hasRegion: false,
    ins: { fr: 'aux' }
  },
  MEX: {
    iso2: 'mx',
    old_id: 57,
    subContinent: 13,
    continent: 3,
    names: {
      en: 'Mexico',
      fr: 'Méxique',
      de: 'Mexiko',
      es: 'México',
      pt: 'México',
      pl: 'Meksyk',
      'pt-BR': 'México',
      sv: 'Mexico',
      zh: '墨西哥',
      ko: '멕시코',
      ja: 'メキシコ',
      ru: 'Мексика',
      th: 'ประเทศเม็กซิโก',
      it: 'Messico'
    },
    permalinks: {
      en: 'mexico',
      fr: 'mexique',
      de: 'mexiko',
      es: 'mexico',
      pt: 'mexico',
      pl: 'meksyk',
      'pt-BR': 'mexico',
      sv: 'mexico',
      ru: '',
      it: 'messico'
    },
    hasRegion: false,
    ins: { fr: 'au' }
  },
  MHL: {
    iso2: 'mh',
    old_id: 214,
    subContinent: 57,
    continent: 6,
    names: {
      en: 'Marshall Islands',
      fr: 'Îles Marshall',
      de: 'Marshallinseln',
      es: 'Islas Marshall',
      pt: 'Ilhas Marshall',
      pl: 'Wyspy Marshalla',
      'pt-BR': 'Ilhas Marshall',
      sv: 'Marshallöarna',
      zh: '马绍尔群岛',
      ko: 'Marshall Islands',
      ja: 'マーシャル諸島',
      ru: 'Маршалловы острова',
      th: 'Marshall Islands',
      it: 'Isole Marshall'
    },
    permalinks: {
      en: 'marshall-islands',
      fr: 'iles-marshall',
      de: 'marshallinseln',
      es: 'islas-marshall',
      pt: 'ilhas-marshall',
      pl: 'wyspy-marshalla',
      'pt-BR': 'ilhas-marshall',
      sv: 'marshalloarna',
      ru: '',
      it: 'isole-marshall'
    },
    hasRegion: false,
    ins: { en: 'in the ', fr: 'aux' }
  },
  MKD: {
    iso2: 'mk',
    old_id: 54,
    subContinent: 39,
    continent: 1,
    names: {
      en: 'North Macedonia',
      fr: 'Macédoine du Nord',
      de: 'Nordmazedonien',
      es: 'Macedonia del Norte',
      pt: 'Macedónia do Norte',
      pl: 'Macedonia Północna',
      'pt-BR': 'Macedónia do Norte',
      sv: 'Nordmakedonien',
      zh: '马其顿',
      ko: '북마케도니아',
      ja: '北マケドニア',
      ru: 'Северная Македония',
      th: 'ประเทศมาซิโดเนียเหนือ',
      it: 'Macedonia del Nord'
    },
    permalinks: {
      en: 'north-macedonia',
      fr: 'macedoine-du-nord',
      de: 'nordmazedonien',
      es: 'macedonia-del-norte',
      pt: 'macedonia-do-norte',
      pl: 'macedonia-polnocna',
      'pt-BR': 'macedonia-do-norte',
      sv: 'nordmakedonien',
      ru: '',
      it: 'macedonia-del-nord'
    },
    hasRegion: false,
    ins: { fr: 'en' }
  },
  MLI: {
    iso2: 'ml',
    old_id: 110,
    subContinent: 11,
    continent: 7,
    names: {
      en: 'Mali',
      fr: 'Mali',
      de: 'Mali',
      es: 'Malí',
      pt: 'Mali',
      pl: 'Mali',
      'pt-BR': 'Mali',
      sv: 'Mali',
      zh: '马里',
      ko: '말리',
      ja: 'マリ共和国',
      ru: 'Мали',
      th: 'ประเทศมาลี',
      it: 'Mali'
    },
    permalinks: {
      en: 'mali',
      fr: 'mali',
      de: 'mali',
      es: 'mali',
      pt: 'mali',
      pl: 'mali',
      'pt-BR': 'mali',
      sv: 'mali',
      ru: '',
      it: 'mali'
    },
    hasRegion: false,
    ins: { fr: 'au' }
  },
  MLT: {
    iso2: 'mt',
    old_id: 56,
    subContinent: 39,
    continent: 1,
    names: {
      en: 'Malta',
      fr: 'Malte',
      de: 'Malta',
      es: 'Malta',
      pt: 'Malta',
      pl: 'Malta',
      'pt-BR': 'Malta',
      sv: 'Malta',
      zh: '马尔他',
      ko: '몰타',
      ja: 'マルタ',
      ru: 'Мальта',
      th: 'ประเทศมอลตา',
      it: 'Malta'
    },
    permalinks: {
      en: 'malta',
      fr: 'malte',
      de: 'malta',
      es: 'malta',
      pt: 'malta',
      pl: 'malta',
      'pt-BR': 'malta',
      sv: 'malta',
      ru: '',
      it: 'malta'
    },
    hasRegion: false,
    ins: { fr: 'à' }
  },
  MMR: {
    iso2: 'mm',
    old_id: 175,
    subContinent: 35,
    continent: 4,
    names: {
      en: 'Burma',
      fr: 'Birmanie',
      de: 'Myanmar',
      es: 'Birmania',
      pt: 'Myanmar',
      pl: 'Mjanma',
      'pt-BR': 'Birmânia',
      sv: 'Myanmar',
      zh: '缅甸',
      ko: '미얀마',
      ja: 'ミャンマー',
      ru: 'Мьянма',
      th: 'ประเทศพม่า',
      it: 'Myanmar'
    },
    permalinks: {
      en: 'burma',
      fr: 'birmanie',
      de: 'myanmar',
      es: 'birmania',
      pt: 'myanmar',
      pl: 'mjanma',
      'pt-BR': 'birmania',
      sv: 'myanmar',
      ru: '',
      it: 'myanmar'
    },
    hasRegion: false,
    ins: {}
  },
  MNE: {
    iso2: 'me',
    old_id: 154,
    subContinent: 39,
    continent: 1,
    names: {
      en: 'Montenegro',
      fr: 'Monténégro',
      de: 'Montenegro',
      es: 'Montenegro',
      pt: 'Montenegro',
      pl: 'Czarnogóra',
      'pt-BR': 'Montenegro',
      sv: 'Montenegro',
      zh: '黑山',
      ko: '몬테네그로',
      ja: 'モンテネグロ',
      ru: 'Черногория',
      th: 'ประเทศมอนเตเนโกร',
      it: 'Montenegro'
    },
    permalinks: {
      en: 'montenegro',
      fr: 'montenegro',
      de: 'montenegro',
      es: 'montenegro',
      pt: 'montenegro',
      pl: 'czarnogora',
      'pt-BR': 'montenegro',
      sv: 'montenegro',
      ru: '',
      it: 'montenegro'
    },
    hasRegion: false,
    ins: { fr: 'au' }
  },
  MNG: {
    iso2: 'mn',
    old_id: 59,
    subContinent: 30,
    continent: 4,
    names: {
      en: 'Mongolia',
      fr: 'Mongolie',
      de: 'Mongolei',
      es: 'Mongolia',
      pt: 'Mongólia',
      pl: 'Mongolia',
      'pt-BR': 'Mongólia',
      sv: 'Mongoliet',
      zh: '蒙古',
      ko: '몽골',
      ja: 'モンゴル国',
      ru: 'Монголия',
      th: 'ประเทศมองโกเลีย',
      it: 'Mongolia'
    },
    permalinks: {
      en: 'mongolia',
      fr: 'mongolie',
      de: 'mongolei',
      es: 'mongolia',
      pt: 'mongolia',
      pl: 'mongolia',
      'pt-BR': 'mongolia',
      sv: 'mongoliet',
      ru: '',
      it: 'mongolia'
    },
    hasRegion: false,
    ins: {}
  },
  MNP: {
    iso2: 'mp',
    old_id: 223,
    subContinent: 57,
    continent: 6,
    names: {
      en: 'Northern Mariana Islands',
      fr: 'Îles Mariannes du Nord',
      de: 'Nördliche Mariana-Inseln',
      es: 'Islas Marianas del Norte',
      pt: 'Ilhas Marianas do Norte',
      pl: 'Mariany Północne',
      'pt-BR': 'Ilhas Marianas Setentrionais',
      sv: 'Nordmarianerna',
      zh: '北马里亚纳群岛',
      ko: 'Northern Mariana Islands',
      ja: '北マリアナ諸島',
      ru: 'Острова северной Марианы',
      th: 'Northern Mariana Islands',
      it: 'Marianne settentrionali'
    },
    permalinks: {
      en: 'northern-mariana-islands',
      fr: 'iles-mariannes-du-nord',
      de: 'nordliche-mariana-inseln',
      es: 'islas-marianas-del-norte',
      pt: 'ilhas-marianas-do-norte',
      pl: 'mariany-polnocne',
      'pt-BR': 'ilhas-marianas-setentrionais',
      sv: 'nordmarianerna',
      ru: '',
      it: 'marianne-settentrionali'
    },
    hasRegion: false,
    ins: { en: 'in the', fr: 'aux' }
  },
  MOZ: {
    iso2: 'mz',
    old_id: 218,
    subContinent: 14,
    continent: 7,
    names: {
      en: 'Mozambique',
      fr: 'Mozambique',
      de: 'Mosambik',
      es: 'Mozambique',
      pt: 'Moçambique',
      pl: 'Mozambik',
      'pt-BR': 'Moçambique',
      sv: 'Moçambique',
      zh: '莫桑比克',
      ko: '모잠비크',
      ja: 'モザンビーク',
      ru: 'Мозамбик',
      th: 'ประเทศโมซัมบิก',
      it: 'Mozambico'
    },
    permalinks: {
      en: 'mozambique',
      fr: 'mozambique',
      de: 'mosambik',
      es: 'mozambique',
      pt: 'mocambique',
      pl: 'mozambik',
      'pt-BR': 'mocambique',
      sv: 'mocambique',
      ru: '',
      it: 'mozambico'
    },
    hasRegion: false,
    ins: { fr: 'au' }
  },
  MRT: {
    iso2: 'mr',
    old_id: 215,
    subContinent: 11,
    continent: 7,
    names: {
      en: 'Mauritania',
      fr: 'Mauritanie',
      de: 'Mauretanien',
      es: 'Mauritania',
      pt: 'Mauritânia',
      pl: 'Mauretania',
      'pt-BR': 'Mauritânia',
      sv: 'Mauretanien',
      zh: '毛里塔尼亚',
      ko: '모리타니',
      ja: 'モーリタニア',
      ru: 'Мавритания',
      th: 'ประเทศมอริเตเนีย',
      it: 'Mauritania'
    },
    permalinks: {
      en: 'mauritania',
      fr: 'mauritanie',
      de: 'mauretanien',
      es: 'mauritania',
      pt: 'mauritania',
      pl: 'mauretania',
      'pt-BR': 'mauritania',
      sv: 'mauretanien',
      ru: '',
      it: 'mauritania'
    },
    hasRegion: false,
    ins: {}
  },
  MSR: {
    iso2: 'ms',
    old_id: 146,
    subContinent: 29,
    continent: 3,
    names: {
      en: 'Montserrat',
      fr: 'Montserrat',
      de: 'Montserrat',
      es: 'Montserrat',
      pt: 'Montserrat',
      pl: 'Montserrat',
      'pt-BR': 'Montserrat',
      sv: 'Montserrat',
      zh: '蒙塞拉特岛',
      ko: '몬트세랫',
      ja: 'モントセラト',
      ru: 'Монсеррат',
      th: 'มอนต์เซอร์รัต',
      it: 'Montserrat'
    },
    permalinks: {
      en: 'montserrat',
      fr: 'montserrat',
      de: 'montserrat',
      es: 'montserrat',
      pt: 'montserrat',
      pl: 'montserrat',
      'pt-BR': 'montserrat',
      sv: 'montserrat',
      ru: '',
      it: 'montserrat'
    },
    hasRegion: false,
    ins: { fr: 'à' }
  },
  MTQ: {
    iso2: 'mq',
    old_id: 120,
    subContinent: 29,
    continent: 3,
    names: {
      en: 'Martinique',
      fr: 'Martinique',
      de: 'Martinique',
      es: 'Martinica',
      pt: 'Martinica',
      pl: 'Martynika',
      'pt-BR': 'Martinique',
      sv: 'Martinique',
      zh: '马提尼克',
      ko: 'Martinique',
      ja: 'Martinique',
      ru: 'Мартиника',
      th: 'Martinique',
      it: 'Martinica'
    },
    permalinks: {
      en: 'martinique',
      fr: 'martinique',
      de: 'martinique',
      es: 'martinica',
      pt: 'martinica',
      pl: 'martynika',
      'pt-BR': 'martinique',
      sv: 'martinique',
      ru: '',
      it: 'martinica'
    },
    hasRegion: false,
    ins: {}
  },
  MUS: {
    iso2: 'mu',
    old_id: 153,
    subContinent: 14,
    continent: 7,
    names: {
      en: 'Mauritius',
      fr: 'Maurice',
      de: 'Mauritius',
      es: 'Mauricio',
      pt: 'Maurícias',
      pl: 'Mauritius',
      'pt-BR': 'Maurícia',
      sv: 'Mauritius',
      zh: '毛里求斯',
      ko: '모리셔스',
      ja: 'モーリシャス',
      ru: 'Маврикий',
      th: 'ประเทศมอริเชียส',
      it: 'Maurizio'
    },
    permalinks: {
      en: 'mauritius',
      fr: 'maurice',
      de: 'mauritius',
      es: 'mauricio',
      pt: 'mauricias',
      pl: 'mauritius',
      'pt-BR': 'mauricia',
      sv: 'mauritius',
      ru: '',
      it: 'maurizio'
    },
    hasRegion: false,
    ins: { fr: 'à' }
  },
  MWI: {
    iso2: 'mw',
    old_id: 212,
    subContinent: 14,
    continent: 7,
    names: {
      en: 'Malawi',
      fr: 'Malawi',
      de: 'Malawi',
      es: 'Malawi',
      pt: 'Malawi',
      pl: 'Malawi',
      'pt-BR': 'Malawi',
      sv: 'Malawi',
      zh: '马拉维',
      ko: '말라위',
      ja: 'マラウイ',
      ru: 'Малави',
      th: 'ประเทศมาลาวี',
      it: 'Malawi'
    },
    permalinks: {
      en: 'malawi',
      fr: 'malawi',
      de: 'malawi',
      es: 'malawi',
      pt: 'malawi',
      pl: 'malawi',
      'pt-BR': 'malawi',
      sv: 'malawi',
      ru: '',
      it: 'malawi'
    },
    hasRegion: false,
    ins: { fr: 'au' }
  },
  MYS: {
    iso2: 'my',
    old_id: 55,
    subContinent: 35,
    continent: 4,
    names: {
      en: 'Malaysia',
      fr: 'Malaisie',
      de: 'Malaysia',
      es: 'Malasia',
      pt: 'Malásia',
      pl: 'Malezja',
      'pt-BR': 'Malásia',
      sv: 'Malaysia',
      zh: '马来西亚',
      ko: '말레이시아',
      ja: 'マレーシア',
      ru: 'Малайзия',
      th: 'ประเทศมาเลเซีย',
      it: 'Malaysia'
    },
    permalinks: {
      en: 'malaysia',
      fr: 'malaisie',
      de: 'malaysia',
      es: 'malasia',
      pt: 'malasia',
      pl: 'malezja',
      'pt-BR': 'malasia',
      sv: 'malaysia',
      ru: '',
      it: 'malaysia'
    },
    hasRegion: false,
    ins: {}
  },
  MYT: {
    iso2: 'yt',
    old_id: 216,
    subContinent: 14,
    continent: 7,
    names: {
      en: 'Mayotte',
      fr: 'Mayotte',
      de: 'Mayotte',
      es: 'Mayotte',
      pt: 'Mayotte',
      pl: 'Majotta',
      'pt-BR': 'Mayotte',
      sv: 'Mayotte',
      zh: '马约特',
      ko: '마요트',
      ja: 'マヨット',
      ru: 'Майот',
      th: 'มายอต',
      it: 'Mayotte'
    },
    permalinks: {
      en: 'mayotte',
      fr: 'mayotte',
      de: 'mayotte',
      es: 'mayotte',
      pt: 'mayotte',
      pl: 'majotta',
      'pt-BR': 'mayotte',
      sv: 'mayotte',
      ru: '',
      it: 'mayotte'
    },
    hasRegion: false,
    ins: { fr: 'à' }
  },
  NAM: {
    iso2: 'na',
    old_id: 114,
    subContinent: 18,
    continent: 7,
    names: {
      en: 'Namibia',
      fr: 'Namibie',
      de: 'Namibia',
      es: 'Namibia',
      pt: 'Namíbia',
      pl: 'Namibia',
      'pt-BR': 'Namíbia',
      sv: 'Namibia',
      zh: '纳米比亚',
      ko: '나미비아',
      ja: 'ナミビア',
      ru: 'Намибия',
      th: 'ประเทศนามิเบีย',
      it: 'Namibia'
    },
    permalinks: {
      en: 'namibia',
      fr: 'namibie',
      de: 'namibia',
      es: 'namibia',
      pt: 'namibia',
      pl: 'namibia',
      'pt-BR': 'namibia',
      sv: 'namibia',
      ru: '',
      it: 'namibia'
    },
    hasRegion: false,
    ins: {}
  },
  NCL: {
    iso2: 'nc',
    old_id: 63,
    subContinent: 54,
    continent: 6,
    names: {
      en: 'New Caledonia',
      fr: 'Nouvelle Calédonie',
      de: 'Neukaledonien',
      es: 'Nueva Caledonia',
      pt: 'Nova Caledónia',
      pl: 'Nowa Kaledonia',
      'pt-BR': 'Nova Caledônia',
      sv: 'Nya Kaledonien',
      zh: '新喀里多尼亚',
      ko: '뉴 칼레도니아',
      ja: 'ニューカレドニア',
      ru: 'Новая Каледония',
      th: 'นิวแคลิโดเนีย',
      it: 'Nuova Caledonia'
    },
    permalinks: {
      en: 'new-caledonia',
      fr: 'nouvelle-caledonie',
      de: 'neukaledonien',
      es: 'nueva-caledonia',
      pt: 'nova-caledonia',
      pl: 'nowa-kaledonia',
      'pt-BR': 'nova-caledonia',
      sv: 'nya-kaledonien',
      ru: '',
      it: 'nuova-caledonia'
    },
    hasRegion: false,
    ins: {}
  },
  NEP: {
    iso2: 'np',
    old_id: 61,
    subContinent: 34,
    continent: 4,
    names: {
      en: 'Nepal',
      fr: 'Népal',
      de: 'Nepal',
      es: 'Nepal',
      pt: 'Nepal',
      pl: 'Nepal',
      'pt-BR': 'Nepal',
      sv: 'Nepal',
      zh: '尼泊尔',
      ko: '네팔',
      ja: 'ネパール',
      ru: 'Непал',
      th: 'ประเทศเนปาล',
      it: 'Nepal'
    },
    permalinks: {
      en: 'nepal',
      fr: 'nepal',
      de: 'nepal',
      es: 'nepal',
      pt: 'nepal',
      pl: 'nepal',
      'pt-BR': 'nepal',
      sv: 'nepal',
      ru: '',
      it: 'nepal'
    },
    hasRegion: false,
    ins: { fr: 'au' }
  },
  NER: {
    iso2: 'ne',
    old_id: 178,
    subContinent: 11,
    continent: 7,
    names: {
      en: 'Niger',
      fr: 'Niger',
      de: 'Niger',
      es: 'Niger',
      pt: 'Níger',
      pl: 'Niger',
      'pt-BR': 'Níger',
      sv: 'Niger',
      zh: '尼日尔',
      ko: '니제르',
      ja: 'ニジェール',
      ru: 'Нигер',
      th: 'ประเทศไนเจอร์',
      it: 'Niger'
    },
    permalinks: {
      en: 'niger',
      fr: 'niger',
      de: 'niger',
      es: 'niger',
      pt: 'niger',
      pl: 'niger',
      'pt-BR': 'niger',
      sv: 'niger',
      ru: '',
      it: 'niger'
    },
    hasRegion: false,
    ins: { fr: 'au' }
  },
  NFK: {
    iso2: 'nf',
    old_id: 222,
    subContinent: 53,
    continent: 6,
    names: {
      en: 'Norfolk Island',
      fr: 'Île Norfolk',
      de: 'Norfolkinsel',
      es: 'Isla Norfolk',
      pt: 'Ilha Norfolk',
      pl: 'Wyspa Norfolk',
      'pt-BR': 'Ilha Norfolk',
      sv: 'Norfolköarna',
      zh: '诺福克岛',
      ko: '노퍽섬',
      ja: 'ノーフォーク島',
      ru: 'Остров Норфолк',
      th: 'เกาะนอร์ฟอล์ก',
      it: 'Isola Norfolk'
    },
    permalinks: {
      en: 'norfolk-island',
      fr: 'ile-norfolk',
      de: 'norfolkinsel',
      es: 'isla-norfolk',
      pt: 'ilha-norfolk',
      pl: 'wyspa-norfolk',
      'pt-BR': 'ilha-norfolk',
      sv: 'norfolkoarna',
      ru: '',
      it: 'isola-norfolk'
    },
    hasRegion: false,
    ins: { en: 'in the', fr: "dans l'Île Norfolk" }
  },
  NGA: {
    iso2: 'ng',
    old_id: 220,
    subContinent: 11,
    continent: 7,
    names: {
      en: 'Nigeria',
      fr: 'Nigéria',
      de: 'Nigeria',
      es: 'Nigeria',
      pt: 'Nigéria',
      pl: 'Nigeria',
      'pt-BR': 'Nigéria',
      sv: 'Nigeria',
      zh: '尼日利亚',
      ko: '나이지리아',
      ja: 'ナイジェリア',
      ru: 'Нигерия',
      th: 'ประเทศไนจีเรีย',
      it: 'Nigeria'
    },
    permalinks: {
      en: 'nigeria',
      fr: 'nigeria',
      de: 'nigeria',
      es: 'nigeria',
      pt: 'nigeria',
      pl: 'nigeria',
      'pt-BR': 'nigeria',
      sv: 'nigeria',
      ru: '',
      it: 'nigeria'
    },
    hasRegion: false,
    ins: { fr: 'au' }
  },
  NIC: {
    iso2: 'ni',
    old_id: 133,
    subContinent: 13,
    continent: 3,
    names: {
      en: 'Nicaragua',
      fr: 'Nicaragua',
      de: 'Nicaragua',
      es: 'Nicaragua',
      pt: 'Nicarágua',
      pl: 'Nikaragua',
      'pt-BR': 'Nicarágua',
      sv: 'Nicaragua',
      zh: '尼加拉瓜',
      ko: '니카라과',
      ja: 'ニカラグア',
      ru: 'Никарагуа',
      th: 'ประเทศนิการากัว',
      it: 'Nicaragua'
    },
    permalinks: {
      en: 'nicaragua',
      fr: 'nicaragua',
      de: 'nicaragua',
      es: 'nicaragua',
      pt: 'nicaragua',
      pl: 'nikaragua',
      'pt-BR': 'nicaragua',
      sv: 'nicaragua',
      ru: '',
      it: 'nicaragua'
    },
    hasRegion: false,
    ins: { fr: 'au' }
  },
  NIU: {
    iso2: 'nu',
    old_id: 221,
    subContinent: 61,
    continent: 6,
    names: {
      en: 'Niue',
      fr: 'Niue',
      de: 'Niue',
      es: 'Niue',
      pt: 'Niue',
      pl: 'Niue',
      'pt-BR': 'Niue',
      sv: 'Niue',
      zh: '纽埃',
      ko: '니우에',
      ja: 'ニウエ',
      ru: 'Ниуэ',
      th: 'นีอูเอ',
      it: 'Niue'
    },
    permalinks: {
      en: 'niue',
      fr: 'niue',
      de: 'niue',
      es: 'niue',
      pt: 'niue',
      pl: 'niue',
      'pt-BR': 'niue',
      sv: 'niue',
      ru: '',
      it: 'niue'
    },
    hasRegion: false,
    ins: { fr: 'à' }
  },
  NLD: {
    iso2: 'nl',
    old_id: 62,
    subContinent: 155,
    continent: 1,
    names: {
      en: 'Netherlands',
      fr: 'Pays-Bas',
      de: 'Niederlande',
      es: 'Países Bajos',
      pt: 'Países Baixos',
      pl: 'Holandia',
      'pt-BR': 'Países Baixos',
      sv: 'Nederländerna',
      zh: '荷兰',
      ko: '네덜란드',
      ja: 'オランダ',
      ru: 'Нидерланды',
      th: 'ประเทศเนเธอร์แลนด์',
      it: 'Paesi Bassi'
    },
    permalinks: {
      en: 'netherlands',
      fr: 'pays-bas',
      de: 'niederlande',
      es: 'paises-bajos',
      pt: 'paises-baixos',
      pl: 'holandia',
      'pt-BR': 'paises-baixos',
      sv: 'nederlanderna',
      ru: '',
      it: 'paesi-bassi'
    },
    hasRegion: true,
    ins: { en: 'in the', fr: 'aux' }
  },
  NOR: {
    iso2: 'no',
    old_id: 66,
    subContinent: 154,
    continent: 1,
    names: {
      en: 'Norway',
      fr: 'Norvège',
      de: 'Norwegen',
      es: 'Noruega',
      pt: 'Noruega',
      pl: 'Norwegia',
      'pt-BR': 'Noruega',
      sv: 'Norge',
      zh: '挪威',
      ko: '노르웨이',
      ja: 'ノルウェー',
      ru: 'Норвегия',
      th: 'ประเทศนอร์เวย์',
      it: 'Norvegia'
    },
    permalinks: {
      en: 'norway',
      fr: 'norvege',
      de: 'norwegen',
      es: 'noruega',
      pt: 'noruega',
      pl: 'norwegia',
      'pt-BR': 'noruega',
      sv: 'norge',
      ru: '',
      it: 'norvegia'
    },
    hasRegion: false,
    ins: {}
  },
  NPO: {
    iso2: 'xx',
    old_id: 65,
    subContinent: null,
    continent: 1,
    names: {
      en: 'North Pole',
      fr: 'Pôle Nord',
      de: 'North Pole',
      es: 'North Pole',
      pt: 'North Pole',
      pl: 'North Pole',
      'pt-BR': 'North Pole',
      sv: 'North Pole',
      zh: 'North Pole',
      ko: 'North Pole',
      ja: 'North Pole',
      ru: 'North Pole',
      th: 'North Pole',
      it: 'North Pole'
    },
    permalinks: {
      en: 'north-pole',
      fr: 'pole-nord',
      de: 'north-pole',
      es: 'north-pole',
      pt: 'north-pole',
      pl: 'north-pole',
      'pt-BR': 'north-pole',
      sv: 'north-pole',
      ru: 'north-pole',
      it: 'north-pole'
    },
    hasRegion: false,
    ins: { en: 'in the', fr: 'au' }
  },
  NRU: {
    iso2: 'nr',
    old_id: 219,
    subContinent: 57,
    continent: 6,
    names: {
      en: 'Nauru',
      fr: 'Nauru',
      de: 'Nauru',
      es: 'Nauru',
      pt: 'Nauru',
      pl: 'Nauru',
      'pt-BR': 'Nauru',
      sv: 'Nauru',
      zh: '瑙鲁',
      ko: 'Nauru',
      ja: 'ナウル',
      ru: 'Науру',
      th: 'ประเทศนาอูรู',
      it: 'Nauru'
    },
    permalinks: {
      en: 'nauru',
      fr: 'nauru',
      de: 'nauru',
      es: 'nauru',
      pt: 'nauru',
      pl: 'nauru',
      'pt-BR': 'nauru',
      sv: 'nauru',
      ru: '',
      it: 'nauru'
    },
    hasRegion: false,
    ins: { fr: 'à' }
  },
  NZL: {
    iso2: 'nz',
    old_id: 64,
    subContinent: 53,
    continent: 6,
    names: {
      en: 'New Zealand',
      fr: 'Nouvelle Zélande',
      de: 'Neuseeland',
      es: 'Nueva Zelanda',
      pt: 'Nova Zelândia',
      pl: 'Nowa Zelandia',
      'pt-BR': 'Nova Zelândia',
      sv: 'Nya Zeeland',
      zh: '新西兰',
      ko: '뉴질랜드',
      ja: 'ニュージーランド',
      ru: 'Новая Зеландия',
      th: 'ประเทศนิวซีแลนด์',
      it: 'Nuova Zelanda'
    },
    permalinks: {
      en: 'new-zealand',
      fr: 'nouvelle-zelande',
      de: 'neuseeland',
      es: 'nueva-zelanda',
      pt: 'nova-zelandia',
      pl: 'nowa-zelandia',
      'pt-BR': 'nova-zelandia',
      sv: 'nya-zeeland',
      ru: '',
      it: 'nuova-zelanda'
    },
    hasRegion: false,
    ins: {}
  },
  OMN: {
    iso2: 'om',
    old_id: 115,
    subContinent: 145,
    continent: 4,
    names: {
      en: 'Oman',
      fr: 'Oman',
      de: 'Oman',
      es: 'Omán',
      pt: 'Oman',
      pl: 'Oman',
      'pt-BR': 'Omã',
      sv: 'Oman',
      zh: '阿曼',
      ko: '오만',
      ja: 'オマーン',
      ru: 'Оман',
      th: 'ประเทศโอมาน',
      it: 'Oman'
    },
    permalinks: {
      en: 'oman',
      fr: 'oman',
      de: 'oman',
      es: 'oman',
      pt: 'oman',
      pl: 'oman',
      'pt-BR': 'oma',
      sv: 'oman',
      ru: '',
      it: 'oman'
    },
    hasRegion: false,
    ins: {}
  },
  PAK: {
    iso2: 'pk',
    old_id: 67,
    subContinent: 34,
    continent: 4,
    names: {
      en: 'Pakistan',
      fr: 'Pakistan',
      de: 'Pakistan',
      es: 'Pakistán',
      pt: 'Paquistão',
      pl: 'Pakistan',
      'pt-BR': 'Paquistão',
      sv: 'Pakistan',
      zh: '巴基斯坦',
      ko: '파키스탄',
      ja: 'パキスタン',
      ru: 'Пакистан',
      th: 'ประเทศปากีสถาน',
      it: 'Pakistan'
    },
    permalinks: {
      en: 'pakistan',
      fr: 'pakistan',
      de: 'pakistan',
      es: 'pakistan',
      pt: 'paquistao',
      pl: 'pakistan',
      'pt-BR': 'paquistao',
      sv: 'pakistan',
      ru: '',
      it: 'pakistan'
    },
    hasRegion: false,
    ins: { fr: 'au' }
  },
  PAN: {
    iso2: 'pa',
    old_id: 68,
    subContinent: 13,
    continent: 3,
    names: {
      en: 'Panama',
      fr: 'Panama',
      de: 'Panama',
      es: 'Panamá',
      pt: 'Panamá',
      pl: 'Panama',
      'pt-BR': 'Panamá',
      sv: 'Panama',
      zh: '巴拿马',
      ko: '파나마',
      ja: 'パナマ',
      ru: 'Панама',
      th: 'ประเทศปานามา',
      it: 'Panama'
    },
    permalinks: {
      en: 'panama',
      fr: 'panama',
      de: 'panama',
      es: 'panama',
      pt: 'panama',
      pl: 'panama',
      'pt-BR': 'panama',
      sv: 'panama',
      ru: '',
      it: 'panama'
    },
    hasRegion: false,
    ins: { fr: 'à' }
  },
  PCN: {
    iso2: 'pn',
    old_id: 227,
    subContinent: 61,
    continent: 6,
    names: {
      en: 'Pitcairn Islands',
      fr: 'Îles Pitcairn',
      de: 'Pitcairn',
      es: 'Pitcairn',
      pt: 'Pitcairn',
      pl: 'Pitcairn',
      'pt-BR': 'Ilhas Pitcairn',
      sv: 'Pitcairn',
      zh: '皮特克恩',
      ko: '핏케언 제도',
      ja: 'ピトケアン諸島',
      ru: 'Питкэрн',
      th: 'Pitcairn Islands',
      it: 'Pitcairn'
    },
    permalinks: {
      en: 'pitcairn-islands',
      fr: 'iles-pitcairn',
      de: 'pitcairn',
      es: 'pitcairn',
      pt: 'pitcairn',
      pl: 'pitcairn',
      'pt-BR': 'ilhas-pitcairn',
      sv: 'pitcairn',
      ru: '',
      it: 'pitcairn'
    },
    hasRegion: false,
    ins: { en: 'in the ', fr: 'aux' }
  },
  PER: {
    iso2: 'pe',
    old_id: 69,
    subContinent: null,
    continent: 2,
    names: {
      en: 'Peru',
      fr: 'Pérou',
      de: 'Peru',
      es: 'Perú',
      pt: 'Peru',
      pl: 'Peru',
      'pt-BR': 'Peru',
      sv: 'Peru',
      zh: '秘鲁',
      ko: '페루',
      ja: 'ペルー',
      ru: 'Перу',
      th: 'ประเทศเปรู',
      it: 'Perù'
    },
    permalinks: {
      en: 'peru',
      fr: 'perou',
      de: 'peru',
      es: 'peru',
      pt: 'peru',
      pl: 'peru',
      'pt-BR': 'peru',
      sv: 'peru',
      ru: '',
      it: 'peru'
    },
    hasRegion: false,
    ins: { fr: 'au' }
  },
  PHL: {
    iso2: 'ph',
    old_id: 70,
    subContinent: 35,
    continent: 4,
    names: {
      en: 'Philippines',
      fr: 'Philippines',
      de: 'Philippinen',
      es: 'Filipinas',
      pt: 'Filipinas',
      pl: 'Filipiny',
      'pt-BR': 'Filipinas',
      sv: 'Filippinerna',
      zh: '菲律宾',
      ko: '필리핀',
      ja: 'フィリピン',
      ru: 'Филиппины',
      th: 'ประเทศฟิลิปปินส์',
      it: 'Filippine'
    },
    permalinks: {
      en: 'philippines',
      fr: 'philippines',
      de: 'philippinen',
      es: 'filipinas',
      pt: 'filipinas',
      pl: 'filipiny',
      'pt-BR': 'filipinas',
      sv: 'filippinerna',
      ru: '',
      it: 'filippine'
    },
    hasRegion: false,
    ins: { en: 'in the', fr: 'aux' }
  },
  PLW: {
    iso2: 'pw',
    old_id: 224,
    subContinent: 57,
    continent: 6,
    names: {
      en: 'Palau',
      fr: 'Palaos',
      de: 'Palau',
      es: 'Palau',
      pt: 'Palau',
      pl: 'Palau',
      'pt-BR': 'Palau',
      sv: 'Palau',
      zh: '帕劳',
      ko: '팔라우',
      ja: 'パラオ',
      ru: 'Палау',
      th: 'Palau',
      it: 'Palau'
    },
    permalinks: {
      en: 'palau',
      fr: 'palaos',
      de: 'palau',
      es: 'palau',
      pt: 'palau',
      pl: 'palau',
      'pt-BR': 'palau',
      sv: 'palau',
      ru: '',
      it: 'palau'
    },
    hasRegion: false,
    ins: { fr: 'aux' }
  },
  PNG: {
    iso2: 'pg',
    old_id: 225,
    subContinent: 54,
    continent: 6,
    names: {
      en: 'Papua New Guinea',
      fr: 'Papouasie-Nouvelle-Guinée',
      de: 'Papua-Neuguinea',
      es: 'Papúa Nueva Guinea',
      pt: 'Papua Nova Guiné',
      pl: 'Papua-Nowa Gwinea',
      'pt-BR': 'Papua-Nova Guiné',
      sv: 'Papua Nya Guinea',
      zh: '巴布亚新几内亚',
      ko: '파푸아 뉴기니',
      ja: 'パプアニューギニア',
      ru: 'Папуа — Новая Гвинея',
      th: 'ประเทศปาปัวนิวกินี',
      it: 'Papua Nuova Guinea'
    },
    permalinks: {
      en: 'papua-new-guinea',
      fr: 'papouasie-nouvelle-guinee',
      de: 'papua-neuguinea',
      es: 'papua-nueva-guinea',
      pt: 'papua-nova-guine',
      pl: 'papua-nowa-gwinea',
      'pt-BR': 'papua-nova-guine',
      sv: 'papua-nya-guinea',
      ru: '',
      it: 'papua-nuova-guinea'
    },
    hasRegion: false,
    ins: {}
  },
  POL: {
    iso2: 'pl',
    old_id: 71,
    subContinent: 151,
    continent: 1,
    names: {
      en: 'Poland',
      fr: 'Pologne',
      de: 'Polen',
      es: 'Polonia',
      pt: 'Polónia',
      pl: 'Polska',
      'pt-BR': 'Polónia',
      sv: 'Polen',
      zh: '波兰',
      ko: '폴란드',
      ja: 'ポーランド',
      ru: 'Польша',
      th: 'ประเทศโปแลนด์',
      it: 'Polonia'
    },
    permalinks: {
      en: 'poland',
      fr: 'pologne',
      de: 'polen',
      es: 'polonia',
      pt: 'polonia',
      pl: 'polska',
      'pt-BR': 'polonia',
      sv: 'polen',
      ru: '',
      it: 'polonia'
    },
    hasRegion: false,
    ins: {}
  },
  PRI: {
    iso2: 'pr',
    old_id: 73,
    subContinent: 29,
    continent: 3,
    names: {
      en: 'Puerto Rico',
      fr: 'Porto rico',
      de: 'Puerto Rico',
      es: 'Puerto Rico',
      pt: 'Porto Rico',
      pl: 'Portoryko',
      'pt-BR': 'Porto Rico',
      sv: 'Puerto Rico',
      zh: '波多黎各',
      ko: '푸에르토리코',
      ja: 'Puerto Rico',
      ru: 'Пуэрто-Рико',
      th: 'Puerto Rico',
      it: 'Puerto Rico'
    },
    permalinks: {
      en: 'puerto-rico',
      fr: 'porto-rico',
      de: 'puerto-rico',
      es: 'puerto-rico',
      pt: 'porto-rico',
      pl: 'portoryko',
      'pt-BR': 'porto-rico',
      sv: 'puerto-rico',
      ru: '',
      it: 'puerto-rico'
    },
    hasRegion: false,
    ins: { fr: 'à' }
  },
  PRK: {
    iso2: 'kp',
    old_id: 205,
    subContinent: 30,
    continent: 4,
    names: {
      en: 'North Korea',
      fr: 'Corée du Nord',
      de: 'Korea, Demokratische Volksrepublik',
      es: 'Corea, República Democrática Popular de',
      pt: 'Coreia, República Popular Democrática de',
      pl: 'Korea Północna',
      'pt-BR': 'Coreia do Norte',
      sv: 'Korea, demokratiska folkrepubliken',
      zh: '朝鲜',
      ko: '조선민주주의인민공화국',
      ja: '朝鮮民主主義人民共和国',
      ru: 'Корейская Народно-Демократическая Республика',
      th: 'ประเทศเกาหลีเหนือ',
      it: 'Corea del Nord'
    },
    permalinks: {
      en: 'north-korea',
      fr: 'coree-du-nord',
      de: 'korea-demokratische-volksrepublik',
      es: 'corea-republica-democratica-popular-de',
      pt: 'coreia-republica-popular-democratica-de',
      pl: 'korea-polnocna',
      'pt-BR': 'coreia-do-norte',
      sv: 'korea-demokratiska-folkrepubliken',
      ru: '',
      it: 'corea-del-nord'
    },
    hasRegion: false,
    ins: {}
  },
  PRT: {
    iso2: 'pt',
    old_id: 72,
    subContinent: 39,
    continent: 1,
    names: {
      en: 'Portugal',
      fr: 'Portugal',
      de: 'Portugal',
      es: 'Portugal',
      pt: 'Portugal',
      pl: 'Portugalia',
      'pt-BR': 'Portugal',
      sv: 'Portugal',
      zh: '葡萄牙',
      ko: '포르투갈',
      ja: 'ポルトガル',
      ru: 'Португалия',
      th: 'ประเทศโปรตุเกส',
      it: 'Portogallo'
    },
    permalinks: {
      en: 'portugal',
      fr: 'portugal',
      de: 'portugal',
      es: 'portugal',
      pt: 'portugal',
      pl: 'portugalia',
      'pt-BR': 'portugal',
      sv: 'portugal',
      ru: '',
      it: 'portogallo'
    },
    hasRegion: false,
    ins: { fr: 'au' }
  },
  PRY: {
    iso2: 'py',
    old_id: 226,
    subContinent: null,
    continent: 2,
    names: {
      en: 'Paraguay',
      fr: 'Paraguay',
      de: 'Paraguay',
      es: 'Paraguay',
      pt: 'Paraguai',
      pl: 'Paragwaj',
      'pt-BR': 'Paraguai',
      sv: 'Paraguay',
      zh: '巴拉圭',
      ko: '파라과이',
      ja: 'パラグアイ',
      ru: 'Парагвай',
      th: 'ประเทศปารากวัย',
      it: 'Paraguay'
    },
    permalinks: {
      en: 'paraguay',
      fr: 'paraguay',
      de: 'paraguay',
      es: 'paraguay',
      pt: 'paraguai',
      pl: 'paragwaj',
      'pt-BR': 'paraguai',
      sv: 'paraguay',
      ru: '',
      it: 'paraguay'
    },
    hasRegion: false,
    ins: { fr: 'au' }
  },
  PSE: {
    iso2: 'ps',
    old_id: 170,
    subContinent: 145,
    continent: 4,
    names: {
      en: 'Palestine (State of)',
      fr: 'Palestine',
      de: 'Palästina, Staat',
      es: 'Palestina, Estado de',
      pt: 'Palestina, Autoridade da',
      pl: 'Autonomia Palestyńska',
      'pt-BR': 'Territórios Palestinianos',
      sv: 'Staten Palestina',
      zh: '巴勒斯坦',
      ko: '팔레스타인 영토',
      ja: 'パレスチナ領土',
      ru: 'Палестина',
      th: 'Palestine (State of)',
      it: 'Palestina, Stato di'
    },
    permalinks: {
      en: 'palestine-state-of',
      fr: 'palestine',
      de: 'palastina-staat',
      es: 'palestina-estado-de',
      pt: 'palestina-autoridade-da',
      pl: 'autonomia-palestynska',
      'pt-BR': 'territorios-palestinianos',
      sv: 'staten-palestina',
      ru: '',
      it: 'palestina-stato-di'
    },
    hasRegion: false,
    ins: { en: 'in Palestine' }
  },
  PYF: {
    iso2: 'pf',
    old_id: 30,
    subContinent: 61,
    continent: 6,
    names: {
      en: 'French Polynesia',
      fr: 'Polynésie Française',
      de: 'Französisch-Polynesien',
      es: 'Polinesia Francesa',
      pt: 'Polinésia Francesa',
      pl: 'Polinezja Francuska',
      'pt-BR': 'Polinésia Francesa',
      sv: 'Franska Polynesien',
      zh: '法属玻利尼西亚',
      ko: '프랑스령 폴리네시아',
      ja: 'フランス領ポリネシア',
      ru: 'Французская Полинезия',
      th: 'เฟรนช์โปลินีเซีย',
      it: 'Polinesia francese'
    },
    permalinks: {
      en: 'french-polynesia',
      fr: 'polynesie-francaise',
      de: 'franzosisch-polynesien',
      es: 'polinesia-francesa',
      pt: 'polinesia-francesa',
      pl: 'polinezja-francuska',
      'pt-BR': 'polinesia-francesa',
      sv: 'franska-polynesien',
      ru: '',
      it: 'polinesia-francese'
    },
    hasRegion: false,
    ins: {}
  },
  QAT: {
    iso2: 'qa',
    old_id: 228,
    subContinent: 145,
    continent: 4,
    names: {
      en: 'Qatar',
      fr: 'Qatar',
      de: 'Katar',
      es: 'Qatar',
      pt: 'Quatar',
      pl: 'Katar',
      'pt-BR': 'Catar',
      sv: 'Qatar',
      zh: '卡塔尔',
      ko: '카타르',
      ja: 'カタール',
      ru: 'Катар',
      th: 'ประเทศกาตาร์',
      it: 'Qatar'
    },
    permalinks: {
      en: 'qatar',
      fr: 'qatar',
      de: 'katar',
      es: 'qatar',
      pt: 'quatar',
      pl: 'katar',
      'pt-BR': 'catar',
      sv: 'qatar',
      ru: '',
      it: 'qatar'
    },
    hasRegion: false,
    ins: { fr: 'au' }
  },
  REU: {
    iso2: 're',
    old_id: 117,
    subContinent: 14,
    continent: 7,
    names: {
      en: 'Réunion',
      fr: 'La Réunion',
      de: 'Réunion',
      es: 'Reunión',
      pt: 'Ilha Reunião',
      pl: 'Reunion',
      'pt-BR': 'Reunião',
      sv: 'Réunion',
      zh: '留尼汪',
      ko: '리유니온',
      ja: 'レユニオン島',
      ru: 'Реюньон',
      th: 'เรอูนียง',
      it: 'Riunione'
    },
    permalinks: {
      en: 'reunion',
      fr: 'la-reunion',
      de: 'reunion',
      es: 'reunion',
      pt: 'ilha-reuniao',
      pl: 'reunion',
      'pt-BR': 'reuniao',
      sv: 'reunion',
      ru: '',
      it: 'riunione'
    },
    hasRegion: false,
    ins: { fr: 'à' }
  },
  ROU: {
    iso2: 'ro',
    old_id: 74,
    subContinent: 151,
    continent: 1,
    names: {
      en: 'Romania',
      fr: 'Roumanie',
      de: 'Rumänien',
      es: 'Rumanía',
      pt: 'Roménia',
      pl: 'Rumunia',
      'pt-BR': 'Roménia',
      sv: 'Rumänien',
      zh: '罗马尼亚',
      ko: '루마니아',
      ja: 'ルーマニア',
      ru: 'Румыния',
      th: 'ประเทศโรมาเนีย',
      it: 'Romania'
    },
    permalinks: {
      en: 'romania',
      fr: 'roumanie',
      de: 'rumanien',
      es: 'rumania',
      pt: 'romenia',
      pl: 'rumunia',
      'pt-BR': 'romenia',
      sv: 'rumanien',
      ru: '',
      it: 'romania'
    },
    hasRegion: false,
    ins: {}
  },
  RUS: {
    iso2: 'ru',
    old_id: 75,
    subContinent: 151,
    continent: 1,
    names: {
      en: 'Russia',
      fr: 'Russie',
      de: 'Russische Föderation',
      es: 'Federación Rusa',
      pt: 'Federação Russa',
      pl: 'Rosja',
      'pt-BR': 'Rússia',
      sv: 'Ryska federationen',
      zh: '俄罗斯',
      ko: '러시아',
      ja: 'ロシア',
      ru: 'Российская Федерация',
      th: 'ประเทศรัสเซีย',
      it: 'Russia'
    },
    permalinks: {
      en: 'russia',
      fr: 'russie',
      de: 'russische-foderation',
      es: 'federacion-rusa',
      pt: 'federacao-russa',
      pl: 'rosja',
      'pt-BR': 'russia',
      sv: 'ryska-federationen',
      ru: '',
      it: 'russia'
    },
    hasRegion: false,
    ins: {}
  },
  RWA: {
    iso2: 'rw',
    old_id: 76,
    subContinent: 14,
    continent: 7,
    names: {
      en: 'Rwanda',
      fr: 'Rwanda',
      de: 'Ruanda',
      es: 'Ruanda',
      pt: 'Ruanda',
      pl: 'Rwanda',
      'pt-BR': 'Ruanda',
      sv: 'Rwanda',
      zh: '卢旺达',
      ko: '르완다',
      ja: 'ルワンダ',
      ru: 'Руанда',
      th: 'ประเทศรวันดา',
      it: 'Ruanda'
    },
    permalinks: {
      en: 'rwanda',
      fr: 'rwanda',
      de: 'ruanda',
      es: 'ruanda',
      pt: 'ruanda',
      pl: 'rwanda',
      'pt-BR': 'ruanda',
      sv: 'rwanda',
      ru: '',
      it: 'ruanda'
    },
    hasRegion: false,
    ins: { fr: 'au' }
  },
  SAU: {
    iso2: 'sa',
    old_id: 144,
    subContinent: 145,
    continent: 4,
    names: {
      en: 'Saudi Arabia',
      fr: 'Arabie Saoudite',
      de: 'Saudi-Arabien',
      es: 'Arabia Saudí',
      pt: 'Arábia Saudita',
      pl: 'Arabia Saudyjska',
      'pt-BR': 'Arábia Saudita',
      sv: 'Saudiarabien',
      zh: '沙特阿拉伯',
      ko: '사우디아라비아',
      ja: 'サウジアラビア',
      ru: 'Саудовская Аравия',
      th: 'ประเทศซาอุดีอาระเบีย',
      it: 'Arabia Saudita'
    },
    permalinks: {
      en: 'saudi-arabia',
      fr: 'arabie-saoudite',
      de: 'saudi-arabien',
      es: 'arabia-saudi',
      pt: 'arabia-saudita',
      pl: 'arabia-saudyjska',
      'pt-BR': 'arabia-saudita',
      sv: 'saudiarabien',
      ru: '',
      it: 'arabia-saudita'
    },
    hasRegion: false,
    ins: {}
  },
  SDN: {
    iso2: 'sd',
    old_id: 244,
    subContinent: 15,
    continent: 7,
    names: {
      en: 'Sudan',
      fr: 'Soudan',
      de: 'Sudan',
      es: 'Sudán',
      pt: 'Sudão',
      pl: 'Sudan',
      'pt-BR': 'Sudão',
      sv: 'Sudan',
      zh: '苏丹',
      ko: '수단',
      ja: 'スーダン',
      ru: 'Судан',
      th: 'ประเทศซูดาน',
      it: 'Sudan'
    },
    permalinks: {
      en: 'sudan',
      fr: 'soudan',
      de: 'sudan',
      es: 'sudan',
      pt: 'sudao',
      pl: 'sudan',
      'pt-BR': 'sudao',
      sv: 'sudan',
      ru: '',
      it: 'sudan'
    },
    hasRegion: false,
    ins: { fr: 'au' }
  },
  SEN: {
    iso2: 'sn',
    old_id: 112,
    subContinent: 11,
    continent: 7,
    names: {
      en: 'Senegal',
      fr: 'Sénégal',
      de: 'Senegal',
      es: 'Senegal',
      pt: 'Senegal',
      pl: 'Senegal',
      'pt-BR': 'Senegal',
      sv: 'Senegal',
      zh: '塞内加尔',
      ko: '세네갈',
      ja: 'セネガル',
      ru: 'Сенегал',
      th: 'ประเทศเซเนกัล',
      it: 'Senegal'
    },
    permalinks: {
      en: 'senegal',
      fr: 'senegal',
      de: 'senegal',
      es: 'senegal',
      pt: 'senegal',
      pl: 'senegal',
      'pt-BR': 'senegal',
      sv: 'senegal',
      ru: '',
      it: 'senegal'
    },
    hasRegion: false,
    ins: { fr: 'au' }
  },
  SGP: {
    iso2: 'sg',
    old_id: 79,
    subContinent: 35,
    continent: 4,
    names: {
      en: 'Singapore',
      fr: 'Singapour',
      de: 'Singapur',
      es: 'Singapur',
      pt: 'Singapura',
      pl: 'Singapur',
      'pt-BR': 'Singapura',
      sv: 'Singapore',
      zh: '新加坡',
      ko: '싱가포르',
      ja: 'シンガポール',
      ru: 'Сингапур',
      th: 'ประเทศสิงคโปร์',
      it: 'Singapore'
    },
    permalinks: {
      en: 'singapore',
      fr: 'singapour',
      de: 'singapur',
      es: 'singapur',
      pt: 'singapura',
      pl: 'singapur',
      'pt-BR': 'singapura',
      sv: 'singapore',
      ru: '',
      it: 'singapore'
    },
    hasRegion: false,
    ins: { fr: 'à' }
  },
  SGS: {
    iso2: 'gs',
    old_id: 456,
    subContinent: null,
    continent: 5,
    names: {
      en: 'South Georgia and the South Sandwich Islands',
      fr: 'Géorgie du Sud et les îles Sandwich du Sud',
      de: 'South Georgia und die Südlichen Sandwichinseln',
      es: 'Georgia del Sur e Islas Sandwitch del Sur',
      pt: 'Geórgia do Sul e Ilhas Sandwich do Sul',
      pl: 'Georgia Południowa i Sandwich Południowy',
      'pt-BR': 'Ilhas Geórgia do Sul e Sanduíche do Sul',
      sv: 'Sydgeorgien och södra Sandwichöarna',
      zh: '南乔治亚岛和南桑德韦奇岛',
      ko: 'South Georgia and the South Sandwich Islands',
      ja: 'サウスジョージア・サウスサンドウィッチ諸島',
      ru: 'Южная Джорджия и Южные Сандвичевы острова',
      th: 'South Georgia and the South Sandwich Islands',
      it: 'Georgia del Sud e Sandwich australi'
    },
    permalinks: {
      en: 'south-georgia-and-the-south-sandwich-islands',
      fr: 'georgie-du-sud-et-les-iles-sandwich-du-sud',
      de: 'south-georgia-und-die-sudlichen-sandwichinseln',
      es: 'georgia-del-sur-e-islas-sandwitch-del-sur',
      pt: 'georgia-do-sul-e-ilhas-sandwich-do-sul',
      pl: 'georgia-poludniowa-i-sandwich-poludniowy',
      'pt-BR': 'ilhas-georgia-do-sul-e-sanduiche-do-sul',
      sv: 'sydgeorgien-och-sodra-sandwichoarna',
      ru: '',
      it: 'georgia-del-sud-e-sandwich-australi'
    },
    hasRegion: false,
    ins: { fr: 'en Géorgie du Sud et  dans les îles Sandwich du Sud' }
  },
  SHN: {
    iso2: 'sh',
    old_id: 169,
    subContinent: 11,
    continent: 7,
    names: {
      en: 'Saint Helena',
      fr: 'Saint Hélène',
      de: 'St. Helena, Ascension und Tristan da Cunha',
      es: 'Santa Elena, Ascensión y Tristán de Acuña',
      pt: 'Santa Helema, Ascenção e Tristão da Cunha',
      pl: 'Wyspa Świętej Heleny, Wyspa Wniebowstąpienia i Tristan da Cunha',
      'pt-BR': 'Santa Helena, Ascensão e Tristão da Cunha',
      sv: 'Saint Helena, Ascension och Tristan da Cunha',
      zh: '圣赫勒拿-阿森松-特里斯坦达库尼亚',
      ko: 'Saint Helena',
      ja: 'セントヘレナ・アセンションおよびトリスタンダクーニャ',
      ru: 'Остров Святой Елены, Остров Вознесения и Тристан-да-Кунья',
      th: 'Saint Helena',
      it: 'Saint Helena, Ascension e Tristan da Cunha'
    },
    permalinks: {
      en: 'saint-helena',
      fr: 'saint-helene',
      de: 'st-helena-ascension-und-tristan-da-cunha',
      es: 'santa-elena-ascension-y-tristan-de-acuna',
      pt: 'santa-helema-ascencao-e-tristao-da-cunha',
      pl: 'wyspa-swietej-heleny-wyspa-wniebowstapienia-i-tristan-da-cunha',
      'pt-BR': 'santa-helena-ascensao-e-tristao-da-cunha',
      sv: 'saint-helena-ascension-och-tristan-da-cunha',
      ru: '',
      it: 'saint-helena-ascension-e-tristan-da-cunha'
    },
    hasRegion: false,
    ins: { fr: 'à' }
  },
  SJM: {
    iso2: 'sj',
    old_id: 458,
    subContinent: 154,
    continent: 1,
    names: {
      en: 'Svalbard and Jan Mayen',
      fr: 'Svalbard et île Jan Mayen',
      de: 'Svalbard und Jan Mayen',
      es: 'Svalbard y Jan Mayen',
      pt: 'Svalbard e Jan Mayen',
      pl: 'Svalbard and Jan Mayen',
      'pt-BR': 'Esvalbarda e Jan Mayen',
      sv: 'Svalbard och Jan Mayen',
      zh: '斯瓦尔巴特和扬马延岛',
      ko: 'Svalbard and Jan Mayen',
      ja: 'Svalbard and Jan Mayen',
      ru: 'Шпицберген и Ян-Майен',
      th: 'Svalbard and Jan Mayen',
      it: 'Svalbard e Jan Mayen'
    },
    permalinks: {
      en: 'svalbard-and-jan-mayen',
      fr: 'svalbard-et-ile-jan-mayen',
      de: 'svalbard-und-jan-mayen',
      es: 'svalbard-y-jan-mayen',
      pt: 'svalbard-e-jan-mayen',
      pl: 'svalbard-and-jan-mayen',
      'pt-BR': 'esvalbarda-e-jan-mayen',
      sv: 'svalbard-och-jan-mayen',
      ru: '',
      it: 'svalbard-e-jan-mayen'
    },
    hasRegion: false,
    ins: { fr: 'au Svalbard et dans île Jan Mayen' }
  },
  SLB: {
    iso2: 'sb',
    old_id: 247,
    subContinent: 54,
    continent: 6,
    names: {
      en: 'Solomon Islands',
      fr: 'Salomon',
      de: 'Salomoninseln',
      es: 'Islas Salomón',
      pt: 'Ilhas Salomão',
      pl: 'Wyspy Salomona',
      'pt-BR': 'Ilhas Salomão',
      sv: 'Salomonöarna',
      zh: '所罗门群岛',
      ko: '솔로몬 제도',
      ja: 'ソロモン諸島',
      ru: 'Соломоновы Острова',
      th: 'หมู่เกาะโซโลมอน',
      it: 'Isole Salomone'
    },
    permalinks: {
      en: 'solomon-islands',
      fr: 'salomon',
      de: 'salomoninseln',
      es: 'islas-salomon',
      pt: 'ilhas-salomao',
      pl: 'wyspy-salomona',
      'pt-BR': 'ilhas-salomao',
      sv: 'salomonoarna',
      ru: '',
      it: 'isole-salomone'
    },
    hasRegion: false,
    ins: { en: 'in the' }
  },
  SLE: {
    iso2: 'sl',
    old_id: 167,
    subContinent: 11,
    continent: 7,
    names: {
      en: 'Sierra Leone',
      fr: 'Sierra Leone',
      de: 'Sierra Leone',
      es: 'Sierra Leona',
      pt: 'Serra Leoa',
      pl: 'Sierra Leone',
      'pt-BR': 'Serra Leoa',
      sv: 'Sierra Leone',
      zh: '塞拉利昂',
      ko: '시에라리온',
      ja: 'シエラレオネ',
      ru: 'Сьерра-Леоне',
      th: 'ประเทศเซียร์ราลีโอน',
      it: 'Sierra Leone'
    },
    permalinks: {
      en: 'sierra-leone',
      fr: 'sierra-leone',
      de: 'sierra-leone',
      es: 'sierra-leona',
      pt: 'serra-leoa',
      pl: 'sierra-leone',
      'pt-BR': 'serra-leoa',
      sv: 'sierra-leone',
      ru: '',
      it: 'sierra-leone'
    },
    hasRegion: false,
    ins: {}
  },
  SLV: {
    iso2: 'sv',
    old_id: 135,
    subContinent: 13,
    continent: 3,
    names: {
      en: 'El Salvador',
      fr: 'Salvador',
      de: 'El Salvador',
      es: 'El Salvador',
      pt: 'El Salvador',
      pl: 'Salwador',
      'pt-BR': 'El Salvador',
      sv: 'El Salvador',
      zh: '萨尔瓦多',
      ko: '엘살바도르',
      ja: 'エルサルバドル',
      ru: 'Сальвадор',
      th: 'ประเทศเอลซัลวาดอร์',
      it: 'El Salvador'
    },
    permalinks: {
      en: 'el-salvador',
      fr: 'salvador',
      de: 'el-salvador',
      es: 'el-salvador',
      pt: 'el-salvador',
      pl: 'salwador',
      'pt-BR': 'el-salvador',
      sv: 'el-salvador',
      ru: '',
      it: 'el-salvador'
    },
    hasRegion: false,
    ins: { fr: 'au' }
  },
  SMR: {
    iso2: 'sm',
    old_id: 250,
    subContinent: 39,
    continent: 1,
    names: {
      en: 'San Marino',
      fr: 'Saint-Marin',
      de: 'San Marino',
      es: 'San Marino',
      pt: 'San Marino',
      pl: 'San Marino',
      'pt-BR': 'San Marino',
      sv: 'San Marino',
      zh: '圣马力诺',
      ko: 'San Marino',
      ja: 'サンマリノ',
      ru: 'Сан-Марино',
      th: 'San Marino',
      it: 'San Marino'
    },
    permalinks: {
      en: 'san-marino',
      fr: 'saint-marin',
      de: 'san-marino',
      es: 'san-marino',
      pt: 'san-marino',
      pl: 'san-marino',
      'pt-BR': 'san-marino',
      sv: 'san-marino',
      ru: '',
      it: 'san-marino'
    },
    hasRegion: false,
    ins: { fr: 'à' }
  },
  SOM: {
    iso2: 'so',
    old_id: 246,
    subContinent: 14,
    continent: 7,
    names: {
      en: 'Somalia',
      fr: 'Somalie',
      de: 'Somalia',
      es: 'Somalia',
      pt: 'Somália',
      pl: 'Somalia',
      'pt-BR': 'Somália',
      sv: 'Somalia',
      zh: '索马里',
      ko: '소말리아',
      ja: 'ソマリア',
      ru: 'Сомали',
      th: 'ประเทศโซมาเลีย',
      it: 'Somalia'
    },
    permalinks: {
      en: 'somalia',
      fr: 'somalie',
      de: 'somalia',
      es: 'somalia',
      pt: 'somalia',
      pl: 'somalia',
      'pt-BR': 'somalia',
      sv: 'somalia',
      ru: '',
      it: 'somalia'
    },
    hasRegion: false,
    ins: {}
  },
  SPM: {
    iso2: 'pm',
    old_id: 252,
    subContinent: null,
    continent: 3,
    names: {
      en: 'Saint Pierre and Miquelon',
      fr: 'Saint Pierre et Miquelon',
      de: 'St. Pierre und Miquelon',
      es: 'San Pedro y Miquelon',
      pt: 'Saint Pierre e Miquelon',
      pl: 'Saint-Pierre i Miquelon',
      'pt-BR': 'São Pedro e Miquelão',
      sv: 'Sankt Pierre och Miquelon',
      zh: '圣皮埃尔和密克隆',
      ko: '세인트피에르-미케롱',
      ja: 'サンピエール島・ミクロン島',
      ru: 'Сен-Пьер и Микелон',
      th: 'แซงปีแยร์และมีเกอลง',
      it: 'Saint-Pierre e Miquelon'
    },
    permalinks: {
      en: 'saint-pierre-and-miquelon',
      fr: 'saint-pierre-et-miquelon',
      de: 'st-pierre-und-miquelon',
      es: 'san-pedro-y-miquelon',
      pt: 'saint-pierre-e-miquelon',
      pl: 'saint-pierre-i-miquelon',
      'pt-BR': 'sao-pedro-e-miquelao',
      sv: 'sankt-pierre-och-miquelon',
      ru: '',
      it: 'saint-pierre-e-miquelon'
    },
    hasRegion: false,
    ins: { fr: 'à' }
  },
  SRB: {
    iso2: 'rs',
    old_id: 78,
    subContinent: 39,
    continent: 1,
    names: {
      en: 'Serbia',
      fr: 'Serbie',
      de: 'Serbien',
      es: 'Serbia',
      pt: 'Sérvia',
      pl: 'Serbia',
      'pt-BR': 'Sérvia',
      sv: 'Serbien',
      zh: '塞尔维亚',
      ko: '세르비아',
      ja: 'セルビア',
      ru: 'Сербия',
      th: 'ประเทศเซอร์เบีย',
      it: 'Serbia'
    },
    permalinks: {
      en: 'serbia',
      fr: 'serbie',
      de: 'serbien',
      es: 'serbia',
      pt: 'servia',
      pl: 'serbia',
      'pt-BR': 'servia',
      sv: 'serbien',
      ru: '',
      it: 'serbia'
    },
    hasRegion: false,
    ins: {}
  },
  SSD: {
    iso2: 'ss',
    old_id: 245,
    subContinent: 14,
    continent: 7,
    names: {
      en: 'South Sudan',
      fr: 'Soudan du Sud',
      de: 'Südsudan',
      es: 'Sudán del Sur',
      pt: 'República do Sudão',
      pl: 'Sudan Południowy',
      'pt-BR': 'Sudão do Sul',
      sv: 'Sydsudan',
      zh: '南苏丹',
      ko: '남수단',
      ja: '南スーダン',
      ru: 'Южный Судан',
      th: 'ซูดานใต้',
      it: 'Sudan meridionale'
    },
    permalinks: {
      en: 'south-sudan',
      fr: 'soudan-du-sud',
      de: 'sudsudan',
      es: 'sudan-del-sur',
      pt: 'republica-do-sudao',
      pl: 'sudan-poludniowy',
      'pt-BR': 'sudao-do-sul',
      sv: 'sydsudan',
      ru: '',
      it: 'sudan-meridionale'
    },
    hasRegion: false,
    ins: { fr: 'au' }
  },
  STP: {
    iso2: 'st',
    old_id: 249,
    subContinent: 17,
    continent: 7,
    names: {
      en: 'São Tomé and Príncipe',
      fr: 'Sao Tomé-et-Principe',
      de: 'São Tomé und Príncipe',
      es: 'Santo Tomé y Príncipe',
      pt: 'São Tomé e Príncipe',
      pl: 'Wyspy Świętego Tomasza i Książęca',
      'pt-BR': 'São Tomé e Príncipe',
      sv: 'São Tomé och Príncipe',
      zh: '圣多美和普林西比',
      ko: 'São Tomé and Príncipe',
      ja: 'サントメ・プリンシペ',
      ru: 'Сан-Томе и Принсипи',
      th: 'São Tomé and Príncipe',
      it: 'São Tomé e Príncipe'
    },
    permalinks: {
      en: 'sao-tome-and-principe',
      fr: 'sao-tome-et-principe',
      de: 'sao-tome-und-principe',
      es: 'santo-tome-y-principe',
      pt: 'sao-tome-e-principe',
      pl: 'wyspy-swietego-tomasza-i-ksiazeca',
      'pt-BR': 'sao-tome-e-principe',
      sv: 'sao-tome-och-principe',
      ru: '',
      it: 'sao-tome-e-principe'
    },
    hasRegion: false,
    ins: { fr: 'à' }
  },
  SUR: {
    iso2: 'sr',
    old_id: 459,
    subContinent: null,
    continent: 2,
    names: {
      en: 'Suriname',
      fr: 'Surinam',
      de: 'Suriname',
      es: 'Surinám',
      pt: 'Suriname',
      pl: 'Surinam',
      'pt-BR': 'Suriname',
      sv: 'Surinam',
      zh: '苏里南',
      ko: '수리남',
      ja: 'スリナム',
      ru: 'Суринам',
      th: 'ประเทศซูรินาเม',
      it: 'Suriname'
    },
    permalinks: {
      en: 'suriname',
      fr: 'surinam',
      de: 'suriname',
      es: 'surinam',
      pt: 'suriname',
      pl: 'surinam',
      'pt-BR': 'suriname',
      sv: 'surinam',
      ru: '',
      it: 'suriname'
    },
    hasRegion: false,
    ins: { fr: 'au' }
  },
  SVK: {
    iso2: 'sk',
    old_id: 80,
    subContinent: 151,
    continent: 1,
    names: {
      en: 'Slovakia',
      fr: 'Slovaquie',
      de: 'Slowakei',
      es: 'Eslovaquia',
      pt: 'Eslováquia',
      pl: 'Słowacja',
      'pt-BR': 'Eslováquia',
      sv: 'Slovakien',
      zh: '斯洛伐克',
      ko: '슬로바키아',
      ja: 'スロバキア',
      ru: 'Словакия',
      th: 'ประเทศสโลวาเกีย',
      it: 'Slovacchia'
    },
    permalinks: {
      en: 'slovakia',
      fr: 'slovaquie',
      de: 'slowakei',
      es: 'eslovaquia',
      pt: 'eslovaquia',
      pl: 'slowacja',
      'pt-BR': 'eslovaquia',
      sv: 'slovakien',
      ru: '',
      it: 'slovacchia'
    },
    hasRegion: false,
    ins: {}
  },
  SVN: {
    iso2: 'si',
    old_id: 81,
    subContinent: 39,
    continent: 1,
    names: {
      en: 'Slovenia',
      fr: 'Slovenie',
      de: 'Slowenien',
      es: 'Eslovenia',
      pt: 'Eslovénia',
      pl: 'Słowenia',
      'pt-BR': 'Eslovénia',
      sv: 'Slovenien',
      zh: '斯洛文尼亚',
      ko: '슬로베니아',
      ja: 'スロベニア',
      ru: 'Словения',
      th: 'ประเทศสโลวีเนีย',
      it: 'Slovenia'
    },
    permalinks: {
      en: 'slovenia',
      fr: 'slovenie',
      de: 'slowenien',
      es: 'eslovenia',
      pt: 'eslovenia',
      pl: 'slowenia',
      'pt-BR': 'eslovenia',
      sv: 'slovenien',
      ru: '',
      it: 'slovenia'
    },
    hasRegion: false,
    ins: {}
  },
  SWE: {
    iso2: 'se',
    old_id: 84,
    subContinent: 154,
    continent: 1,
    names: {
      en: 'Sweden',
      fr: 'Suède',
      de: 'Schweden',
      es: 'Suecia',
      pt: 'Suécia',
      pl: 'Szwecja',
      'pt-BR': 'Suécia',
      sv: 'Sverige',
      zh: '瑞典',
      ko: '스웨덴',
      ja: 'スウェーデン',
      ru: 'Швеция',
      th: 'ประเทศสวีเดน',
      it: 'Svezia'
    },
    permalinks: {
      en: 'sweden',
      fr: 'suede',
      de: 'schweden',
      es: 'suecia',
      pt: 'suecia',
      pl: 'szwecja',
      'pt-BR': 'suecia',
      sv: 'sverige',
      ru: '',
      it: 'svezia'
    },
    hasRegion: false,
    ins: {}
  },
  SWZ: {
    iso2: 'sz',
    old_id: 243,
    subContinent: 18,
    continent: 7,
    names: {
      en: 'Swaziland',
      fr: 'Swaziland',
      de: 'Swasiland',
      es: 'Swazilandia',
      pt: 'Suazilândia',
      pl: 'Eswatini',
      'pt-BR': 'Essuatíni',
      sv: 'Swaziland',
      zh: '斯威士兰',
      ko: '스와질란드',
      ja: 'スワジランド',
      ru: 'Свазиленд',
      th: 'ประเทศสวาซิแลนด์',
      it: 'Swaziland'
    },
    permalinks: {
      en: 'swaziland',
      fr: 'swaziland',
      de: 'swasiland',
      es: 'swazilandia',
      pt: 'suazilandia',
      pl: 'eswatini',
      'pt-BR': 'essuatini',
      sv: 'swaziland',
      ru: '',
      it: 'swaziland'
    },
    hasRegion: false,
    ins: { fr: 'au' }
  },
  SXM: {
    iso2: 'sx',
    old_id: 253,
    subContinent: 29,
    continent: 3,
    names: {
      en: 'Sint Maarten',
      fr: 'Saint-Martin (Royaume des Pays-Bas)',
      de: 'Saint-Martin (Niederländischer Teil)',
      es: 'Isla de San Martín (zona holandsea)',
      pt: 'São Martinho (Países Baixos)',
      pl: 'Sint Maarten',
      'pt-BR': 'São Martinho',
      sv: 'Sint Maarten (nederländska delen)',
      zh: '荷属圣马丁',
      ko: '신트마르턴',
      ja: 'Sint Maarten',
      ru: 'Синт-Мартен (голландская часть)',
      th: 'Sint Maarten',
      it: 'Sint Maarten (parte olandese)'
    },
    permalinks: {
      en: 'sint-maarten',
      fr: 'saint-martin-royaume-des-pays-bas',
      de: 'saint-martin-niederlandischer-teil',
      es: 'isla-de-san-martin-zona-holandsea',
      pt: 'sao-martinho-paises-baixos',
      pl: 'sint-maarten',
      'pt-BR': 'sao-martinho',
      sv: 'sint-maarten-nederlandska-delen',
      ru: '',
      it: 'sint-maarten-parte-olandese'
    },
    hasRegion: false,
    ins: { fr: 'à' }
  },
  SYC: {
    iso2: 'sc',
    old_id: 248,
    subContinent: 14,
    continent: 7,
    names: {
      en: 'Seychelles',
      fr: 'Seychelles',
      de: 'Seychellen',
      es: 'Seychelles',
      pt: 'Seychelles',
      pl: 'Seszele',
      'pt-BR': 'Seychelles',
      sv: 'Seychellerna',
      zh: '塞舌尔',
      ko: '세이셸',
      ja: 'セーシェル',
      ru: 'Сейшельские Острова',
      th: 'ประเทศเซเชลส์',
      it: 'Seicelle'
    },
    permalinks: {
      en: 'seychelles',
      fr: 'seychelles',
      de: 'seychellen',
      es: 'seychelles',
      pt: 'seychelles',
      pl: 'seszele',
      'pt-BR': 'seychelles',
      sv: 'seychellerna',
      ru: '',
      it: 'seicelle'
    },
    hasRegion: false,
    ins: { en: 'in the', fr: 'aux' }
  },
  SYR: {
    iso2: 'sy',
    old_id: 242,
    subContinent: 145,
    continent: 4,
    names: {
      en: 'Syria',
      fr: 'Syrie',
      de: 'Syrien, Arabische Republik',
      es: 'República árabe de Siria',
      pt: 'República Árabe Síria',
      pl: 'Syria',
      'pt-BR': 'Síria',
      sv: 'Syriska arabrepubliken',
      zh: '叙利亚',
      ko: '시리아',
      ja: 'シリア',
      ru: 'Сирийская Арабская Республика',
      th: 'ประเทศซีเรีย',
      it: 'Siria'
    },
    permalinks: {
      en: 'syria',
      fr: 'syrie',
      de: 'syrien-arabische-republik',
      es: 'republica-arabe-de-siria',
      pt: 'republica-arabe-siria',
      pl: 'syria',
      'pt-BR': 'siria',
      sv: 'syriska-arabrepubliken',
      ru: '',
      it: 'siria'
    },
    hasRegion: false,
    ins: {}
  },
  TCA: {
    iso2: 'tc',
    old_id: 236,
    subContinent: 29,
    continent: 3,
    names: {
      en: 'Turks and Caicos Islands',
      fr: 'Îles Turques-et-Caïques',
      de: 'Turks- und Caicosinseln',
      es: 'Turks y Caicos, Islas',
      pt: 'Ilhas Turks e Caicos',
      pl: 'Turks i Caicos',
      'pt-BR': 'Turks e Caicos',
      sv: 'Turks- och Caicosöarna',
      zh: '特克斯和凯科斯群岛',
      ko: '터크스 케이커스 제도',
      ja: 'タークス・カイコス諸島',
      ru: 'Острова Туркс и Каикос',
      th: 'หมู่เกาะเติร์กและไคคอส',
      it: 'Isole Turks e Caicos'
    },
    permalinks: {
      en: 'turks-and-caicos-islands',
      fr: 'iles-turques-et-caiques',
      de: 'turks-und-caicosinseln',
      es: 'turks-y-caicos-islas',
      pt: 'ilhas-turks-e-caicos',
      pl: 'turks-i-caicos',
      'pt-BR': 'turks-e-caicos',
      sv: 'turks-och-caicosoarna',
      ru: '',
      it: 'isole-turks-e-caicos'
    },
    hasRegion: false,
    ins: { en: 'in the', fr: 'aux' }
  },
  TCD: {
    iso2: 'td',
    old_id: 185,
    subContinent: 17,
    continent: 7,
    names: {
      en: 'Chad',
      fr: 'Tchad',
      de: 'Tschad',
      es: 'Chad',
      pt: 'Chade',
      pl: 'Republika Środkowoafrykańska',
      'pt-BR': 'Chade',
      sv: 'Tchad',
      zh: '乍得',
      ko: '차드',
      ja: 'チャド',
      ru: 'Чад',
      th: 'ประเทศชาด',
      it: 'Ciad'
    },
    permalinks: {
      en: 'chad',
      fr: 'tchad',
      de: 'tschad',
      es: 'chad',
      pt: 'chade',
      pl: 'republika-srodkowoafrykanska',
      'pt-BR': 'chade',
      sv: 'tchad',
      ru: '',
      it: 'ciad'
    },
    hasRegion: false,
    ins: { fr: 'au' }
  },
  TGO: {
    iso2: 'tg',
    old_id: 240,
    subContinent: 11,
    continent: 7,
    names: {
      en: 'Togo',
      fr: 'Togo',
      de: 'Togo',
      es: 'Togo',
      pt: 'Togo',
      pl: 'Togo',
      'pt-BR': 'Togo',
      sv: 'Togo',
      zh: '多哥',
      ko: '토고',
      ja: 'トーゴ',
      ru: 'Того',
      th: 'ประเทศโตโก',
      it: 'Togo'
    },
    permalinks: {
      en: 'togo',
      fr: 'togo',
      de: 'togo',
      es: 'togo',
      pt: 'togo',
      pl: 'togo',
      'pt-BR': 'togo',
      sv: 'togo',
      ru: '',
      it: 'togo'
    },
    hasRegion: false,
    ins: { fr: 'au' }
  },
  THA: {
    iso2: 'th',
    old_id: 88,
    subContinent: 35,
    continent: 4,
    names: {
      en: 'Thailand',
      fr: 'Thailande',
      de: 'Thailand',
      es: 'Tailandia',
      pt: 'Tailândia',
      pl: 'Tajlandia',
      'pt-BR': 'Tailândia',
      sv: 'Thailand',
      zh: '泰国',
      ko: '태국',
      ja: 'タイ王国',
      ru: 'Таиланд',
      th: 'ประเทศไทย',
      it: 'Thailandia'
    },
    permalinks: {
      en: 'thailand',
      fr: 'thailande',
      de: 'thailand',
      es: 'tailandia',
      pt: 'tailandia',
      pl: 'tajlandia',
      'pt-BR': 'tailandia',
      sv: 'thailand',
      ru: '',
      it: 'thailandia'
    },
    hasRegion: false,
    ins: {}
  },
  TJK: {
    iso2: 'tj',
    old_id: 241,
    subContinent: 143,
    continent: 4,
    names: {
      en: 'Tajikistan',
      fr: 'Tajikistan',
      de: 'Tadschikistan',
      es: 'Tadjikistán',
      pt: 'Tadjaquistão',
      pl: 'Tadżykistan',
      'pt-BR': 'Tajiquistão',
      sv: 'Tadzjikistan',
      zh: '塔吉克斯坦',
      ko: '타지키스탄',
      ja: 'タジキスタン',
      ru: 'Таджикистан',
      th: 'ประเทศทาจิกิสถาน',
      it: 'Tagikistan'
    },
    permalinks: {
      en: 'tajikistan',
      fr: 'tajikistan',
      de: 'tadschikistan',
      es: 'tadjikistan',
      pt: 'tadjaquistao',
      pl: 'tadzykistan',
      'pt-BR': 'tajiquistao',
      sv: 'tadzjikistan',
      ru: '',
      it: 'tagikistan'
    },
    hasRegion: false,
    ins: { fr: 'au' }
  },
  TKL: {
    iso2: 'tk',
    old_id: 239,
    subContinent: 61,
    continent: 6,
    names: {
      en: 'Tokelau',
      fr: 'Tokelau',
      de: 'Tokelau',
      es: 'Tokelau',
      pt: 'Tokelau',
      pl: 'Tokelau',
      'pt-BR': 'Toquelau',
      sv: 'Tokelau',
      zh: '托克劳群岛',
      ko: '토켈라우',
      ja: 'トケラウ',
      ru: 'Токелау',
      th: 'โตเกเลา',
      it: 'Tokelau'
    },
    permalinks: {
      en: 'tokelau',
      fr: 'tokelau',
      de: 'tokelau',
      es: 'tokelau',
      pt: 'tokelau',
      pl: 'tokelau',
      'pt-BR': 'toquelau',
      sv: 'tokelau',
      ru: '',
      it: 'tokelau'
    },
    hasRegion: false,
    ins: { fr: 'au' }
  },
  TKM: {
    iso2: 'tm',
    old_id: 237,
    subContinent: 143,
    continent: 4,
    names: {
      en: 'Turkmenistan',
      fr: 'Turkmenistan',
      de: 'Turkmenistan',
      es: 'Turkmenistán',
      pt: 'Turcomenistão',
      pl: 'Turkmenistan',
      'pt-BR': 'Turquemenistão',
      sv: 'Turkmenistan',
      zh: '土库曼斯坦',
      ko: '투르크메니스탄',
      ja: 'トルクメニスタン',
      ru: 'Туркменистан',
      th: 'ประเทศเติร์กเมนิสถาน',
      it: 'Turkmenistan'
    },
    permalinks: {
      en: 'turkmenistan',
      fr: 'turkmenistan',
      de: 'turkmenistan',
      es: 'turkmenistan',
      pt: 'turcomenistao',
      pl: 'turkmenistan',
      'pt-BR': 'turquemenistao',
      sv: 'turkmenistan',
      ru: '',
      it: 'turkmenistan'
    },
    hasRegion: false,
    ins: { fr: 'au' }
  },
  TLS: {
    iso2: 'tl',
    old_id: 128,
    subContinent: 35,
    continent: 4,
    names: {
      en: 'East Timor',
      fr: 'Timor oriental',
      de: 'Timor-Leste',
      es: 'Timor Oriental',
      pt: 'Timor-Leste',
      pl: 'Timor Wschodni',
      'pt-BR': 'Timor-Leste',
      sv: 'Östtimor',
      zh: '东帝汶',
      ko: '동티모르',
      ja: '東ティモール',
      ru: 'Восточный Тимор',
      th: 'ประเทศติมอร์-เลสเต',
      it: 'Timor orientale'
    },
    permalinks: {
      en: 'east-timor',
      fr: 'timor-oriental',
      de: 'timor-leste',
      es: 'timor-oriental',
      pt: 'timor-leste',
      pl: 'timor-wschodni',
      'pt-BR': 'timor-leste',
      sv: 'osttimor',
      ru: '',
      it: 'timor-orientale'
    },
    hasRegion: false,
    ins: { fr: 'au' }
  },
  TON: {
    iso2: 'to',
    old_id: 238,
    subContinent: 61,
    continent: 6,
    names: {
      en: 'Tonga',
      fr: 'Tonga',
      de: 'Tonga',
      es: 'Tonga',
      pt: 'Tonga',
      pl: 'Tonga',
      'pt-BR': 'Tonga',
      sv: 'Tonga',
      zh: '汤加',
      ko: '통가',
      ja: 'トンガ',
      ru: 'Тонга',
      th: 'ประเทศตองกา',
      it: 'Tonga'
    },
    permalinks: {
      en: 'tonga',
      fr: 'tonga',
      de: 'tonga',
      es: 'tonga',
      pt: 'tonga',
      pl: 'tonga',
      'pt-BR': 'tonga',
      sv: 'tonga',
      ru: '',
      it: 'tonga'
    },
    hasRegion: false,
    ins: { fr: 'au' }
  },
  TTO: {
    iso2: 'tt',
    old_id: 89,
    subContinent: 29,
    continent: 3,
    names: {
      en: 'Trinidad and Tobago',
      fr: 'Trinidad et Tobago',
      de: 'Trinidad und Tobago',
      es: 'Trinidad y Tobago',
      pt: 'Trinidade e Tobago',
      pl: 'Trynidad i Tobago',
      'pt-BR': 'Trinidad e Tobago',
      sv: 'Trinidad och Tobago',
      zh: '特里尼达和多巴哥',
      ko: '트리니다드 토바고',
      ja: 'トリニダード・トバゴ',
      ru: 'Тринидад и Тобаго',
      th: 'ประเทศตรินิแดดและโตเบโก',
      it: 'Trinidad e Tobago'
    },
    permalinks: {
      en: 'trinidad-and-tobago',
      fr: 'trinidad-et-tobago',
      de: 'trinidad-und-tobago',
      es: 'trinidad-y-tobago',
      pt: 'trinidade-e-tobago',
      pl: 'trynidad-i-tobago',
      'pt-BR': 'trinidad-e-tobago',
      sv: 'trinidad-och-tobago',
      ru: '',
      it: 'trinidad-e-tobago'
    },
    hasRegion: false,
    ins: { fr: 'à' }
  },
  TUN: {
    iso2: 'tn',
    old_id: 90,
    subContinent: 15,
    continent: 7,
    names: {
      en: 'Tunisia',
      fr: 'Tunisie',
      de: 'Tunesien',
      es: 'Tunez',
      pt: 'Tunísia',
      pl: 'Tunezja',
      'pt-BR': 'Tunísia',
      sv: 'Tunisien',
      zh: '突尼斯',
      ko: '튀니지',
      ja: 'チュニジア',
      ru: 'Тунис',
      th: 'ประเทศตูนิเซีย',
      it: 'Tunisia'
    },
    permalinks: {
      en: 'tunisia',
      fr: 'tunisie',
      de: 'tunesien',
      es: 'tunez',
      pt: 'tunisia',
      pl: 'tunezja',
      'pt-BR': 'tunisia',
      sv: 'tunisien',
      ru: '',
      it: 'tunisia'
    },
    hasRegion: false,
    ins: {}
  },
  TUR: {
    iso2: 'tr',
    old_id: 91,
    subContinent: 145,
    continent: 4,
    names: {
      en: 'Turkey',
      fr: 'Turquie',
      de: 'Türkei',
      es: 'Turquía',
      pt: 'Turquia',
      pl: 'Turcja',
      'pt-BR': 'Turquia',
      sv: 'Turkiet',
      zh: '土耳其',
      ko: '튀르키예',
      ja: 'トルコ',
      ru: 'Турция',
      th: 'ประเทศตุรกี',
      it: 'Turchia'
    },
    permalinks: {
      en: 'turkey',
      fr: 'turquie',
      de: 'turkei',
      es: 'turquia',
      pt: 'turquia',
      pl: 'turcja',
      'pt-BR': 'turquia',
      sv: 'turkiet',
      ru: '',
      it: 'turchia'
    },
    hasRegion: false,
    ins: {}
  },
  TUV: {
    iso2: 'tv',
    old_id: 235,
    subContinent: 61,
    continent: 6,
    names: {
      en: 'Tuvalu',
      fr: 'Tuvalu',
      de: 'Tuvalu',
      es: 'Tuvalu',
      pt: 'Tuvalu',
      pl: 'Tuvalu',
      'pt-BR': 'Tuvalu',
      sv: 'Tuvalu',
      zh: '图瓦鲁',
      ko: '투발루',
      ja: 'ツバル',
      ru: 'Тувалу',
      th: 'ประเทศตูวาลู',
      it: 'Tuvalu'
    },
    permalinks: {
      en: 'tuvalu',
      fr: 'tuvalu',
      de: 'tuvalu',
      es: 'tuvalu',
      pt: 'tuvalu',
      pl: 'tuvalu',
      'pt-BR': 'tuvalu',
      sv: 'tuvalu',
      ru: '',
      it: 'tuvalu'
    },
    hasRegion: false,
    ins: {}
  },
  TWN: {
    iso2: 'tw',
    old_id: 86,
    subContinent: 30,
    continent: 4,
    names: {
      en: 'Taiwan',
      fr: 'Taiwan',
      de: 'Taiwan',
      es: 'Taiwán',
      pt: 'Taiwan',
      pl: 'Tajwan',
      'pt-BR': 'Taiwan',
      sv: 'Taiwan',
      zh: '台湾',
      ko: '대만',
      ja: '台湾',
      ru: 'Тайвань',
      th: 'ไต้หวัน',
      it: 'Taiwan'
    },
    permalinks: {
      en: 'taiwan',
      fr: 'taiwan',
      de: 'taiwan',
      es: 'taiwan',
      pt: 'taiwan',
      pl: 'tajwan',
      'pt-BR': 'taiwan',
      sv: 'taiwan',
      ru: '',
      it: 'taiwan'
    },
    hasRegion: false,
    ins: { fr: 'à' }
  },
  TZA: {
    iso2: 'tz',
    old_id: 87,
    subContinent: 14,
    continent: 7,
    names: {
      en: 'Tanzania',
      fr: 'Tanzanie',
      de: 'Tansania, Vereinigte Republik',
      es: 'Tanzania, República unida de',
      pt: 'Tanzânia, República Unida da',
      pl: 'Tanzania',
      'pt-BR': 'Tanzânia',
      sv: 'Tanzania, förenade republiken',
      zh: '坦桑尼亚',
      ko: '탄자니아',
      ja: 'タンザニア',
      ru: 'Танзания',
      th: 'ประเทศแทนซาเนีย',
      it: 'Tanzania'
    },
    permalinks: {
      en: 'tanzania',
      fr: 'tanzanie',
      de: 'tansania-vereinigte-republik',
      es: 'tanzania-republica-unida-de',
      pt: 'tanzania-republica-unida-da',
      pl: 'tanzania',
      'pt-BR': 'tanzania',
      sv: 'tanzania-forenade-republiken',
      ru: '',
      it: 'tanzania'
    },
    hasRegion: false,
    ins: {}
  },
  UGA: {
    iso2: 'ug',
    old_id: 92,
    subContinent: 14,
    continent: 7,
    names: {
      en: 'Uganda',
      fr: 'Ouganda',
      de: 'Uganda',
      es: 'Uganda',
      pt: 'Uganda',
      pl: 'Uganda',
      'pt-BR': 'Uganda',
      sv: 'Uganda',
      zh: '乌干达',
      ko: '우간다',
      ja: 'ウガンダ',
      ru: 'Уганда',
      th: 'ประเทศยูกันดา',
      it: 'Uganda'
    },
    permalinks: {
      en: 'uganda',
      fr: 'ouganda',
      de: 'uganda',
      es: 'uganda',
      pt: 'uganda',
      pl: 'uganda',
      'pt-BR': 'uganda',
      sv: 'uganda',
      ru: '',
      it: 'uganda'
    },
    hasRegion: false,
    ins: {}
  },
  UKR: {
    iso2: 'ua',
    old_id: 147,
    subContinent: 151,
    continent: 1,
    names: {
      en: 'Ukraine',
      fr: 'Ukraine',
      de: 'Ukraine',
      es: 'Ucrania',
      pt: 'Ucrânia',
      pl: 'Ukraina',
      'pt-BR': 'Ucrânia',
      sv: 'Ukraina',
      zh: '乌克兰',
      ko: '우크라이나',
      ja: 'ウクライナ',
      ru: 'Украина',
      th: 'ประเทศยูเครน',
      it: 'Ucraina'
    },
    permalinks: {
      en: 'ukraine',
      fr: 'ukraine',
      de: 'ukraine',
      es: 'ucrania',
      pt: 'ucrania',
      pl: 'ukraina',
      'pt-BR': 'ucrania',
      sv: 'ukraina',
      ru: '',
      it: 'ucraina'
    },
    hasRegion: false,
    ins: {}
  },
  UMI: {
    iso2: 'um',
    old_id: 234,
    subContinent: 57,
    continent: 6,
    names: {
      en: 'United States Minor Outlying Islands',
      fr: 'Îles mineures éloignées des États-Unis',
      de: 'United States Minor Outlying Islands',
      es: 'Islas menores exteriores de Estados Unidos',
      pt: 'Ilhas Menores Exteriores dos Estados Unidos',
      pl: 'Dalekie Wyspy Mniejsze Stanów Zjednoczonych',
      'pt-BR': 'Ilhas Menores Distantes dos Estados Unidos',
      sv: 'USA:s avlägsna mindre öar',
      zh: '美国本土外小岛屿',
      ko: 'United States Minor Outlying Islands',
      ja: 'United States Minor Outlying Islands',
      ru: 'Соединенные штаты Малых  Удаленных островов',
      th: 'United States Minor Outlying Islands',
      it: 'Isole minori statunitensi'
    },
    permalinks: {
      en: 'united-states-minor-outlying-islands',
      fr: 'iles-mineures-eloignees-des-etats-unis',
      de: 'united-states-minor-outlying-islands',
      es: 'islas-menores-exteriores-de-estados-unidos',
      pt: 'ilhas-menores-exteriores-dos-estados-unidos',
      pl: 'dalekie-wyspy-mniejsze-stanow-zjednoczonych',
      'pt-BR': 'ilhas-menores-distantes-dos-estados-unidos',
      sv: 'usa-s-avlagsna-mindre-oar',
      ru: '',
      it: 'isole-minori-statunitensi'
    },
    hasRegion: false,
    ins: { en: 'in the ', fr: 'aux' }
  },
  URY: {
    iso2: 'uy',
    old_id: 98,
    subContinent: null,
    continent: 2,
    names: {
      en: 'Uruguay',
      fr: 'Uruguay',
      de: 'Uruguay',
      es: 'Uruguay',
      pt: 'Uruguai',
      pl: 'Urugwaj',
      'pt-BR': 'Uruguai',
      sv: 'Uruguay',
      zh: '乌拉圭',
      ko: '우루과이',
      ja: 'ウルグアイ',
      ru: 'Уругвай',
      th: 'ประเทศอุรุกวัย',
      it: 'Uruguay'
    },
    permalinks: {
      en: 'uruguay',
      fr: 'uruguay',
      de: 'uruguay',
      es: 'uruguay',
      pt: 'uruguai',
      pl: 'urugwaj',
      'pt-BR': 'uruguai',
      sv: 'uruguay',
      ru: '',
      it: 'uruguay'
    },
    hasRegion: false,
    ins: {}
  },
  USA: {
    iso2: 'us',
    old_id: 95,
    subContinent: null,
    continent: 3,
    names: {
      en: 'USA',
      fr: 'USA',
      de: 'Vereinigte Staaten',
      es: 'Estados Unidos',
      pt: 'Estados Unidos',
      pl: 'Stany Zjednoczone',
      'pt-BR': 'Estados Unidos da América',
      sv: 'USA',
      zh: '美国',
      ko: '미국',
      ja: 'アメリカ合衆国',
      ru: 'Соединённые штаты',
      th: 'สหรัฐอเมริกา',
      it: 'Stati Uniti'
    },
    permalinks: {
      en: 'usa',
      fr: 'usa',
      de: 'vereinigte-staaten',
      es: 'estados-unidos',
      pt: 'estados-unidos',
      pl: 'stany-zjednoczone',
      'pt-BR': 'estados-unidos-da-america',
      sv: 'usa',
      ru: '',
      it: 'stati-uniti'
    },
    hasRegion: true,
    ins: { en: 'in the ', fr: 'aux' }
  },
  UZB: {
    iso2: 'uz',
    old_id: 233,
    subContinent: 143,
    continent: 4,
    names: {
      en: 'Uzbekistan',
      fr: 'Uzbekistan',
      de: 'Usbekistan',
      es: 'Uzbekistán',
      pt: 'Uzbequistão',
      pl: 'Uzbekistan',
      'pt-BR': 'Uzbequistão',
      sv: 'Uzbekistan',
      zh: '乌兹别克斯坦',
      ko: '우즈베키스탄',
      ja: 'ウズベキスタン',
      ru: 'Узбекистан',
      th: 'ประเทศอุซเบกิสถาน',
      it: 'Uzbekistan'
    },
    permalinks: {
      en: 'uzbekistan',
      fr: 'uzbekistan',
      de: 'usbekistan',
      es: 'uzbekistan',
      pt: 'uzbequistao',
      pl: 'uzbekistan',
      'pt-BR': 'uzbequistao',
      sv: 'uzbekistan',
      ru: '',
      it: 'uzbekistan'
    },
    hasRegion: false,
    ins: {}
  },
  VAT: {
    iso2: 'va',
    old_id: 202,
    subContinent: 39,
    continent: 1,
    names: {
      en: 'Vatican',
      fr: 'Vatican',
      de: 'Heiliger Stuhl (Staat Vatikanstadt)',
      es: 'Santa Sede (Ciudad Estado del Vaticano)',
      pt: 'Santa Sé (Estado Cidade do Vaticano)',
      pl: 'Watykan',
      'pt-BR': 'Vaticano',
      sv: 'Vatikanstaten',
      zh: '梵地冈',
      ko: '바티칸 시국',
      ja: 'バチカン',
      ru: 'Государство-город Ватикан',
      th: 'นครรัฐวาติกัน',
      it: 'Santa Sede (Stato della Città del Vaticano)'
    },
    permalinks: {
      en: 'vatican',
      fr: 'vatican',
      de: 'heiliger-stuhl-staat-vatikanstadt',
      es: 'santa-sede-ciudad-estado-del-vaticano',
      pt: 'santa-se-estado-cidade-do-vaticano',
      pl: 'watykan',
      'pt-BR': 'vaticano',
      sv: 'vatikanstaten',
      ru: '',
      it: 'santa-sede-stato-della-citta-del-vaticano'
    },
    hasRegion: false,
    ins: { fr: 'au' }
  },
  VCT: {
    iso2: 'vc',
    old_id: 251,
    subContinent: 29,
    continent: 3,
    names: {
      en: 'Saint Vincent and the Grenadines',
      fr: 'Saint-Vincent-et-les-Grenadines',
      de: 'St. Vincent und die Grenadinen',
      es: 'San Vicente y las Granadinas',
      pt: 'São Vicente e Grenadinas',
      pl: 'Saint Vincent i Grenadyny',
      'pt-BR': 'São Vicente e Granadinas',
      sv: 'Sankt Vincent och Grenadinerna',
      zh: '圣文森特和格林纳丁斯',
      ko: '세인트빈센트그레나딘',
      ja: 'セントビンセント・グレナディーン諸島',
      ru: 'Сент-Винсент и Гренадины',
      th: 'ประเทศเซนต์วินเซนต์และเกรนาดีนส์',
      it: 'Saint Vincent e Grenadine'
    },
    permalinks: {
      en: 'saint-vincent-and-the-grenadines',
      fr: 'saint-vincent-et-les-grenadines',
      de: 'st-vincent-und-die-grenadinen',
      es: 'san-vicente-y-las-granadinas',
      pt: 'sao-vicente-e-grenadinas',
      pl: 'saint-vincent-i-grenadyny',
      'pt-BR': 'sao-vicente-e-granadinas',
      sv: 'sankt-vincent-och-grenadinerna',
      ru: '',
      it: 'saint-vincent-e-grenadine'
    },
    hasRegion: false,
    ins: { fr: 'à' }
  },
  VEN: {
    iso2: 've',
    old_id: 149,
    subContinent: null,
    continent: 2,
    names: {
      en: 'Venezuela',
      fr: 'Venezuela',
      de: 'Venezuela, Bolivarische Republik',
      es: 'Venezuela, República Bolivariana de',
      pt: 'Venezuela, República Bolivariana da',
      pl: 'Wenezuela',
      'pt-BR': 'Venezuela',
      sv: 'Venezuela, Bolivarianska republiken',
      zh: '委内瑞拉玻利瓦尔共和国',
      ko: '베네수엘라',
      ja: 'ベネズエラ',
      ru: 'Боливарианская Республика Венесуэла',
      th: 'ประเทศเวเนซุเอลา',
      it: 'Venezuela, Repubblica bolivariana'
    },
    permalinks: {
      en: 'venezuela',
      fr: 'venezuela',
      de: 'venezuela-bolivarische-republik',
      es: 'venezuela-republica-bolivariana-de',
      pt: 'venezuela-republica-bolivariana-da',
      pl: 'wenezuela',
      'pt-BR': 'venezuela',
      sv: 'venezuela-bolivarianska-republiken',
      ru: '',
      it: 'venezuela-repubblica-bolivariana'
    },
    hasRegion: false,
    ins: { fr: 'au' }
  },
  VGB: {
    iso2: 'vg',
    old_id: 232,
    subContinent: 29,
    continent: 3,
    names: {
      en: 'British Virgin Islands',
      fr: 'Îles Vierges britanniques',
      de: 'Britische Jungferninseln',
      es: 'Islas Vírgenes, Británicas',
      pt: 'Ilhas Virgens, Britânicas',
      pl: 'Brytyjskie Wyspy Dziewicze',
      'pt-BR': 'Ilhas Virgens Britânicas',
      sv: 'Jungfruöarna, brittiska',
      zh: '英属维京群岛',
      ko: '영국령 버진아일랜드',
      ja: '英領ヴァージン諸島',
      ru: 'Виргинские острова (Британия)',
      th: 'British Virgin Islands',
      it: 'Isole Vergini britanniche'
    },
    permalinks: {
      en: 'british-virgin-islands',
      fr: 'iles-vierges-britanniques',
      de: 'britische-jungferninseln',
      es: 'islas-virgenes-britanicas',
      pt: 'ilhas-virgens-britanicas',
      pl: 'brytyjskie-wyspy-dziewicze',
      'pt-BR': 'ilhas-virgens-britanicas',
      sv: 'jungfruoarna-brittiska',
      ru: '',
      it: 'isole-vergini-britanniche'
    },
    hasRegion: false,
    ins: { en: 'in the ', fr: 'dans les' }
  },
  VIR: {
    iso2: 'vi',
    old_id: 96,
    subContinent: 29,
    continent: 3,
    names: {
      en: 'Virgin Islands (USA)',
      fr: 'Iles Vierges (US)',
      de: 'Amerikanische Jungferninseln',
      es: 'Islas Vírgenes, de EEUU',
      pt: 'Ilhas Virgens, Estados Unidos',
      pl: 'Wyspy Dziewicze Stanów Zjednoczonych',
      'pt-BR': 'Ilhas Virgens dos Estados Unidos',
      sv: 'Jungfruöarna, amerikanska',
      zh: '美属维京群岛',
      ko: '미국령 버진아일랜드',
      ja: 'Virgin Islands (USA)',
      ru: 'Виргинские острова (США)',
      th: 'Virgin Islands (USA)',
      it: 'Isole Vergini, U.S.'
    },
    permalinks: {
      en: 'virgin-islands-usa',
      fr: 'iles-vierges-us',
      de: 'amerikanische-jungferninseln',
      es: 'islas-virgenes-de-eeuu',
      pt: 'ilhas-virgens-estados-unidos',
      pl: 'wyspy-dziewicze-stanow-zjednoczonych',
      'pt-BR': 'ilhas-virgens-dos-estados-unidos',
      sv: 'jungfruoarna-amerikanska',
      ru: '',
      it: 'isole-vergini-u-s'
    },
    hasRegion: false,
    ins: { en: 'in the ', fr: 'aux' }
  },
  VNM: {
    iso2: 'vn',
    old_id: 166,
    subContinent: 35,
    continent: 4,
    names: {
      en: 'Vietnam',
      fr: 'Viêt Nam',
      de: 'Vietnam',
      es: 'Vietnam',
      pt: 'Vietname',
      pl: 'Wietnam',
      'pt-BR': 'Vietname',
      sv: 'Vietnam',
      zh: '越南',
      ko: '베트남',
      ja: 'ベトナム',
      ru: 'Вьетнам',
      th: 'ประเทศเวียดนาม',
      it: 'Vietnam'
    },
    permalinks: {
      en: 'vietnam',
      fr: 'viet-nam',
      de: 'vietnam',
      es: 'vietnam',
      pt: 'vietname',
      pl: 'wietnam',
      'pt-BR': 'vietname',
      sv: 'vietnam',
      ru: '',
      it: 'vietnam'
    },
    hasRegion: false,
    ins: { fr: 'au' }
  },
  VUT: {
    iso2: 'vu',
    old_id: 138,
    subContinent: 54,
    continent: 6,
    names: {
      en: 'Vanuatu',
      fr: 'Vanuatu',
      de: 'Vanuatu',
      es: 'Vanuatu',
      pt: 'Vanuatu',
      pl: 'Vanuatu',
      'pt-BR': 'Vanuatu',
      sv: 'Vanuatu',
      zh: '瓦努阿图',
      ko: '바누아투',
      ja: 'バヌアツ',
      ru: 'Вануату',
      th: 'ประเทศวานูอาตู',
      it: 'Vanuatu'
    },
    permalinks: {
      en: 'vanuatu',
      fr: 'vanuatu',
      de: 'vanuatu',
      es: 'vanuatu',
      pt: 'vanuatu',
      pl: 'vanuatu',
      'pt-BR': 'vanuatu',
      sv: 'vanuatu',
      ru: '',
      it: 'vanuatu'
    },
    hasRegion: false,
    ins: { fr: 'au' }
  },
  WLF: {
    iso2: 'wf',
    old_id: 231,
    subContinent: 61,
    continent: 6,
    names: {
      en: 'Wallis and Futuna',
      fr: 'Wallis et Futuna',
      de: 'Wallis und Futuna',
      es: 'Wallis y Futuna',
      pt: 'Wallis e Futuna',
      pl: 'Wallis i Futuna',
      'pt-BR': 'Wallis e Futuna',
      sv: 'Wallis och Futuna',
      zh: '瓦利斯和富图纳',
      ko: '왈리스 퓌튀나',
      ja: 'ウォリス・フツナ',
      ru: 'Уоллес и Футана',
      th: 'หมู่เกาะวาลลิสและหมู่เกาะฟุตูนา',
      it: 'Wallis e Futuna'
    },
    permalinks: {
      en: 'wallis-and-futuna',
      fr: 'wallis-et-futuna',
      de: 'wallis-und-futuna',
      es: 'wallis-y-futuna',
      pt: 'wallis-e-futuna',
      pl: 'wallis-i-futuna',
      'pt-BR': 'wallis-e-futuna',
      sv: 'wallis-och-futuna',
      ru: '',
      it: 'wallis-e-futuna'
    },
    hasRegion: false,
    ins: { fr: 'à ' }
  },
  WSM: {
    iso2: 'ws',
    old_id: 77,
    subContinent: 61,
    continent: 6,
    names: {
      en: 'Samoa',
      fr: 'Samoa',
      de: 'Samoa',
      es: 'Samoa',
      pt: 'Samoa',
      pl: 'Samoa',
      'pt-BR': 'Samoa',
      sv: 'Samoa',
      zh: '萨摩亚',
      ko: '사모아',
      ja: 'サモア',
      ru: 'Самоа',
      th: 'ประเทศซามัว',
      it: 'Samoa'
    },
    permalinks: {
      en: 'samoa',
      fr: 'samoa',
      de: 'samoa',
      es: 'samoa',
      pt: 'samoa',
      pl: 'samoa',
      'pt-BR': 'samoa',
      sv: 'samoa',
      ru: '',
      it: 'samoa'
    },
    hasRegion: false,
    ins: { fr: 'aux' }
  },
  XKX: {
    iso2: 'XK',
    old_id: null,
    subContinent: null,
    continent: 1,
    names: {
      en: 'Kosovo',
      fr: 'Kosovo',
      de: 'Kosovo',
      es: 'Kosovo',
      pt: 'Kosovo',
      pl: 'Kosovo',
      'pt-BR': 'Kosovo',
      sv: 'Kosovo',
      zh: 'Kosovo',
      ko: 'Kosovo',
      ja: 'Kosovo',
      ru: 'Kosovo',
      th: 'Kosovo',
      it: 'Kosovo'
    },
    permalinks: {
      en: 'kosovo',
      fr: 'kosovo',
      de: 'kosovo',
      es: 'kosovo',
      pt: 'kosovo',
      pl: 'kosovo',
      'pt-BR': 'kosovo',
      sv: 'kosovo',
      ru: 'kosovo',
      it: 'kosovo'
    },
    hasRegion: false,
    ins: {}
  },
  YEM: {
    iso2: 'ye',
    old_id: 230,
    subContinent: 145,
    continent: 4,
    names: {
      en: 'Yemen',
      fr: 'Yemen',
      de: 'Jemen',
      es: 'Yemen',
      pt: 'Yémen',
      pl: 'Jemen',
      'pt-BR': 'Iémen',
      sv: 'Yemen',
      zh: '也门',
      ko: '예멘',
      ja: 'イエメン',
      ru: 'Йемен',
      th: 'ประเทศเยเมน',
      it: 'Yemen'
    },
    permalinks: {
      en: 'yemen',
      fr: 'yemen',
      de: 'jemen',
      es: 'yemen',
      pt: 'yemen',
      pl: 'jemen',
      'pt-BR': 'iemen',
      sv: 'yemen',
      ru: '',
      it: 'yemen'
    },
    hasRegion: false,
    ins: { fr: 'au' }
  },
  ZAF: {
    iso2: 'za',
    old_id: 82,
    subContinent: 18,
    continent: 7,
    names: {
      en: 'South Africa',
      fr: 'Afrique du Sud',
      de: 'Südafrika',
      es: 'Suráfrica',
      pt: 'África do Sul',
      pl: 'Republika Południowej Afryki',
      'pt-BR': 'África do Sul',
      sv: 'Sydafrika',
      zh: '南非',
      ko: '남아프리카 공화국',
      ja: '南アフリカ',
      ru: 'Южная Африка',
      th: 'แอฟริกาใต้',
      it: 'Sud Africa'
    },
    permalinks: {
      en: 'south-africa',
      fr: 'afrique-du-sud',
      de: 'sudafrika',
      es: 'surafrica',
      pt: 'africa-do-sul',
      pl: 'republika-poludniowej-afryki',
      'pt-BR': 'africa-do-sul',
      sv: 'sydafrika',
      ru: '',
      it: 'sud-africa'
    },
    hasRegion: false,
    ins: {}
  },
  ZMB: {
    iso2: 'zm',
    old_id: 229,
    subContinent: 14,
    continent: 7,
    names: {
      en: 'Zambia',
      fr: 'Zambie',
      de: 'Sambia',
      es: 'Zambia',
      pt: 'Zâmbia',
      pl: 'Zambia',
      'pt-BR': 'Zâmbia',
      sv: 'Zambia',
      zh: '赞比亚',
      ko: '잠비아',
      ja: 'ザンビア',
      ru: 'Замбия',
      th: 'ประเทศแซมเบีย',
      it: 'Zambia'
    },
    permalinks: {
      en: 'zambia',
      fr: 'zambie',
      de: 'sambia',
      es: 'zambia',
      pt: 'zambia',
      pl: 'zambia',
      'pt-BR': 'zambia',
      sv: 'zambia',
      ru: '',
      it: 'zambia'
    },
    hasRegion: false,
    ins: {}
  },
  ZWE: {
    iso2: 'zw',
    old_id: 97,
    subContinent: 14,
    continent: 7,
    names: {
      en: 'Zimbabwe',
      fr: 'Zimbabwe',
      de: 'Simbabwe',
      es: 'Zimbabue',
      pt: 'Zimbabwe',
      pl: 'Zimbabwe',
      'pt-BR': 'Zimbabué',
      sv: 'Zimbabwe',
      zh: '津巴布韦',
      ko: '짐바브웨',
      ja: 'ジンバブエ',
      ru: 'Зимбабве',
      th: 'ประเทศซิมบับเว',
      it: 'Zimbabwe'
    },
    permalinks: {
      en: 'zimbabwe',
      fr: 'zimbabwe',
      de: 'simbabwe',
      es: 'zimbabue',
      pt: 'zimbabwe',
      pl: 'zimbabwe',
      'pt-BR': 'zimbabue',
      sv: 'zimbabwe',
      ru: '',
      it: 'zimbabwe'
    },
    hasRegion: false,
    ins: { fr: 'au' }
  },
  afg: {
    iso2: 'af',
    old_id: 139,
    subContinent: 34,
    continent: 4,
    names: {
      en: 'Afghanistan',
      fr: 'Afghanistan',
      de: 'Afghanistan',
      es: 'Afganistán',
      pt: 'Afeganistão',
      pl: 'Afganistan',
      'pt-BR': 'Afeganistão',
      sv: 'Afghanistan',
      zh: '阿富汗',
      ko: '아프가니스탄',
      ja: 'アフガニスタン',
      ru: 'Афганистан',
      th: 'ประเทศอัฟกานิสถาน',
      it: 'Afghanistan'
    },
    permalinks: {
      en: 'afghanistan',
      fr: 'afghanistan',
      de: 'afghanistan',
      es: 'afganistan',
      pt: 'afeganistao',
      pl: 'afganistan',
      'pt-BR': 'afeganistao',
      sv: 'afghanistan',
      ru: '',
      it: 'afghanistan'
    },
    hasRegion: false,
    ins: { en: '' }
  }
};
export default dict;
